import { Action } from 'redux';
import { call, takeLatest, put, delay } from 'redux-saga/effects';

import * as reduxActions from '../actions/menus';
import * as reduxTypes from '../types/menus';
import * as api from '../api/menus';
import { SearchQuery } from '../api';
import { IdAction, DataAction } from '../actions';
import { Menu } from '../api/apiTypes';

export function* listSaga(action: Action & SearchQuery) {
    try {
        yield delay(action.throttling || 0);
        const response = yield call(api.list, action);
        return yield put(reduxActions.listSuccess(response));
    } catch (error) {
        return yield put(reduxActions.listFailed(error));
    }
}

export function* detailsSaga(action: IdAction<Menu['id']>) {
    try {
        const response = yield call(api.details, action.id);
        return yield put(reduxActions.detailsSuccess(response));
    } catch (error) {
        return yield put(reduxActions.detailsFailed(error));
    }
}

export function* updateSaga(action: IdAction<Menu['id']> & DataAction<Partial<Menu>>) {
    try {
        const response = yield call(api.update, action.id, action.data);
        return yield put(reduxActions.updateSuccess(response));
    } catch (error) {
        return yield put(reduxActions.updateFailed(error));
    }
}

export default function* menusSaga() {
    yield takeLatest(reduxTypes.LIST, listSaga);
    yield takeLatest(reduxTypes.DETAILS, detailsSaga);
    yield takeLatest(reduxTypes.UPDATE, updateSaga);
}
