import React, { FC, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { ColumnProps } from 'antd/lib/table';
import { TabsProps } from 'antd/lib/tabs';
import { Spin, Descriptions, Button, Table, Tabs, Icon, Divider, Popconfirm, Switch } from 'antd';
// tslint:disable-next-line: no-implicit-dependencies
import { RouteChildrenProps } from 'react-router';

import { MainReducerState } from '../../store/reducers';
import * as RestaurantActions from '../../store/actions/restaurants';
import * as MealTypesActions from '../../store/actions/mealTypes';
import * as ConstraintsActions from '../../store/actions/constraints';
import * as TimeRangesActions from '../../store/actions/timeRanges';
import { RestaurantsState, getRestaurantDetailsState } from '../../store/reducers/restaurants';
import { ConstraintsState, removeConstraintState } from '../../store/reducers/constraints';
import { ParcsState, getSelectedParcState } from '../../store/reducers/parcs';
import { getMealTypesData } from '../../store/reducers/mealTypes';
import { getTimeRangesData } from '../../store/reducers/timeRanges';
import { DeliveryTimeConstraint, MealType } from '../../store/api/apiTypes';

import { RestaurantEdit, ConstraintEdit } from '.';

interface MatchParams {
    id: string;
}

interface RestaurantProps extends RouteChildrenProps<MatchParams> {
    getDetails: typeof RestaurantActions.details;
    getMealTypes: typeof MealTypesActions.list;
    getTimeRanges: typeof TimeRangesActions.list;
    mealTypesListData: MealType[];
    removeConstraint: typeof ConstraintsActions.remove;
    removes: ConstraintsState['remove'];
    restaurantDetails: RestaurantsState['details'];
    siteIdState: ParcsState['selected'];
}

const Restaurant: FC<RestaurantProps> = ({
    getMealTypes, match, mealTypesListData, restaurantDetails, removes,
    removeConstraint, getDetails, getTimeRanges, siteIdState }) => {

    const [ isFormVisible, setIsFormVisible ] = useState(false);
    const [ isConstraintVisible, setIsConstraintVisible ] = useState(false);
    const [ selectedConstraint, setSelectedConstraint ] = useState();
    const [ selectedMealType, setSelectedMealType ] = useState(1);

    useEffect(() => {
        getMealTypes();
        getTimeRanges();
        getDetails(match ? parseInt(match.params.id, 10) : null, {
            siteId: siteIdState,
        });
    }, [siteIdState]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getDetails(match ? parseInt(match.params.id, 10) : null, {
            siteId: siteIdState,
        });
    }, [removes.success]); // eslint-disable-line react-hooks/exhaustive-deps

    const onUpdateSuccess = () => {
        getDetails(match ? parseInt(match.params.id, 10) : null, {
            siteId: siteIdState,
        });
    };

    const onDrawerClose = () => {
        setIsFormVisible(false);
    };

    const onConstraintClose = () => {
        setIsConstraintVisible(false);
    };

    const onRemoveConstraint = (constraint: DeliveryTimeConstraint) => {
        const payload = {
            deliveryMessage: constraint.deliveryMessage,
            productionCapacity: constraint.productionCapacity,
            isEnabled: !constraint.isEnabled,
        };
        removeConstraint(constraint.id, payload);
    };

    const setEditRestaurantMode = () => {
        setIsFormVisible(true);
    };

    const setEditConstraintMode = (constraintId: DeliveryTimeConstraint['id'] | undefined) => {
        setSelectedConstraint(constraintId);
        setIsConstraintVisible(true);
    };

    const changeMealType: TabsProps['onChange'] = (mealTypeId) => {
        setSelectedMealType(parseInt(mealTypeId, 10));
    };

    const tabsHandler = () => {
        return (
            <Tabs onChange={changeMealType} type="card" defaultActiveKey={`${selectedMealType}`}>
                {mealTypesListData && mealTypesListData.map((e) => (
                    <Tabs.TabPane
                        tab={e.label.en}
                        key={`${e.id}`}
                    />
                ))}
            </Tabs>
        );
    };

    const rowKey =  (item: DeliveryTimeConstraint) => `${item.id}`;

    const columns: Array<ColumnProps<DeliveryTimeConstraint>> = [
        {
            dataIndex: 'id',
            title: 'Id',
        },
        {
            dataIndex: 'deliveryTimeRange',
            title: 'Livraison entre',
            render: (record) => record.timeStart.substr(0, 5) + '-' + record.timeEnd.substr(0, 5),
        },
        {
            dataIndex: 'productionCapacity',
            title: 'Capacité',
        },
        {
            dataIndex: 'minTimeToOrder',
            title: 'Début commandes',
            render: (text) => text.substr(0, 5),
        },
        {
            dataIndex: 'maxTimeToOrder',
            title: 'Fin commandes',
            render: (text) => text.substr(0, 5),
        },
        {
            dataIndex: 'deliveryTimeRange.canBeOrderedTheDayBefore',
            title: 'Commandable la veille?',
            render: (text) => (text ? <Icon type="check" /> : <Icon type="minus" />
            ),
        },
        // {
        //     dataIndex: 'deliveryMessage',
        //     title: 'Message de livraison',
        // },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (
                <>
                    <Button
                        icon="edit"
                        className="list-action-button-edit"
                        onClick={setEditConstraintMode.bind(null, record.id)}
                    />
                    <Divider type="vertical"/>
                    <Popconfirm
                        title="Voulez-vous vraiment désactiver?"
                        onConfirm={onRemoveConstraint.bind(null, record)}
                        okText="Oui"
                        cancelText="Non"
                    >
                        <Switch
                            checked={record.isEnabled}
                            checkedChildren={<Icon type="check" />}
                            unCheckedChildren={<Icon type="close" />}
                        />
                    </Popconfirm>
                </>
            ),
        },
    ];

    return (
        <>
            <Spin spinning={restaurantDetails.loading} tip="Chargement...">
                <Descriptions title="Détails de restaurant" bordered>
                    <Descriptions.Item label="Id">
                        {restaurantDetails.data ? restaurantDetails.data.id : null}
                    </Descriptions.Item>
                    <Descriptions.Item label="Nom">
                        {restaurantDetails.data ? restaurantDetails.data.label : null}
                    </Descriptions.Item>
                    <Descriptions.Item label="Actif">
                        {restaurantDetails.data && restaurantDetails.data.isEnabled ?
                            <Icon type="check"/> :
                            <Icon type="close"/>
                        }
                    </Descriptions.Item>
                </Descriptions>
                <Button
                    type="primary"
                    shape="round"
                    icon="edit"
                    onClick={setEditRestaurantMode}
                    className="modifier-button"
                >
                    Modifier
                </Button>
                <hr/>
                <div className="list-header">

                    <h1>Contraintes de livraison</h1>
                    <Button
                        type="primary"
                        shape="round"
                        icon="plus-circle"
                        onClick={setEditConstraintMode.bind(null, undefined)}
                        className="modifier-button"
                    >
                        Ajouter contrainte
                    </Button>
                </div>
                <hr/>
                    {restaurantDetails.data && (
                        <Table<DeliveryTimeConstraint>
                            title={tabsHandler}
                            rowKey={rowKey}
                            columns={columns}
                            dataSource={restaurantDetails.data ?
                                restaurantDetails.data.deliveryTimeConstraints.filter((dc) =>
                                    dc.mealTypeId === selectedMealType,
                                ) : []
                            }
                        />
                    )}
                <hr/>
                {(
                    <RestaurantEdit
                        siteId={siteIdState}
                        id={restaurantDetails.data ? restaurantDetails.data.id : undefined}
                        onUpdateSuccess={onUpdateSuccess}
                        isVisible={isFormVisible}
                        onClose={onDrawerClose}
                    />
                )}
                {(
                    <ConstraintEdit
                        id={selectedConstraint}
                        siteId={siteIdState}
                        onUpdateSuccess={onUpdateSuccess}
                        isVisible={isConstraintVisible}
                        onClose={onConstraintClose}
                    />
                )}
            </Spin>
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    restaurantDetails: getRestaurantDetailsState(state),
    mealTypesListData: getMealTypesData(state),
    timeRangesListData: getTimeRangesData(state),
    removes: removeConstraintState(state),
    siteIdState: getSelectedParcState(state),
});

export default connect(
    mapStateToProps,
    {
        getDetails: RestaurantActions.details,
        getMealTypes: MealTypesActions.list,
        getTimeRanges: TimeRangesActions.list,
        removeConstraint: ConstraintsActions.remove,
    },
)(Restaurant);
