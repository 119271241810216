import api, { urlWithQuery } from './_client';
import { PreSale } from './apiTypes';
import { QueryListPayload } from '../actions';

export const list = (payload: QueryListPayload) => {
    return api.get(urlWithQuery('/cms/presales', payload));
};

export const create = (payload: QueryListPayload, body: Partial<PreSale>) => {
    return api.post(urlWithQuery(`/cms/presale`, payload), body);
};

export const details = (id: PreSale['id'], payload: QueryListPayload) => {
    return api.get<PreSale>(urlWithQuery(`/cms/presale/${id}`, payload));
};

export const update = (id: PreSale['id'], payload: QueryListPayload, body: Partial<PreSale>) => {
    return api.post(urlWithQuery(`/cms/presale/${id}`, payload), body);
};

export const remove = (id: PreSale['id']) => {
    return api.delete(`/cms/presale/${id}`);
};
