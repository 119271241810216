import { ActionCreator } from 'redux';
import { DataAction, QueryListAction, QueryListPayload } from '../actions';
import * as reduxTypes from '../types/suggestions';
import { Suggestion } from '../api/apiTypes';

export const list: ActionCreator<QueryListAction> = (payload: QueryListPayload) => ({
    type: reduxTypes.LIST,
    ...payload,

});

export const listSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_SUCCESS,
    data,
});

export const listFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_FAIL,
    data,
});

export const create: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.CREATE,
    data,
});

export const createSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.CREATE_SUCCESS,
    data,
});

export const createFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.CREATE_FAIL,
    data,
});

export const del: ActionCreator<QueryListAction> = (id: Suggestion['id']) => ({
    type: reduxTypes.DELETE,
    id,
});

export const deleteSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.DELETE_SUCCESS,
    data,
});

export const deleteFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.DELETE_FAIL,
    data,
});

export const detail: ActionCreator<QueryListAction> = (id: Suggestion['id']) => ({
    type: reduxTypes.DETAIL,
    id,
});

export const detailSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.DETAIL_SUCCESS,
    data,
});

export const detailFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.DETAIL_FAIL,
    data,
});

export const update: ActionCreator<QueryListAction> = (id: Suggestion['id'], data: any) => ({
    type: reduxTypes.UPDATE,
    id,
    data,
});

export const updateSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.UPDATE_SUCCESS,
    data,
});

export const updateFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.UPDATE_FAIL,
    data,
});
