import { CounterPaiement, Refund } from './../api/apiTypes';
import { call, takeLatest, put, delay, take, race } from 'redux-saga/effects';

import * as reduxActions from '../actions/orders';
import * as reduxTypes from '../types/orders';
import * as api from '../api/orders';
import { QueryListAction, IdAction, DataAction } from '../actions';
import { Order, Client } from '../api/apiTypes';
import { SiteIdQuery } from '../api';

export function* listSaga(action: QueryListAction) {
    try {
        yield delay(action.throttling || 0);
        const response = yield call(api.list, action);
        return yield put(reduxActions.listSuccess(response));
    } catch (error) {
        return yield put(reduxActions.listFailed(error));
    }
}

export function* listByClientSaga(action: IdAction<Client['id']>) {

    try {
        const response = yield call(api.listByClient, action.id);
        return yield put(reduxActions.listByClientSuccess(response));
    } catch (error) {
        return yield put(reduxActions.listByClientFailed(error));
    }
}

export function* detailsSaga(action: IdAction<Order['id']> & SiteIdQuery) {
    try {
        const response = yield call(api.details, action.id, action);
        return yield put(reduxActions.detailsSuccess(response));
    } catch (error) {
        return yield put(reduxActions.detailsFailed(error));
    }
}

export function* updateSaga(action: IdAction<Order['id']> & DataAction<Partial<Order>>) {
    try {
        const response = yield call(api.update, action.id, action.data);
        return yield put(reduxActions.updateSuccess(response));
    } catch (error) {
        return yield put(reduxActions.updateFailed(error));
    }
}

export function* invoiceSaga(action: IdAction<Order['id']> & SiteIdQuery) {
    try {
        const response = yield call(api.invoice, action.id, action);
        const file = new Blob([response.data], { type: 'application/pdf' });
        const fileUrl = URL.createObjectURL(file);
        return yield put(reduxActions.invoiceSuccess(fileUrl));
    } catch (error) {
        return yield put(reduxActions.invoiceFailed(error));
    }
}

export function* invoiceByMailSaga(action: IdAction<Order['id']>) {
    try {
        const response = yield call(api.invoiceByMail, action.id);
        return yield put(reduxActions.invoiceByMailSuccess(response));
    } catch (error) {
        return yield put(reduxActions.invoiceByMailFailed(error));
    }
}

export function* listCounterPaiment(action: IdAction<Order['id']>) {
    try {
        const response = yield call(api.listCounterPaiement, action.id);
        yield put(reduxActions.listCounterPaiementSuccess(response));
    } catch (error) {
        yield put(reduxActions.listCounterPaiementFailed(error));
    }
}

export function* createCounterPaiment(action: IdAction<Order['id']> & DataAction<Partial<CounterPaiement>>) {
    try {
        const response = yield call(api.createCounterPaiement, action.id, action.data);
        return yield put(reduxActions.createCounterPaiementSuccess(response));
    } catch (error) {
        return yield put(reduxActions.createCounterPaiementFailed(error));
    }
}
export function* updateCounterPaiment(action: IdAction<Order['id']> & DataAction<Partial<CounterPaiement>>) {
    try {
        const response = yield call(api.updateCounterPaiement, action.id, action.data);
        return yield put(reduxActions.updateCounterPaiementSuccess(response));
    } catch (error) {
        return yield put(reduxActions.updateCounterPaiementFailed(error));
    }
}

export function* deleteCounterPaiment(action: IdAction<Order['id']>) {
    try {
        const response = yield call(api.deleteCounterPaiement, action.id);
        return yield put(reduxActions.deleteCounterPaiementSuccess(response));
    } catch (error) {
        return yield put(reduxActions.deleteCounterPaiementFailed(error));
    }
}

export function* listRefunds(action: IdAction<Order['id']>) {

    try {
        const response = yield call(api.listRefunds, action.id);
        return yield put(reduxActions.listRefundsSuccess(response));
    } catch (error) {
        return yield put(reduxActions.listRefundsFailed(error));
    }
}

export function* createRefund(action: IdAction<Order['id']> & DataAction<Refund>) {

    try {
        const response = yield call(api.createRefund, action.data);
        return yield put(reduxActions.createRefundSuccess(response));
    } catch (error) {
        return yield put(reduxActions.createRefundFail(error));
    }
}

/* Worker Function */
export function* pollTask(action: QueryListAction) {
    while (true) {
        try {
            // Fetching posts at regular interval 5 seconds.
            const response = yield call(api.waitForPaiment, action);
            yield put(reduxActions.pollSuccess(response));
            yield delay(5000);
        } catch (error) {
            yield put(reduxActions.pollFailed(error));
            // Once the polling has encountered an error,
            // it should be stopped immediately
            yield put({ type: reduxTypes.STOP_POLLING, error });
            yield delay(5000);
        }
    }
}

/* Watcher Function */
export function* pollTaskWatcher(action: QueryListAction) {
    while (true) {
        yield take(reduxTypes.START_POLLING);
        yield race([call(pollTask, action), take(reduxTypes.STOP_POLLING)]);
    }
}

export default function* ordersSaga() {
    yield takeLatest(reduxTypes.LIST, listSaga);
    yield takeLatest(reduxTypes.WAIT_FOR_PAIEMENT, pollTaskWatcher);
    yield takeLatest(reduxTypes.LIST_BY_CLIENT, listByClientSaga);
    yield takeLatest(reduxTypes.DETAILS, detailsSaga);
    yield takeLatest(reduxTypes.UPDATE, updateSaga);
    yield takeLatest(reduxTypes.INVOICE, invoiceSaga);
    yield takeLatest(reduxTypes.INVOICE_BY_MAIL, invoiceByMailSaga);
    yield takeLatest(reduxTypes.LIST_COUNTER_PAIEMENT, listCounterPaiment);
    yield takeLatest(reduxTypes.CREATE_COUNTER_PAIEMENT, createCounterPaiment);
    yield takeLatest(reduxTypes.UPDATE_COUNTER_PAIEMENT, updateCounterPaiment);
    yield takeLatest(reduxTypes.DELETE_COUNTER_PAIEMENT, deleteCounterPaiment);
    yield takeLatest(reduxTypes.CREATE_REFUND, createRefund);
    yield takeLatest(reduxTypes.LIST_REFUNDS, listRefunds);

}
