import { call, takeLatest, put, delay } from 'redux-saga/effects';

import * as reduxActions from '../actions/constraints';
import * as reduxTypes from '../types/constraints';
import * as api from '../api/constraints';
import { SearchQuery } from '../api';
import { IdAction, DataAction } from '../actions';
import { DeliveryTimeConstraint, Restaurant } from '../api/apiTypes';

export function* listSaga(action: IdAction<Restaurant['id']> & SearchQuery) {
    try {
        yield delay(action.throttling || 0);
        const response = yield call(api.list, action.id, action);
        return yield put(reduxActions.listSuccess(response));
    } catch (error) {
        return yield put(reduxActions.listFailed(error));
    }
}

export function* detailsSaga(action: IdAction<DeliveryTimeConstraint['id']>) {
    try {
        const response = yield call(api.details, action.id);
        return yield put(reduxActions.detailsSuccess(response));
    } catch (error) {
        return yield put(reduxActions.detailsFailed(error));
    }
}

export function* createSaga(action: DataAction) {
    try {
        const response = yield call(api.create, action.data);
        return yield put(reduxActions.createSuccess(response));
    } catch (error) {
        return yield put(reduxActions.createFailed(error));
    }
}

export function* updateSaga(action: IdAction<DeliveryTimeConstraint['id']> & DataAction) {
    try {
        const response = yield call(api.update, action.id, action.data);
        return yield put(reduxActions.updateSuccess(response));
    } catch (error) {
        return yield put(reduxActions.updateFailed(error));
    }
}

export function* removeSaga(action: IdAction<DeliveryTimeConstraint['id']> & DataAction) {
    try {
        const response = yield call(api.update, action.id, action.data);
        return yield put(reduxActions.removeSuccess(response));
    } catch (error) {
        return yield put(reduxActions.removeFailed(error));
    }
}

export default function* constraintsSaga() {
    yield takeLatest(reduxTypes.LIST, listSaga);
    yield takeLatest(reduxTypes.DETAILS, detailsSaga);
    yield takeLatest(reduxTypes.CREATE, createSaga);
    yield takeLatest(reduxTypes.UPDATE, updateSaga);
    yield takeLatest(reduxTypes.REMOVE, removeSaga);
}
