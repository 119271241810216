import { RequestState } from '.';
import { Reducer } from 'redux';

export interface RequestReducerTypes {
    readonly START: string;
    readonly SUCCESS: string;
    readonly FAILED: string;
    readonly RESET?: string;
    readonly START_POLLING?: string;
    readonly STOP_POLLING?: string;
}

export type RequestReducer = <T>(params: RequestReducerTypes, dataInitialState?: T) =>
    Reducer<RequestState<T>>;

export const requestReducer: RequestReducer = (reduxTypes, dataInitialState) => {
    const requestReducerInitialState: RequestState = {
        data: dataInitialState,
        page: 0,
        pageSize: 0,
        totalItems: 0,
        totalPages: 0,
        headers: {},
        loading: false,
        polling: false,
    };
    return (state = requestReducerInitialState, { data, type }) => {
        switch (type) {
            case reduxTypes.START_POLLING:
                return {
                    ...state,
                    polling: true,
                };
            case reduxTypes.STOP_POLLING:
                return {
                    ...state,
                    polling: false,
                };
            case reduxTypes.START:
                return {
                    ...state,
                    loading: true,
                    error: undefined,
                    success: undefined,
                };

            case reduxTypes.SUCCESS:
                if (data.items !== undefined) {
                    return {
                        ...state,
                        data: data.items,
                        headers: data.headers,
                        page: data.page,
                        pageSize: data.pageSize,
                        totalPages: data.totalPages,
                        totalItems: data.totalItems,
                        loading: false,
                        success: true,
                        error: undefined,
                    };
                }
                return {
                    ...state,
                    data,
                    headers: data.headers,
                    loading: false,
                    success: true,
                    error: false,
                };

            case reduxTypes.FAILED:
                return {
                    ...state,
                    loading: false,
                    error: data && data.data ?
                        data.data :
                        true,
                    response: data,
                    success: false,
                };
            case reduxTypes.RESET:
                return {
                    ...state,
                    success: false,
                };

            default:
                return state;
        }
    };
};
