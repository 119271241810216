import React, { FC, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Descriptions, Button, Spin, Icon, Tabs, Tooltip } from 'antd';
// tslint:disable-next-line: no-implicit-dependencies
import { RouteChildrenProps } from 'react-router';

import { MainReducerState } from '../../store/reducers';
import * as ProductActions from '../../store/actions/products';
import { getProductDetailsState, ProductsState } from '../../store/reducers/products';
import { getLangsData } from '../../store/reducers/langs';
import { ProductEdit } from '.';
import Constants from '../../utils/constants';
import { Lang } from '../../store/api/apiTypes';
import { TabsProps } from 'antd/lib/tabs';
import AllergensTooltip from '../../components/AllergensTooltip';
import imageSelector from '../../utils/imageSelector';

interface MatchParams {
  id: string;
}

interface ProductProps extends RouteChildrenProps<MatchParams> {
    productsState: ProductsState['details'];
    langsState: Lang[];
    getDetails: typeof ProductActions.details;
}

const Product: FC<ProductProps> = ({ match, productsState, langsState, getDetails }) => {

    const [ isFormVisible, setIsFormVisible ] = useState(false);
    const [ selectedLang, setSelectedLang ] = useState(Constants.DEFAULT_LANG);

    useEffect(() => {
        getDetails(match ? parseInt(match.params.id, 10) : null);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onUpdateSuccess = () => {
        getDetails(match ? parseInt(match.params.id, 10) : null);
    };

    const onDrawerClose = () => {
        setIsFormVisible(false);
    };

    const setEditMode = () => {
        setIsFormVisible(true);
    };

    const changeLang: TabsProps['onChange'] = (event) => {
        setSelectedLang(event);
    };

    return (
        <>
            <Spin spinning={productsState.loading} tip="Chargement...">
                <Tabs onChange={changeLang} type="card">
                    {langsState && langsState.map((e) => (
                        <Tabs.TabPane
                            tab={e.languageCode}
                            key={e.languageCode}
                        />
                    ))}
                </Tabs>
                <Descriptions title="Détails de produit" bordered>
                    <Descriptions.Item label="Id">
                        {productsState.data ? productsState.data.id : null}
                    </Descriptions.Item>
                    <Descriptions.Item label="Réf. interne">
                        {productsState.data ? productsState.data.internalRef : null}
                    </Descriptions.Item>
                    <Descriptions.Item label="Label technique">
                        {productsState.data ? productsState.data.techLabel : null}
                    </Descriptions.Item>
                    <Descriptions.Item label="Label">
                        {productsState.data && productsState.data.label[selectedLang] ?
                        productsState.data.label[selectedLang] : null}
                    </Descriptions.Item>
                    <Descriptions.Item label="Description">
                        {productsState.data && productsState.data.description[selectedLang] ?
                        productsState.data.description[selectedLang] : null}
                    </Descriptions.Item>
                    <Descriptions.Item label="Ingrédients">
                        {productsState.data && productsState.data.ingredients[selectedLang] ?
                        productsState.data.ingredients[selectedLang] : null}
                    </Descriptions.Item>
                    <Descriptions.Item label="Allergen codes">
                        {productsState.data ? (
                            <Tooltip
                                placement="right"
                                title={<AllergensTooltip list={productsState.data.allergenCodes}/>}
                            >
                                {productsState.data.allergenCodes}
                            </Tooltip>
                        ) : null}
                    </Descriptions.Item>
                    <Descriptions.Item label="Actif">
                        {productsState.data && productsState.data.isEnabled ? <Icon type="check"/> : null}
                    </Descriptions.Item>
                    <Descriptions.Item label="Ingrédient supplémentaire">
                        {productsState.data && productsState.data.isSupplementalIngredient ?
                        <Icon type="check"/> : null}
                    </Descriptions.Item>
                    <Descriptions.Item label="Menu">
                        {productsState.data && productsState.data.isMenu ? <Icon type="check"/> : null}
                    </Descriptions.Item>
                    <Descriptions.Item label="Produit d'un menu">
                        {productsState.data && productsState.data.isMenuProduct ? <Icon type="check"/> : null}
                    </Descriptions.Item>
                    <Descriptions.Item label="Variante d'un produit">
                        {productsState.data && productsState.data.isVariantProduct ? <Icon type="check"/> : null}
                    </Descriptions.Item>
                    <Descriptions.Item label="Catégorie Principale">
                        {productsState.data && productsState.data.category !== null &&
                        productsState.data.category.mainCategory &&
                        productsState.data.category.mainCategory.categoryLabel ?
                        productsState.data.category.mainCategory.categoryLabel[selectedLang] : null}
                    </Descriptions.Item>
                    <Descriptions.Item label="Catégorie">
                        {productsState.data && productsState.data.category !== null &&
                        productsState.data.category.subcategoryLabel ?
                        productsState.data.category.subcategoryLabel[selectedLang] : null}
                    </Descriptions.Item>
                    <Descriptions.Item label="Type de repas">
                        {productsState.data && productsState.data.mealType !== null &&
                        productsState.data.mealType.label ? productsState.data.mealType.label[selectedLang] : null}
                    </Descriptions.Item>
                    <Descriptions.Item label="Image produit">
                        {productsState.data ?
                            <img src={imageSelector(productsState.data.images)} alt="produit"/>
                            :
                            null}
                    </Descriptions.Item>
                </Descriptions>
                <Button
                    type="primary"
                    shape="round"
                    icon="edit"
                    onClick={setEditMode}
                    className="modifier-button"
                >
                    Modifier
                </Button>
                {(
                    <ProductEdit
                        id={productsState.data ? productsState.data.id : undefined}
                        onUpdateSuccess={onUpdateSuccess}
                        isVisible={isFormVisible}
                        onClose={onDrawerClose}
                    />
                )}
            </Spin>
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    productsState: getProductDetailsState(state),
    langsState: getLangsData(state),
});

export default connect(
    mapStateToProps,
    {
      getDetails: ProductActions.details,
    },
)(Product);
