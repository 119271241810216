import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Spin, Tabs, Modal } from 'antd';
import { TabsProps } from 'antd/lib/tabs';
import Form, { FormComponentProps } from 'antd/lib/form';
import ReactQuill from 'react-quill';

import { MainReducerState } from '../../store/reducers';
import * as TermsActions from '../../store/actions/terms';
import { getTermsCGVState, TermsState, updateTermsCGVState } from '../../store/reducers/terms';
import { getLangsData } from '../../store/reducers/langs';

import Constants from '../../utils/constants';
import { Lang } from '../../store/api/apiTypes';

interface CGVProps extends FormComponentProps {
    cgvState: TermsState['fetchCGV'];
    fetchCGV: typeof TermsActions.fetchCGV;
    langsState: Lang[];
    updateCGV: typeof TermsActions.updateCGV;
    updates: TermsState['updateCGV'];
}

const CGVEdit: FC<CGVProps> = ({ cgvState, form, fetchCGV, langsState, updateCGV, updates }) => {

    const [selectedLang, setSelectedLang] = useState(Constants.DEFAULT_LANG);

    const toolbarFormat = {
        toolbar: [
            [
                { header: [1, 2, 3] },
            ],
            [
                { font: [] },
                { size: [] },
                { color: [] },
            ],
            ['bold', 'italic', 'underline'],
            ['image', 'link'],
        ],
    };

    useEffect(() => {
        if (cgvState.data) {
            form.setFieldsValue({html: cgvState.data.html});
        }
    }, [cgvState.data]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchCGV({
            lang: selectedLang,
        });
    }, [selectedLang]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (updates.success) {
            form.resetFields();
            fetchCGV({
                lang: selectedLang,
            });
        }
    }, [updates.success]); // eslint-disable-line react-hooks/exhaustive-deps

    const onTabChange: TabsProps['onChange'] = (event) => {
        if (form.getFieldValue('html') !== cgvState.data.html) {
            confirmModal(event);
        } else {
            setSelectedLang(event);
        }
    };

    const confirmModal = (event: Lang['languageCode']) => {
        Modal.confirm({
            title: 'Voulez-vous garder les modifications?',
            okText: 'Oui',
            cancelText: 'Non',
            onOk() {
                updateCGV({
                    termsOfSale: form.getFieldValue('html'),
                    lang: selectedLang,
                });
                form.resetFields();
                setSelectedLang(event);
            },
            onCancel() {
                form.resetFields();
                setSelectedLang(event);
            },
        });
    };

    const onSubmit = (e?: React.FormEvent) => {
        if (e) {
            e.preventDefault();
        }
        form.validateFieldsAndScroll(async (err, val) => {
            if (err) {
                return;
            }
            const payload = {
                termsOfSale: val.html,
                lang: selectedLang,
            };
            updateCGV(payload);
        });
    };

    const { getFieldDecorator } = form;

    return (
        <>
            <Spin spinning={cgvState.loading} tip="Chargement...">
                <Tabs onChange={onTabChange} type="card">
                    {langsState && langsState.map((e) => (
                        <Tabs.TabPane
                            tab={e.languageCode}
                            key={e.languageCode}
                        />
                    ))}
                </Tabs>
                <Form onSubmit={onSubmit} layout="vertical">
                    <Form.Item label="CGV">
                        {getFieldDecorator(`html`, {
                            rules: [{
                                required: true,
                                message: 'champ requis',
                            }],
                            initialValue: cgvState.data ? cgvState.data.html : '',
                        })((
                            <ReactQuill
                                modules={toolbarFormat}
                                theme="snow"
                                placeholder="Vous pouvez utiliser la barre d'outils pour personnaliser votre contenu"
                            />
                        ))}
                    </Form.Item>
                </Form>
                <div className="form-actions">
                    <Button htmlType="submit" type="primary" onClick={onSubmit} loading={updates.loading}>
                        Mettre à jour
                    </Button>
                </div>
            </Spin>
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    cgvState: getTermsCGVState(state),
    langsState: getLangsData(state),
    updates: updateTermsCGVState(state),
});

const CGVForm = Form.create<CGVProps>()(CGVEdit);

export default connect(
    mapStateToProps,
    {
        fetchCGV: TermsActions.fetchCGV,
        updateCGV: TermsActions.updateCGV,
    },
)(CGVForm);
