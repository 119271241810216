import React, { FC, useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { Form, Drawer, Row, Col, Divider, Button, Spin, InputNumber, Select, message } from 'antd';
import { FormComponentProps } from 'antd/lib/form';

import * as PresalesActions from '../../store/actions/presales';
import * as PresaleNaturesActions from '../../store/actions/presaleNatures';
import { MainReducerState } from '../../store/reducers';
import { getPresaleDetailsState, updatePresaleState, PreSalesState, createPresaleState } from '../../store/reducers/presales';
import { getPreSaleNaturesData, PreSaleNaturesState } from '../../store/reducers/presaleNatures';
import { DrawerProps } from 'antd/lib/drawer';
import { ButtonProps } from 'antd/lib/button';
import { AuthState, getUser } from '../../store/reducers/auth';
import { ParcsState, getSelectedParcState } from '../../store/reducers/parcs';

interface PresaleEditProps extends FormComponentProps {
    createPresale: typeof PresalesActions.create;
    creates: PreSalesState['create'];
    getDetails: typeof PresalesActions.details;
    getPresaleNatures: typeof PresaleNaturesActions.list;
    presaleNatures: PreSaleNaturesState['list'];
    id?: number;
    isVisible: boolean;
    onClose: () => void;
    onUpdateSuccess: () => void;
    presaleDetailsState: PreSalesState['details'];
    updatePresale: typeof PresalesActions.update;
    updates: PreSalesState['update'];
    user: AuthState['data'];
    siteIdState: ParcsState['selected'];
}

const PresaleEdit: FC<PresaleEditProps> = ({
    form, createPresale, creates, getDetails, id, isVisible, onClose,
    onUpdateSuccess, presaleDetailsState, updatePresale, updates, getPresaleNatures, presaleNatures, user, siteIdState,
}) => {
    const [ selectedNature, setSelectedNature ] = useState();
    const [ quantity, setQuantity ] = useState();
    const [ numberOfLines, setNumberOfLines] = useState(1);
    const [ price, setPrice ] = useState(0);
    // const [ tableData , setTableData ] = useState<number[]>([]);
    const onAddNewLine = () => {
        setNumberOfLines(numberOfLines + 1);
    };
    const getSiteId = () => {
        return siteIdState || (user && user.siteId);
    };
    const getTotalPrice = () => {
        setPrice(0);
        let localPrice = 0;
        [...Array(numberOfLines + 1).keys()].forEach((key) => {
            const priceByNature = presaleNatures.data.find((data) =>
                data.id === form.getFieldValue(`natureId${key}`));
            if (priceByNature && form.getFieldValue(`quantity${key}`)) {
                localPrice = localPrice +  (form.getFieldValue(`quantity${key}`) * priceByNature.price);
                setPrice(price + (form.getFieldValue(`quantity${key}`) * priceByNature.price));
            }
        });
        setPrice(Number(localPrice.toFixed(2)));
    };
    const  makeid = () => {
        let text = '';
        const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < 10; i++) {
          text += possible.charAt(Math.floor(Math.random() * possible.length));
      }
        return text;
    };
    useEffect(() => {
        getTotalPrice();
    }, [ selectedNature, quantity ]); // eslint-disable-line react-hooks/exhaustive-deps
    const userId = user ? user.id : null;
    useEffect(() => {
        if (id) {
            getDetails(id);
        }
    }, [id]); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        getPresaleNatures({siteId: getSiteId()});
    }, [isVisible]); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (creates.success || updates.success) {
            form.resetFields();
            onUpdateSuccess();
            onClose();
        }
    }, [creates.success, updates.success]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (updates.error) {
            if (updates.error.errorCode === 'PRESALE_UNAUTHORIZED_TIME_LIMIT') {
                message.error('La date de mise à jour est dépassée');
            } else {
                message.error('Erreur lors de la mise à jour');
            }
        }
    }, [updates.error]);

    const { getFieldDecorator } = form;

    const onDrawerClose: DrawerProps['onClose'] & ButtonProps['onClick'] = () => {
        form.resetFields();
        setNumberOfLines(1);
        onClose();
    };
    const onSubmit = (e?: React.FormEvent) => {
        const orderRef = makeid();
        if (e) {
            e.preventDefault();
        }
        form.validateFieldsAndScroll(async (err, val) => {
            if (err) {
                return;
            }
            if (id !== undefined) {
                if ([...Array(numberOfLines).keys()].length === 1) {
                    updatePresale(id,
                        null,
                        {...val, natureId: val.natureId0, quantity: val.quantity0, userId},
                    );
                } else {
                    [...Array(numberOfLines).keys()].forEach((test, index) => {
                        if (index === 0) {
                            updatePresale(id,
                                null,
                                {...val, natureId: val.natureId0, quantity: val.quantity0, userId},
                            );
                        } else {
                            createPresale({siteId: getSiteId()}, {
                                siteId: getSiteId(),
                                quantity: val[`quantity${test}`],
                                natureId: val[`natureId${test}`],
                                cotage: val.cotage,
                                userId,
                                orderRef,
                            });
                        }
                    });
                }
            } else {
                [...Array(numberOfLines).keys()].forEach((test) => {
                    createPresale({siteId: getSiteId()}, {
                        siteId: getSiteId(),
                        quantity: val[`quantity${test}`],
                        natureId: val[`natureId${test}`],
                        cotage: val.cotage,
                        userId,
                        orderRef,
                    });
                });
            }
        });
    };

    return (
        <>
            <Drawer
                title={id !== undefined ? 'Modification de Pré-Vente' : 'Création de Pré-Vente'}
                width={600}
                onClose={onDrawerClose}
                visible={isVisible}
                className="edit-product-drawer"
            >
                <Spin spinning={presaleDetailsState.loading}>
                    <Form>
                        { [...Array(numberOfLines).keys()].map((test) => (
                            <Fragment key={test}>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item key={`label${test}`} label="Nature">
                                        {getFieldDecorator(`natureId${test}`, {
                                            rules: [{
                                                required: true,
                                                message: 'champ requis',
                                            }],
                                            initialValue: presaleDetailsState.data ?
                                                presaleDetailsState.data.natureId : '',
                                            })((
                                            <Select
                                                key={`selectLabel${test}`}
                                                placeholder="Entrez un nom"
                                                // disabled={id !== undefined}
                                                // tslint:disable-next-line:jsx-no-lambda
                                                onChange={(value) => {
                                                    setSelectedNature(presaleNatures.data.filter((data) =>
                                                        data.id === value,
                                                    ));
                                                }}
                                            >
                                            {presaleNatures &&  presaleNatures.data.map((nature) => (
                                                <Select.Option
                                                    key={nature.id}
                                                    value={nature.id}
                                                >
                                                {nature.nature}
                                                </Select.Option>
                                            ))}
                                            </Select>
                                        ))}
                                    </Form.Item>
                                </Col>
                                <Col span={3}>
                                    <Form.Item label="quantité" key={`quantity${test}`}>
                                        {getFieldDecorator(`quantity${test}`, {
                                            rules: [{
                                                required: true,
                                                message: 'champ requis',
                                            }],
                                            initialValue: presaleDetailsState.data ?
                                                presaleDetailsState.data.quantity : '',
                                        })((
                                            <InputNumber
                                                min={0}
                                                key={`InputQuantity${test}`}
                                                // tslint:disable-next-line:jsx-no-lambda
                                                onChange={(value) => {setQuantity(value); }}
                                                placeholder="Entrez une quantité"
                                            />
                                        ))}
                                    </Form.Item>
                                </Col>
                            </Row>
                            </Fragment>
                        ))
                    }
                       <Row gutter={16}>
                            <Col span={3}>
                                <Form.Item label="cottage">
                                    {getFieldDecorator('cotage', {
                                        rules: [{
                                            required: true,
                                            message: 'champ requis',
                                        }],
                                        initialValue: presaleDetailsState.data ?
                                            presaleDetailsState.data.cotage : '',
                                    })((
                                        <InputNumber placeholder="Entrez le cottage"/>
                                    ))}
                                </Form.Item>
                            </Col>
                           </Row>
                           <Row gutter={16} style={{height: '50px'}}>
                                <div style={{height: '20px'}}>
                                    Prix total : {
                                    id === undefined ?  price :
                                    selectedNature && quantity ? (selectedNature[0].price * quantity).toFixed(2) :
                                    quantity && presaleDetailsState.data ?
                                    (presaleDetailsState.data.nature.price * quantity).toFixed(2) :
                                    selectedNature && presaleDetailsState.data ?
                                    (presaleDetailsState.data.quantity * selectedNature[0].price).toFixed(2) :
                                    presaleDetailsState.data ? presaleDetailsState.data.totalPrice : 0}
                                </div>
                           </Row>
                           <Row gutter={16} style={{height: '50px'}}>
                                <div style={{height: '20px'}}>
                                    mode de paiement :  Clients différés
                                </div>
                           </Row>
                        <div className="form-actions">
                            <Button onClick={onAddNewLine}>
                                {'Ajouter'}
                            </Button>
                            <Divider type="vertical"/>
                            <Button htmlType="submit" type="primary" onClick={onSubmit} loading={updates.loading}>
                                {id !== undefined ? 'Mettre à jour' : 'valider'}
                            </Button>
                            <Divider type="vertical"/>
                            <Button onClick={onDrawerClose} type="ghost">
                                Annuler
                            </Button>
                        </div>
                    </Form>
                </Spin>
            </Drawer>
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    creates: createPresaleState(state),
    presaleDetailsState: getPresaleDetailsState(state),
    updates: updatePresaleState(state),
    presaleNatures: getPreSaleNaturesData(state),
    siteIdState: getSelectedParcState(state),
    user: getUser(state),
});

const PresaleFormDrawer = Form.create<PresaleEditProps>()(PresaleEdit);

export default connect(
    mapStateToProps,
    {
        createPresale: PresalesActions.create,
        getDetails: PresalesActions.details,
        updatePresale: PresalesActions.update,
        getPresaleNatures: PresaleNaturesActions.list,
    },
)(PresaleFormDrawer);
