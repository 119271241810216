import { PaiementMethod, OrderStatus, OrderStatusLevels, Order, OrderPrices } from './../store/api/apiTypes';
import { useEffect, useRef } from 'react';

import { Label, User, UserRole, Parc } from '../store/api/apiTypes';

export { default as constants } from './constants';

interface LoggerType {
    isAllowed: boolean;
    log: (messages?: any, ...optionalParams: any[]) => void;
    warn: (messages?: any, ...optionalParams: any[]) => void;
}

class Logger implements LoggerType {
    public isAllowed: boolean;

    constructor() {
        this.isAllowed = process.env.NODE_ENV !== 'production';
    }

    public log(messages?: any, ...optionalParams: any[]) {
        if (this.isAllowed) {
            console.log('%c[Logger]', 'color: dodgerblue; font-weight: bold', messages, ...optionalParams);
        }
    }

    public info(messages?: any, ...optionalParams: any[]) {
        if (this.isAllowed) {
            console.log('%c[Logger]', 'color: cornflowerblue; font-weight: bold', messages, ...optionalParams);
        }
    }

    public warn(messages?: any, ...optionalParams: any[]) {
        if (this.isAllowed) {
            console.log('%c[Logger]', 'color: darkorange; font-weight: bold', messages, ...optionalParams);
        }
    }

    public error(messages?: any, ...optionalParams: any[]) {
        if (this.isAllowed) {
            console.log('%c[Logger]', 'color: tomato; font-weight: bold', messages, ...optionalParams);
        }
    }
}

export const debug = new Logger();

export const getFullName = (firstname?: string, lastname?: string) => {
    if (!firstname && !lastname) {
        return '';
    }

    return `${firstname || ''}${lastname ? ` ${lastname}` : ''}`.trim();
};

export const capitalize = (str: string) => {
    if (typeof str !== 'string') {
        return '';
    }

    const lowerCased = str.toLowerCase();

    return `${lowerCased.charAt(0).toUpperCase()}${lowerCased.slice(1)}`;
};

export const capitalizeWords = (str: string) => {
    if (typeof str !== 'string') {
        return '';
    }

    return str.split(' ').map(capitalize).join(' ');
};

export const hasOwnProp = (obj: object, value: string | number | [], strict = true) => {
    let result = false;

    if (typeof obj === 'object') {
        if (Array.isArray(value)) {
            result = strict ?
                !value.some((property) => !Object.prototype.hasOwnProperty.call(obj, property)) :
                value.some((property) => Object.prototype.hasOwnProperty.call(obj, property));
        } else {
            result = Object.prototype.hasOwnProperty.call(obj, value);
        }
    }

    return result;
};

export const t = (name: Label) => {
    if (!name) {
        return '';
    }

    const { id, fr, ...langs } = name;

    if (fr) {
        return fr;
    } else if (Object.keys(langs).length) {
        return `[${Object.keys(langs)[0]}] ${langs[Object.keys(langs)[0]]}`;
    } else {
        return 'non traduit';
    }
};

export const isSuperAdmin = (user?: User) => {
    if (user && user.role === UserRole.superAdmin) {
        return true;
    }
    return false;
};

export const isAdmin = (user?: User) => {
    if (user && user.role === UserRole.admin) {
        return true;
    }
    return false;
};

export const isSiteAdmin = (user?: User, site?: Parc['id']) => {
    if (user && user.role === UserRole.admin) {
        if (site && user.siteId === site) {
            return true;
        }
        return false;
    }
    return false;
};

export const isDeskUser = (user?: User, site?: Parc['id']) => {
    if (user && user.role === UserRole.desk) {
        if (site && user.siteId === site) {
            return true;
        }
        return false;
    }
    return false;
};

export const usePrevious = <T extends {}>(value: T) => {
    const ref = useRef<T>();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

export const formatCurrency = (amount: number) => {

    return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' })
        .format(amount < 0 ? 0 : amount);
};

export const getPaiementMothod = (mode: string | null | undefined) => {

    switch (mode) {
        case PaiementMethod.cb:
            return 'CB';
        case PaiementMethod.onDesk:
            return 'DESK';
        default:
            return 'CB';
    }
};

export const canCancelOrder = (mode: string, orderStatus: OrderStatus) => {

    const orderLevel = OrderStatusLevels[orderStatus];
    switch (mode) {

        case PaiementMethod.onBorne:
        case PaiementMethod.onDesk:
            return orderLevel <= OrderStatusLevels[OrderStatus.waitForPaiement];

        // case PaiementMethod.cb:
        // paiementMode by default is cb
        default:
            return orderLevel < OrderStatusLevels[OrderStatus.delivered];

    }
};

export const canRefundOrder = (mode: string, orderStatus: OrderStatus) => {

    const orderLevel = OrderStatusLevels[orderStatus];

    switch (mode) {

        case PaiementMethod.onBorne:
        case PaiementMethod.onDesk:
            return orderLevel >= OrderStatusLevels[OrderStatus.waitForPaiement]
                && orderLevel <= OrderStatusLevels[OrderStatus.delivered];

        // case PaiementMethod.cb:
        // paiementMode by default is cb
        default:
            return orderLevel === OrderStatusLevels[OrderStatus.delivered];

    }
};

export const getOrderPrices: (order: Order) => OrderPrices = (order) => {

    return order.status === OrderStatus.delivered ?

        {
            withTaxAndDiscount: order.cart
                .deliveredProductsTotalWithTaxAndDiscount,
            withTaxWithoutDiscount: order.cart.deliveredProductsTotalWithTaxWithoutDiscount,
            withoutTax: order.cart.deliveredProductsTotalWithoutTax,
        }
        : {
            withTaxAndDiscount: order.cart
                .totalWithTaxAndDiscount,
            withTaxWithoutDiscount: order.cart.totalWithTaxWithoutDiscount,
            withoutTax: order.cart.totalWithoutTax,
        };
};
