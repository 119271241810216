import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Input, { InputProps, SearchProps } from 'antd/lib/input';
import { Button, Table, Divider, Icon, Tooltip, message } from 'antd';
import { ColumnProps, TableProps } from 'antd/lib/table';

import { CatalogProduct, Product } from '../../store/api/apiTypes';
import * as CatalogProductActions from '../../store/actions/catalogProducts';
import * as RestaurantActions from '../../store/actions/restaurants';
import { MainReducerState } from '../../store/reducers';
import { getCatalogProductsState, CatalogProductsState } from '../../store/reducers/catalogProducts';
import { ParcsState, getSelectedParcState } from '../../store/reducers/parcs';

import ButtonLink from '../../components/ButtonLink';
import { getRoute, RoutePathName } from '../../routes';
import Constants from '../../utils/constants';
import EmptyResult from '../../components/EmptyResult';
import { CatalogProductForm } from '.';
import { t } from '../../utils';
import AllergensTooltip from '../../components/AllergensTooltip';

const productTableColumns: Array<ColumnProps<Product>> = [
    {
        dataIndex: 'internalRef',
        title: 'Référence',
    },
    {
        dataIndex: `description[${Constants.DEFAULT_LANG}]`,
        title: 'Description',
    },
    {
        dataIndex: 'allergenCodes',
        title: 'Allergènes',
        render: (text) => (
            <Tooltip placement="right" title={<AllergensTooltip list={text}/>}>
                {text}
            </Tooltip>
        ),
    },
    {
        dataIndex: 'isEnabled',
        title: 'Actif',
        render: (val) => val === true ? <Icon type="check" /> : '',
    },
    {
        dataIndex: 'isSupplementalIngredient',
        title: 'Supp.',
        // sorter: (x, y) => (x === y) ? 0 : x ? -1 : 1,
        render: (val) => val === true ? <Icon type="check" /> : '',
    },
    {
        dataIndex: 'isVariantCatalogProduct',
        title: 'Variante',
        render: (val) => val === true ? <Icon type="check" /> : '',
    },
    {
        dataIndex: 'isMenu',
        title: 'Menu',
        render: (val) => val === true ? <Icon type="check" /> : '',
    },
];

const SubTable: TableProps<CatalogProduct>['expandedRowRender'] = (catalogProduct) => {
    const rowKey = (item: Product) => `${item.id}`;

    return (
        <Table<Product>
            columns={productTableColumns}
            dataSource={[catalogProduct.product]}
            locale={{
                emptyText: <EmptyResult />,
            }}
            pagination={false}
            rowKey={rowKey}
        />
    );
};

export interface CatalogProductsProps {
    catalogProductsState: CatalogProductsState['list'];
    getList: typeof CatalogProductActions.list;
    getRestaurants: typeof RestaurantActions.list;
    siteIdState: ParcsState['selected'];
}

const CatalogProducts: FC<CatalogProductsProps> = ({ catalogProductsState, getList, getRestaurants, siteIdState }) => {

    const [ isFormVisible, setIsFormVisible ] = useState(false);
    const [ selectedId, setSelectedId ] = useState();
    const [ search, setSearch ] = useState<string>();

    useEffect(() => {
        if (siteIdState !== null) {
            getRestaurants({
                siteId: siteIdState,
            });
            getList({
                searchString: search,
                limit: Constants.PAGE_SIZE,
                throttling: 300,
                siteId: siteIdState,
            });
        } else {
            message.error('Veuillez sélectionner un site.');
        }
    }, [siteIdState, search]); // eslint-disable-line react-hooks/exhaustive-deps

    const setEditMode = (id: number) => {
        setIsFormVisible(true);
        setSelectedId(id);
    };

    const onSearchChange: InputProps['onChange'] = (e) => {
        setSearch(e.target.value);
    };

    const onSearch: SearchProps['onSearch'] = (value) => {
        setSearch(value);
    };

    const onDrawerClose = () => {
        setIsFormVisible(false);
    };

    const onUpdateSuccess = () => {
        getList({
            limit: Constants.PAGE_SIZE,
            siteId: siteIdState,
        });
    };

    // const onAddCatalogProductClick = () => {
    //     setIsFormVisible(true);
    // };

    const onTableChange: TableProps<CatalogProduct>['onChange'] = (pagination) => {
        getList({
            searchString: search,
            page: (pagination.current || 1) - 1,
            limit: Constants.PAGE_SIZE,
            siteId: siteIdState || undefined,
        });
    };

    const rowKey =  (item: CatalogProduct) => `${item.id}`;

    const columns: Array<ColumnProps<CatalogProduct>> = [
        {
            dataIndex: 'product.techLabel',
            title: 'Label',
        },
        {
            dataIndex: 'price',
            title: 'Prix',
            render: (val) => new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(val),
        },
        {
            dataIndex: 'vatRate',
            title: 'TVA',
            render: (val) => `${val}%`,
        },
        {
            dataIndex: 'isEnabled',
            title: 'Actif',
            render: (val) => val === true ? <Icon type="check" /> : '',
        },
        {
            dataIndex: 'restaurant',
            title: 'Restaurant',
            render: (val) => val ? <Link to={getRoute(RoutePathName.restaurant, {id: val.id})}>{val.label}</Link> : '',
        },
        {
            dataIndex: `product.category.mainCategory.categoryLabel`,
            title: 'Catégorie',
            render: t,
        },
        {
            dataIndex: `product.category.subcategoryLabel`,
            title: 'Sous-cat.',
            render: t,
        },
        {
            dataIndex: `product.mealType.label`,
            title: 'Type',
            render: t,
        },
        {
            dataIndex: `suppIngredients`,
            title: 'Ingr. Supp.',
            render: (val) => val.length ? `${val.length}` : '',
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (
                <>
                    <ButtonLink
                        className="list-action-button-details"
                        to={getRoute(RoutePathName.catalogProduct, { id: record.id })}
                    >
                        Détails
                    </ButtonLink>
                    <Divider type="vertical"/>
                    <Button
                        icon="edit"
                        className="list-action-button-edit"
                        onClick={setEditMode.bind(null, record.id)}
                    />
                </>
            ),
        },
    ];

    return (
        <>
            <div className="list-inline">
                <h1>Liste des produits catalogue</h1>
                <div className="search-input">
                    <Input.Search
                        className="table-search"
                        placeholder="Rechercher"
                        onChange={onSearchChange}
                        onSearch={onSearch}
                        size="small"
                    />
                </div>
            </div>
            <Table<CatalogProduct>
                bordered={false}
                rowKey={rowKey}
                columns={columns}
                onChange={onTableChange}
                dataSource={catalogProductsState.data}
                loading={catalogProductsState.loading}
                expandedRowRender={SubTable}
                pagination={{
                    current: catalogProductsState.page + 1,
                    pageSize: catalogProductsState.pageSize,
                    total: catalogProductsState.totalItems,
                }}
            />
            <CatalogProductForm
                id={selectedId}
                onUpdateSuccess={onUpdateSuccess}
                isVisible={isFormVisible}
                siteId={siteIdState}
                onClose={onDrawerClose}
            />
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    catalogProductsState: getCatalogProductsState(state),
    siteIdState: getSelectedParcState(state),
});

export default connect(
    mapStateToProps,
    {
        getList: CatalogProductActions.list,
        getRestaurants: RestaurantActions.list,
    },
)(CatalogProducts);
