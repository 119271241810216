import React, { FC, useEffect, useState } from 'react';
import { Button, Drawer, Table, Tag } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { connect } from 'react-redux';
import moment from 'moment';
import { useParams } from 'react-router-dom';

import { LogRegister, getOrderStatusColor, getOrderStatusName } from '../../store/api/apiTypes';
import { LogsRegisterState, getLogsRegisterState } from '../../store/reducers/logsRegister';
import { MainReducerState } from '../../store/reducers';
import * as LogsRegisterActions from '../../store/actions/logsRegister';

interface LogsRegisterProps {
  logsRegister: LogsRegisterState['list'];
  getList: typeof LogsRegisterActions.list;
}

const LogsDrawer: FC<LogsRegisterProps> = ({ logsRegister, getList }) => {
  const [open, setOpen] = useState<boolean>(false);

  const { id } = useParams<{ id: string }>();

  const showDrawer = () => {
    setOpen(true);
    getList(id);
  };

  const onClose = () => {
    setOpen(false);
  };

  const cols: Array<ColumnProps<LogRegister>> = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Statuts',
      dataIndex: 'status',
      key: 'status',
      render: (_, { status }) => (
        <Tag color={getOrderStatusColor(status)}>
        {getOrderStatusName(status)}
      </Tag>
      ),
    },
    {
      title: 'Origin',
      dataIndex: 'origin',
      key: 'origin',
    },
    {
      title: 'Date de création',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, { createdAt }) => (
        <span>{moment(createdAt).format('DD/MM/YYYY HH:mm')}</span>
      ),
    },
    {
      title: 'Nom (id)',
      dataIndex: 'user',
      key: 'user',
    },
  ];

  useEffect(() => {
    getList(id);
  }, [getList, id]);

  const rowKey = (item: LogRegister) => `${item.id}`;

  return (
  <>
    <Button type="ghost" onClick={showDrawer}>
        Afficher l'historique des statuts
    </Button>
    <Drawer title={`Historique des statuts de la commande C${id}`} onClose={onClose} visible={open} width={'40%'}>
      <Table
        dataSource={logsRegister.data}
        loading={logsRegister.loading}
        columns={cols}
        pagination={false}
        rowKey={rowKey}
      />
    </Drawer>
  </>
  );
};

const mapStateToProps = (state: MainReducerState) => ({
  logsRegister: getLogsRegisterState(state),
});

export default connect(
  mapStateToProps,
  {
    getList: LogsRegisterActions.list,
  },
)(LogsDrawer);
