import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Tabs } from 'antd';
import * as OrderActions from '../../store/actions/orders';
import { MainReducerState } from '../../store/reducers';
import { OrdersState, getWaitingForPaiementOrders } from '../../store/reducers/orders';
import { ParcsState, getSelectedParcState } from '../../store/reducers/parcs';
import { getRoute, RoutePathName, AllowedOrdersStatus } from '../../routes';
import { Link, useParams } from 'react-router-dom';
import { OrderStatus } from '../../store/api/apiTypes';
import Constants from '../../utils/constants';

import WaitForPaiementOrdersTab from './WaitForPaiementOrdersTab';
import AllOrdersTab from './AllOrdersTab';
import { AuthState, getUser } from '../../store/reducers/auth';
import OrderReportModal from './OrderReportModal';

const { TabPane } = Tabs;
export interface OrdersProps {
    user: AuthState['data'];
    siteIdState: ParcsState['selected'];
    waitForPaiementOrders: OrdersState['waitForPaiements'];
    getWaitForPaiementList: typeof OrderActions.waitForPaiment;
    stopPolling: typeof OrderActions.stopPoll;
    startPolling: typeof OrderActions.startPoll;
}

const Orders: FC<OrdersProps> = ({
    siteIdState, waitForPaiementOrders, user,
    getWaitForPaiementList, stopPolling, startPolling,
}) => {

    const params = useParams<{ status: string }>();

    const getSiteId = () => {
        return (user && user.siteId) || siteIdState;
    };

    function openModal() {
        setModalState(true);
    }

    useEffect(() => {
        getWaitForPaiementList({
            status: OrderStatus.waitForPaiement,
            siteId: (user && user.siteId) || siteIdState,
            limit: Constants.PAGE_SIZE,
        });
        startPolling();

        return () => {
            stopPolling();
        };
    }, [siteIdState, getWaitForPaiementList, user, startPolling, stopPolling]);

    // State production modal (open/close)
    const [isModalOpen, setModalState] = useState(false);

    return (
        <>
            <Button onClick={openModal}>Télécharger les commandes</Button>
            <Tabs
                activeKey={params.status}
                className="orders-tab-header"
                defaultActiveKey={AllowedOrdersStatus.waitForPaiement}
                animated={false}
            >
                <TabPane
                    tab={(
                        <Link to={getRoute(RoutePathName.orders, { status: AllowedOrdersStatus.waitForPaiement })}>
                            <span className={params.status === AllowedOrdersStatus.waitForPaiement ? 'active' : 'default-color'}>
                                En attente de paiement ({waitForPaiementOrders.totalItems})
                            </span>
                        </Link>
                    )}
                    key={AllowedOrdersStatus.waitForPaiement}
                >
                    <WaitForPaiementOrdersTab
                        isActive={params.status === AllowedOrdersStatus.waitForPaiement}
                        siteId={getSiteId()}
                    />
                </TabPane>
                <TabPane
                    tab={(
                        <Link to={getRoute(RoutePathName.orders, { status: AllowedOrdersStatus.all })}>
                            <span
                                className={params.status === AllowedOrdersStatus.all ? 'active' : 'default-color'}
                            >
                                Liste des commandes
                            </span>
                        </Link>
                    )}
                    key={AllowedOrdersStatus.all}
                >
                    <AllOrdersTab siteId={getSiteId()} />
                </TabPane>
            </Tabs >
            <OrderReportModal siteId={getSiteId()} display={isModalOpen} setDisplay={setModalState}/>
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    siteIdState: getSelectedParcState(state),
    waitForPaiementOrders: getWaitingForPaiementOrders(state),
    user: getUser(state),
});

export default connect(
    mapStateToProps,
    {
        getWaitForPaiementList: OrderActions.waitForPaiment,
        stopPolling: OrderActions.stopPoll,
        startPolling: OrderActions.startPoll,
    },
)(Orders);
