import api, { urlWithQuery } from './_client';
import { Restaurant } from './apiTypes';
import { QueryListPayload } from '../actions';

export const list = (payload: QueryListPayload) => {
    return api.get(urlWithQuery('/cms/restaurants', payload));
};

export const create = (payload: QueryListPayload, body: Partial<Restaurant>) => {
    return api.post(urlWithQuery(`/cms/restaurant`, payload), body);
};

export const details = (id: Restaurant['id'], payload: QueryListPayload) => {
    return api.get<Restaurant>(urlWithQuery(`/cms/restaurant/${id}`, payload));
};

export const update = (id: Restaurant['id'], payload: QueryListPayload, body: Partial<Restaurant>) => {
    return api.put(urlWithQuery(`/cms/restaurant/${id}`, payload), body);
};
