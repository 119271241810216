import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { ColumnProps, TableProps } from 'antd/lib/table';
import { Table, Icon, Input, Tag } from 'antd';
import { InputProps, SearchProps } from 'antd/lib/input';
import moment from 'moment';
import { DateTime } from 'luxon';

import { MainReducerState } from '../../store/reducers';
import { OrdersState, getOrdersListByClientState } from '../../store/reducers/orders';
import { Client as ClientType, Order, getOrderStatusColor, getOrderStatusName} from '../../store/api/apiTypes';
import * as ClientActions from '../../store/actions/clients';
import * as OrderActions from '../../store/actions/orders';
import { getClients, ClientsState } from '../../store/reducers/clients';

import Constants from '../../utils/constants';
import { getFullName } from '../../utils';
import { getRoute, RoutePathName } from '../../routes';
import { getSelectedParcState, ParcsState } from '../../store/reducers/parcs';

export interface ClientsProps {
    clientsState: ClientsState['list'];
    getList: typeof ClientActions.list;
    siteIdState: ParcsState['selected'];
    getOrders: typeof OrderActions.listByClient;
    ordersState: OrdersState['listByClient'];
}

const Clients: FC<ClientsProps> = ({ getList, getOrders, clientsState, ordersState, siteIdState}) => {

    const [ search, setSearch ] = useState<string>();

    useEffect(() => {
        if (siteIdState !== null) {
            getList({
                limit: Constants.PAGE_SIZE,
                siteId: siteIdState,
            });
        } else {
            getList({
                limit: Constants.PAGE_SIZE,
            });
        }
    }, [siteIdState]); // eslint-disable-line react-hooks/exhaustive-deps

    const onSearchChange: InputProps['onChange'] = (e) => {
        setSearch(e.target.value);
    };

    const onSearch: SearchProps['onSearch'] = (value) => {
        setSearch(value);
    };
    useEffect(() => {
        getList({
            siteId: siteIdState || undefined,
            searchString: search,
            limit: Constants.PAGE_SIZE,
            throttling: 300,
        });
    }, [search]); // eslint-disable-line react-hooks/exhaustive-deps

    const onTableChange: TableProps<ClientType>['onChange'] = (pagination) => {
        getList({
            siteId: siteIdState || undefined,
            searchString: search,
            page: (pagination.current || 1) - 1,
            limit: Constants.PAGE_SIZE,
        });
    };

    const onExpandClick = (expanded: boolean, record: ClientType) => {
        getOrders(record.id);
    };

    const rowKey = (item: ClientType) => `${item.id}`;

    const columns: Array<ColumnProps<ClientType>> = [
        // {
        //     dataIndex: 'id',
        //     title: 'Id client',
        // },
        {
            dataIndex: 'lastName',
            title: 'Nom',
        },
        {
            dataIndex: 'firstName',
            title: 'Prénom',
        },
        {
            dataIndex: 'email',
            title: 'Email',
        },
        {
            dataIndex: 'phone',
            title: 'Téléphone',
        },
        {
            dataIndex: 'vacation.cottageNumber',
            title: 'Cottage',
        },
        {
            dataIndex: 'vacation.siteId',
            title: 'Parc',
        },
        {
            dataIndex: 'allowsNewsletters',
            title: 'Newsletter',
            render: (val) => (
                val ? <Icon type="check"/> : ''
            ),
        },
        {
            dataIndex: 'creationDate',
            title: 'Crée le',
            render: (text) => (
                moment(text).format('DD/MM/YYYY')
            ),
        },
    ];

    const orderColumns: Array<ColumnProps<Order>> = [
        {
            dataIndex: 'id',
            title: 'Id',
        },
        {
            dataIndex: 'reference',
            title: 'Référence',
            render: (text, record) => record ? <Link to={getRoute(RoutePathName.order, {id: record.id})}>{text}</Link> : '',
        },
        {
            dataIndex: 'bagsCount',
            title: 'Sac(s)',
        },
        {
            dataIndex: 'deliveryDate',
            title: 'Date livraison',
            render: (val) => val ? DateTime.fromISO(val).toLocaleString() : '',
        },
        {
            dataIndex: 'client',
            title: 'Client',
            render: (val) => getFullName(val.firstName, val.lastName),
        },
        {
            dataIndex: 'client.vacation.cottageNumber',
            title: 'Cottage',
        },
        {
            key: 'deliveryTimeRange',
            title: 'Créneau',
            render: (val, record) => `${
                new Intl.DateTimeFormat('fr-FR', { hour: 'numeric', minute: 'numeric' }).format(
                    new Date(`01/01/1970 ${record.deliveryTimeRange.timeStart}`),
                )
            } - ${
                new Intl.DateTimeFormat('fr-FR', { hour: 'numeric', minute: 'numeric' }).format(
                    new Date(`01/01/1970 ${record.deliveryTimeRange.timeEnd}`),
                )
            }`,
        },
        {
            dataIndex: 'status',
            title: 'Statut',
            render: (val) => (
                <Tag color={getOrderStatusColor(val)}>
                    {getOrderStatusName(val)}
                </Tag>
            ),
        },
    ];

    const SubTable: TableProps<ClientType>['expandedRowRender'] = (order) => {
        const orderRowKey = (item: Order) => `${item.id}`;

        return (
            <Table<Order>
                columns={orderColumns}
                rowKey={orderRowKey}
                dataSource={ordersState.data}
                loading={ordersState.loading}
                pagination={false}
            />
        );
    };

    return (
        <>
            <div className="list-inline">
                <h1 className="list-header-title">Liste des clients</h1>
                <div className="search-input">
                    <Input.Search
                        className="table-search"
                        placeholder="Rechercher"
                        onChange={onSearchChange}
                        onSearch={onSearch}
                        size="small"
                    />
                </div>
            </div>
            <Table<ClientType>
                bordered={false}
                rowKey={rowKey}
                columns={columns}
                onChange={onTableChange}
                dataSource={clientsState.data}
                loading={clientsState.loading}
                expandedRowRender={SubTable}
                onExpand={onExpandClick}
                pagination={{
                    current: clientsState.page + 1,
                    pageSize: clientsState.pageSize,
                    total: clientsState.totalItems,
                }}
            />
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    clientsState: getClients(state),
    siteIdState: getSelectedParcState(state),
    ordersState: getOrdersListByClientState(state),
});

export default connect(
    mapStateToProps,
    {
        getList: ClientActions.list,
        getOrders: OrderActions.listByClient,
    },
)(Clients);
