import React, { FC, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Drawer, Form, Row, Col, Input, Typography, Divider, Button, Radio, message } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { getCreateRefundState, OrdersState } from '../../store/reducers/orders';
import { MainReducerState } from '../../store/reducers';
import * as OrderActions from '../../store/actions/orders';
import { Refund, refundTypes } from '../../store/api/apiTypes';
import { usePrevious } from './../../utils';
interface RefundOrderProps extends FormComponentProps {
    orderId: number;
    orderPrice: number;
    isVisible: boolean;
    onClose: () => void;
    onSubmit: () => void;
    formatCurrency: (val: number) => string;
    createRefundState: OrdersState['createRefund'];
    createRefund: typeof OrderActions.createRefund;
}

const RefundForm: FC<RefundOrderProps> = ({
    orderId, orderPrice, isVisible, onClose, onSubmit, form,
    createRefund, createRefundState, formatCurrency }) => {

    const [refundType, setRefundType] = useState<string>(refundTypes.full.id);
    const previous = usePrevious<Partial<RefundOrderProps>>({
        createRefundState,
    });

    useEffect(() => {

        if (previous && previous.createRefundState &&
            previous.createRefundState.loading && !createRefundState.loading) {

            if (createRefundState.success && createRefundState.data) {
                message.success('La commande a été remboursé avec succès');
                form.resetFields();
                onSubmit();
            } else if (createRefundState.error) {
                message.error('Une erreur s\'est produite, veuillez réessayer plus tard');
            }
        }

    }, [createRefundState.success, createRefundState.error,
        previous, form, onSubmit,
    createRefundState.data, createRefundState.loading]);

    const handleTypeChange = (type: string) => {

        return () => setRefundType(type);
    };

    const { getFieldDecorator } = form;

    const handlecloseForm = () => {
        form.resetFields();
        onClose();
    };

    const submitForm = (e?: React.FormEvent) => {
        if (e) {
            e.preventDefault();
        }

        form.validateFieldsAndScroll(async (err, values) => {
            if (err) {
                return;
            }
            const isPartiel = values.type === refundTypes.partiel.id;

            const amount = Number(values.amount && values.amount.replace(',', '.'));

            if (isPartiel && (values.amount <= 0 || amount > orderPrice)) {
                form.setFields({
                    amount: { errors: [new Error('Entrer un montant valide !')] },
                });
                return;
            }
            const payload: Refund = {
                orderId,
                amount: isPartiel ? amount : orderPrice,
                reason: values.reason,
                refundType: values.type,
            };
            createRefund(payload);
        });
    };

    return (
        <Drawer
            width={650}
            onClose={onClose}
            visible={isVisible}
            title="Effectuer un remboursement"
        >
            <Form>
                <Row>
                    <Col span={24}>
                        <Form.Item label={<Typography.Text>Type de remboursement </Typography.Text>}>
                            {
                                getFieldDecorator('type', {
                                    rules: [{
                                        required: true,
                                        message: 'Selectionner un type de remboursement !',

                                    }],
                                    initialValue: refundType,
                                })((
                                    <Radio.Group>
                                        {Object.values(refundTypes).map(({ id, alias }, i) => (
                                            <Row key={id}>
                                                <Radio
                                                    id={id}
                                                    onClick={handleTypeChange(id)}
                                                    value={id}
                                                >
                                                    {alias}
                                                </Radio>
                                            </Row>
                                        ))}
                                    </Radio.Group>
                                ))
                            }
                        </Form.Item>
                    </Col>
                    {refundType === refundTypes.partiel.id && (
                        <Col offset={1}>
                            <Form.Item
                                required={false}
                                label={<Typography.Text>Montant du remboursement en € </Typography.Text>}
                            >
                                {
                                    getFieldDecorator('amount', {
                                        rules: [{
                                            required: true,
                                            message: 'Entrez un montant valide !',
                                            pattern: /^\d+([,.]\d+)?$/,
                                        }],
                                        initialValue: '',
                                    })((
                                        <Row type="flex" align="middle">
                                            <Col span={12}>
                                                <Input size="large" placeholder="Entrez un montant en euros" />
                                            </Col>
                                            <Col offset={2}>
                                                <Typography.Text className="primary-color">
                                                    Maximum {formatCurrency(orderPrice)}
                                                </Typography.Text>
                                            </Col>
                                        </Row>
                                    ))
                                }
                            </Form.Item>
                        </Col>
                    )}
                    <Col>
                        <Form.Item
                            label={<Typography.Text>Motif de remboursement </Typography.Text>}
                        >
                            {
                                getFieldDecorator('reason', {
                                    rules: [{
                                        required: true,
                                        message: 'Entrez un motif valide !',
                                    }],
                                    initialValue: '',
                                })((
                                    <Input size="large" placeholder="Saisir un motif" />
                                ))
                            }
                        </Form.Item>
                    </Col>
                </Row>
                <div className="form-actions mt-20">
                    <Button loading={createRefundState.loading} type="primary" onClick={submitForm}>
                        Valider
                    </Button>
                    <Divider type="vertical" />
                    <Button type="ghost" onClick={handlecloseForm} >
                        Annuler
                    </Button>

                </div>
            </Form>
        </Drawer>
    );
};

const RefundFormWrapper = Form.create<RefundOrderProps>()(RefundForm);

const mapStateToProps = (state: MainReducerState) => ({
    createRefundState: getCreateRefundState(state),
});

export default connect(mapStateToProps, {
    createRefund: OrderActions.createRefund,
})(RefundFormWrapper);
