import React, { FC, useCallback } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import { FormComponentProps } from 'antd/lib/form/Form';
import { Layout, Form, Icon, Input, Button, Alert } from 'antd';

import '../../assets/styles/App.less';
import '../../assets/styles/Login.less';

import * as AllergensActions from '../../store/actions/allergens';
import * as AuthActions from '../../store/actions/auth';
import * as LangsActions from '../../store/actions/langs';
import * as ParcsActions from '../../store/actions/parcs';
import { MainReducerState } from '../../store/reducers';
import { AuthState, getAuthState } from '../../store/reducers/auth';
import { getLangsData } from '../../store/reducers/langs';
import { getAllergensData } from '../../store/reducers/allergens';

interface LoginProps extends RouteComponentProps, FormComponentProps {
    onLogin: typeof AuthActions.login;
    getAllergens: typeof AllergensActions.list;
    getLangs: typeof LangsActions.list;
    authState: AuthState;
    setSelected: typeof ParcsActions.setSelectedSite;
}

const Login: FC<LoginProps> = ({ authState, form, onLogin, getAllergens, getLangs, setSelected }) => {
    const { getFieldDecorator } = form;

    const onFormSubmit = useCallback((e: React.FormEvent) => {
        e.preventDefault();
        form.validateFields((err, values) => {
            if (err) {
                return;
            }
            onLogin(values);
        });
    }, [form, onLogin]);

    if (authState.isConnected) {
        getLangs();
        getAllergens();
        setSelected(authState.data && authState.data.siteId ? authState.data.siteId : null);
        return <Redirect to="/" />;
    }

    const errorMessage = () => {
        switch (authState.error.status) {
            case 401:
                return 'Non autorisé';
            case 403:
                return 'Mot de passe incorrect';
            case 404:
                return 'Nom d\'utilisateur introuvable';
            default:
                return 'Une erreur s\'est produite';
        }
    };

    return (
        <Layout id="login-layout">
            <Layout.Content>
                <header>
                    <img id="logo" src={`${process.env.PUBLIC_URL}/images/logo_delivery_cms.png`} alt="logo" />
                </header>
                <div className="login-error-message">
                    {authState.error && (
                        <Alert
                            type="error"
                            message={errorMessage()}
                            showIcon
                        />
                    )}
                </div>
                <Form onSubmit={onFormSubmit} className="login-form">
                    <Form.Item>
                        {getFieldDecorator('email', {
                            rules: [{ required: true, message: 'Please input your username!' }],
                        })((
                            <Input
                                prefix={<Icon type="user" />}
                                placeholder="Username"
                            />
                        ))}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('password', {
                            rules: [{ required: true, message: 'Please input your Password!' }],
                        })((
                            <Input.Password
                                prefix={<Icon type="lock" />}
                                placeholder="Password"
                            />
                        ))}
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={authState.loading}
                            block
                        >
                            Log in
                        </Button>
                    </Form.Item>
                </Form>
            </Layout.Content>
        </Layout>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    authState: getAuthState(state),
    langsState: getLangsData(state),
    allergensState: getAllergensData(state),
});

const LoginForm = Form.create()(Login);

export default connect(
    mapStateToProps,
    {
        onLogin: AuthActions.login,
        getLangs: LangsActions.list,
        getAllergens: AllergensActions.list,
        setSelected: ParcsActions.setSelectedSite,
    },
)(LoginForm);
