import React, { FC, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { ColumnProps } from 'antd/lib/table';
import { Button, Table, Popconfirm, Switch, Icon, Alert } from 'antd';
import moment from 'moment';

import { MainReducerState } from '../../store/reducers';
import { Banner, LabelBanner } from '../../store/api/apiTypes';
import * as BannersActions from '../../store/actions/banners';
import { BannersState , getBannersState, updateBannerState } from '../../store/reducers/banners';
import { ParcsState, getSelectedParcState } from '../../store/reducers/parcs';

import BannerEdit from './BannerEdit';
// import { getDetailsCategoryState } from '../../store/reducers/categories';

interface BannersProps {
    bannersState: BannersState['list'];
    getBanners: typeof BannersActions.list;
    siteIdState: ParcsState['selected'];
    updateBanner: typeof BannersActions.update;
    updates: BannersState['update'];
}

const Banners: FC<BannersProps> = ({
    bannersState, getBanners, siteIdState, updateBanner, updates,
}) => {

    const [ isFormVisible, setIsFormVisible ] = useState(false);
    const [ selectedId, setSelectedId ] = useState();

    useEffect(() => {
        getBanners({
            siteId: siteIdState,
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (updates.success) {
            getBanners({
                siteId: siteIdState,
            });
        }
    }, [updates.success]); // eslint-disable-line react-hooks/exhaustive-deps

    const setEditMode = (id: number) => {
        setSelectedId(id);
        setIsFormVisible(true);
    };

    const onUpdateSuccess = () => {
        getBanners({
            siteId: siteIdState,
        });
    };

    const onDrawerClose = () => {
        setIsFormVisible(false);
    };

    const onCreateBanner = () => {
        setSelectedId(undefined);
        setIsFormVisible(true);
    };

    const onSwitchChange = (elem: Banner) => {
        const languageCode = Object.keys(elem.label)[0] as keyof LabelBanner;
        const payload = {
            ...elem,
            isEnabled : !elem.isEnabled,
            languageCode,
            label: elem.label[languageCode],
        };
        updateBanner(
            elem.id,
            payload,
        );
    };

    const rowKey = (item: Banner) => `${item.id}`;

    const columns: Array<ColumnProps<Banner>> = [
        {
            dataIndex: 'id',
            title: 'Id',
        },
        {
            dataIndex: 'label.fr',
            title: 'Nom',
        },
        {
            dataIndex: 'languageCode',
            title: 'Langue',
        },
        {
            dataIndex: 'creationDate',
            title: 'Date de création',
            render: (record) => moment(record).format('YYYY-MM-DD'),
        },
        // {
        //     dataIndex: 'linkUrl',
        //     title: 'Lien url',
        // },
        {
            dataIndex: 'imageUrl',
            title: 'Adresse image',
        },
        {
            dataIndex: 'isEnabled',
            title: 'Statut',
            render: (text, record) => (
                <Popconfirm
                    title="Voulez-vous vraiment changer le statut?"
                    onConfirm={onSwitchChange.bind(null, record)}
                    okText="Oui"
                    cancelText="Non"
                >
                    <Switch
                        checked={text}
                        checkedChildren={<Icon type="check" />}
                        unCheckedChildren={<Icon type="close" />}
                    />
                </Popconfirm>
            ),
        },
        {
            dataIndex: 'Actions',
            title: 'Actions',
            render: (text, record) => (
                <>
                    {/* <ButtonLink
                        className="list-action-button-details"
                        to={getRoute(RoutePathName.banner, { id: record.id })}
                    >
                        Détails
                    </ButtonLink> */}
                    <Button
                        icon="edit"
                        className="list-action-button-edit"
                        onClick={setEditMode.bind(null, record.id)}
                    />
                </>
            ),
        },

    ];

    return (
        <>
            <Button
                type="primary"
                icon="plus-circle"
                onClick={onCreateBanner}
            >
                Ajouter une bannière
            </Button>
            <Table<Banner>
                bordered={false}
                rowKey={rowKey}
                columns={columns}
                dataSource={bannersState.data}
                loading={bannersState.loading}
            />
            <BannerEdit
                id={selectedId}
                onUpdateSuccess={onUpdateSuccess}
                isVisible={isFormVisible}
                onClose={onDrawerClose}
            />
            <div className="modifier-button">
                {!updates.loading && updates.error && (
                    <Alert
                        type="error"
                        message={updates.error.errorCode === 'DISCOUNT_EXISTS' ?
                            'Ce code de réduction est déjà utilisé!' :
                            updates.error.errorMessage}
                        showIcon
                    />
                )}
            </div>
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    bannersState: getBannersState(state),
    siteIdState: getSelectedParcState(state),
    updates: updateBannerState(state),
});

export default connect(
    mapStateToProps,
    {
        getBanners: BannersActions.list,
        updateBanner: BannersActions.update,
    },
)(Banners);
