import React, { useState, useCallback, FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Icon, Layout, Menu } from 'antd';

import { MenuRoutesByRole } from '../routes';
import { AuthState, getUser } from '../store/reducers/auth';
import { MainReducerState } from '../store/reducers';
import { connect } from 'react-redux';

interface MainMenuProps {
    user: AuthState['data'];
}

const Sidebar: FC<MainMenuProps> = ({ user }) => {

    const [collapsed, setCollapsed] = useState(false);
    const location = useLocation();
    const onCollapse = useCallback((value) => {
        setCollapsed(value);
    }, [setCollapsed]);

    const AllowedMenuItems = MenuRoutesByRole.filter((item) => user && item.roles.includes(user.role));

    // i write this to set defaultSelectedKeys an allowed item when redirect from not allowed route
    // otherwise  it should be location.pathName directly
    const getDefaultActiveKey = () => {
        const length = AllowedMenuItems.length;
        return AllowedMenuItems.find((item) => item.path === location.pathname)
            ? location.pathname : length ? AllowedMenuItems[0].path : '';
    };

    return (
        <Layout.Sider
            collapsible
            collapsed={collapsed}
            onCollapse={onCollapse}
        >
            <Menu
                mode="inline"
                defaultSelectedKeys={[getDefaultActiveKey()]}
            >
                {
                    user && AllowedMenuItems.map((item) => (
                        <Menu.Item key={item.path}>
                            <Link to={item.path}>
                                <Icon type={item.icon} theme={item.theme} />
                                <span>{item.name}</span>
                            </Link>
                        </Menu.Item>
                    ))
                }
            </Menu>
        </Layout.Sider>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    user: getUser(state),
});

export default connect(
    mapStateToProps,
)(Sidebar);
