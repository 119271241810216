import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Statistic, Card, Row, Col, DatePicker, Button, message } from 'antd';
import { RangePickerValue, RangePickerProps } from 'antd/lib/date-picker/interface';
import moment from 'moment';

import '../../assets/styles/Stats.less';

import * as DashboardActions from '../../store/actions/dashboard';
import { MainReducerState } from '../../store/reducers';
import { ParcsState, getSelectedParcState } from '../../store/reducers/parcs';
import { getDashboardFetchState, getDashboardCaState, DashboardState,
    getDashboardDiscountsState, getDeliveredTurnoverState, getAverageCartSizeState,
    getTop10ProductsState, getNotCollectedState, getDeliveryTimeRankingState, getMonthlyExportState } from '../../store/reducers/dashboard';

import { usePrevious } from '../../utils/index';
import { AuthState, getUser } from '../../store/reducers/auth';

import {downloadFile} from '../../utils/downloadFile';

import ProdReportModal from './ProdReportModal';
interface DashboardProps {
    getStats: typeof DashboardActions.fetch;
    getCa: typeof DashboardActions.getCa;
    getMonthlyExport: typeof DashboardActions.getMonthlyExport;
    getDiscounts: typeof DashboardActions.getDiscounts;
    getDeliveredTurnover: typeof DashboardActions.getDeliveredTurnover;
    getAverageCartSize: typeof DashboardActions.getAverageCartSize;
    getTop10Products: typeof DashboardActions.getTop10Products;
    getNotCollected: typeof DashboardActions.getNotCollected;
    getDeliveryTimeRanking: typeof DashboardActions.getDeliveryTimeRanking;
    fetchState: DashboardState['fetch'];
    caState: DashboardState['getCa'];
    monthlyExportState: DashboardState['getMonthlyExport'];
    discountsState: DashboardState['getDiscounts'];
    deliveredTurnoverState: DashboardState['getDeliveredTurnover'];
    averageCartSizeState: DashboardState['getAverageCartSize'];
    top10ProductsState: DashboardState['getTop10Products'];
    notCollectedState: DashboardState['getNotCollected'];
    deliveryTimeRankingState: DashboardState['getDeliveryTimeRanking'];
    siteIdState: ParcsState['selected'];
    user: AuthState['data'];
}
const Dashboard: FC<DashboardProps> = ({
    fetchState, caState, discountsState, getStats, getCa, getDiscounts, getMonthlyExport,
    getDeliveredTurnover, getAverageCartSize, getTop10Products, getNotCollected,
    getDeliveryTimeRanking, deliveredTurnoverState, averageCartSizeState,
    top10ProductsState, notCollectedState, deliveryTimeRankingState, siteIdState, monthlyExportState, user,
}) => {
    const getSiteId = () => {
        return siteIdState || (user && user.siteId);
    };

    const [ startDate, setStartDate ] = useState(moment().subtract(1, 'day').startOf('day').toISOString());
    const [ endDate, setEndDate ] = useState(moment().subtract(1, 'day').endOf('day').toISOString());

    const [prodModalVisible, setProdModalVisible] = useState(false);

    useEffect(() => {
        getStats({
            siteId: getSiteId(),
        });
    }, [getSiteId()]); // eslint-disable-line react-hooks/exhaustive-deps

    const prevProps = usePrevious<Partial<DashboardProps>>({
        caState, discountsState, deliveredTurnoverState, averageCartSizeState,
        top10ProductsState, notCollectedState, deliveryTimeRankingState, monthlyExportState,
    });

    useEffect(() => {
        if (prevProps && prevProps.caState && prevProps.caState.loading &&
            !caState.loading && caState.success && caState.data
        ) {
            if (caState.data.data && caState.data.headers && caState.data.headers.filename) {
                downloadFile(caState.data.data, caState.data.headers.filename);
            } else {
                message.error('Problème avec le fichier ou le nom du fichier.');
                console.warn('caState.success data, headers', caState.data.data, caState.data.headers);
            }
        }
    }, [caState.success]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (prevProps && prevProps.monthlyExportState && prevProps.monthlyExportState.loading &&
            !monthlyExportState.loading && monthlyExportState.success && monthlyExportState.data
        ) {
            if (monthlyExportState.data.data && monthlyExportState.data.headers
                && monthlyExportState.data.headers.filename) {
                downloadFile(monthlyExportState.data.data, monthlyExportState.data.headers.filename);
            } else {
                message.error('Problème avec le fichier ou le nom du fichier.');
                console.warn('caState.success data, headers',
                monthlyExportState.data.data, monthlyExportState.data.headers);
            }
        }
    }, [monthlyExportState.success]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (prevProps && prevProps.discountsState && prevProps.discountsState.loading &&
            !discountsState.loading && discountsState.success && discountsState.data
        ) {
            if (discountsState.data.data && discountsState.data.headers && discountsState.data.headers.filename) {
                downloadFile(discountsState.data.data, discountsState.data.headers.filename);
            } else {
                message.error('Problème avec le fichier ou le nom du fichier.');
                console.warn('discountsState.success data, headers',
                    discountsState.data.data, discountsState.data.headers);
            }
        }
    }, [discountsState.success]); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (prevProps && prevProps.deliveredTurnoverState && prevProps.deliveredTurnoverState.loading &&
            !deliveredTurnoverState.loading && deliveredTurnoverState.success && deliveredTurnoverState.data
        ) {
            if (deliveredTurnoverState.data.data && deliveredTurnoverState.data.headers &&
                deliveredTurnoverState.data.headers.filename) {
                    downloadFile(deliveredTurnoverState.data.data, deliveredTurnoverState.data.headers.filename);
            } else {
                message.error('Problème avec le fichier ou le nom du fichier.');
                console.warn('deliveredTurnoverState.success data, headers',
                    deliveredTurnoverState.data.data, deliveredTurnoverState.data.headers);
            }
        }
    }, [deliveredTurnoverState.success]); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (prevProps && prevProps.averageCartSizeState && prevProps.averageCartSizeState.loading &&
            !averageCartSizeState.loading && averageCartSizeState.success && averageCartSizeState.data
        ) {
            if (averageCartSizeState.data.data && averageCartSizeState.data.headers &&
                averageCartSizeState.data.headers.filename) {
                downloadFile(averageCartSizeState.data.data, averageCartSizeState.data.headers.filename);
            } else {
                message.error('Problème avec le fichier ou le nom du fichier.');
                console.warn('averageCartSizeState.success data, headers',
                    averageCartSizeState.data.data, averageCartSizeState.data.headers);
            }
        }
    }, [averageCartSizeState.success]); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (prevProps && prevProps.top10ProductsState && prevProps.top10ProductsState.loading &&
            !top10ProductsState.loading && top10ProductsState.success && top10ProductsState.data
        ) {
            if (top10ProductsState.data.data && top10ProductsState.data.headers &&
                top10ProductsState.data.headers.filename) {
                downloadFile(top10ProductsState.data.data, top10ProductsState.data.headers.filename);
            } else {
            message.error('Problème avec le fichier ou le nom du fichier.');
            console.warn('top10ProductsState.success data, headers',
                top10ProductsState.data.data, top10ProductsState.data.headers);
        }
        }
    }, [top10ProductsState.success]); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (prevProps && prevProps.notCollectedState && prevProps.notCollectedState.loading &&
            !notCollectedState.loading && notCollectedState.success && notCollectedState.data
        ) {
            if (notCollectedState.data.data && notCollectedState.data.headers &&
                notCollectedState.data.headers.filename) {
                downloadFile(notCollectedState.data.data, notCollectedState.data.headers.filename);
            } else {
                message.error('Problème avec le fichier ou le nom du fichier.');
                console.warn('notCollectedState.success data, headers',
                    notCollectedState.data.data, notCollectedState.data.headers);
            }
        }
    }, [notCollectedState.success]); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (prevProps && prevProps.deliveryTimeRankingState && prevProps.deliveryTimeRankingState.loading &&
            !deliveryTimeRankingState.loading && deliveryTimeRankingState.success && deliveryTimeRankingState.data
        ) {
            if (deliveryTimeRankingState.data.data && deliveryTimeRankingState.data.headers &&
                deliveryTimeRankingState.data.headers.filename) {
                    downloadFile(deliveryTimeRankingState.data.data, deliveryTimeRankingState.data.headers.filename);
            } else {
                message.error('Problème avec le fichier ou le nom du fichier.');
                console.warn('deliveryTimeRankingState.success data, headers',
                    deliveryTimeRankingState.data.data, deliveryTimeRankingState.data.headers);
            }
        }
    }, [deliveryTimeRankingState.success]); // eslint-disable-line react-hooks/exhaustive-deps

    const day: RangePickerValue = [moment().subtract(1, 'day').startOf('day'),
        moment().subtract(1, 'day').endOf('day')];
    const week: RangePickerValue = [moment().subtract(1, 'week').startOf('day'),
        moment().subtract(1, 'day').endOf('day')];
    const month: RangePickerValue = [moment().subtract(1, 'month').startOf('day'),
        moment().subtract(1, 'day').endOf('day')];

    const onChange: RangePickerProps['onChange'] = (initial, val) => {
        setStartDate(moment(val[0]).startOf('day').toISOString());
        setEndDate(moment(val[1]).endOf('day').toISOString());
    };

    // const humanReadableError = (err: any) => {
    //     if (err && err.errorMessage) {
    //         return `Erreur: ${err.errorMessage}`;
    //     } else if (err && typeof err === 'string') {
    //         return err;
    //     } else {
    //         return 'Une erreur est survenue.';
    //     }
    // };

    const onCa = () => {
        if (getSiteId() !== null) {
            getCa({
                siteId: getSiteId(),
                file: 'chiffreAff',
                startDate: startDate ? startDate : undefined,
                endDate: endDate ? endDate : undefined,
            });
        } else {
            message.error('Veuillez sélectionner un site.');
        }
    };

    const onMonthlyExport = () => {
            getMonthlyExport({
                startDate: startDate ? startDate : undefined,
                endDate: endDate ? endDate : undefined,
            });

    };

    const onDiscounts = () => {
        if (getSiteId() !== null) {
            getDiscounts({
                siteId: getSiteId(),
                file: 'discounts',
                startDate: startDate ? startDate : undefined,
                endDate: endDate ? endDate : undefined,
            });
        } else {
            message.error('Veuillez sélectionner un site.');
        }
    };

    const onDeliveredTurnover = () => {
        if (getSiteId() !== null) {
            getDeliveredTurnover({
                siteId: getSiteId(),
                file: 'deliveredTurnover',
                startDate: startDate ? startDate : undefined,
                endDate: endDate ? endDate : undefined,
            });
        } else {
            message.error('Veuillez sélectionner un site.');
        }
    };

    const onAverageCartSize = () => {
        if (getSiteId() !== null) {
            getAverageCartSize({
                siteId: getSiteId(),
                file: 'averageCartSize',
                startDate: startDate ? startDate : undefined,
                endDate: endDate ? endDate : undefined,
            });
        } else {
            message.error('Veuillez sélectionner un site.');
        }
    };

    const onTop10Products = () => {
        if (getSiteId() !== null) {
            getTop10Products({
                siteId: getSiteId(),
                file: 'top10products',
                startDate: startDate ? startDate : undefined,
                endDate: endDate ? endDate : undefined,
            });
        } else {
            message.error('Veuillez sélectionner un site.');
        }
    };

    const onNotCollected = () => {
        if (getSiteId() !== null) {
            getNotCollected({
                siteId: getSiteId(),
                file: 'notCollected',
                startDate: startDate ? startDate : undefined,
                endDate: endDate ? endDate : undefined,
            });
        } else {
            message.error('Veuillez sélectionner un site.');
        }
    };

    const onDeliveryTimeRanking = () => {
        if (getSiteId() !== null) {
            getDeliveryTimeRanking({
                siteId: getSiteId(),
                file: 'deliveryTimeRanking',
                startDate: startDate ? startDate : undefined,
                endDate: endDate ? endDate : undefined,
            });
        } else {
            message.error('Veuillez sélectionner un site.');
        }
    };

    function closeModal() {
        setProdModalVisible(true);
    }

    return (
        <>
            <h1>Tableau de bord</h1>
            <div className="dashboard-layout">
                <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
                    <Col xs={24} sm={12} md={8} lg={4}>
                        <Card>
                        <Statistic
                            className="stats-card"
                            title="Nombre de clients"
                            value={fetchState.data.clientsCount}
                        />
                        </Card>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={4}>
                        <Card>
                        <Statistic
                            className="stats-card"
                            title="Nombre de commandes"
                            value={fetchState.data.ordersCount}
                        />
                        </Card>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={4}>
                        <Card>
                        <Statistic
                            className="stats-card"
                            title="Chiffre d'affaires"
                            value={fetchState.data.ca}
                            precision={2}
                        />
                        </Card>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={4}>
                        <Card>
                            <Statistic
                                className="stats-card"
                                title="Nombre de produits"
                                value={fetchState.data.productsCount}
                            />
                        </Card>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={4}>
                        <Card>
                            <Statistic
                                className="stats-card"
                                title="Nombre de parcs"
                                value={fetchState.data.siteCount}
                            />
                        </Card>
                    </Col>
                </Row>
            </div>
            <hr/>
            {day && week && month && (
                <>
                <div className="list-inline">
                    Choissez la période:
                    <div className="search-input">
                        <DatePicker.RangePicker
                            defaultValue={day}
                            onChange={onChange}
                            ranges={
                                {
                                    Jour: day,
                                    Semaine: week,
                                    Mois: month,
                                }
                            }
                        />
                    </div>
                </div>
                <div className="kpi-buttons">
                    <Button
                        id="caButton"
                        loading={caState.loading}
                        className="margin-button"
                        type="primary"
                        icon="download"
                        onClick={onCa}
                    >
                        Chiffre d'affaires
                    </Button>
                    <Button
                        id="caButton"
                        loading={monthlyExportState.loading}
                        className="margin-button"
                        type="primary"
                        icon="download"
                        onClick={onMonthlyExport}
                    >
                        Export ventes
                    </Button>
                    <Button
                        id="discountButton"
                        loading={discountsState.loading}
                        className="margin-button"
                        type="primary"
                        icon="download"
                        onClick={onDiscounts}
                    >
                        Réductions
                    </Button>
                    <Button
                        id="deliveredTurnoverButton"
                        loading={deliveredTurnoverState.loading}
                        className="margin-button"
                        type="primary"
                        icon="download"
                        onClick={onDeliveredTurnover}
                    >
                        CA des paniers livrés
                    </Button>
                    <Button
                        id="averageCartSizeButton"
                        loading={averageCartSizeState.loading}
                        className="margin-button"
                        type="primary"
                        icon="download"
                        onClick={onAverageCartSize}
                    >
                        Nombre de produits moyen par panier livré
                    </Button>
                    <Button
                        id="top10Button"
                        loading={top10ProductsState.loading}
                        className="margin-button"
                        type="primary"
                        icon="download"
                        onClick={onTop10Products}
                    >
                        Top 10 des produits commandés
                    </Button>
                    <Button
                        id="notCollectedButton"
                        loading={notCollectedState.loading}
                        className="margin-button"
                        type="primary"
                        icon="download"
                        onClick={onNotCollected}
                    >
                        Produits non collectés
                    </Button>
                    <Button
                        id="deliveredTurnoverButton"
                        loading={deliveryTimeRankingState.loading}
                        className="margin-button"
                        type="primary"
                        icon="download"
                        onClick={onDeliveryTimeRanking}
                    >
                        Respect des horaires de livraison
                    </Button>
                    <Button
                        id="prodReport"
                        className="margin-button"
                        type="primary"
                        icon="download"
                        onClick={closeModal}
                    >
                        Rapport de Production
                    </Button>
                </div>
                <ProdReportModal siteId={getSiteId()} display={prodModalVisible} setDisplay={setProdModalVisible} />
                </>
            )}
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    fetchState: getDashboardFetchState(state),
    caState: getDashboardCaState(state),
    discountsState: getDashboardDiscountsState(state),
    deliveredTurnoverState: getDeliveredTurnoverState(state),
    averageCartSizeState: getAverageCartSizeState(state),
    top10ProductsState: getTop10ProductsState(state),
    notCollectedState: getNotCollectedState(state),
    deliveryTimeRankingState: getDeliveryTimeRankingState(state),
    siteIdState: getSelectedParcState(state),
    monthlyExportState: getMonthlyExportState(state),
    user: getUser(state),

});

export default connect(
    mapStateToProps,
    {
        getStats: DashboardActions.fetch,
        getCa: DashboardActions.getCa,
        getMonthlyExport: DashboardActions.getMonthlyExport,
        getDiscounts: DashboardActions.getDiscounts,
        getDeliveredTurnover: DashboardActions.getDeliveredTurnover,
        getAverageCartSize: DashboardActions.getAverageCartSize,
        getTop10Products: DashboardActions.getTop10Products,
        getNotCollected: DashboardActions.getNotCollected,
        getDeliveryTimeRanking: DashboardActions.getDeliveryTimeRanking,
    },
)(Dashboard);
