import { combineReducers } from 'redux';

import * as reduxTypes from '../types/terms';
import { CGU, CGV, FAQ } from '../api/apiTypes';
import { MainReducerState, RequestState } from './';
import { requestReducer } from './_generics';

export interface TermsState {
    readonly fetchCGU: RequestState<CGU>;
    readonly fetchCGV: RequestState<CGV>;
    readonly fetchFAQ: RequestState<FAQ>;
    readonly updateCGU: RequestState<CGU>;
    readonly updateCGV: RequestState<CGV>;
    readonly updateFAQ: RequestState<FAQ>;
}

export default combineReducers<TermsState>({
    fetchCGU: requestReducer({
        START: reduxTypes.FETCH_CGU,
        SUCCESS: reduxTypes.FETCH_CGU_SUCCESS,
        FAILED: reduxTypes.FETCH_CGU_FAIL,
    }),
    updateCGU: requestReducer({
        START: reduxTypes.UPDATE_CGU,
        SUCCESS: reduxTypes.UPDATE_CGU_SUCCESS,
        FAILED: reduxTypes.UPDATE_CGU_FAIL,
    }),
    fetchCGV: requestReducer({
        START: reduxTypes.FETCH_CGV,
        SUCCESS: reduxTypes.FETCH_CGV_SUCCESS,
        FAILED: reduxTypes.FETCH_CGV_FAIL,
    }),
    updateCGV: requestReducer({
        START: reduxTypes.UPDATE_CGV,
        SUCCESS: reduxTypes.UPDATE_CGV_SUCCESS,
        FAILED: reduxTypes.UPDATE_CGV_FAIL,
    }),
    fetchFAQ: requestReducer({
        START: reduxTypes.FETCH_FAQ,
        SUCCESS: reduxTypes.FETCH_FAQ_SUCCESS,
        FAILED: reduxTypes.FETCH_FAQ_FAIL,
    }),
    updateFAQ: requestReducer({
        START: reduxTypes.UPDATE_FAQ,
        SUCCESS: reduxTypes.UPDATE_FAQ_SUCCESS,
        FAILED: reduxTypes.UPDATE_FAQ_FAIL,
    }),
});

export const getTermsCGUState = (state: MainReducerState) => state.terms.fetchCGU;
export const getTermsCGVState = (state: MainReducerState) => state.terms.fetchCGV;
export const getTermsFAQState = (state: MainReducerState) => state.terms.fetchFAQ;
export const updateTermsCGUState = (state: MainReducerState) => state.terms.updateCGU;
export const updateTermsCGVState = (state: MainReducerState) => state.terms.updateCGV;
export const updateTermsFAQState = (state: MainReducerState) => state.terms.updateFAQ;
