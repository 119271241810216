import React, { FC, useEffect, ReactNode } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { Spin } from 'antd';

import * as AuthActions from '../store/actions/auth';
import * as LangsActions from '../store/actions/langs';
import { MainReducerState } from '../store/reducers';
import { getAuthState, AuthState } from '../store/reducers/auth';

interface ProtectedRouteProps {
    auth: AuthState;
    checkLoginStatus: typeof AuthActions.checkLoginStatus;
    children: ReactNode;
    getLangs: typeof LangsActions.list;
    loginPath: string;
    unauthorizedErrorOccured: typeof AuthActions.loginFailed;
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({
    auth, checkLoginStatus, children, getLangs, loginPath, unauthorizedErrorOccured,
}) => {
    useEffect(() => {
        getLangs();
        // dispatched from axios interceptor in api/_client.ts
        window.addEventListener('unauthorized.error', unauthorizedErrorOccured);

        if (!auth.hasCheckedLoginStatus) {
            checkLoginStatus();
        }

        return () => window.removeEventListener('unauthorized.error', unauthorizedErrorOccured);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (auth.hasCheckedLoginStatus && !auth.isConnected) {
        return <Redirect to={loginPath} />;
    } else if (auth.hasCheckedLoginStatus && auth.isConnected) {
        return <>{children}</>;
    } else {
        return (
            <Route>
                <div id="initial-loader">
                    <Spin />
                </div>
            </Route>
        );
    }
};

const mapStateToProps = (state: MainReducerState) => ({
    auth: getAuthState(state),
});

export default connect(
    mapStateToProps,
    {
        checkLoginStatus: AuthActions.checkLoginStatus,
        getLangs: LangsActions.list,
        unauthorizedErrorOccured: AuthActions.loginFailed,
    },
)(ProtectedRoute);
