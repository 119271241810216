import React, { FC, useState, useEffect, useCallback, useRef } from 'react';
import { Drawer, Form, Row, Col, Input, Typography, Divider, Button, Select, Popconfirm, message } from 'antd';
import { PaiementModes, getPaiementMode, CounterPaiement, preSaleNatures, getPaiementModeByKey } from '../../store/api/apiTypes';
import { FormComponentProps } from 'antd/lib/form';
import { connect } from 'react-redux';
import { MainReducerState } from '../../store/reducers';
import {
    getCounterPaimentState, OrdersState, getUpdateCounterPaiementState,
    getcreationCounterPaiementState, getDeleteCounterPaiementState,
} from '../../store/reducers/orders';
import * as OrderActions from '../../store/actions/orders';
import { usePrevious, formatCurrency } from '../../utils';
interface CounterPeimentProps extends FormComponentProps {
    onClose: () => void;
    orderId: number | null;
    isVisible: boolean;
    paiementMode: string | undefined;
    counterPaiementId: CounterPaiement['id'];
    listState: OrdersState['listCounterPaiement'];
    updateState: OrdersState['updateCounterPaiement'];
    creationState: OrdersState['createCounterPaiement'];
    deletionState: OrdersState['deleteCounterPaiement'];
    leftToPay: number;
    createCounterPaiement: typeof OrderActions.createCounterPaiement;
    updateCounterPaiement: typeof OrderActions.updateCounterPaiement;
    deleteCounterPaiement: typeof OrderActions.deleteCounterPaiement;
    getCounterPaiementList: typeof OrderActions.listCounterPaiement;
}

const CounterPaiementForm: FC<CounterPeimentProps> = ({
    onClose, orderId, isVisible, paiementMode, form, counterPaiementId, listState, updateState, creationState,
    deletionState, leftToPay, createCounterPaiement, deleteCounterPaiement, updateCounterPaiement,
    getCounterPaiementList }) => {

    const { getFieldDecorator } = form;

    const [counterPaiement, setCounterPaiement] = useState<CounterPaiement | undefined>();

    const priceRef = useRef<Input>(null);

    const getPaimentModeName = () => counterPaiementId ? counterPaiement && getPaiementModeByKey(counterPaiement.method)
        : getPaiementMode(paiementMode);

    useEffect(() => {
        if (listState.success) {
            const counterPaiment = counterPaiementId ?
                listState.data.find((cp) => cp.id === counterPaiementId) : undefined;
            setCounterPaiement(counterPaiment);
        }

    }, [counterPaiementId, listState.success]); // eslint-disable-line react-hooks/exhaustive-deps

    const isVoucherCode = [PaiementModes.holidayVoucher, PaiementModes.mealTicket]
        .find((pm) => pm === getPaimentModeName());

    const amountFormat = (amount: string) => Number(amount && amount.replace(',', '.')) || 0;

    const handleSubmit = (e?: React.FormEvent) => {
        if (e) {
            e.preventDefault();
        }

        form.validateFieldsAndScroll(async (err, values) => {
            if (err) {
                return;
            }

            const amount = amountFormat(values.amount);

            if (counterPaiementId) {
                updateCounterPaiement(counterPaiementId, {
                    ...values,
                    occurrences: values.occurrences ? parseInt(values.occurrences, 10) : undefined,
                    amount,
                });

            } else {
                const method = Object.entries(PaiementModes).reduce((acc, [key, val]) => {
                    return val === paiementMode ? key : acc;
                }, '');

                createCounterPaiement(orderId, {
                    method,
                    ...values,
                    occurrences: values.occurrences ? parseInt(values.occurrences, 10) : undefined,
                    amount,
                });
            }
        });
    };

    const previous = usePrevious<Partial<CounterPeimentProps>>({
        creationState, updateState, deletionState,
    });

    const onSuccess = useCallback(() => {
        form.resetFields();
        getCounterPaiementList(orderId);
        onClose();
    }, [orderId, form, getCounterPaiementList, onClose]);

    const onError = () => {
        message.error('Une erreur s\'est produite, veuillez réessayer plus tard');
    };

    useEffect(() => {
        const isPreviousLoading = previous && previous.creationState && previous.creationState.loading;
        if (isPreviousLoading && !creationState.loading) {
            if (creationState.success) {
                onSuccess();
            } else if (creationState.error) {
                onError();
            }
        }
    }, [creationState.error, creationState.loading, creationState.success, previous,
        onSuccess]);

    useEffect(() => {
        const isPreviousLoading = previous && previous.updateState && previous.updateState.loading;
        if (isPreviousLoading && !updateState.loading) {
            if (updateState.success) {
                onSuccess();
            } else if (updateState.error) {
                onError();
            }
        }
    }, [updateState.error, updateState.loading, updateState.success, previous,
        onSuccess]);

    useEffect(() => {
        const isPreviousLoading = previous && previous.deletionState && previous.deletionState.loading;
        if (isPreviousLoading && !deletionState.loading) {
            if (deletionState.success) {
                onSuccess();
            } else if (deletionState.error) {
                onError();
            }
        }
    }, [deletionState.error, deletionState.loading, deletionState.success, previous,
        onSuccess]);

    const handleCounterPaiementDelete: () => void = () => {
        deleteCounterPaiement(counterPaiementId);
    };

    const renderPresaleForm = () => {
        return (
            <>
                <Col span={24}>
                    <Form.Item label={<Typography.Text strong={true}>Nature </Typography.Text>} >
                        {
                            getFieldDecorator('nature', {
                                rules: [{
                                    required: true,
                                    message: 'Veuillez selectionner un nature !',
                                    min: 0,
                                    enum: Object.keys(preSaleNatures),
                                }],
                                initialValue: `${(counterPaiement && counterPaiement.nature) || ''}`,
                            })((
                                <Select
                                    size="large"
                                    placeholder="Sélectionner la nature de la pré-vente"
                                >
                                    {
                                        Object.entries(preSaleNatures).map(([key, value]) => (
                                            <Select.Option
                                                key={key}
                                                value={key}
                                            >
                                                {value}
                                            </Select.Option>
                                        ),
                                        )
                                    }
                                </Select>
                            ))
                        }
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label={<Typography.Text strong={true}>Nombre d'unité(s) </Typography.Text>} >
                        {
                            getFieldDecorator('occurrences', {
                                rules: [{
                                    required: true,
                                    message: 'Entrez un nombre valide !',
                                    min: 0,
                                    pattern: /^\d+$/,

                                }],
                                initialValue: `${(counterPaiement && counterPaiement.occurrences) || ''}`,
                            })((
                                <Input ref={priceRef} size="large" placeholder="Entrez un nombre" />
                            ))
                        }
                    </Form.Item>
                </Col>
            </ >
        );
    };

    const handleCancel: () => void = () => {
        form.resetFields();
        onClose();
    };

    const setFocus = () => {
        if (priceRef && priceRef.current && isVisible)  {
            priceRef.current.focus();
           }
    };

    const validateAmount =  (rule: any, value: any, cb: any) => {

        const inCludeMoreThanComma = value.replaceAll('.', ',').split(/,/).length > 2;

        const isGreaterThanNeeded = [PaiementModes.cb, PaiementModes.bankCheck, PaiementModes.differed]
        .find((pm) => pm === getPaimentModeName()) && amountFormat(value) > Number(formatCurrency(leftToPay))
        + ((counterPaiement && counterPaiement.amount) || 0);
        console.log('amountFormat(value) ', amountFormat(value));
        console.log('leftToPay ', leftToPay);
        console.log('((counterPaiement && counterPaiement.amount) || 0) ',
        ((counterPaiement && counterPaiement.amount) || 0));
        console.log('included more than comma ', inCludeMoreThanComma);
        console.log('is greater than ', isGreaterThanNeeded);
        if (inCludeMoreThanComma || isGreaterThanNeeded) {
            cb('Entrez un montant valide !');
         } else {
             cb();
         }
        };

    const hasError = () => {
       return Object.values(form.getFieldsError()).some(Boolean);
    };

    return (
        <Drawer
            width={650}
            onClose={handleCancel}
            visible={isVisible}
            title={`Ajouter un paiement - ${getPaimentModeName()}`}
            afterVisibleChange={setFocus}
        >
            <Form>
                <Row type="flex" justify="start" align={isVoucherCode ? 'bottom' : 'middle'} gutter={16}>
                    {
                        getPaimentModeName() === PaiementModes.pre_sale ? renderPresaleForm() : (
                            <Col span={12}>
                                <Form.Item label={<Typography.Text strong={true}>Montant en € </Typography.Text>} >
                                    {
                                        getFieldDecorator('amount', {
                                            rules: [{
                                                required: true,
                                                message: 'Entrez un montant valide !',
                                                min: 0,
                                                pattern: /^\d+([,.]\d+)?$/,
                                            }, {
                                                validator: validateAmount,
                                            }],
                                            initialValue: `${(counterPaiement && counterPaiement.amount) || ''}`,
                                        })((
                                            <Input
                                               ref={priceRef}
                                               size="large"
                                               placeholder="Entrez un montant en euros"
                                            />
                                        ))
                                    }
                                </Form.Item>
                            </Col>
                        )
                    }
                    {isVoucherCode && (
                        <Col span={10}>
                            <Row type="flex" align="middle" justify="start">
                                <Col span={2}>
                                    <Form.Item>
                                        <Typography.Text strong={true}>X</Typography.Text>
                                    </Form.Item>
                                </Col>
                                <Col span={2}>
                                    <Form.Item >
                                        {
                                            getFieldDecorator('occurrences', {
                                                rules: [{
                                                    message: 'Entrez un valeur valide !',
                                                    // pattern: /^\d+$/,
                                                }],
                                                initialValue: `${(counterPaiement && counterPaiement.occurrences)
                                                    || 1}`,
                                            })((
                                                <Input style={{ width: '40px' }} size="large" placeholder="1" />
                                            ))
                                        }
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    )}
                    <Col span={10} className="mt-10">
                        <Typography.Text className="primary-color" strong={true}>
                            <i> Reste à payer : {formatCurrency(leftToPay)} </i>
                        </Typography.Text>
                    </Col>
                </Row>
                <div className="form-actions mt-20">
                    <Button
                        htmlType="submit"
                        type="primary"
                        onClick={handleSubmit}
                        loading={creationState.loading || updateState.loading}
                        disabled={hasError()}
                    >
                        Valider
                    </Button>
                    <Divider type="vertical" />
                    <Button onClick={onClose} type="ghost">
                        Annuler
                   </Button>
                    {
                        counterPaiementId && (
                            <>
                                <Divider type="vertical" />
                                <Popconfirm
                                    title="Voulez-vous vraiment supprimer le paiement"
                                    onConfirm={handleCounterPaiementDelete}
                                    okText="Oui"
                                    cancelText="Non"
                                >
                                    <Button
                                        type="danger"
                                        ghost={true}
                                        loading={deletionState.loading}
                                    >
                                        supprimer le paiement
                                    </Button>
                                </Popconfirm>
                            </>
                        )
                    }
                </div>
            </Form>
        </Drawer>
    );
};

const CounterPaimentWrapper = Form.create<CounterPeimentProps>()(CounterPaiementForm);

const mapStateToProps = (state: MainReducerState) => ({
    listState: getCounterPaimentState(state),
    updateState: getUpdateCounterPaiementState(state),
    creationState: getcreationCounterPaiementState(state),
    deletionState: getDeleteCounterPaiementState(state),
});

export default connect(mapStateToProps, {
    createCounterPaiement: OrderActions.createCounterPaiement,
    updateCounterPaiement: OrderActions.updateCounterPaiement,
    deleteCounterPaiement: OrderActions.deleteCounterPaiement,
    getCounterPaiementList: OrderActions.listCounterPaiement,
})(CounterPaimentWrapper);
