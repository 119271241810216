import { combineReducers } from 'redux';

import * as reduxTypes from '../types/mealTypes';
import { MealType } from '../api/apiTypes';
import { MainReducerState, RequestState } from './';
import { requestReducer } from './_generics';

export interface MealTypesState {
    readonly list: RequestState<MealType[]>;
}

const initialState: MealType[] = [];

export default combineReducers<MealTypesState>({
    list: requestReducer({
        START: reduxTypes.LIST,
        SUCCESS: reduxTypes.LIST_SUCCESS,
        FAILED: reduxTypes.LIST_FAIL,
    }, initialState),
});

export const getMealTypesData = (state: MainReducerState) => state.mealTypes.list.data;
