import { combineReducers } from 'redux';

import * as reduxTypes from '../types/banners';
import { Banner } from '../api/apiTypes';
import { MainReducerState, RequestState } from './';
import { requestReducer } from './_generics';

export interface BannersState {
    readonly list: RequestState<Banner[]>;
    readonly details: RequestState<Banner>;
    readonly create: RequestState<Banner>;
    readonly update: RequestState<Banner>;
}

const initialState: Banner[] = [];

export default combineReducers<BannersState>({
    list: requestReducer({
        START: reduxTypes.LIST,
        SUCCESS: reduxTypes.LIST_SUCCESS,
        FAILED: reduxTypes.LIST_FAIL,
    }, initialState),
    details: requestReducer({
        START: reduxTypes.DETAILS,
        SUCCESS: reduxTypes.DETAILS_SUCCESS,
        FAILED: reduxTypes.DETAILS_FAIL,
    }),
    create: requestReducer({
        START: reduxTypes.CREATE,
        SUCCESS: reduxTypes.CREATE_SUCCESS,
        FAILED: reduxTypes.CREATE_FAIL,
    }),
    update: requestReducer({
        START: reduxTypes.UPDATE,
        SUCCESS: reduxTypes.UPDATE_SUCCESS,
        FAILED: reduxTypes.UPDATE_FAIL,
    }),
});

export const getBannersState = (state: MainReducerState) => state.banners.list;
export const getBannerDetailsState = (state: MainReducerState) => state.banners.details;
export const createBannerState = (state: MainReducerState) => state.banners.create;
export const updateBannerState = (state: MainReducerState) => state.banners.update;
