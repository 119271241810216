import { Action } from 'redux';
import { call, takeLatest, put } from 'redux-saga/effects';

import * as reduxActions from '../actions/discounts';
import * as reduxTypes from '../types/discounts';
import * as api from '../api/discounts';
import { SiteIdQuery } from '../api';
import { IdAction, DataAction } from '../actions';
import { Discount } from '../api/apiTypes';

export function* listSaga(action: Action & SiteIdQuery) {
    try {
        const response = yield call(api.list, action);
        return yield put(reduxActions.listSuccess(response));
    } catch (error) {
        return yield put(reduxActions.listFailed(error));
    }
}

export function* detailsSaga(action: IdAction<Discount['id']> & SiteIdQuery) {
    try {
        const response = yield call(api.details, action.id, action);
        return yield put(reduxActions.detailsSuccess(response));
    } catch (error) {
        return yield put(reduxActions.detailsFailed(error));
    }
}

export function* createSaga(action: SiteIdQuery & DataAction<Partial<Discount>>) {
    try {
        const response = yield call(api.create, action, action.data);
        return yield put(reduxActions.createSuccess(response));
    } catch (error) {
        return yield put(reduxActions.createFailed(error));
    }
}

export function* updateSaga(action: IdAction<Discount['id']> & SiteIdQuery & DataAction<Partial<Discount>>) {
    try {
        const response = yield call(api.update, action.id, action, action.data);
        return yield put(reduxActions.updateSuccess(response));
    } catch (error) {
        return yield put(reduxActions.updateFailed(error));
    }
}

export function* deleteSaga(action: IdAction<Discount['id']> & SiteIdQuery) {
    try {
        const response = yield call(api.del, action.id, action);
        return yield put(reduxActions.deleteSuccess(response));
    } catch (error) {
        return yield put(reduxActions.deleteFailed(error));
    }
}

export default function* discountsSaga() {
    yield takeLatest(reduxTypes.LIST, listSaga);
    yield takeLatest(reduxTypes.DETAILS, detailsSaga);
    yield takeLatest(reduxTypes.CREATE, createSaga);
    yield takeLatest(reduxTypes.UPDATE, updateSaga);
    yield takeLatest(reduxTypes.DELETE, deleteSaga);
}
