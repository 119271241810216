import api from './_client';
import { Parc } from './apiTypes';

export const list = () => {
    return api.get('/cms/sites');
};

export const details = (id: Parc['id']) => {
    return api.get<Parc>(`/cms/site/${id}`);
};

export const create = (body: Parc) => {
    return api.post<Parc>(`/cms/site`, body);
};

export const update = (id: Parc['id'], body: Partial<Parc>) => {
    return api.post<Parc>(`/cms/site/${id}`, body);
};
