import React, { FC, useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { MainReducerState } from '../../store/reducers';
import * as PreSalesActions from '../../store/actions/presales';
import { getPresaleDetailsState, PreSalesState } from '../../store/reducers/presales';
import { PresaleEdit } from '.';
import { Descriptions, Button, Spin } from 'antd';
// tslint:disable-next-line: no-implicit-dependencies
import { RouteChildrenProps } from 'react-router';

interface MatchParams {
  id: string;
}

interface PreSaleProps extends RouteChildrenProps<MatchParams> {
    preSalesState: PreSalesState['details'];
    getDetails: typeof PreSalesActions.details;
    updateParc: typeof PreSalesActions.update;
}

const Presale: FC<PreSaleProps> = ({ match, preSalesState, getDetails }) => {

    const [ isFormVisible, setIsFormVisible ] = useState(false);

    useEffect(() => {
        getDetails(match ? parseInt(match.params.id, 10) : null);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onDrawerClose = () => {
        setIsFormVisible(false);
    };

    const onUpdateSuccess = () => {
        getDetails(match ? parseInt(match.params.id, 10) : null);
    };

    const setEditMode = () => {
        setIsFormVisible(true);
    };

    return (
        <>
            <Spin spinning={preSalesState.loading} tip="Chargement...">
                <Descriptions title="Détails de pré-vente" bordered>
                    <Descriptions.Item label="Référence">
                        {preSalesState.data ? preSalesState.data.natureId : null}
                    </Descriptions.Item>
                    <Descriptions.Item label="Nom">
                        {preSalesState.data ? preSalesState.data.quantity : null}
                    </Descriptions.Item>
                    <Descriptions.Item label="Code postal">
                        {preSalesState.data ? preSalesState.data.siteId : null}
                    </Descriptions.Item>
                    <Descriptions.Item label="Prix total">
                        {preSalesState.data ? preSalesState.data.totalPrice : null}
                    </Descriptions.Item>
                </Descriptions>
            </Spin>
            <Button
                type="primary"
                shape="round"
                icon="edit"
                onClick={setEditMode}
                className="modifier-button"
            >
                Modifier
            </Button>
            <PresaleEdit
                id={preSalesState.data ? preSalesState.data.id : undefined}
                isVisible={isFormVisible}
                onUpdateSuccess={onUpdateSuccess}
                onClose={onDrawerClose}
            />
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    preSaleState: getPresaleDetailsState(state),
});

export default connect(
    mapStateToProps,
    {
      getDetails: PreSalesActions.details,
    },
)(Presale);
