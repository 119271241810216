import api, { urlWithQuery } from './_client';
import { QueryListPayload } from '../actions';
import { OrderReportPayload, ProdReportPayload } from '../types/report';

export const fetch = (payload: QueryListPayload) => {
    return api.get(urlWithQuery('/cms/dashboard', payload));
};

export const getCa = (payload: QueryListPayload) => {
    return api.get<string>(urlWithQuery('cms/export/ca', payload), { responseType: 'blob' });
};

export const getDiscounts = (payload: QueryListPayload) => {
    return api.get<string>(urlWithQuery('cms/export/discounts', payload));
};

export const getDeliveredTurnover = (payload: QueryListPayload) => {
    return api.get<string>(urlWithQuery('cms/kpi/deliveredTurnover', payload));
};

export const getAverageCartSize = (payload: QueryListPayload) => {
    return api.get<string>(urlWithQuery('cms/kpi/averageCartSize', payload));
};

export const getTop10Products = (payload: QueryListPayload) => {
    return api.get<string>(urlWithQuery('cms/kpi/topTenProducts', payload));
};

export const getNotCollected = (payload: QueryListPayload) => {
    return api.get<string>(urlWithQuery('cms/kpi/notCollected', payload));
};

export const getDeliveryTimeRanking = (payload: QueryListPayload) => {
    return api.get<string>(urlWithQuery('cms/kpi/deliveryTimeRanking', payload));
};

export const getMonthlyExport = (payload: QueryListPayload) => {
    return api.get<string>(urlWithQuery('cms/monthlyExport', payload));
};

export const getOrderReport = (siteId: number, payload: OrderReportPayload) => {
    return api.get<string>(urlWithQuery(`/cms/export/${siteId}/orders`, payload), { responseType: 'blob' });
};

export const getProdReport = (siteId: number, payload: ProdReportPayload) => {
    return api.get<string>(urlWithQuery(`/cms/export/${siteId}/prod`, {}, payload), { responseType: 'blob' });
};
