import { combineReducers } from 'redux';

import allergens, { AllergensState } from './allergens';
import auth, { AuthState } from './auth';
import banners, { BannersState } from './banners';
import catalogProducts, { CatalogProductsState } from './catalogProducts';
import clients, { ClientsState } from './clients';
import categories, { CategoriesState } from './categories';
import constraints, { ConstraintsState } from './constraints';
import dashboard, { DashboardState } from './dashboard';
import discounts, { DiscountsState } from './discounts';
import langs, { LangsState } from './langs';
import mealTypes, { MealTypesState } from './mealTypes';
import menus, { MenusState } from './menus';
import orders, { OrdersState } from './orders';
import parcs, { ParcsState } from './parcs';
import productCategories, { ProductCategoriesState } from './productCategories';
import products, { ProductsState } from './products';
import suggestions, { SuggestionsState } from './suggestions';
import restaurants, { RestaurantsState } from './restaurants';
import terms, { TermsState } from './terms';
import timeRanges, { TimeRangesState } from './timeRanges';
import users, { UsersState } from './users';
import presales, { PreSalesState } from './presales';
import presaleNatures, { PreSaleNaturesState } from './presaleNatures';
import logsRegister, { LogsRegisterState } from './logsRegister';

export interface RequestState<T = any> {
    readonly data: T;
    readonly headers: any;
    readonly error?: any;
    readonly loading: boolean;
    readonly response?: any;
    readonly success?: boolean;
    readonly page: number;
    readonly pageSize: number;
    readonly totalItems: number;
    readonly totalPages: number;
    readonly polling: boolean;
}

export interface MainReducerState {
    readonly allergens: AllergensState;
    readonly auth: AuthState;
    readonly banners: BannersState;
    readonly catalogProducts: CatalogProductsState;
    readonly categories: CategoriesState;
    readonly clients: ClientsState;
    readonly constraints: ConstraintsState;
    readonly dashboard: DashboardState;
    readonly discounts: DiscountsState;
    readonly langs: LangsState;
    readonly logsRegister: LogsRegisterState;
    readonly mealTypes: MealTypesState;
    readonly menus: MenusState;
    readonly orders: OrdersState;
    readonly parcs: ParcsState;
    readonly productCategories: ProductCategoriesState;
    readonly products: ProductsState;
    readonly restaurants: RestaurantsState;
    readonly suggestions: SuggestionsState;
    readonly terms: TermsState;
    readonly timeRanges: TimeRangesState;
    readonly users: UsersState;
    readonly presales: PreSalesState;
    readonly presaleNatures: PreSaleNaturesState;
}

const mainReducer = combineReducers<MainReducerState>({
    allergens,
    auth,
    banners,
    catalogProducts,
    categories,
    clients,
    constraints,
    dashboard,
    discounts,
    langs,
    logsRegister,
    mealTypes,
    menus,
    orders,
    parcs,
    productCategories,
    products,
    suggestions,
    restaurants,
    terms,
    timeRanges,
    users,
    presales,
    presaleNatures,
});

export default mainReducer;
