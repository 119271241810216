import { combineReducers } from 'redux';

import * as reduxTypes from '../types/catalogProducts';
import { CatalogProduct, SuppIngredientForCatalogProduct } from '../api/apiTypes';
import { MainReducerState, RequestState } from './';
import { requestReducer } from './_generics';

export interface CatalogProductsState {
    readonly list: RequestState<CatalogProduct[]>;
    readonly details: RequestState<CatalogProduct>;
    readonly listSelect: RequestState<CatalogProduct[]>;
    readonly updates: RequestState<Partial<CatalogProduct>>;
    readonly listSuppIngredients: RequestState<SuppIngredientForCatalogProduct[]>;
    readonly listAllSuppIngredients: RequestState<SuppIngredientForCatalogProduct[]>;
    readonly assignesSuppIngredient: RequestState;
    readonly unAssignesSuppIngredient: RequestState;
}

const initialState: CatalogProduct[] = [];

export default combineReducers<CatalogProductsState>({
    list: requestReducer<CatalogProduct[]>({
        START: reduxTypes.LIST,
        SUCCESS: reduxTypes.LIST_SUCCESS,
        FAILED: reduxTypes.LIST_FAIL,
    }, initialState),
    details: requestReducer<CatalogProduct>({
        START: reduxTypes.DETAILS,
        SUCCESS: reduxTypes.DETAILS_SUCCESS,
        FAILED: reduxTypes.DETAILS_FAIL,
    }),
    listSelect: requestReducer<CatalogProduct[]>({
        START: reduxTypes.LIST_SELECT,
        SUCCESS: reduxTypes.LIST_SELECT_SUCCESS,
        FAILED: reduxTypes.LIST_SELECT_FAIL,
    }, initialState),
    updates: requestReducer<Partial<CatalogProduct>>({
        START: reduxTypes.UPDATE,
        SUCCESS: reduxTypes.UPDATE_SUCCESS,
        FAILED: reduxTypes.UPDATE_FAIL,
        RESET: reduxTypes.RESET_SUCCESS,
    }),
    listSuppIngredients: requestReducer<SuppIngredientForCatalogProduct[]>({
        START: reduxTypes.LIST_SUPP_INGREDIENTS,
        SUCCESS: reduxTypes.LIST_SUPP_INGREDIENTS_SUCCESS,
        FAILED: reduxTypes.LIST_SUPP_INGREDIENTS_FAIL,
    }, []),
    listAllSuppIngredients: requestReducer<SuppIngredientForCatalogProduct[]>({
        START: reduxTypes.LIST_ALL_SUPP_INGREDIENTS,
        SUCCESS: reduxTypes.LIST_ALL_SUPP_INGREDIENTS_SUCCESS,
        FAILED: reduxTypes.LIST_ALL_SUPP_INGREDIENTS_FAIL,
    }, []),
    assignesSuppIngredient: requestReducer({
        START: reduxTypes.ASSIGN_SUPP_INGREDIENTS,
        SUCCESS: reduxTypes.ASSIGN_SUPP_INGREDIENTS_SUCCESS,
        FAILED: reduxTypes.ASSIGN_SUPP_INGREDIENTS_FAIL,
    }),
    unAssignesSuppIngredient: requestReducer({
        START: reduxTypes.UNASSIGN_SUPP_INGREDIENTS,
        SUCCESS: reduxTypes.UNASSIGN_SUPP_INGREDIENTS_SUCCESS,
        FAILED: reduxTypes.UNASSIGN_SUPP_INGREDIENTS_FAIL,
    }),
});

export const getCatalogProductsState = (state: MainReducerState) => state.catalogProducts.list;
export const getCatalogProductSelectListState = (state: MainReducerState) => state.catalogProducts.listSelect;
export const getCatalogProductDetailsState = (state: MainReducerState) => state.catalogProducts.details;
export const getCatalogProductUpdatesState = (state: MainReducerState) => state.catalogProducts.updates;
export const getSuppIngredientsListState = (state: MainReducerState) => state.catalogProducts.listSuppIngredients;
export const getAllSuppIngredientsListState = (state: MainReducerState) => state.catalogProducts.listAllSuppIngredients;
export const getAssignesSuppIngredientState = (state: MainReducerState) => state.catalogProducts.assignesSuppIngredient;
export const getUnAssignesSuppIngredientState = (state: MainReducerState) =>
    state.catalogProducts.unAssignesSuppIngredient;
