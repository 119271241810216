import React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Spin, Icon } from 'antd';

import 'react-quill/dist/quill.snow.css';
import './assets/styles/AntOverride.less';

import App from './App';
import configureStore from './configureStore';

Spin.setDefaultIndicator(<Icon type="loading" spin />);

const store = configureStore();

const render = (Component: React.ComponentType) => {
    return ReactDOM.render(
        (
            <Provider store={store}>
                <BrowserRouter>
                    <Component />
                </BrowserRouter>
            </Provider>
        ),
        document.getElementById('root') as HTMLElement,
    );
};

render(App);

if (module.hot) {
    module.hot.accept('./App', () => {
        const NextApp = require('./App').default;
        render(NextApp);
    });
}
