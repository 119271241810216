import { call, takeLatest, put } from 'redux-saga/effects';

import * as reduxActions from '../actions/categories';
import * as reduxTypes from '../types/categories';
import * as api from '../api/categories';
import { IdAction, DataAction } from '../actions';
import { Category, Subcategory } from '../api/apiTypes';

export function* listCategoriesSaga() {
    try {
        const response = yield call(api.listCategories);
        return yield put(reduxActions.listCategoriesSuccess(response));
    } catch (error) {
        return yield put(reduxActions.listCategoriesFailed(error));
    }
}

export function* listSubcategoriesSaga() {
    try {
        const response = yield call(api.listSubcategories);
        return yield put(reduxActions.listSubcategoriesSuccess(response));
    } catch (error) {
        return yield put(reduxActions.listSubcategoriesFailed(error));
    }
}

export function* detailsCategorySaga(action: IdAction<Category['id']>) {
    try {
        const response = yield call(api.detailsCategory, action.id);
        return yield put(reduxActions.detailsCategorySuccess(response));
    } catch (error) {
        return yield put(reduxActions.detailsCategoryFailed(error));
    }
}

export function* detailsSubcategorySaga(action: IdAction<Subcategory['id']>) {
    try {
        const response = yield call(api.detailsSubcategory, action.id);
        return yield put(reduxActions.detailsSubcategorySuccess(response));
    } catch (error) {
        return yield put(reduxActions.detailsSubcategoryFailed(error));
    }
}

export function* createCategorySaga(action: DataAction) {
    try {
        const response = yield call(api.createCategory, action.data);
        return yield put(reduxActions.createCategorySuccess(response));
    } catch (error) {
        return yield put(reduxActions.createCategoryFailed(error));
    }
}

export function* createSubcategorySaga(action: DataAction) {
    try {
        const response = yield call(api.createSubcategory, action.data);
        return yield put(reduxActions.createSubcategorySuccess(response));
    } catch (error) {
        return yield put(reduxActions.createSubcategoryFailed(error));
    }
}

export function* updateCategorySaga(action: IdAction<Category['id']> & DataAction) {
    try {
        const response = yield call(api.updateCategory, action.id, action.data);
        return yield put(reduxActions.updateCategorySuccess(response));
    } catch (error) {
        return yield put(reduxActions.updateCategoryFailed(error));
    }
}

export function* updateSubcategorySaga(action: IdAction<Subcategory['id']> & DataAction) {
    try {
        const response = yield call(api.updateSubcategory, action.id, action.data);
        return yield put(reduxActions.updateSubcategorySuccess(response));
    } catch (error) {
        return yield put(reduxActions.updateSubcategoryFailed(error));
    }
}

export default function* categoriesSaga() {
    yield takeLatest(reduxTypes.LIST_CATEGORIES, listCategoriesSaga);
    yield takeLatest(reduxTypes.LIST_SUBCATEGORIES, listSubcategoriesSaga);
    yield takeLatest(reduxTypes.DETAILS_CATEGORY, detailsCategorySaga);
    yield takeLatest(reduxTypes.DETAILS_SUBCATEGORY, detailsSubcategorySaga);
    yield takeLatest(reduxTypes.CREATE_CATEGORY, createCategorySaga);
    yield takeLatest(reduxTypes.CREATE_SUBCATEGORY, createSubcategorySaga);
    yield takeLatest(reduxTypes.UPDATE_CATEGORY, updateCategorySaga);
    yield takeLatest(reduxTypes.UPDATE_SUBCATEGORY, updateSubcategorySaga);
}
