export const LIST_CATEGORIES = 'categories/LIST_CATEGORIES';
export const LIST_CATEGORIES_SUCCESS = 'categories/LIST_CATEGORIES_SUCCESS';
export const LIST_CATEGORIES_FAIL = 'categories/LIST_CATEGORIES_FAIL';

export const LIST_SUBCATEGORIES = 'categories/LIST_SUBCATEGORIES';
export const LIST_SUBCATEGORIES_SUCCESS = 'categories/LIST_SUBCATEGORIES_SUCCESS';
export const LIST_SUBCATEGORIES_FAIL = 'categories/LIST_CATEGORIES_FAIL';

export const DETAILS_CATEGORY = 'categories/DETAILS_CATEGORY';
export const DETAILS_CATEGORY_SUCCESS = 'categories/DETAILS_CATEGORY_SUCCESS';
export const DETAILS_CATEGORY_FAIL = 'categories/DETAILS_CATEGORY_FAIL';

export const DETAILS_SUBCATEGORY = 'categories/DETAILS_SUBCATEGORY';
export const DETAILS_SUBCATEGORY_SUCCESS = 'categories/DETAILS_SUBCATEGORY_SUCCESS';
export const DETAILS_SUBCATEGORY_FAIL = 'categories/DETAILS_SUBCATEGORY_FAIL';

export const CREATE_CATEGORY = 'categories/CREATE_CATEGORY';
export const CREATE_CATEGORY_SUCCESS = 'categories/CREATE_CATEGORY_SUCCESS';
export const CREATE_CATEGORY_FAIL = 'categories/CREATE_CATEGORY_FAIL';

export const CREATE_SUBCATEGORY = 'categories/CREATE_SUBCATEGORY';
export const CREATE_SUBCATEGORY_SUCCESS = 'categories/CREATE_SUBCATEGORY_SUCCESS';
export const CREATE_SUBCATEGORY_FAIL = 'categories/CREATE_SUBCATEGORY_FAIL';

export const UPDATE_CATEGORY = 'categories/UPDATE_CATEGORY';
export const UPDATE_CATEGORY_SUCCESS = 'categories/UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_FAIL = 'categories/UPDATE_CATEGORY_FAIL';

export const UPDATE_SUBCATEGORY = 'categories/UPDATE_SUBCATEGORY';
export const UPDATE_SUBCATEGORY_SUCCESS = 'categories/UPDATE_SUBCATEGORY_SUCCESS';
export const UPDATE_SUBCATEGORY_FAIL = 'categories/UPDATE_SUBCATEGORY_FAIL';
