export const LIST = 'restaurants/LIST';
export const LIST_SUCCESS = 'restaurants/LIST_SUCCESS';
export const LIST_FAIL = 'restaurants/LIST_FAIL';

export const CREATE = 'restaurants/CREATE';
export const CREATE_SUCCESS = 'restaurants/CREATE_SUCCESS';
export const CREATE_FAIL = 'restaurants/CREATE_FAIL';

export const DETAILS = 'restaurants/DETAILS';
export const DETAILS_SUCCESS = 'restaurants/DETAILS_SUCCESS';
export const DETAILS_FAIL = 'restaurants/DETAILS_FAIL';

export const UPDATE = 'restaurants/UPDATE';
export const UPDATE_SUCCESS = 'restaurants/UPDATE_SUCCESS';
export const UPDATE_FAIL = 'restaurants/UPDATE_FAIL';

export const LIST_SELECT = 'restaurants/LIST_SELECT';
export const LIST_SELECT_SUCCESS = 'restaurants/LIST_SELECT_SUCCESS';
export const LIST_SELECT_FAIL = 'restaurants/LIST_SELECT_FAIL';
