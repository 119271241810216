import { combineReducers } from 'redux';

import * as reduxTypes from '../types/parcs';
import { Parc } from '../api/apiTypes';
import { MainReducerState, RequestState } from './';
import { requestReducer } from './_generics';

export interface ParcsState {
    readonly list: RequestState<Parc[]>;
    readonly details: RequestState<Parc>;
    readonly create: RequestState<Parc>;
    readonly update: RequestState<Partial<Parc>>;
    readonly selected: Parc['id'] | null;
}

const initialState: Parc[] = [];
const initialSelected = null;

export default combineReducers<ParcsState>({
    list: requestReducer({
        START: reduxTypes.LIST,
        SUCCESS: reduxTypes.LIST_SUCCESS,
        FAILED: reduxTypes.LIST_FAIL,
    }, initialState),
    details: requestReducer({
        START: reduxTypes.DETAILS,
        SUCCESS: reduxTypes.DETAILS_SUCCESS,
        FAILED: reduxTypes.DETAILS_FAIL,
    }),
    create: requestReducer({
        START: reduxTypes.CREATE,
        SUCCESS: reduxTypes.CREATE_SUCCESS,
        FAILED: reduxTypes.CREATE_FAIL,
    }),
    update: requestReducer({
        START: reduxTypes.UPDATE,
        SUCCESS: reduxTypes.UPDATE_SUCCESS,
        FAILED: reduxTypes.UPDATE_FAIL,
    }),
    selected: (state = initialSelected, action) => {
        switch (action.type) {
            case reduxTypes.SET_SELECTED:
                const newState = action.data;
                return newState;
            default:
                return state;
        }
    },
});

export const getParcsState = (state: MainReducerState) => state.parcs.list;
export const getSelectedParcState = (state: MainReducerState) => state.parcs.selected;
export const getParcDetailsState = (state: MainReducerState) => state.parcs.details;
export const createParcState = (state: MainReducerState) => state.parcs.create;
export const updateParcState = (state: MainReducerState) => state.parcs.update;
