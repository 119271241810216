import api, { urlWithQuery } from './_client';
import { Menu } from './apiTypes';
import { QueryListPayload } from '../actions';

export const list = (payload: QueryListPayload) => {
    return api.get(urlWithQuery('/cms/menus', payload));
};

export const details = (id: Menu['id']) => {
    return api.get<Menu>(`/cms/menu/${id}`);
};

export const update = (id: Menu['id'], body: Partial<Menu>) => {
    return api.put<Menu>(`/cms/menu/${id}`, body);
};
