import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Form, Drawer, Row, Col, Icon, Switch, Input, Divider, Button, Spin } from 'antd';
import { FormComponentProps } from 'antd/lib/form';

import * as RestaurantsActions from '../../store/actions/restaurants';
import { MainReducerState } from '../../store/reducers';
import { getRestaurantDetailsState, updateRestaurantState, RestaurantsState, createRestaurantState } from '../../store/reducers/restaurants';
import { DrawerProps } from 'antd/lib/drawer';
import { ButtonProps } from 'antd/lib/button';

interface RestaurantEditProps extends FormComponentProps {
    creates: RestaurantsState['create'];
    createRestaurant: typeof RestaurantsActions.create;
    getDetails: typeof RestaurantsActions.details;
    id?: number;
    isVisible: boolean;
    siteId: number | null;
    onClose: () => void;
    onUpdateSuccess: () => void;
    restaurantState: RestaurantsState['details'];
    updateRestaurant: typeof RestaurantsActions.update;
    updates: RestaurantsState['update'];
}

const RestaurantEdit: FC<RestaurantEditProps> = ({
    creates, createRestaurant, form, getDetails, id, isVisible, onClose,
    onUpdateSuccess, restaurantState, siteId, updateRestaurant, updates,
}) => {
    const [isEditing, setIsEditing] = useState(false);
    useEffect(() => {
        if (id) {
            setIsEditing(true);
            getDetails(id, {
                siteId,
            });
        }
    }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (creates.success || updates.success) {
            form.resetFields();
            onUpdateSuccess();
            setIsEditing(false);
            onClose();
        }
    }, [creates.success, updates.success]); // eslint-disable-line react-hooks/exhaustive-deps

    const { getFieldDecorator } = form;

    const onSwitchChange = (val: boolean) => {
        form.setFieldsValue({isEnabled: val});
    };

    const onDrawerClose: DrawerProps['onClose'] & ButtonProps['onClick'] = () => {
        form.resetFields();
        setIsEditing(false);
        onClose();
    };
    const onSubmit = (e?: React.FormEvent) => {
        if (e) {
            e.preventDefault();
        }
        form.validateFieldsAndScroll(async (err, val) => {
            if (err) {
                return;
            }
            if (id !== undefined) {
                updateRestaurant(id, {siteId}, val);
            } else {
                const payload = {
                    ...val,
                    siteId,
                };
                createRestaurant({siteId}, payload);
            }
        });
    };

    return (
        <>
            <Drawer
                title={id !== undefined ? 'Modification de Restaurant' :
                    'Création de Restaurant'}
                width={960}
                onClose={onDrawerClose}
                visible={isVisible}
                className="edit-product-drawer"
            >
                <Spin spinning={restaurantState.loading}>
                {(!isEditing || (isEditing && !restaurantState.loading && restaurantState.data)) && (
                    <Form>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label="Nom du restaurant">
                                    {getFieldDecorator('label', {
                                        rules: [{
                                            required: true,
                                            message: 'champ requis',
                                        }],
                                        initialValue: isEditing && restaurantState.data ?
                                            restaurantState.data.label : '',
                                    })((
                                        <Input placeholder="Entrez un nom"/>
                                    ))}
                                </Form.Item>
                            </Col>
                            <Col span={4} offset={4}>
                                <Form.Item label="Actif">
                                    {getFieldDecorator('isEnabled', {
                                        valuePropName: 'checked',
                                        initialValue: isEditing && restaurantState.data ?
                                            restaurantState.data.isEnabled : true,
                                    })((
                                        <Switch
                                            checkedChildren={<Icon type="check" />}
                                            unCheckedChildren={<Icon type="close" />}
                                            onChange={onSwitchChange}
                                        />
                                    ))}
                                </Form.Item>
                            </Col>
                        </Row>
                        <div className="form-actions">
                            <Button htmlType="submit" type="primary" onClick={onSubmit} loading={updates.loading}>
                                {id !== undefined ? 'Mettre à jour' : 'Ajouter'}
                            </Button>
                            <Divider type="vertical"/>
                            <Button onClick={onDrawerClose} type="ghost">
                                Annuler
                            </Button>
                        </div>
                    </Form>
                )}
                </Spin>
            </Drawer>
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    creates: createRestaurantState(state),
    restaurantState: getRestaurantDetailsState(state),
    updates: updateRestaurantState(state),
});

const RestaurantFormDrawer = Form.create<RestaurantEditProps>()(RestaurantEdit);

export default connect(
    mapStateToProps,
    {
        createRestaurant: RestaurantsActions.create,
        getDetails: RestaurantsActions.details,
        updateRestaurant: RestaurantsActions.update,
    },
)(RestaurantFormDrawer);
