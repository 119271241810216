import React, { FC, useEffect } from 'react';
import { Button, DatePicker, Form, message, Modal, Spin } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import { FormComponentProps } from 'antd/lib/form';
import * as DashboardActions from '../../store/actions/dashboard';
import { MainReducerState } from '../../store/reducers';
import { DashboardState, getOrderReportState } from '../../store/reducers/dashboard';
import { usePrevious } from '../../utils';
import { downloadFile } from '../../utils/downloadFile';

export interface OrderReportModalProps extends FormComponentProps {
    siteId: number | null;
    display: boolean;
    setDisplay: (display: boolean) => void;
    getOrderReport: typeof DashboardActions.getOrderReport;
    orderReportState: DashboardState['getOrderReport'];
}

const OrderReportModal: FC<OrderReportModalProps> = ({
    siteId,
    display,
    setDisplay,
    form,
    getOrderReport,
    orderReportState,
}) => {
    const { getFieldDecorator } = form;

    function closeModal() {
        setDisplay(false);
    }

    const onSubmit = (e?: React.FormEvent) => {
        if (e) {
            e.preventDefault();
        }
        form.validateFieldsAndScroll(async (err, val) => {
            if (err) {
                return;
            }
            getOrderReport(siteId, {
                startDate : val.startDate ? val.startDate.format('YYYY-MM-DD') : undefined,
                endDate : val.endDate.format('YYYY-MM-DD'),
            });
        });
    };

    const prevProps = usePrevious<Partial<OrderReportModalProps>>({
        orderReportState,
    });

    useEffect(() => {
        if (prevProps && prevProps.orderReportState && prevProps.orderReportState.loading &&
            !orderReportState.loading && orderReportState.success && orderReportState.data
        ) {
            if (orderReportState.data.data && orderReportState.data.headers && orderReportState.data.headers.filename) {
                downloadFile(orderReportState.data.data, orderReportState.data.headers.filename);
                setDisplay(false);
            } else {
                message.error('Problème avec le fichier ou le nom du fichier.');
                console.warn(
                    'caState.success data, headers',
                    orderReportState.data.data,
                    orderReportState.data.headers,
                );
            }
        }
    }, [orderReportState, prevProps, setDisplay]);

    return (
        <Modal
            title="Télécharger les commandes"
            centered
            onCancel={closeModal}
            visible={display}
            width={1000}
            footer={null}
        >
            <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} autoComplete="off" onSubmit={onSubmit}>
                <Form.Item
                    label="Début"
                >
                    {getFieldDecorator('startDate')(
                        <DatePicker/>,
                    )}

                </Form.Item>
                <Form.Item label="Fin">
                    {getFieldDecorator('endDate', {
                        rules: [{
                            required: true,
                            message: 'champ requis',
                        }],
                        initialValue: moment(),
                    })(
                        <DatePicker/>,
                    )}
                </Form.Item>
                <Form.Item>
                    {orderReportState.loading
                        ? <Spin/>
                        : <Button type="primary" htmlType="submit">Télécharger</Button>
                    }
                </Form.Item>
            </Form>
        </Modal>
    );
};

const OrderReportModalDrawer = Form.create<OrderReportModalProps>()(OrderReportModal);
const mapStateToProps = (state: MainReducerState) => ({
    orderReportState: getOrderReportState(state),
});
export default connect(mapStateToProps, {
    getOrderReport: DashboardActions.getOrderReport,
})(OrderReportModalDrawer);
