export const LIST = 'users/LIST';
export const LIST_SUCCESS = 'users/LIST_SUCCESS';
export const LIST_FAIL = 'users/LIST_FAIL';

export const DETAILS = 'users/DETAILS';
export const DETAILS_SUCCESS = 'users/DETAILS_SUCCESS';
export const DETAILS_FAIL = 'users/DETAILS_FAIL';

export const CREATE = 'users/CREATE';
export const CREATE_SUCCESS = 'users/CREATE_SUCCESS';
export const CREATE_FAIL = 'users/CREATE_FAIL';

export const UPDATE = 'users/UPDATE';
export const UPDATE_SUCCESS = 'users/UPDATE_SUCCESS';
export const UPDATE_FAIL = 'users/UPDATE_FAIL';

// export const DELETE = 'users/DELETE';
// export const DELETE_SUCCESS = 'users/DELETE_SUCCESS';
// export const DELETE_FAIL = 'users/DELETE_FAIL';
