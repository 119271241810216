import React, { FC, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { ColumnProps } from 'antd/lib/table';
import { Button, Table, Popconfirm, Divider, Switch, Icon } from 'antd';
import moment from 'moment';
// import { InputProps, SearchProps } from 'antd/lib/input';

import { MainReducerState } from '../../store/reducers';
import { User } from '../../store/api/apiTypes';
import * as UsersActions from '../../store/actions/users';
import { UsersState , getUsersState, updateUserState, createUserState} from '../../store/reducers/users';
import { ParcsState, getSelectedParcState } from '../../store/reducers/parcs';

import UserEdit from './UserEdit';
import ButtonLink from '../../components/ButtonLink';
import { getRoute, RoutePathName } from '../../routes';

interface UsersProps {
    creates: UsersState['create'];
    // deleteUser: typeof UsersActions.del;
    // deletes: UsersState['delete'];
    getUsers: typeof UsersActions.list;
    siteIdState: ParcsState['selected'];
    updates: UsersState['update'];
    updateUser: typeof UsersActions.update;
    usersState: UsersState['list'];
}

const Users: FC<UsersProps> = ({
    creates, getUsers, updates, updateUser, usersState, siteIdState,
}) => {

    const [ isFormVisible, setIsFormVisible ] = useState(false);
    const [ selectedId, setSelectedId ] = useState();

    useEffect(() => {
        getUsers({
            siteId: siteIdState,
        });
    }, [siteIdState]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (creates.success || updates.success) {
            getUsers({
                siteId: siteIdState,
            });
        }
    }, [creates.success, updates.success]); // eslint-disable-line react-hooks/exhaustive-deps

    const setEditMode = (id: number) => {
        setSelectedId(id);
        setIsFormVisible(true);
    };

    const onUpdateSuccess = () => {
        getUsers({
            siteId: siteIdState,
        });
    };

    const onDrawerClose = () => {
        setIsFormVisible(false);
    };

    // const onSearchChange: InputProps['onChange'] = (e) => {
    //     setSearch(e.target.value);
    // };

    // const onSearch: SearchProps['onSearch'] = (value) => {
    //     setSearch(value);
    // };

    const onSwitchChange = (elem: User) => {
        const payload = {
            ...elem,
            isEnabled: !elem.isEnabled,
        };
        updateUser(elem.id, payload);
    };

    const onAddNew  = () => {
        setSelectedId(undefined);
        setIsFormVisible(true);
    };

    // const onDeleteUser = (elem: User['id']) => {
    //     deleteUser(elem, {siteId: siteIdInit});
    // };

    const rowKey = (item: User) => `${item.id}`;

    const columns: Array<ColumnProps<User>> = [
        // {
        //     dataIndex: 'id',
        //     title: 'Id',
        // },
        {
            dataIndex: 'siteId',
            title: 'Id parc',
            render: (text, record) => (record.siteId !== null ? record.siteId :
                <Icon type="android"/>
            ),
        },
        {
            dataIndex: 'lastName',
            title: 'Nom',
        },
        {
            dataIndex: 'firstName',
            title: 'Prénom',
        },
        {
            dataIndex: 'login',
            title: 'Login',
        },
        {
            dataIndex: 'role',
            title: 'Fonction',
        },
        {
            dataIndex: 'creationDate',
            title: 'Crée le',
            render: (text, record) => (record.creationDate ?
                moment(record.creationDate).format('DD/MM/YYYY') : null
            ),
        },
        {
            dataIndex: 'phone',
            title: 'Téléphone',
        },
        // {
        //     dataIndex: 'contact',
        //     title: 'Contact',
        // },
        {
            dataIndex: 'isEnabled',
            title: 'Statut',
            render: (text, record) => (
                <Popconfirm
                    title="Voulez-vous vraiment changer le statut?"
                    onConfirm={onSwitchChange.bind(null, record)}
                    okText="Oui"
                    cancelText="Non"
                >
                    <Switch
                        checked={text}
                        checkedChildren={<Icon type="check" />}
                        unCheckedChildren={<Icon type="close" />}
                    />
                </Popconfirm>
            ),
        },
        {
            dataIndex: 'Actions',
            title: 'Actions',
            render: (text, record) => (
                <>
                    <ButtonLink
                        className="list-action-button-details"
                        to={getRoute(RoutePathName.user, { id: record.id })}
                    >
                        Détails
                    </ButtonLink>
                    <Divider type="vertical"/>
                    <Button
                        icon="edit"
                        className="list-action-button-edit"
                        onClick={setEditMode.bind(null, record.id)}
                    />
                    {/* <Divider type="vertical"/>
                    <Popconfirm
                        title="Voulez-vous vraiment changer le statut?"
                        onConfirm={onDeleteUser.bind(null, record.id)}
                        okText="Oui"
                        cancelText="Non"
                    >
                        <Button icon="delete" type="danger" />
                    </Popconfirm> */}
                </>
            ),
        },

    ];

    return (
        <>
            <div className="list-header">
                <div className="list-inline">
                    <h1 className="list-header-title">Liste des employés</h1>
                    {/* <div className="search-input">
                        <Input.Search
                            className="table-search"
                            placeholder="Rechercher"
                            onChange={onSearchChange}
                            onSearch={onSearch}
                            size="small"
                        />
                    </div> */}
                </div>
                <Button
                    type="primary"
                    icon="plus-circle"
                    shape="round"
                    className="list-create-element"
                    onClick={onAddNew}
                >
                    Ajouter un employé
                </Button>
            </div>
            <Table<User>
                bordered={false}
                rowKey={rowKey}
                columns={columns}
                dataSource={usersState.data}
                loading={usersState.loading}
            />
            <UserEdit
                id={selectedId}
                siteId={siteIdState}
                onUpdateSuccess={onUpdateSuccess}
                isVisible={isFormVisible}
                onClose={onDrawerClose}
            />
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    usersState: getUsersState(state),
    creates: createUserState(state),
    // deletes: deleteUserState(state),
    updates: updateUserState(state),
    siteIdState: getSelectedParcState(state),
});

export default connect(
    mapStateToProps,
    {
        // deleteUser: UsersActions.del,
        getUsers: UsersActions.list,
        updateUser: UsersActions.update,
    },
)(Users);
