export const LIST = 'suggestions/LIST';
export const LIST_SUCCESS = 'suggestions/LIST_SUCCESS';
export const LIST_FAIL = 'suggestions/LIST_FAIL';

export const CREATE = 'suggestions/CREATE';
export const CREATE_SUCCESS = 'suggestions/CREATE_SUCCESS';
export const CREATE_FAIL = 'suggestions/CREATE_FAIL';

export const DELETE = 'suggestions/DELETE';
export const DELETE_SUCCESS = 'suggestions/DELETE_SUCCESS';
export const DELETE_FAIL = 'suggestions/DELETE_FAIL';

export const DETAIL = 'suggestions/DETAIL';
export const DETAIL_SUCCESS = 'suggestions/DETAIL_SUCCESS';
export const DETAIL_FAIL = 'suggestions/DETAIL_FAIL';

export const UPDATE = 'suggestions/UPDATE';
export const UPDATE_SUCCESS = 'suggestions/UPDATE_SUCCESS';
export const UPDATE_FAIL = 'suggestions/UPDATE_FAIL';
