import { combineReducers } from 'redux';

import * as reduxTypes from '../types/products';
import { Product, ProductVariant } from '../api/apiTypes';
import { MainReducerState, RequestState } from './';
import { requestReducer } from './_generics';

export interface ProductsState {
    readonly list: RequestState<Product[]>;
    readonly listSelect: RequestState<Product[]>;
    readonly details: RequestState<Product>;
    readonly update: RequestState<Partial<Product>>;
    readonly variantsList: RequestState<ProductVariant[]>;
    readonly addVariant: RequestState<ProductVariant>;
    readonly removeVariant: RequestState<ProductVariant>;
}

const initialState: Product[] = [];

export default combineReducers<ProductsState>({
    list: requestReducer({
        START: reduxTypes.LIST,
        SUCCESS: reduxTypes.LIST_SUCCESS,
        FAILED: reduxTypes.LIST_FAIL,
    }, initialState),
    listSelect: requestReducer({
        START: reduxTypes.LIST_SELECT,
        SUCCESS: reduxTypes.LIST_SELECT_SUCCESS,
        FAILED: reduxTypes.LIST_SELECT_FAIL,
    }, initialState),
    details: requestReducer({
        START: reduxTypes.DETAILS,
        SUCCESS: reduxTypes.DETAILS_SUCCESS,
        FAILED: reduxTypes.DETAILS_FAIL,
    }),
    update: requestReducer({
        START: reduxTypes.UPDATE,
        SUCCESS: reduxTypes.UPDATE_SUCCESS,
        FAILED: reduxTypes.UPDATE_FAIL,
        RESET: reduxTypes.RESET_SUCCESS,
    }),
    variantsList: requestReducer({
        START: reduxTypes.LIST_VARIANTS,
        SUCCESS: reduxTypes.LIST_VARIANTS_SUCCESS,
        FAILED: reduxTypes.LIST_VARIANTS_FAIL,
    }),
    addVariant: requestReducer({
        START: reduxTypes.ADD_VARIANT,
        SUCCESS: reduxTypes.ADD_VARIANT_SUCCESS,
        FAILED: reduxTypes.ADD_VARIANT_FAIL,
    }),
    removeVariant: requestReducer({
        START: reduxTypes.REMOVE_VARIANT,
        SUCCESS: reduxTypes.REMOVE_VARIANT_SUCCESS,
        FAILED: reduxTypes.REMOVE_VARIANT_FAIL,
    }),
});

export const getProducts = (state: MainReducerState) => state.products.list;
export const getSelectProducts = (state: MainReducerState) => state.products.listSelect;
export const getProductDetailsState = (state: MainReducerState) => state.products.details;
export const updateProductState = (state: MainReducerState) => state.products.update;
export const getVariantsList = (state: MainReducerState) => state.products.variantsList;
export const addVariantState = (state: MainReducerState) => state.products.addVariant;
export const removeVariantState = (state: MainReducerState) => state.products.removeVariant;
