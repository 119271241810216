import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Table, Tooltip } from 'antd';
import { ColumnProps, TableProps } from 'antd/lib/table';

import { getCartType, Order, OrderStatus } from '../../store/api/apiTypes';
import * as OrderActions from '../../store/actions/orders';
import { MainReducerState } from '../../store/reducers';
import { OrdersState, getWaitingForPaiementOrders } from '../../store/reducers/orders';

import Constants from '../../utils/constants';
import { getFullName, formatCurrency, getOrderPrices } from '../../utils';
import ButtonLink from '../../components/ButtonLink';
import SearchOrder from './SearchOrder';
import { getRoute, RoutePathName } from '../../routes';
import { InputProps } from 'antd/lib/input';

export interface OrdersProps {
    siteId: number | null;
    waitForPaiementOrders: OrdersState['waitForPaiements'];
    getWaitForPaiementList: typeof OrderActions.waitForPaiment;
    stopPolling: typeof OrderActions.stopPoll;
    startPolling: typeof OrderActions.startPoll;
    isActive: boolean;
}

let timer: number;

const WaitForPaiementOrdersTab: FC<OrdersProps> = ({
    siteId, getWaitForPaiementList, isActive,
    waitForPaiementOrders, startPolling,
}) => {

    const [searchValue, setSearchValue] = useState<string>('');

    useEffect(() => {
        if (!isActive) {
            fetchOrdersAndPoll();
        } else {
            fetchOrdersAndPoll(searchValue);
        }
    }, [isActive]); // eslint-disable-line react-hooks/exhaustive-deps

    const onTableChange: TableProps<Order>['onChange'] = (pagination, filters) => {
        getWaitForPaiementList({
            status: OrderStatus.waitForPaiement,
            searchString: searchValue,
            limit: Constants.PAGE_SIZE,
            siteId,
            page: (pagination.current || 1) - 1,
        });
        startPolling();
    };
    const handlesearchChange: InputProps['onChange'] = (e) => {

        clearTimeout(timer);
        const search = e.target.value;
        timer = window.setTimeout(() => {
            setSearchValue(search);
            fetchOrdersAndPoll(search);

        }, 300);

    };

    const fetchOrdersAndPoll = (searchString?: string) => {

        getWaitForPaiementList({
            status: OrderStatus.waitForPaiement,
            ...(searchString ? {
                searchString,
            } : {}),
            limit: Constants.PAGE_SIZE,
            siteId,
        });
        startPolling();
    };

    const rowKey = (item: Order) => `${item.id}`;

    const columns: Array<ColumnProps<Order>> = [
        {
            dataIndex: 'reference',
            title: 'Référence',
        },
        {
            dataIndex: 'cart.creationDate',
            title: 'Date et heure cmde',
            render: (val) => `${
                new Intl.DateTimeFormat('fr-FR').format(
                    new Date(val),
                )
                } - ${
                new Intl.DateTimeFormat('fr-FR', { hour: 'numeric', minute: 'numeric' }).format(
                    new Date(val),
                )
                }`,
        },
        {
            title: 'Client',
            render: (order: Order) => {
                const { client, cart } = order;
                return client ? getFullName(client.firstName, client.lastName) : cart.clientEmail;
            },
        },
        {
            dataIndex: 'cart.cartType',
            title: 'Canal',
            render: (val) => val ? getCartType(val) : null,
        },
        {
            title: 'Cottage',
            render: (record: Order) => record.client ? record.client.vacation.cottageNumber : record.cart.cottageNumber,
        },
        {
            key: 'totalWithDiscount',
            title: 'Total',
            render: (record) => {

                const prices = getOrderPrices(record);
                return (
                <Tooltip
                    title={(
                        <p>
                            Total sans taxes: {
                                formatCurrency(record.cart.totalWithoutTax)
                            }<br />
                            Total avec TVA sans réduc: {
                                formatCurrency(record.cart.totalWithTaxWithoutDiscount)
                            }<br />
                            Total avec TVA et réduc: {
                                formatCurrency(record.cart.totalWithTaxAndDiscount)
                            }<br />
                            Total livré sans taxes: {
                                formatCurrency(record.cart.deliveredProductsTotalWithoutTax)
                            }<br />
                            Total livré avec TVA sans réduc: {
                                formatCurrency(record.cart.deliveredProductsTotalWithTaxWithoutDiscount)
                            }<br />
                            Total livré avec TVA et réduc: {
                                formatCurrency(record.cart.deliveredProductsTotalWithTaxAndDiscount)
                            }<br />
                        </p>
                    )}
                >
                        {formatCurrency(prices.withTaxAndDiscount)}
                </Tooltip>
                );
            },

        },
        {
            dataIndex: 'cart.discount.code',
            title: 'Code réduc',

        },
        {
            dataIndex: 'cart.reference',
            title: 'Ref panier',

        },
        {
            title: 'Actions',
            key: 'actions',
            fixed: 'right',
            render: (record) => (
                <ButtonLink
                    to={getRoute(RoutePathName.order, { id: record.id })}
                >
                    Détails
                </ButtonLink>
            ),
            width: 100,

        },
    ];
    return (
        <>
            <SearchOrder
                onSearch={fetchOrdersAndPoll}
                onChange={handlesearchChange}

            />
            <Table<Order>
                bordered={false}
                rowKey={rowKey}
                columns={columns}
                scroll={{ x: 850 }}
                onChange={onTableChange}
                dataSource={waitForPaiementOrders.data}
                loading={waitForPaiementOrders.loading}
                pagination={{
                    current: waitForPaiementOrders.page + 1,
                    pageSize: waitForPaiementOrders.pageSize,
                    total: waitForPaiementOrders.totalItems,
                }}
            />
        </>

    );
};

const mapStateToProps = (state: MainReducerState) => ({
    waitForPaiementOrders: getWaitingForPaiementOrders(state),
});

export default connect(
    mapStateToProps,
    {
        getWaitForPaiementList: OrderActions.waitForPaiment,
        stopPolling: OrderActions.stopPoll,
        startPolling: OrderActions.startPoll,
    },
)(WaitForPaiementOrdersTab);
