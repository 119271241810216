import api, { urlWithQuery } from './_client';
import { CGU, CGV, FAQ } from './apiTypes';
import { QueryListPayload } from '../actions';

export const fetchCGU = (payload: QueryListPayload) => {
    return api.get(urlWithQuery('/cms/terms/cgu', payload));
};
export const updateCGU = (body: CGU) => {
    return api.post<CGU>(`/cms/terms/cgu`, body);
};

export const fetchCGV = (payload: QueryListPayload) => {
    return api.get(urlWithQuery('/cms/terms/cgv', payload));
};

export const updateCGV = (body: CGV) => {
    return api.post<CGV>(`/cms/terms/cgv`, body);
};

export const fetchFAQ = (payload: QueryListPayload) => {
    return api.get(urlWithQuery('/cms/terms/faq', payload));
};

export const updateFAQ = (body: FAQ) => {
    return api.post<FAQ>(`/cms/terms/faq`, body);
};
