import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Table, Icon, Button, message } from 'antd';
import { ColumnProps, TableProps } from 'antd/lib/table';

import { Menu, MenuItem } from '../../store/api/apiTypes';
import * as MenuActions from '../../store/actions/menus';
import { MainReducerState } from '../../store/reducers';
import { getMenusListState, MenusState } from '../../store/reducers/menus';

import Constants from '../../utils/constants';
import { Link } from 'react-router-dom';
import { getRoute, RoutePathName } from '../../routes';
import EmptyResult from '../../components/EmptyResult';
import { MenuEdit } from '.';
import { t } from '../../utils';
import { ParcsState, getSelectedParcState } from '../../store/reducers/parcs';

export interface MenusProps {
    menusListState: MenusState['list'];
    getList: typeof MenuActions.list;
    siteIdState: ParcsState['selected'];
}

const Menus: FC<MenusProps> = ({ menusListState, getList, siteIdState }) => {
    useEffect(() => {
        if (siteIdState !== null) {
            getList({
                limit: Constants.PAGE_SIZE,
                siteId: siteIdState,
            });
        } else {
            message.error('Veuillez sélectionner un site.');
        }
    }, [siteIdState]); // eslint-disable-line react-hooks/exhaustive-deps

    const [ isFormVisible, setIsFormVisible ] = useState(false);
    const [ selectedId, setSelectedId ] = useState();
    const [ selectedItemId, setSelectedItemId ] = useState();

    const setEditMode = (id: Menu['id'], itemId: MenuItem['id']) => {
        setIsFormVisible(true);
        setSelectedId(id);
        setSelectedItemId(itemId);
    };

    const onDrawerClose = () => {
        setIsFormVisible(false);
    };

    const onUpdateSuccess = () => {
        if (siteIdState !== null) {
            getList({
                limit: Constants.PAGE_SIZE,
                siteId: siteIdState,
            });
        } else {
            message.error('Veuillez sélectionner un site.');
        }
    };

    const rowKey =  (item: Menu) => `${item.id}`;

    const columns: Array<ColumnProps<Menu>> = [
        {
            dataIndex: 'internalRef',
            title: 'Référence',
        },
        {
            key: 'label',
            title: 'Label technique',
            render: (val, record) => (
                <Link to={getRoute(RoutePathName.product, { id: record.id })}>
                    {record.techLabel}
                </Link>
            ),
        },
        {
            dataIndex: 'description',
            title: 'Description',
            render: t,
        },
        {
            dataIndex: 'isEnabled',
            title: 'Actif',
            render: (val) => val === true ? <Icon type="check" /> : '',
        },
    ];

    const menuDetailTableColumns: Array<ColumnProps<MenuItem>> = [
        {
            dataIndex: 'levelName',
            title: 'Section',
            render: t,
        },
        {
            dataIndex: 'product',
            title: 'Produit',
            render: (val) => <Link to={getRoute(RoutePathName.product, { id: val.id })}>{val.techLabel}</Link>,
        },
        {
            dataIndex: 'price',
            title: 'Prix',
            render: (val) => new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(val),
        },
        {
            dataIndex: 'vatRate',
            title: 'TVA',
            render: (val) => `${val}%`,
        },
        {
            dataIndex: 'isOptional',
            title: 'Optionnel',
            render: (val) => val === true ? <Icon type="check" /> : '',
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (
                <>
                    <Button
                        icon="edit"
                        className="list-action-button-edit"
                        onClick={setEditMode.bind(null, record.mainProductId, record.id)}
                    />
                </>
            ),
        },
    ];

    const SubTable: TableProps<Menu>['expandedRowRender'] = (menu) => {
        const subRowKey = (item: MenuItem) => `${item.id}`;

        return (
            <Table<MenuItem>
                columns={menuDetailTableColumns}
                dataSource={menu.menuDetails}
                locale={{
                    emptyText: <EmptyResult />,
                }}
                pagination={false}
                rowKey={subRowKey}
            />
        );
    };

    return (
        <>
            <div className="list-header">
                <h1>Liste des menus</h1>
            </div>
            <Table<Menu>
                bordered={false}
                columns={columns}
                dataSource={menusListState.data}
                expandedRowRender={SubTable}
                loading={menusListState.loading}
                // onChange={onTableChange}
                // pagination={{
                //     current: menusListState.page + 1,
                //     pageSize: menusListState.pageSize,
                //     total: menusListState.totalItems,
                // }}
                rowKey={rowKey}
            />
            <MenuEdit
                id={selectedId}
                menuItemId={selectedItemId}
                onUpdateSuccess={onUpdateSuccess}
                isVisible={isFormVisible}
                onClose={onDrawerClose}
            />
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    menusListState: getMenusListState(state),
    siteIdState: getSelectedParcState(state),
});

export default connect(
    mapStateToProps,
    {
        getList: MenuActions.list,
    },
)(Menus);
