import { ActionCreator } from 'redux';

import * as reduxTypes from '../types/constraints';
import { DataAction, IdAction, QueryListAction, QueryListPayload } from '.';
import { DeliveryTimeConstraint } from '../api/apiTypes';

export const list: ActionCreator<QueryListAction> = (payload: QueryListPayload) => ({
    type: reduxTypes.LIST,
    ...payload,
});

export const listSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_SUCCESS,
    data,
});

export const listFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_FAIL,
    data,
});

export const details: ActionCreator<IdAction<DeliveryTimeConstraint['id']>> =
(id: DeliveryTimeConstraint['id']) => ({
    type: reduxTypes.DETAILS,
    id,
});

export const detailsSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.DETAILS_SUCCESS,
    data,
});

export const detailsFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.DETAILS_FAIL,
    data,
});

export const create: ActionCreator<DataAction> = (data: DeliveryTimeConstraint) => ({
    type: reduxTypes.CREATE,
    data,
});

export const createSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.CREATE_SUCCESS,
    data,
});

export const createFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.CREATE_FAIL,
    data,
});

export const update: ActionCreator<DataAction> = (id: DeliveryTimeConstraint['id'],
                                                  data: DeliveryTimeConstraint) => ({
    type: reduxTypes.UPDATE,
    id,
    data,
});

export const updateSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.UPDATE_SUCCESS,
    data,
});

export const updateFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.UPDATE_FAIL,
    data,
});

export const remove: ActionCreator<DataAction> = (id: DeliveryTimeConstraint['id'],
                                                  data: Partial<DeliveryTimeConstraint>) => ({
    type: reduxTypes.REMOVE,
    id,
    data,
});

export const removeSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.REMOVE_SUCCESS,
    data,
});
export const removeFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.REMOVE_FAIL,
    data,
});
