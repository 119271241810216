import api, { urlWithQuery } from './_client';
import { User } from './apiTypes';
import { QueryListPayload } from '../actions';

export const list = (payload: QueryListPayload) => {
    return api.get(urlWithQuery('/cms/users', payload));
};

export const details = (id: User['id']) => {
    return api.get<User>(`/cms/user/${id}`);
};

export const create = (body: Partial<User>) => {
    return api.post(`/cms/user`, body);
};

export const update = (id: User['id'], body: Partial<User>) => {
    return api.put(`/cms/user/${id}`, body);
};

// export const del = (id: User['id'], payload: QueryListPayload) => {
//     return api.delete(urlWithQuery(`/cms/user/${id}`, payload));
// };
