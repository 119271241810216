import api, { urlWithQuery } from './_client';
import { DeliveryTimeConstraint, Restaurant } from './apiTypes';
import { QueryListPayload } from '../actions';

export const list = (id: Restaurant['id'], payload: QueryListPayload) => {
    return api.get(urlWithQuery(`/cms/deliveryConstraints/restaurant/${id}`, payload));
};

export const details = (id: DeliveryTimeConstraint['id']) => {
    return api.get<DeliveryTimeConstraint>(`/cms/deliveryConstraint/${id}`);
};

export const create = (body: DeliveryTimeConstraint) => {
    return api.post<DeliveryTimeConstraint>(`/cms/deliveryConstraint`, body);
};

export const update = (id: DeliveryTimeConstraint['id'], body: DeliveryTimeConstraint) => {
    return api.put<DeliveryTimeConstraint>(`/cms/deliveryConstraint/${id}`, body);
};
