export const FETCH = 'dashboard/FETCH';
export const FETCH_SUCCESS = 'dashboard/FETCH_SUCCESS';
export const FETCH_FAIL = 'dashboard/FETCH_FAIL';

export const GET_CA = 'dashboard/GET_CA';
export const GET_CA_SUCCESS = 'dashboard/GET_CA_SUCCESS';
export const GET_CA_FAIL = 'dashboard/GET_CA_FAIL';

export const GET_DISCOUNTS = 'dashboard/GET_DISCOUNTS';
export const GET_DISCOUNTS_SUCCESS = 'dashboard/GET_DISCOUNTS_SUCCESS';
export const GET_DISCOUNTS_FAIL = 'dashboard/GET_DISCOUNTS_FAIL';

export const GET_DELIVERED_TURNOVER = 'dashboard/GET_DELIVERED_TURNOVER';
export const GET_DELIVERED_TURNOVER_SUCCESS = 'dashboard/GET_DELIVERED_TURNOVER_SUCCESS';
export const GET_DELIVERED_TURNOVER_FAIL = 'dashboard/GET_DELIVERED_TURNOVER_FAIL';

export const GET_AVERAGE_CART_SIZE = 'dashboard/GET_AVERAGE_CART_SIZE';
export const GET_AVERAGE_CART_SIZE_SUCCESS = 'dashboard/GET_AVERAGE_CART_SIZE_SUCCESS';
export const GET_AVERAGE_CART_SIZE_FAIL = 'dashboard/GET_AVERAGE_CART_SIZE_FAIL';

export const GET_TOP10_PRODUCTS = 'dashboard/GET_TOP10_PRODUCTS';
export const GET_TOP10_PRODUCTS_SUCCESS = 'dashboard/GET_TOP10_PRODUCTS_SUCCESS';
export const GET_TOP10_PRODUCTS_FAIL = 'dashboard/GET_TOP10_PRODUCTS_FAIL';

export const GET_NOT_COLLECTED = 'dashboard/GET_NOT_COLLECTED';
export const GET_NOT_COLLECTED_SUCCESS = 'dashboard/GET_NOT_COLLECTED_SUCCESS';
export const GET_NOT_COLLECTED_FAIL = 'dashboard/GET_NOT_COLLECTED_FAIL';

export const GET_DELIVERY_TIME_RANKING = 'dashboard/GET_DELIVERY_TIME_RANKING';
export const GET_DELIVERY_TIME_RANKING_SUCCESS = 'dashboard/GET_DELIVERY_TIME_RANKING_SUCCESS';
export const GET_DELIVERY_TIME_RANKING_FAIL = 'dashboard/GET_DELIVERY_TIME_RANKING_FAIL';

export const GET_MONTHLY_EXPORT_SUCCESS = 'dashboard/GET_MONTHLY_EXPORT_SUCCESS';
export const GET_MONTHLY_EXPORT = 'dashboard/GET_MONTHLY_EXPORT';
export const GET_MONTHLY_EXPORT_FAIL = 'dashboard/GET_MONTHLY_EXPORT_FAIL';

export const GET_ORDER_REPORT_SUCCESS = 'dashboard/GET_ORDER_REPORT_SUCCESS';
export const GET_ORDER_REPORT = 'dashboard/GET_ORDER_REPORT';
export const GET_ORDER_REPORT_FAIL = 'dashboard/GET_ORDER_REPORT_FAIL';

export const GET_PROD_REPORT_SUCCESS = 'dashboard/GET_PROD_REPORT_SUCCESS';
export const GET_PROD_REPORT = 'dashboard/GET_PROD_REPORT';
export const GET_PROD_REPORT_FAIL = 'dashboard/GET_PROD_REPORT_FAIL';
