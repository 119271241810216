import { combineReducers } from 'redux';

import * as reduxTypes from '../types/suggestions';
import { Suggestion } from '../api/apiTypes';
import { MainReducerState, RequestState } from './';
import { requestReducer } from './_generics';

export interface SuggestionsState {
    readonly list: RequestState<Suggestion[]>;
    readonly create: RequestState<Suggestion>;
    readonly delete: RequestState<Suggestion>;
    readonly detail: RequestState<Suggestion>;
    readonly update: RequestState<Suggestion>;
}

const initialState: Suggestion[] = [];

export default combineReducers<SuggestionsState>({
    list: requestReducer({
        START: reduxTypes.LIST,
        SUCCESS: reduxTypes.LIST_SUCCESS,
        FAILED: reduxTypes.LIST_FAIL,
    }, initialState),
    create: requestReducer({
        START: reduxTypes.CREATE,
        SUCCESS: reduxTypes.CREATE_SUCCESS,
        FAILED: reduxTypes.CREATE_FAIL,
    }),
    delete: requestReducer({
        START: reduxTypes.DELETE,
        SUCCESS: reduxTypes.DELETE_SUCCESS,
        FAILED: reduxTypes.DELETE_FAIL,
    }),
    detail: requestReducer({
        START: reduxTypes.DETAIL,
        SUCCESS: reduxTypes.DETAIL_SUCCESS,
        FAILED: reduxTypes.DETAIL_FAIL,
    }),
    update: requestReducer({
        START: reduxTypes.UPDATE,
        SUCCESS: reduxTypes.UPDATE_SUCCESS,
        FAILED: reduxTypes.UPDATE_FAIL,
    }),
});

export const getSuggestionsState = (state: MainReducerState) => state.suggestions.list;
export const createSuggestionState = (state: MainReducerState) => state.suggestions.create;
export const deleteSuggestionState = (state: MainReducerState) => state.suggestions.delete;
export const getSuggestionDetailsState = (state: MainReducerState) => state.suggestions.detail;
export const updateSuggestionState = (state: MainReducerState) => state.suggestions.update;
