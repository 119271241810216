import { ActionCreator } from 'redux';
import { DataAction, IdAction } from './';

import * as reduxTypes from '../types/logsRegister';
import { LogRegister } from '../api/apiTypes';

export const list: ActionCreator<IdAction<LogRegister['id']>> = (id: LogRegister['id']) => ({
  type: reduxTypes.LIST,
  id,
});

export const listSuccess: ActionCreator<DataAction> = (data: any) => ({
  type: reduxTypes.LIST_SUCCESS,
  data,
});

export const listFailed: ActionCreator<DataAction> = (data: any) => ({
  type: reduxTypes.LIST_FAIL,
  data,
});
