export const LIST = 'products/LIST';
export const LIST_SUCCESS = 'products/LIST_SUCCESS';
export const LIST_FAIL = 'products/LIST_FAIL';

export const LIST_SELECT = 'products/LIST_SELECT';
export const LIST_SELECT_SUCCESS = 'products/LIST_SELECT_SUCCESS';
export const LIST_SELECT_FAIL = 'products/LIST_SELECT_FAIL';

export const DETAILS = 'products/DETAILS';
export const DETAILS_SUCCESS = 'products/DETAILS_SUCCESS';
export const DETAILS_FAIL = 'products/DETAILS_FAIL';

export const CREATE = 'products/CREATE';
export const CREATE_SUCCESS = 'products/CREATE_SUCCESS';
export const CREATE_FAIL = 'products/CREATE_FAIL';

export const UPDATE = 'products/UPDATE';
export const UPDATE_SUCCESS = 'products/UPDATE_SUCCESS';
export const UPDATE_FAIL = 'products/UPDATE_FAIL';

export const RESET_SUCCESS = 'products/RESET_SUCCESS';

export const LIST_VARIANTS = 'products/LIST_VARIANTS';
export const LIST_VARIANTS_SUCCESS = 'products/LIST_VARIANTS_SUCCESS';
export const LIST_VARIANTS_FAIL = 'products/LIST_VARIANTS_FAIL';

export const ADD_VARIANT = 'products/ADD_VARIANT';
export const ADD_VARIANT_SUCCESS = 'products/ADD_VARIANT_SUCCESS';
export const ADD_VARIANT_FAIL = 'products/ADD_VARIANT_FAIL';

export const REMOVE_VARIANT = 'products/REMOVE_VARIANT';
export const REMOVE_VARIANT_SUCCESS = 'products/REMOVE_VARIANT_SUCCESS';
export const REMOVE_VARIANT_FAIL = 'products/REMOVE_VARIANT_FAIL';
