import api, { urlWithQuery } from './_client';
import { QueryListPayload } from '../actions';
import { Client } from './apiTypes';

export const list = (payload: QueryListPayload) => {
    return api.get(urlWithQuery('/cms/clients', payload));
};

export const details = (id: Client['id']) => {
    return api.get<Client>(`/cms/client/${id}`);
};
