import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Form, Drawer, Spin, Button, Icon, Divider, Switch, Input, Row, Col, InputNumber, DatePicker, Alert } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import moment from 'moment';

import { MainReducerState } from '../../store/reducers';
import * as DiscountsActions from '../../store/actions/discounts';
import { DiscountsState, getDiscountDetailsState, updateDiscountState, createDiscountState } from '../../store/reducers/discounts';
import { Discount } from '../../store/api/apiTypes';
import { DrawerProps } from 'antd/lib/drawer';
import { ButtonProps } from 'antd/lib/button';

interface DiscountEditProps extends FormComponentProps {
    id?: Discount['id'];
    createDiscount: typeof DiscountsActions.create;
    creates: DiscountsState['create'];
    discountDetails: DiscountsState['details'];
    getDetails: typeof DiscountsActions.details;
    isVisible: boolean;
    onUpdateSuccess: () => void;
    onClose: () => void;
    updateDiscount: typeof DiscountsActions.update;
    updates: DiscountsState['update'];
    siteId: number | null;
}

const DiscountEdit: FC<DiscountEditProps> = ({
    id, createDiscount, creates, discountDetails, form, getDetails, isVisible,
    onUpdateSuccess, onClose, siteId, updateDiscount, updates,
}) => {

    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        if (id) {
            setIsEditing(true);
            getDetails(id, {
                siteId,
            });
        }
    }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (creates.success || updates.success) {
            form.resetFields();
            onUpdateSuccess();
            onClose();
            setIsEditing(false);
        }
    }, [creates.success, updates.success]); // eslint-disable-line react-hooks/exhaustive-deps

    const { getFieldDecorator } = form;

    const onDrawerClose: DrawerProps['onClose'] & ButtonProps['onClick'] = () => {
        form.resetFields();
        setIsEditing(false);
        onClose();
    };

    const onSubmit = (e?: React.FormEvent) => {
        if (e) {
            e.preventDefault();
        }
        form.validateFieldsAndScroll(async (err, val) => {
            if (err) {
                return;
            }
            const payload = {
                ...val,
                siteId, // à commenter après modif de la route API get discounts
                startDate: val.startDate.format('YYYY-MM-DD HH:mm:ss'),
                endDate: val.endDate.format('YYYY-MM-DD HH:mm:ss'),
            };
            if (id !== undefined) {
                updateDiscount(id, {siteId}, payload);
            } else {
                createDiscount({siteId}, payload);
            }
        });
    };

    const canEditValue = (field: string) => {
        if (form.getFieldValue(field) === 0 || form.getFieldValue(field) === undefined) {
                return false;
        }
        return true;
    };

    const dateFormat: string = 'DD/MM/YYYY';

    return (
        <>
            <Drawer
                title={id !== undefined ? 'Modification de Réduction' : 'Création de Réduction'}
                width={960}
                onClose={onDrawerClose}
                visible={isVisible}
                className="edit-product-drawer"
            >
                <Spin spinning={discountDetails.loading}>
                    {(!isEditing || (isEditing && !discountDetails.loading && discountDetails.data)) && (
                        <Form>
                            <Row gutter={16}>
                                <Col span={10}>
                                    <Form.Item label="Nom">
                                        {getFieldDecorator('label', {
                                            rules: [{
                                                required: true,
                                                message: 'champ requis',
                                            }],
                                            initialValue: isEditing && discountDetails.data ?
                                                discountDetails.data.label : '',
                                        })((
                                            <Input placeholder="Entrez un nom"/>
                                        ))}
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="Code">
                                        {getFieldDecorator('code', {
                                            rules: [{
                                                required: true,
                                                message: 'champ requis',
                                            }],
                                            initialValue: isEditing && discountDetails.data ?
                                                discountDetails.data.code : '',
                                        })((
                                            <Input placeholder="Entrez le code souhaité"/>
                                        ))}
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item label="Utilisations max">
                                        {getFieldDecorator('maxUseCount', {
                                            rules: [{
                                                required: true,
                                                type: 'number',
                                                message: 'champ requis',
                                            }],
                                            initialValue: isEditing && discountDetails.data ?
                                                discountDetails.data.maxUseCount : 0,
                                        })((
                                            <InputNumber min={1} placeholder="Entrez un nombre"/>
                                        ))}
                                    </Form.Item>
                                </Col>
                                <Col span={3} offset={1}>
                                    <Form.Item label="Actif">
                                        {getFieldDecorator('isEnabled', {
                                            valuePropName: 'checked',
                                            initialValue: isEditing && discountDetails.data ?
                                                discountDetails.data.isEnabled : true,
                                        })((
                                            <Switch
                                                checkedChildren={<Icon type="check" />}
                                                unCheckedChildren={<Icon type="close" />}
                                            />
                                        ))}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={4}>
                                    <Form.Item label="Montant min.">
                                        {getFieldDecorator('minOrderAmount', {
                                            rules: [{
                                                required: true,
                                                type: 'number',
                                                message: 'champ requis',
                                            }],
                                            initialValue: isEditing && discountDetails.data ?
                                            discountDetails.data.minOrderAmount : 0,
                                        })((
                                            <InputNumber  min={0} placeholder="Entrez un nombre"/>
                                        ))}
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item label="Réduc en €">
                                        {getFieldDecorator('amountValue', {
                                            rules: [{
                                                required: true,
                                                type: 'number',
                                                message: 'champ requis',
                                            }],
                                            initialValue: isEditing && discountDetails.data ?
                                                discountDetails.data.amountValue : 0,
                                        })((
                                            <InputNumber
                                                min={0}
                                                placeholder="Entrez un nombre"
                                                disabled={canEditValue('percentValue')}
                                            />
                                        ))}
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item label="Réduc en %">
                                        {getFieldDecorator('percentValue', {
                                            rules: [{
                                                required: true,
                                                type: 'number',
                                                message: 'champ requis',
                                            }],
                                            initialValue: isEditing && discountDetails.data ?
                                                discountDetails.data.percentValue : 0,
                                        })((
                                            <InputNumber
                                                min={0}
                                                placeholder="Entrez un nombre"
                                                disabled={canEditValue('amountValue')}
                                            />
                                        ))}
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item label="Date de début">
                                        {getFieldDecorator('startDate', {
                                            rules: [{
                                                required: true,
                                                message: 'champ requis',
                                            }],
                                            initialValue: isEditing && discountDetails.data ?
                                                moment(discountDetails.data.startDate) : undefined,
                                        })((
                                            <DatePicker
                                                format={dateFormat}
                                                placeholder="Choisissez une date"
                                            />
                                        ))}
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item label="Date de fin">
                                        {getFieldDecorator('endDate', {
                                            rules: [{
                                                required: true,
                                                message: 'champ requis',
                                            }],
                                            initialValue: isEditing && discountDetails.data ?
                                                moment(discountDetails.data.endDate) : undefined,
                                        })((
                                            <DatePicker
                                                format={dateFormat}
                                                placeholder="Choisissez une date"
                                            />
                                        ))}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <div className="form-actions">
                                <Button htmlType="submit" type="primary" onClick={onSubmit} loading={updates.loading}>
                                    {id !== undefined ? 'Mettre à jour' : 'Ajouter'}
                                </Button>
                                <Divider type="vertical"/>
                                <Button onClick={onDrawerClose} type="ghost">
                                    Annuler
                                </Button>
                            </div>
                            <div className="modifier-button">
                                {!creates.loading && creates.error && (
                                    <Alert
                                        type="error"
                                        message={creates.error.errorCode === 'DISCOUNT_EXISTS' ?
                                            'Ce code de réduction est déjà utilisé!' :
                                            creates.error.errorMessage}
                                        showIcon
                                    />
                                )}
                            </div>
                            <div className="modifier-button">
                                {!updates.loading && updates.error && (
                                    <Alert
                                        type="error"
                                        message={updates.error.errorCode === 'DISCOUNT_EXISTS' ?
                                            'Ce code de réduction est déjà utilisé!' :
                                            updates.error.errorMessage}
                                        showIcon
                                    />
                                )}
                            </div>
                        </Form>
                    )}
                </Spin>
            </Drawer>
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    creates: createDiscountState(state),
    discountDetails: getDiscountDetailsState(state),
    updates: updateDiscountState(state),
});

const DiscountFormDrawer = Form.create<DiscountEditProps>()(DiscountEdit);

export default connect(
    mapStateToProps,
    {
        createDiscount: DiscountsActions.create,
        getDetails: DiscountsActions.details,
        updateDiscount: DiscountsActions.update,
    },
)(DiscountFormDrawer);
