import { combineReducers } from 'redux';

import * as reduxTypes from '../types/productCategories';
import { ProductCategory } from '../api/apiTypes';
import { MainReducerState, RequestState } from './';
import { requestReducer } from './_generics';

export interface ProductCategoriesState {
    readonly list: RequestState<ProductCategory[]>;
}

const initialState: ProductCategory[] = [];

export default combineReducers<ProductCategoriesState>({
    list: requestReducer<ProductCategory[]>({
        START: reduxTypes.LIST,
        SUCCESS: reduxTypes.LIST_SUCCESS,
        FAILED: reduxTypes.LIST_FAIL,
    }, initialState),
});

export const getProductCategoriesData = (state: MainReducerState) => state.productCategories.list.data;
