import { IconProps } from 'antd/lib/icon';
import { FC } from 'react';

enum Origin {
  user = 'user',
  process = 'process',
  web = 'web',
  cms = 'cms',
  borne = 'borne',
  job = 'job',
}

export interface LogRegister {
  id: number;
  status: OrderStatus;
  origin: Origin;
  user?: string;
  createdAt: string;
}

export enum DeliveryModes {
    Cottage = 'C',
    Takeaway = 'T',
}

export const getDeliveryModeType = (mode: string) => {
    switch (mode) {
        case DeliveryModes.Cottage:
            return 'Cottage';
        case DeliveryModes.Takeaway:
            return 'À emporter';
        default:
            return '';
    }
};

export enum OrderStatus {
    waitForPrepare = 'waitForPrepare',
    preparing = 'preparing',
    collected = 'collected',
    prepared = 'prepared',
    delivered = 'delivered',
    cancelled = 'cancelled',
    waitForPaiement = 'waitForPayement',
    refunded = 'refunded',
}
export type OrderStatusLevel = {
    [status in OrderStatus]: number
};

export const OrderStatusLevels: OrderStatusLevel = {
    [OrderStatus.waitForPaiement]: 0,
    [OrderStatus.waitForPrepare]: 1,
    [OrderStatus.preparing]: 2,
    [OrderStatus.prepared]: 3,
    [OrderStatus.collected]: 4,
    [OrderStatus.delivered]: 5,
    [OrderStatus.cancelled]: 6,
    [OrderStatus.refunded]: 7,
};

export enum PaiementModes {
    cb = 'CB',
    cash = 'Espèces',
    mealTicket = 'Ticket Restaurant',
    bankCheck = 'Chèque',
    holidayVoucher = 'Chèque-Vacances',
    differed = 'Paiement différé',
    pre_sale = 'Pré-ventes',
}

export enum preSaleNatures {
    f_breackfast_adulte = 'breackfast adulte',
    f_breackfast_children = 'breackfast children',
    p_essential = 'essential',
    p_fireplace = 'fireplace',
}

export interface PreSale {
    id: number;
    natureId: number;
    quantity: number;
    totalPrice: number;
    userId: number;
    userUpdateId: number;
    orderRef: string;
    siteId: number;
    paymentMethod: string;
    cotage: number;
    nature: PreSaleNature;
}

export interface PreSaleNature {
    id: number;
    price: number;
    siteId: number;
    nature: string;
    isEnabled: boolean;
}

export interface CounterPaiement {
    amount: number;
    method: string;
    orderId: Order['id'];
    occurrences?: number;
    returned?: number;
    id?: number;
    nature?: string;
    userId?: number;
}
export const getPaiementModeByKey = (search: string | undefined) => {

    return Object.entries(PaiementModes).reduce((acc, [key, value]) => {
        return search === key ? value : acc;

    }, 'mode de paiement inconnu');
};

export const getPaiementMode = (value: string | undefined) => {

    switch (value) {
        case PaiementModes.cb:
            return PaiementModes.cb;
        case PaiementModes.bankCheck:
            return PaiementModes.bankCheck;
        case PaiementModes.cash:
            return PaiementModes.cash;
        case PaiementModes.differed:
            return PaiementModes.differed;
        case PaiementModes.holidayVoucher:
            return PaiementModes.holidayVoucher;
        case PaiementModes.mealTicket:
            return PaiementModes.mealTicket;
        case PaiementModes.pre_sale:
            return PaiementModes.pre_sale;
        default:
            return 'mode de paiement inconnu';

    }
};
export interface CounterPaiement {
    amount: number;
    method: string;
    orderId: Order['id'];
    occurrences?: number;
    returned?: number;
    id?: number;
    nature?: string;
}

export const getOrderStatusName = (status: OrderStatus) => {
    switch (status) {
        case OrderStatus.waitForPrepare:
            return 'En attente de préparation';

        case OrderStatus.preparing:
            return 'En cours de préparation';
        case OrderStatus.collected:
            return 'Collectée';

        case OrderStatus.prepared:
            return 'Commande préparée';

        case OrderStatus.delivered:
            return 'Livrée';

        case OrderStatus.waitForPaiement:
            return 'En attente de paiement';
        case OrderStatus.cancelled:
            return 'Annulée';
        case OrderStatus.refunded:
            return 'Remboursée';

        default:
            return 'statut inconnu';
    }
};

export const getOrderStatusColor = (status: OrderStatus) => {
    switch (status) {
        case OrderStatus.waitForPrepare:
            return '#bfbfbf';

        case OrderStatus.preparing:
        case OrderStatus.collected:
        case OrderStatus.prepared:
            return 'blue';

        case OrderStatus.delivered:
            return 'green';

        case OrderStatus.cancelled:
            return 'red';

        case OrderStatus.waitForPaiement:
            return 'orange';
        case OrderStatus.refunded:
            return 'purple';

        default:
            return 'orange';
    }
};

export enum UserRole {
    admin = 'admin',
    delivery = 'delivery',
    collect = 'collect',
    superAdmin = 'superAdmin',
    desk = 'desk',
}

export const getUserRole = (role: UserRole) => {
    switch (role) {
        case UserRole.admin:
            return 'Admin site';
        case UserRole.superAdmin:
            return 'Super admin';
        case UserRole.collect:
            return 'Préparateur';
        case UserRole.delivery:
            return 'Livreur';
        case UserRole.desk:
            return 'Desk';
        default:
            return 'role inconnu';
    }
};

export interface Allergen {
    id: number;
    label: Label;
    allergenCode: string;
}

export interface Banner {
    id: number;
    creationDate?: string;
    label: LabelBanner;
    languageCode: Lang['languageCode'];
    filename: string;
    image?: string;
    imageType?: string;
    imageUrl?: string;
    isEnabled: boolean;
}

export interface LabelBanner {
    fr: string;
    en?: string;
}

export enum CartType {
    web = 'web',
    kiosk = 'kiosk',
}

export const getCartType = (cartType: CartType) => {
    switch (cartType) {
        case CartType.web:
            return 'Web';
        case CartType.kiosk:
            return 'Borne';
        default:
            return 'Canal inconnu';
    }
};

export interface Cart {
    id: number;
    sessionId: number | null; // Session ?
    isOrdered: boolean;
    discountId: number | null;
    discount?: Discount;
    clientId: Client['id'];
    clientEmail?: string;
    siteId: Parc['id'];
    deliveryDate: string;
    deliveryMode: DeliveryModes;
    paymentTransactionId: string | null;
    animalPresence: boolean;
    allowsSubstitute: boolean;
    deliveryTimeRangeId: DeliveryTimeRange['id'] | null;
    paymentStatus: string | null;
    transactionAmount: number;
    transactionStatus: string | null;
    order?: Order;
    cartItems?: CartItem[];
    totalWithoutTax: number;
    vat: number;
    totalWithTaxWithoutDiscount: number;
    discountAmount: number;
    totalWithTaxAndDiscount: number;
    deliveredProductsTotalWithoutTax: number;
    deliveredProductsVat: number;
    deliveredProductsTotalWithTaxWithoutDiscount: number;
    deliveredProductsTotalWithTaxAndDiscount: number;
    deliveryFees: number;
    reference: string;
    cottageNumber?: string;
    cartType: CartType;
}

export interface CatalogProductSuppIngredient {
    id: number;
    siteId: Parc['id'];
    suppIngredient: SuppIngredient;
}
export interface CartItem {
    id: number;
    cartId: number;
    status: string;
    comment: string;
    vatRate: number;
    price: number;
    hasSubstitute: boolean;
    deliveryDate: string;
    menuProductId: number | undefined;
    referToMenuCartItemId: number | undefined;
    altCatalogProduct: number | undefined;
    catalogProduct: CatalogProduct;
    priceWithoutTax: number;
    vat: number;
    priceWithTax: number;
    deliveredProductPriceWithoutTax: number;
    deliveredProductVat: number;
    deliveredProductPriceWithTax: number;
}

export interface CatalogProduct {
    id: number;
    productId: Product['id'];
    product: Product;
    restaurantId: Restaurant['id'];
    restaurant: Restaurant | null;
    enableDate: string;
    disableDate: string;
    creationDate: string;
    vatRate: number;
    price: number;
    priceWithoutTax: number;
    vat: number;
    priceWithTax: number;
    deliveredProductPriceWithoutTax: number;
    deliveredProductVat: number;
    deliveredProductPriceWithTax: number;
    isEnabled: boolean;
    isSupplementalIngredient: boolean;
    suppIngredients: CatalogProductSuppIngredient[];
}

export interface Category {
    id: number;
    code: string;
    languageCode?: Lang['languageCode'];
    creationDate: string;
    categoryLabel: Label;
    subCategories: Subcategory[];
}

export interface Client {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
    allowsNewsletters: boolean;
    allowsSubstitute: boolean;
    vacation: Vacation;
    crDate: string;
    orders: Order[];
}

export interface CGU {
    termsOfUse?: string;
    html?: string;
    lang: Lang['languageCode'];
}

export interface CGV {
    termsOfSale?: string;
    html?: string;
    lang: Lang['languageCode'];
}

export interface DashboardTotals {
    ca: string;
    clientsCount: number;
    ordersCount: number;
    productsCount: number;
    siteCount: number;
}

export interface DeliveryTimeConstraint {
    id: number;
    deliveryTimeRangeId: DeliveryTimeRange['id'];
    isEnabled: boolean;
    deliveryMessage: string;
    minTimeToOrder: string;
    maxTimeToOrder: string;
    productionCapacity: number;
    mealTypeId: MealType['id'];
    restaurantId: Restaurant['id'];
}

export interface DeliveryTimeRangeName {
    id: number;
    label: Label;
}

export interface DeliveryTimeRange {
    id: number;
    timeStart: string;
    timeEnd: string;
    deliveryTimeRangeNameId: DeliveryTimeRangeName['id'];
    isEnabled: boolean;
    canBeOrderedTheDayBefore: boolean;
    capacity: number;
    deliveryTimeRangeName?: DeliveryTimeRangeName;
}

export interface Discount {
    id: number;
    creationDate: string;
    code: string;
    label: string;
    maxUseCount: number;
    minOrderAmount: number;
    amountValue: number;
    percentValue: number;
    startDate: string;
    endDate: string;
    isEnabled: boolean;
    siteId: Parc['id'];
    usedCount: number;
}

export interface FAQ {
    faq?: string;
    html?: string;
    lang: Lang['languageCode'];
}

export interface LabelMultilang {
    [key: string]: string;
}

export type Label = LabelMultilang & {};

export interface Lang {
    id: number;
    languageCode: string;
    language: string;
}

export interface MealType {
    id: number;
    label: Label;
}

export interface MenuItem {
    id: number;
    mainProductId: Product['id'];
    levelProductId: number;
    isOptional: boolean;
    level: number;
    levelName: Label;
    price: number;
    vatRate: number;
    product: Product;
}

export interface Menu {
    id: number;
    internalRef: string;
    mealTypeId: MealType['id'];
    techLabel: string;
    label: Label;
    description: Label;
    ingredients: Label;
    allergenCodes: string | null;
    isEnabled: boolean;
    isSupplementalIngredient: boolean;
    isMenu: boolean;
    isMenuProduct: boolean;
    isVariantProduct: boolean;
    imageUrl: string;
    menuDetails: MenuItem[];
}

export interface Order {
    id: number;
    status: OrderStatus;
    bagsCount: number;
    deliveryDate: string | null;
    creationDate: string | null;
    allowsSubstitute: boolean;
    animalPresence: boolean;
    deliveryTimeRangeId: DeliveryTimeRange['id'];
    cart: Cart;
    client: Client;
    deliveryTimeRange: DeliveryTimeRange;
    reference: string;
    paymentMethod: PaiementMethod;
}

export interface Parc {
    id: number;
    label: string;
    siteReference: string;
    address?: string;
    city?: string;
    zipCode?: number;
    isDeliveryEnabled: boolean;
    deliveryFees: number;
    email?: string;
    phone?: string;
    contact?: string;
    slug: string;
    hours?: string;
    hasKiosk: boolean;
    orderExpirationTimer: number;
}

export interface ProductCategory {
    id: number;
    categoryLabel: Label;
    subCategories: ProductSubCategory[];
}

export interface ProductSubCategory {
    id: number;
    creationDate: string;
    mainCategoryId: ProductCategory['id'];
    subcategoryLabel: Label;
}

export interface Product {
    id: number;
    internalRef: string;
    techLabel: string;
    label: Label;
    description: Label;
    ingredients: Label;
    allergenCodes: string;
    isEnabled: boolean;
    isSupplementalIngredient: boolean;
    isMenu: boolean;
    isMenuProduct: boolean;
    isVariantProduct: boolean;
    variants: any[];
    category: any;
    mealType: any;
    translated: boolean;
    catalogProduct?: CatalogProduct;
    images: string[];
}

export interface ProductVariant {
    id: number;
    mainProductId: Product['id'];
    variantProductId: Product['id'];
    crDate: string;
}

export interface Restaurant {
    id: number;
    siteId: Parc['id'];
    label: Label;
    isEnabled: boolean;
    catalogProducts: CatalogProduct[];
    deliveryTimeConstraints: DeliveryTimeConstraint[];
}

export interface Subcategory {
    id: number;
    subcategoryLabel: Label;
    languageCode: Lang['languageCode'];
    code: string;
    creationDate?: string;
    mainCategoryId: Category['id'];
}

export interface Suggestion {
    id: number;
    creationDate: string;
    startDate: string;
    endDate: string;
    siteId: Parc['id'];
    site?: Parc;
    catalogProductId: CatalogProduct['id'];
    catalogProduct?: CatalogProduct;
    mealTypeId: MealType['id'];
    mealType?: MealType;
}

export interface SuppIngredient {
    id: number;
    vatRate: number;
    price: number;
    enableDate: string;
    disableDate: string;
    restaurantId: Restaurant['id'];
    isEnabled: boolean;
    product: Product;
}

export interface SuppIngredientForCatalogProduct {
    id: number;
    creationDate: string;
    enableDate: string;
    disableDate: string;
    vatRate: number | null;
    price: number;
    productId: Product['id'];
    restaurantId: Restaurant['id'];
    isEnabled: boolean;
    siteId: Parc['id'];
    product: Product;
    disabledOnFilter: boolean;
}

export interface TimeRangesList {
    id: number;
    label?: Label;
    deliveryTimeRanges: DeliveryTimeRange[];
}

export interface User {
    id: number;
    siteId: number | null;
    authToken?: string;
    role: UserRole;
    firstName: string;
    lastName: string;
    creationDate: string;
    contact?: string;
    phone?: string;
    login: string;
    password?: string;
    isEnabled: boolean;
}

export interface Vacation {
    id: number;
    creationDate: string;
    cottageNumber: string;
    reservationNumber: string;
    animalPresence: boolean;
    startDate: string;
    endDate: string;
    siteId: Parc['id'];
    clientId: Client['id'];
    allowsSubstitute: boolean;
}

export interface Refund {
    id?: number;
    orderId: number;
    amount: number;
    reason: string;
    refundType: string;
}

export const refundTypes = {
    full: { id: 'full', alias: 'Total' },
    partiel: { id: 'partial', alias: 'Partiel' },
};

export interface BaseRoute {
    path: string;
    roles: UserRole[];
    name: string;
}

export interface MenuRoute extends BaseRoute {
    icon: string;
    theme?: IconProps['theme'];
}
export interface AppRoute extends BaseRoute {
    exact: boolean;
    component: FC<any>;
}
export enum PaiementMethod {
    cb = 'cb',
    onDesk = 'onDesk',
    onBorne = 'onBorne',
}

export interface OrderPrices {
    withoutTax: number;
    withTaxWithoutDiscount: number;
    withTaxAndDiscount: number;
}
