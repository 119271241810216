export const LIST = 'constraints/LIST';
export const LIST_SUCCESS = 'constraints/LIST_SUCCESS';
export const LIST_FAIL = 'constraints/LIST_FAIL';

export const DETAILS = 'constraints/DETAILS';
export const DETAILS_SUCCESS = 'constraints/DETAILS_SUCCESS';
export const DETAILS_FAIL = 'constraints/DETAILS_FAIL';

export const CREATE = 'constraints/CREATE';
export const CREATE_SUCCESS = 'constraints/CREATE_SUCCESS';
export const CREATE_FAIL = 'constraints/CREATE_FAIL';

export const UPDATE = 'constraints/UPDATE';
export const UPDATE_SUCCESS = 'constraints/UPDATE_SUCCESS';
export const UPDATE_FAIL = 'constraints/UPDATE_FAIL';

export const REMOVE = 'constraints/REMOVE';
export const REMOVE_SUCCESS = 'constraints/REMOVE_SUCCESS';
export const REMOVE_FAIL = 'constraints/REMOVE_FAIL';
