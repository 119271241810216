import { combineReducers } from 'redux';

import * as reduxTypes from '../types/timeRanges';
import { TimeRangesList } from '../api/apiTypes';
import { MainReducerState, RequestState } from './';
import { requestReducer } from './_generics';

export interface TimeRangesState {
    readonly list: RequestState<TimeRangesList[]>;
}

const initialState: TimeRangesList[] = [];

export default combineReducers<TimeRangesState>({
    list: requestReducer({
        START: reduxTypes.LIST,
        SUCCESS: reduxTypes.LIST_SUCCESS,
        FAILED: reduxTypes.LIST_FAIL,
    }, initialState),
});

export const getTimeRangesData = (state: MainReducerState) => state.timeRanges.list.data;
