import { fork } from 'redux-saga/effects';

import allergens from './allergens';
import auth from './auth';
import banners from './banners';
import catalogProducts from './catalogProducts';
import categories from './categories';
import clients from './clients';
import constraints from './constraints';
import dashboard from './dashboard';
import discounts from './discounts';
import langs from './langs';
import mealTypes from './mealTypes';
import menus from './menus';
import orders from './orders';
import parcs from './parcs';
import productCategories from './productCategories';
import products from './products';
import restaurants from './restaurants';
import suggestions from './suggestions';
import terms from './terms';
import timeRanges from './timeRanges';
import users from './users';
import presales from './presales';
import presaleNatures from './presaleNatures';
import logsRegister from './logsRegister';

export default function* mainSaga() {
    yield fork(allergens);
    yield fork(auth);
    yield fork(banners);
    yield fork(catalogProducts);
    yield fork(categories);
    yield fork(clients);
    yield fork(constraints);
    yield fork(dashboard);
    yield fork(discounts);
    yield fork(langs);
    yield fork(logsRegister);
    yield fork(mealTypes);
    yield fork(menus);
    yield fork(orders);
    yield fork(parcs);
    yield fork(productCategories);
    yield fork(products);
    yield fork(restaurants);
    yield fork(suggestions);
    yield fork(terms);
    yield fork(timeRanges);
    yield fork(users);
    yield fork(presales);
    yield fork(presaleNatures);
}
