import React, { FC } from 'react';
import { Input } from 'antd';
import { InputProps, SearchProps } from 'antd/lib/input';

export interface SearchOrderProps {
    onSearch: SearchProps['onSearch'];
    onChange: InputProps['onChange'];
}

const SearchOrder: FC<SearchOrderProps> = ({ onSearch, onChange }) => {

    return (
        <div className="mb-20">
            <Input.Search
                size="large"
                placeholder="Rechercher une commande (numéro, client, email, n° de panier)"
                onSearch={onSearch}
                onChange={onChange}
            />
        </div>
    );
};

export default SearchOrder;
