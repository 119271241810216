import { combineReducers } from 'redux';

import * as reduxTypes from '../types/clients';
import { Client } from '../api/apiTypes';
import { MainReducerState, RequestState } from './';
import { requestReducer } from './_generics';

export interface ClientsState {
    readonly list: RequestState<Client[]>;
    readonly details: RequestState<Client>;
}

const initialState: Client[] = [];

export default combineReducers<ClientsState>({
    list: requestReducer({
        START: reduxTypes.LIST,
        SUCCESS: reduxTypes.LIST_SUCCESS,
        FAILED: reduxTypes.LIST_FAIL,
    }, initialState),
    details: requestReducer({
        START: reduxTypes.LIST,
        SUCCESS: reduxTypes.LIST_SUCCESS,
        FAILED: reduxTypes.LIST_FAIL,
    }),
});

export const getClients = (state: MainReducerState) => state.clients.list;
export const getClientDetailsState = (state: MainReducerState) => state.clients.details;
