import { Action } from 'redux';
import { call, takeLatest, put } from 'redux-saga/effects';

import * as reduxActions from '../actions/suggestions';
import * as reduxTypes from '../types/suggestions';
import * as api from '../api/suggestions';
import { SiteIdQuery } from '../api';
import { IdAction, DataAction } from '../actions';
import { Suggestion } from '../api/apiTypes';

export function* listSaga(action: Action & SiteIdQuery) {
    try {
        const response = yield call(api.list, action);
        return yield put(reduxActions.listSuccess(response));
    } catch (error) {
        return yield put(reduxActions.listFailed(error));
    }
}

export function* createSaga(action: SiteIdQuery & DataAction<Partial<Suggestion>>) {
    try {
        const response = yield call(api.create, action.data);
        return yield put(reduxActions.createSuccess(response));
    } catch (error) {
        return yield put(reduxActions.createFailed(error));
    }
}

export function* deleteSaga(action: IdAction<Suggestion['id']> & SiteIdQuery) {
    try {
        const response = yield call(api.del, action.id);
        return yield put(reduxActions.deleteSuccess(response));
    } catch (error) {
        return yield put(reduxActions.deleteFailed(error));
    }
}

export function* updateSaga(action: IdAction<Suggestion['id']> & DataAction<Partial<Suggestion>>) {
    try {
        const response = yield call(api.update, action.id, action.data);
        return yield put(reduxActions.updateSuccess(response));
    } catch (error) {
        return yield put(reduxActions.updateFailed(error));
    }
}

export function* detailSaga(action: IdAction<Suggestion['id']> & SiteIdQuery) {
    try {
        const response = yield call(api.detail, action.id);
        return yield put(reduxActions.detailSuccess(response));
    } catch (error) {
        return yield put(reduxActions.detailFailed(error));
    }
}

export default function* suggestionsSaga() {
    yield takeLatest(reduxTypes.LIST, listSaga);
    yield takeLatest(reduxTypes.CREATE, createSaga);
    yield takeLatest(reduxTypes.DELETE, deleteSaga);
    yield takeLatest(reduxTypes.UPDATE, updateSaga);
    yield takeLatest(reduxTypes.DETAIL, detailSaga);
}
