import { Action } from 'redux';
import { call, delay, takeLatest, put } from 'redux-saga/effects';

import * as reduxActions from '../actions/restaurants';
import * as reduxTypes from '../types/restaurants';
import * as api from '../api/restaurants';
import { SiteIdQuery, SearchQuery } from '../api';
import { IdAction, DataAction } from '../actions';
import { Restaurant } from '../api/apiTypes';

export function* listSaga(action: Action & SiteIdQuery) {
    try {
        const response = yield call(api.list, action);
        return yield put(reduxActions.listSuccess(response));
    } catch (error) {
        return yield put(reduxActions.listFailed(error));
    }
}

export function* listSelectSaga(action: Action & SearchQuery) {
    try {
        yield delay(action.throttling || 0);
        const response = yield call(api.list, action);
        return yield put(reduxActions.listSelectSuccess(response));
    } catch (error) {
        return yield put(reduxActions.listSelectFailed(error));
    }
}

export function* createSaga(action: SiteIdQuery & DataAction<Partial<Restaurant>>) {
    try {
        const response = yield call(api.create, action, action.data);
        return yield put(reduxActions.createSuccess(response));
    } catch (error) {
        return yield put(reduxActions.createFailed(error));
    }
}

export function* detailsSaga(action: IdAction<Restaurant['id']> & SiteIdQuery) {
    try {
        const response = yield call(api.details, action.id, action);
        return yield put(reduxActions.detailsSuccess(response));
    } catch (error) {
        return yield put(reduxActions.detailsFailed(error));
    }
}

export function* updateSaga(action: IdAction<Restaurant['id']> & SiteIdQuery & DataAction<Partial<Restaurant>>) {
    try {
        const response = yield call(api.update, action.id, action, action.data);
        return yield put(reduxActions.updateSuccess(response));
    } catch (error) {
        return yield put(reduxActions.updateFailed(error));
    }
}

export default function* restaurantsSaga() {
    yield takeLatest(reduxTypes.LIST, listSaga);
    yield takeLatest(reduxTypes.LIST_SELECT, listSelectSaga);
    yield takeLatest(reduxTypes.CREATE, createSaga);
    yield takeLatest(reduxTypes.DETAILS, detailsSaga);
    yield takeLatest(reduxTypes.UPDATE, updateSaga);
}
