import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Spin, Tabs, Modal } from 'antd';
import { TabsProps } from 'antd/lib/tabs';
import Form, { FormComponentProps } from 'antd/lib/form';
import ReactQuill from 'react-quill';

import { MainReducerState } from '../../store/reducers';
import * as TermsActions from '../../store/actions/terms';
import { getTermsCGUState, TermsState, updateTermsCGUState } from '../../store/reducers/terms';
import { getLangsData } from '../../store/reducers/langs';

import Constants from '../../utils/constants';
import { Lang } from '../../store/api/apiTypes';

interface CGUProps extends FormComponentProps {
    cguState: TermsState['fetchCGU'];
    fetchCGU: typeof TermsActions.fetchCGU;
    langsState: Lang[];
    updateCGU: typeof TermsActions.updateCGU;
    updates: TermsState['updateCGU'];
}

const CGUEdit: FC<CGUProps> = ({ cguState, form, fetchCGU, langsState, updateCGU, updates }) => {

    const [selectedLang, setSelectedLang] = useState(Constants.DEFAULT_LANG);

    const toolbarFormat = {
        toolbar: [
            [
                { header: [1, 2, 3] },
            ],
            [
                { font: [] },
                { size: [] },
                { color: [] },
            ],
            ['bold', 'italic', 'underline'],
            ['image', 'link'],
        ],
    };

    useEffect(() => {
        if (cguState.data) {
            form.setFieldsValue({html: cguState.data.html});
        }
    }, [cguState.data]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchCGU({
            lang: selectedLang,
        });
    }, [selectedLang]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (updates.success) {
            form.resetFields();
            fetchCGU({
                lang: selectedLang,
            });
        }
    }, [updates.success]); // eslint-disable-line react-hooks/exhaustive-deps

    const onTabChange: TabsProps['onChange'] = (event) => {
        if (form.getFieldValue('html') !== cguState.data.html) {
            confirmModal(event);
        } else {
            setSelectedLang(event);
        }
    };

    const confirmModal = (event: Lang['languageCode']) => {
        Modal.confirm({
            title: 'Voulez-vous garder les modifications?',
            okText: 'Oui',
            cancelText: 'Non',
            onOk() {
                updateCGU({
                    termsOfUse: form.getFieldValue('html'),
                    lang: selectedLang,
                });
                form.resetFields();
                setSelectedLang(event);
            },
            onCancel() {
                form.resetFields();
                setSelectedLang(event);
            },
        });
    };

    const onSubmit = (e?: React.FormEvent) => {
        if (e) {
            e.preventDefault();
        }
        form.validateFieldsAndScroll(async (err, val) => {
            if (err) {
                return;
            }
            const payload = {
                termsOfUse: val.html,
                lang: selectedLang,
            };
            updateCGU(payload);
        });
    };

    const { getFieldDecorator } = form;

    return (
        <>
            <Spin spinning={cguState.loading} tip="Chargement...">
                <Tabs onChange={onTabChange} type="card">
                    {langsState && langsState.map((e) => (
                        <Tabs.TabPane
                            tab={e.languageCode}
                            key={e.languageCode}
                        />
                    ))}
                </Tabs>
                <Form onSubmit={onSubmit} layout="vertical">
                    <Form.Item label="CGU">
                        {getFieldDecorator(`html`, {
                            rules: [{
                                required: true,
                                message: 'champ requis',
                            }],
                            initialValue: cguState.data ? cguState.data.html : '',
                        })((
                            <ReactQuill
                                modules={toolbarFormat}
                                theme="snow"
                                placeholder="Vous pouvez utiliser la barre d'outils pour personnaliser votre contenu"
                            />
                        ))}
                    </Form.Item>
                </Form>
                <div className="form-actions">
                    <Button htmlType="submit" type="primary" onClick={onSubmit} loading={updates.loading}>
                        Mettre à jour
                    </Button>
                </div>
            </Spin>
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    cguState: getTermsCGUState(state),
    langsState: getLangsData(state),
    updates: updateTermsCGUState(state),
});

const CGUForm = Form.create<CGUProps>()(CGUEdit);

export default connect(
    mapStateToProps,
    {
        fetchCGU: TermsActions.fetchCGU,
        updateCGU: TermsActions.updateCGU,
    },
)(CGUForm);
