import { combineReducers } from 'redux';

import * as reduxTypes from '../types/menus';
import { Menu, MenuItem } from '../api/apiTypes';
import { MainReducerState, RequestState } from './';
import { requestReducer } from './_generics';

export interface MenusState {
    readonly list: RequestState<Menu[]>;
    readonly details: RequestState<MenuItem[]>;
    readonly update: RequestState<Partial<MenuItem>>;
}

const initialState: Menu[] = [];

export default combineReducers<MenusState>({
    list: requestReducer({
        START: reduxTypes.LIST,
        SUCCESS: reduxTypes.LIST_SUCCESS,
        FAILED: reduxTypes.LIST_FAIL,
    }, initialState),
    details: requestReducer({
        START: reduxTypes.DETAILS,
        SUCCESS: reduxTypes.DETAILS_SUCCESS,
        FAILED: reduxTypes.DETAILS_FAIL,
    }),
    update: requestReducer({
        START: reduxTypes.UPDATE,
        SUCCESS: reduxTypes.UPDATE_SUCCESS,
        FAILED: reduxTypes.UPDATE_FAIL,
        RESET: reduxTypes.RESET_SUCCESS,
    }),
});

export const getMenusListState = (state: MainReducerState) => state.menus.list;
export const getMenuDetailsState = (state: MainReducerState) => state.menus.details;
export const getUpdateMenuState = (state: MainReducerState) => state.menus.update;
