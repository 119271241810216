import React, { FC, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { ColumnProps } from 'antd/lib/table';
import { Button, Table, Divider, Popconfirm, Switch, Icon } from 'antd';

import ButtonLink from '../../components/ButtonLink';
import { MainReducerState } from '../../store/reducers';
import { Parc as ParcType} from '../../store/api/apiTypes';
import { ParcEdit } from '.';
import * as ParcActions from '../../store/actions/parcs';
import { getParcsState, ParcsState, updateParcState } from '../../store/reducers/parcs';
import { getRoute, RoutePathName } from '../../routes';

interface ParcsProps {
    getList: typeof ParcActions.list;
    parcsState: ParcsState['list'];
    parcUpdateState: ParcsState['update'];
    updateParc: typeof ParcActions.update;
}

const Parcs: FC<ParcsProps> = ({ getList, parcsState, parcUpdateState, updateParc }) => {

    const [ selectedId, setSelectedId ] = useState();

    useEffect(() => {
        getList();
    }, [parcUpdateState.success]); // eslint-disable-line react-hooks/exhaustive-deps

    const [ isFormVisible, setIsFormVisible ] = useState(false);

    const setEditMode = (id: number) => {
        setSelectedId(id);
        setIsFormVisible(true);
    };

    const onDrawerClose = () => {
        setIsFormVisible(false);
    };

    const onSwitchChange = (elem: ParcType) => {
        const payload = {
            ...elem,
            isDeliveryEnabled: !elem.isDeliveryEnabled,
        };
        updateParc(elem.id, payload);
    };

    const onCreate = () => {
        setSelectedId(undefined);
        setIsFormVisible(true);
    };

    const onUpdateSuccess = () => {
        getList();
    };

    const rowKey =  (item: ParcType) => `${item.id}`;

    const columns: Array<ColumnProps<ParcType>> = [
        {
            dataIndex: 'siteReference',
            title: 'Référence',
        },
        {
            dataIndex: 'label',
            title: 'Nom',
        },
        {
            dataIndex: 'address',
            title: 'Adresse',
        },
        {
            dataIndex: 'city',
            title: 'Ville',
        },
        {
            dataIndex: 'zipCode',
            title: 'Code postal',
        },
        {
            dataIndex: 'isDeliveryEnabled',
            title: 'Livraisons',
            render: (text, record) => (
                <Popconfirm
                    title="Voulez-vous désactiver les livraisons?"
                    onConfirm={onSwitchChange.bind(null, record)}
                    okText="Oui"
                    cancelText="Non"
                >
                    <Switch
                        checked={text}
                        checkedChildren={<Icon type="check" />}
                        unCheckedChildren={<Icon type="close" />}
                    />
                </Popconfirm>
            ),
        },
        {
            dataIndex: 'deliveryFees',
            title: 'Frais de livraison',
            render: (val) => new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(val),
        },
        {
            dataIndex: 'email',
            title: 'Email',
        },
        {
            dataIndex: 'phone',
            title: 'Téléphone',
        },
        {
            dataIndex: 'contact',
            title: 'Contact',
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (
                <>
                    <ButtonLink
                        className="list-action-button-details"
                        to={getRoute(RoutePathName.parc, { id: record.id })}
                    >
                        Détails
                    </ButtonLink>
                    <Divider type="vertical"/>
                    <Button
                        icon="edit"
                        className="list-action-button-edit"
                        onClick={setEditMode.bind(null, record.id)}
                    />
                </>
            ),
        },
    ];

    return (
        <>
            <div className="list-header">
                <h1 className="list-header-title">Liste des parcs</h1>
                <Button
                    type="primary"
                    icon="plus-circle"
                    shape="round"
                    className="list-create-element"
                    onClick={onCreate}
                >
                    Ajouter un parc
                </Button>
            </div>
            <Table<ParcType>
                rowKey={rowKey}
                columns={columns}
                dataSource={parcsState.data}
            />
            <ParcEdit
                id={selectedId}
                isVisible={isFormVisible}
                onClose={onDrawerClose}
                onUpdateSuccess={onUpdateSuccess}
            />
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    parcsState: getParcsState(state),
    parcUpdateState: updateParcState(state),
});

export default connect(
    mapStateToProps,
    {
        getList: ParcActions.list,
        updateParc: ParcActions.update,
    },
)(Parcs);
