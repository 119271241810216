import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { Form, Drawer, Row, Col, Icon, Switch, Input, Divider, Button, Spin, InputNumber } from 'antd';
import { FormComponentProps } from 'antd/lib/form';

import * as ParcsActions from '../../store/actions/parcs';
import { MainReducerState } from '../../store/reducers';
import { getParcDetailsState, updateParcState, ParcsState, createParcState } from '../../store/reducers/parcs';
import { DrawerProps } from 'antd/lib/drawer';
import { ButtonProps } from 'antd/lib/button';

interface ParcEditProps extends FormComponentProps {
    createParc: typeof ParcsActions.create;
    creates: ParcsState['create'];
    getDetails: typeof ParcsActions.details;
    id?: number;
    isVisible: boolean;
    onClose: () => void;
    onUpdateSuccess: () => void;
    parcDetailsState: ParcsState['details'];
    updateParc: typeof ParcsActions.update;
    updates: ParcsState['update'];
}

const ParcEdit: FC<ParcEditProps> = ({
    form, createParc, creates, getDetails, id, isVisible, onClose,
    onUpdateSuccess, parcDetailsState, updateParc, updates,
}) => {
    useEffect(() => {
        if (id) {
            getDetails(id);
        }
    }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (creates.success || updates.success) {
            form.resetFields();
            onUpdateSuccess();
            onClose();
        }
    }, [creates.success, updates.success]); // eslint-disable-line react-hooks/exhaustive-deps

    const { getFieldDecorator } = form;

    const onSwitchChange = (val: boolean) => {
        form.setFieldsValue({isDeliveryEnabled: val});
    };

    const onKioskAvailableChange = (val: boolean) => {
        form.setFieldsValue({hasKiosk: val});
    };

    const onDrawerClose: DrawerProps['onClose'] & ButtonProps['onClick'] = () => {
        form.resetFields();
        onClose();
    };
    const onSubmit = (e?: React.FormEvent) => {
        if (e) {
            e.preventDefault();
        }
        form.validateFieldsAndScroll(async (err, val) => {
            if (err) {
                return;
            }
            if (id !== undefined) {
                updateParc(id, val);
            } else {
                createParc(val);
            }
        });
    };

    return (
        <>
            <Drawer
                title={id !== undefined ? 'Modification de Parc' : 'Création de Parc'}
                width={960}
                onClose={onDrawerClose}
                visible={isVisible}
                className="edit-product-drawer"
            >
                <Spin spinning={parcDetailsState.loading}>
                    <Form>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label="Nom du parc">
                                    {getFieldDecorator('label', {
                                        rules: [{
                                            required: true,
                                            message: 'champ requis',
                                        }],
                                        initialValue: parcDetailsState.data ?
                                            parcDetailsState.data.label : '',
                                    })((
                                        <Input placeholder="Entrez un nom"/>
                                    ))}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Adresse">
                                    {getFieldDecorator('address', {
                                        rules: [{
                                            required: true,
                                            message: 'champ requis',
                                        }],
                                        initialValue: parcDetailsState.data ?
                                            parcDetailsState.data.address : '',
                                    })((
                                        <Input placeholder="Entrez l'adresse"/>
                                    ))}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label="Ville">
                                    {getFieldDecorator('city', {
                                        rules: [{
                                            required: true,
                                            message: 'champ requis',
                                        }],
                                        initialValue: parcDetailsState.data ?
                                            parcDetailsState.data.city : '',
                                    })((
                                        <Input placeholder="Entrez la ville"/>
                                    ))}
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item label="Code postal">
                                    {getFieldDecorator('zipCode', {
                                        rules: [{
                                            required: true,
                                            message: 'champ requis',
                                        }],
                                        initialValue: parcDetailsState.data ?
                                            parcDetailsState.data.zipCode : '',
                                    })((
                                        <Input placeholder="Entrez l'adresse"/>
                                    ))}
                                </Form.Item>
                            </Col>
                            <Col span={3}>
                                <Form.Item label="Frais de livraison">
                                    {getFieldDecorator('deliveryFees', {
                                        rules: [{
                                            required: true,
                                            message: 'champ requis',
                                        }],
                                        initialValue: parcDetailsState.data ?
                                            parcDetailsState.data.deliveryFees : '',
                                    })((
                                        <InputNumber placeholder="Entrez une somme"/>
                                    ))}
                                </Form.Item>
                            </Col>
                            <Col span={2} offset={1}>
                                <Form.Item label="Livraisons">
                                    {getFieldDecorator('isDeliveryEnabled', {
                                        valuePropName: 'checked',
                                        initialValue: parcDetailsState.data ?
                                            parcDetailsState.data.isDeliveryEnabled : true,
                                    })((
                                        <Switch
                                            checkedChildren={<Icon type="check" />}
                                            unCheckedChildren={<Icon type="close" />}
                                            onChange={onSwitchChange}
                                        />
                                    ))}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label="Email">
                                    {getFieldDecorator('email', {
                                        rules: [{
                                            required: true,
                                            message: 'champ requis',
                                        }],
                                        initialValue: parcDetailsState.data ?
                                            parcDetailsState.data.email : '',
                                    })((
                                        <Input type="email" placeholder="Entrez l'adresse email"/>
                                    ))}
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="Téléphone">
                                    {getFieldDecorator('phone', {
                                        rules: [{
                                            required: true,
                                            message: 'champ requis',
                                        }],
                                        initialValue: parcDetailsState.data ?
                                            parcDetailsState.data.phone : '',
                                    })((
                                        <Input placeholder="Entrez le téléphone"/>
                                    ))}
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="Référence">
                                    {getFieldDecorator('siteReference', {
                                        rules: [{
                                            required: true,
                                            message: 'champ requis',
                                        }],
                                        initialValue: parcDetailsState.data ?
                                            parcDetailsState.data.siteReference : '',
                                    })((
                                        <Input placeholder="Référence du site"/>
                                    ))}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label="Contact">
                                    {getFieldDecorator('contact', {
                                        rules: [{
                                            required: true,
                                            message: 'champ requis',
                                        }],
                                        initialValue: parcDetailsState.data ?
                                            parcDetailsState.data.contact : '',
                                    })((
                                        <Input placeholder="Personne à contacter"/>
                                    ))}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="URL partielle site">
                                    {getFieldDecorator('slug', {
                                        rules: [{
                                            required: true,
                                            message: 'champ requis',
                                        }],
                                        initialValue: parcDetailsState.data ?
                                            parcDetailsState.data.slug : '',
                                    })((
                                        <Input placeholder="url partielle du site"/>
                                    ))}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label="Borne disponible">
                                    {getFieldDecorator('hasKiosk', {
                                        valuePropName: 'checked',
                                        rules: [{
                                            required: true,
                                            message: 'champ requis',
                                        }],
                                        initialValue: parcDetailsState.data ?
                                            parcDetailsState.data.hasKiosk : true,
                                    })((
                                        <Switch
                                            checkedChildren={<Icon type="check" />}
                                            unCheckedChildren={<Icon type="close" />}
                                            onChange={onKioskAvailableChange}
                                        />
                                    ))}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Durée avant expiration des commandes borne non payé">
                                    {getFieldDecorator('orderExpirationTimer', {
                                        rules: [{
                                            required: true,
                                            message: 'champ requis',
                                        }],
                                        initialValue: parcDetailsState.data ?
                                            parcDetailsState.data.orderExpirationTimer : '',
                                    })((
                                        <InputNumber placeholder="Entrez un nombre d'heure"/>
                                    ))}
                                    <span style={{marginLeft: '1em'}}>heure(s)</span>
                                </Form.Item>
                            </Col>

                        </Row>
                        <div className="form-actions">
                            <Button htmlType="submit" type="primary" onClick={onSubmit} loading={updates.loading}>
                                {id !== undefined ? 'Mettre à jour' : 'Ajouter'}
                            </Button>
                            <Divider type="vertical"/>
                            <Button onClick={onDrawerClose} type="ghost">
                                Annuler
                            </Button>
                        </div>
                    </Form>
                </Spin>
            </Drawer>
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    creates: createParcState(state),
    parcDetailsState: getParcDetailsState(state),
    updates: updateParcState(state),
});

const ParcFormDrawer = Form.create<ParcEditProps>()(ParcEdit);

export default connect(
    mapStateToProps,
    {
        createParc: ParcsActions.create,
        getDetails: ParcsActions.details,
        updateParc: ParcsActions.update,
    },
)(ParcFormDrawer);
