
import { ActionCreator } from 'redux';
import { DataAction, IdAction, QueryListAction, QueryListPayload } from '.';
import { Client, Order } from '../api/apiTypes';
import * as reduxTypes from '../types/orders';
import { CounterPaiement, Refund } from './../api/apiTypes';

export const list: ActionCreator<QueryListAction> = (payload: QueryListPayload) => ({
    type: reduxTypes.LIST,
    ...payload,
});

export const listSuccess: ActionCreator<DataAction> = (data) => ({
    type: reduxTypes.LIST_SUCCESS,
    data,
});

export const listFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_FAIL,
    data,
});

export const listByClient: ActionCreator<QueryListAction> = (id: Client['id']) => ({
    type: reduxTypes.LIST_BY_CLIENT,
    id,
});

export const listByClientSuccess: ActionCreator<DataAction> = (data) => ({
    type: reduxTypes.LIST_BY_CLIENT_SUCCESS,
    data,
});

export const listByClientFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_BY_CLIENT_FAIL,
    data,
});

export const details: ActionCreator<QueryListAction> = (id: Order['id'], payload: QueryListPayload) => ({
    type: reduxTypes.DETAILS,
    ...payload,
    id,
});

export const detailsSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.DETAILS_SUCCESS,
    data,
});

export const detailsFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.DETAILS_FAIL,
    data,
});

export const update: ActionCreator<DataAction> = (id: Order['id'], data: Order) => ({
    type: reduxTypes.UPDATE,
    id,
    data,
});

export const updateSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.UPDATE_SUCCESS,
    data,
});

export const updateFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.UPDATE_FAIL,
    data,
});

export const invoice: ActionCreator<QueryListAction> = (id: Order['id'], payload: QueryListPayload) => ({
    type: reduxTypes.INVOICE,
    ...payload,
    id,
});

export const invoiceSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.INVOICE_SUCCESS,
    data,
});

export const invoiceFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.INVOICE_FAIL,
    data,
});

export const invoiceByMail: ActionCreator<QueryListAction> = (id: Order['id']) => ({
    type: reduxTypes.INVOICE_BY_MAIL,
    id,
});

export const invoiceByMailSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.INVOICE_BY_MAIL_SUCCESS,
    data,
});

export const invoiceByMailFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.INVOICE_BY_MAIL_FAIL,
    data,
});

export const waitForPaiment: ActionCreator<QueryListAction> = (payload: QueryListPayload) => ({
    type: reduxTypes.WAIT_FOR_PAIEMENT,
    ...payload,
});

export const pollSuccess: ActionCreator<DataAction> = (data) => ({
    type: reduxTypes.WAIT_FOR_PAIEMENT_SUCCESS,
    data,
});

export const pollFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.WAIT_FOR_PAIEMENT_FAIL,
    data,
});

export const startPoll: ActionCreator<QueryListAction> = (payload: QueryListPayload) => ({
    type: reduxTypes.START_POLLING,
    ...payload,
});

export const stopPoll: ActionCreator<QueryListAction> = (payload: QueryListPayload) => ({
    type: reduxTypes.STOP_POLLING,
    ...payload,

});

export const listCounterPaiement: ActionCreator<QueryListAction> = (id: Order['id']) => ({
    type: reduxTypes.LIST_COUNTER_PAIEMENT,
    id,
});

export const listCounterPaiementSuccess: ActionCreator<DataAction> = (data) => ({
    type: reduxTypes.LIST_COUNTER_PAIEMENT_SUCCESS,
    data,
});

export const listCounterPaiementFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_COUNTER_PAIEMENT_FAIL,
    data,
});

export const createCounterPaiement: ActionCreator<DataAction> = (id: Order['id'], data: CounterPaiement) => ({
    type: reduxTypes.CREATE_COUNTER_PAIEMENT,
    id,
    data,
});

export const createCounterPaiementSuccess: ActionCreator<DataAction> = (data) => ({
    type: reduxTypes.CREATE_COUNTER_PAIEMENT_SUCCESS,
    data,
});

export const createCounterPaiementFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.CREATE_COUNTER_PAIEMENT_FAIL,
    data,
});

export const updateCounterPaiement: ActionCreator<DataAction> = (id: Order['id'], data: CounterPaiement) => ({
    type: reduxTypes.UPDATE_COUNTER_PAIEMENT,
    id,
    data,
});

export const updateCounterPaiementSuccess: ActionCreator<DataAction> = (data) => ({
    type: reduxTypes.UPDATE_COUNTER_PAIEMENT_SUCCESS,
    data,
});

export const updateCounterPaiementFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.UPDATE_COUNTER_PAIEMENT_FAIL,
    data,
});

export const deleteCounterPaiement: ActionCreator<IdAction<CounterPaiement['id']>> = (id: CounterPaiement['id']) => ({
    type: reduxTypes.DELETE_COUNTER_PAIEMENT,
    id,
});

export const deleteCounterPaiementSuccess: ActionCreator<DataAction> = (data) => ({
    type: reduxTypes.DELETE_COUNTER_PAIEMENT_SUCCESS,
    data,
});

export const deleteCounterPaiementFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.DELETE_COUNTER_PAIEMENT_FAIL,
    data,
});

export const createRefund: ActionCreator<DataAction> = (data: Refund) => ({
    type: reduxTypes.CREATE_REFUND,
    data,
});

export const createRefundSuccess: ActionCreator<DataAction> = (data: Refund) => ({
    type: reduxTypes.CREATE_REFUND_SUCCESS,
    data,
});

export const createRefundFail: ActionCreator<DataAction> = (data: Refund) => ({
    type: reduxTypes.CREATE_REFUND_FAIL,
    data,
});

export const listRefunds: ActionCreator<QueryListAction> = (id: Order['id']) => ({
    type: reduxTypes.LIST_REFUNDS,
    id,
});

export const listRefundsSuccess: ActionCreator<DataAction> = (data) => ({
    type: reduxTypes.LIST_REFUNDS_SUCCESS,
    data,
});

export const listRefundsFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_REFUNDS_FAIL,
    data,
});
