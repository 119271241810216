import React, { FC, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { ColumnProps } from 'antd/lib/table';
import { Button, Table, message } from 'antd';

import { MainReducerState } from '../../store/reducers';
import { PreSale as PresaleType} from '../../store/api/apiTypes';

import { PresaleEdit } from '.';
import * as PresaleActions from '../../store/actions/presales';
import { getPresalesState, PreSalesState, updatePresaleState } from '../../store/reducers/presales';
import { ParcsState, getSelectedParcState } from '../../store/reducers/parcs';
import Constants from '../../utils/constants';
import { AuthState, getUser } from '../../store/reducers/auth';
import { DateTime } from 'luxon';
import { isSuperAdmin, isAdmin } from '../../utils';
interface PresalesProps {
    user: AuthState['data'];
    getList: typeof PresaleActions.list;
    removePresale: typeof PresaleActions.remove;
    presalesState: PreSalesState['list'];
    presaleUpdateState: PreSalesState['update'];
    updatePresale: typeof PresaleActions.update;
    siteIdState: ParcsState['selected'];
}

const Presales: FC<PresalesProps> = ({ getList, presalesState, removePresale,
                                       presaleUpdateState, updatePresale, siteIdState, user }) => {

    const [ selectedId, setSelectedId ] = useState<number>();
    const getSiteId = () => {
        return siteIdState || (user && user.siteId) ;
    };
    useEffect(() => {
        if (getSiteId()) {
            getList({
                limit: Constants.PAGE_SIZE,
                siteId: getSiteId(),
            });
        } else {
            message.error('Veuillez sélectionner un site.');
        }
    }, [siteIdState]); // eslint-disable-line react-hooks/exhaustive-deps

    const [ isFormVisible, setIsFormVisible ] = useState(false);

    const setEditMode = (id: number) => {
        setSelectedId(id);
        setIsFormVisible(true);
    };

    const onDrawerClose = () => {
        setIsFormVisible(false);
    };

    const onCreate = () => {
        setSelectedId(undefined);
        setIsFormVisible(true);
    };
    /*const onDelete = (id: number) => {
        removePresale(id);
    };*/
    const onUpdateSuccess = () => {
        getList({
            limit: Constants.PAGE_SIZE,
            siteId: getSiteId(),
        });
    };

    const rowKey =  (item: PresaleType) => `${item.id}`;

    const columns: Array<ColumnProps<PresaleType>> = [
        {
            dataIndex: 'id',
            title: 'Id de prévente',
        },
        {
            dataIndex: 'creationDate',
            title: 'Date de création ',
            render: (val) => val ? DateTime.fromISO(val).toLocaleString() : '',
        },
        {
            dataIndex: 'nature.nature',
            title: 'Nature',
        },
        {
            dataIndex: 'orderRef',
            title: 'Référence Commande',
        },
        {
            dataIndex: 'cotage',
            title: 'Cottage',
        },
        {
            dataIndex: 'quantity',
            title: 'Quantité',
        },
        {
            title: 'Prix total',
            render: (record) =>
                (record.totalPrice.toFixed(2) + '€'),
        },
        {
            title: 'Prix total HT',
            render: (record) =>
                (record.totalPrice * 100 / (record.nature.TVA + 100)).toFixed(2) + '€',
        },
        {
            title: 'Taux de TVA',
            render: (record) =>
                (record.nature.TVA + '%'),
        },
        {
            dataIndex: 'user.login',
            title: 'créé par',
        },
        {
            dataIndex: 'userUpdated.login',
            title: 'modifié par ',
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (
                <>
                    {/*<ButtonLink
                        className="list-action-button-details"
                        to={getRoute(RoutePathName.presale, { id: record.id })}
                    >
                        Détails
                    </ButtonLink>
            <Divider type="vertical"/>*/}
                    { (isSuperAdmin(user) || isAdmin(user)) && (
                        <>
                        <Button
                            icon="edit"
                            className="list-action-button-edit"
                            onClick={setEditMode.bind(null, record.id)}
                        />
                        {/*<Button
                            icon="delete"
                            className="list-action-button-delete"
                            onClick={onDelete.bind(null,  record.id)}
                        />*/}
                    </>
                    )}
                </>
            ),
        },
    ];

    return (
        <>
            <div className="list-header">
                <h1 className="list-header-title">Liste des pré-ventes</h1>
                <Button
                    type="primary"
                    icon="plus-circle"
                    shape="round"
                    className="list-create-element"
                    onClick={onCreate}
                >
                    Ajouter une pré-vente
                </Button>
            </div>
            <Table<PresaleType>
                rowKey={rowKey}
                columns={columns}
                dataSource={presalesState.data}
            />
            <PresaleEdit
                id={selectedId}
                isVisible={isFormVisible}
                onClose={onDrawerClose}
                onUpdateSuccess={onUpdateSuccess}
            />
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    presalesState: getPresalesState(state),
    presaleUpdateState: updatePresaleState(state),
    siteIdState: getSelectedParcState(state),
    user: getUser(state),
});

export default connect(
    mapStateToProps,
    {
        getList: PresaleActions.list,
        updatePresale: PresaleActions.update,
    },
)(Presales);
