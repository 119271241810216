import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Spin, Tabs, Modal } from 'antd';
import { TabsProps } from 'antd/lib/tabs';
import Form, { FormComponentProps } from 'antd/lib/form';
import ReactQuill from 'react-quill';

import { MainReducerState } from '../../store/reducers';
import * as TermsActions from '../../store/actions/terms';
import { getTermsFAQState, TermsState, updateTermsFAQState } from '../../store/reducers/terms';
import { getLangsData } from '../../store/reducers/langs';

import Constants from '../../utils/constants';
import { Lang } from '../../store/api/apiTypes';

interface FAQProps extends FormComponentProps {
    faqState: TermsState['fetchFAQ'];
    fetchFAQ: typeof TermsActions.fetchFAQ;
    langsState: Lang[];
    updateFAQ: typeof TermsActions.updateFAQ;
    updates: TermsState['updateFAQ'];
}

const FAQEdit: FC<FAQProps> = ({ faqState, form, fetchFAQ, langsState, updateFAQ, updates }) => {

    const [selectedLang, setSelectedLang] = useState(Constants.DEFAULT_LANG);

    const toolbarFormat = {
        toolbar: [
            [
                { header: [1, 2, 3] },
            ],
            [
                { font: [] },
                { size: [] },
                { color: [] },
            ],
            ['bold', 'italic', 'underline'],
            ['image', 'link'],
        ],
    };

    useEffect(() => {
        if (faqState.data) {
            form.setFieldsValue({html: faqState.data.html});
        }
    }, [faqState.data]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchFAQ({
            lang: selectedLang,
        });
    }, [selectedLang]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (updates.success) {
            form.resetFields();
            fetchFAQ({
                lang: selectedLang,
            });
        }
    }, [updates.success]); // eslint-disable-line react-hooks/exhaustive-deps

    const onTabChange: TabsProps['onChange'] = (event) => {
        if (form.getFieldValue('html') !== faqState.data.html) {
            confirmModal(event);
        } else {
            setSelectedLang(event);
        }
    };

    const confirmModal = (event: Lang['languageCode']) => {
        Modal.confirm({
            title: 'Voulez-vous garder les modifications?',
            okText: 'Oui',
            cancelText: 'Non',
            onOk() {
                updateFAQ({
                    faq: form.getFieldValue('html'),
                    lang: selectedLang,
                });
                form.resetFields();
                setSelectedLang(event);
            },
            onCancel() {
                form.resetFields();
                setSelectedLang(event);
            },
        });
    };

    const onSubmit = (e?: React.FormEvent) => {
        if (e) {
            e.preventDefault();
        }
        form.validateFieldsAndScroll(async (err, val) => {
            if (err) {
                return;
            }
            const payload = {
                faq: val.html,
                lang: selectedLang,
            };
            updateFAQ(payload);
        });
    };

    const { getFieldDecorator } = form;

    return (
        <>
            <Spin spinning={faqState.loading} tip="Chargement...">
                <Tabs onChange={onTabChange} type="card">
                    {langsState && langsState.map((e) => (
                        <Tabs.TabPane
                            tab={e.languageCode}
                            key={e.languageCode}
                        />
                    ))}
                </Tabs>
                <Form onSubmit={onSubmit} layout="vertical">
                    <Form.Item label="FAQ">
                        {getFieldDecorator(`html`, {
                            rules: [{
                                required: true,
                                message: 'champ requis',
                            }],
                            initialValue: faqState.data ? faqState.data.html : '',
                        })((
                            <ReactQuill
                                modules={toolbarFormat}
                                theme="snow"
                                placeholder="Vous pouvez utiliser la barre d'outils pour personnaliser votre contenu"
                            />
                        ))}
                    </Form.Item>
                </Form>
                <div className="form-actions">
                    <Button htmlType="submit" type="primary" onClick={onSubmit} loading={updates.loading}>
                        Mettre à jour
                    </Button>
                </div>
            </Spin>
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    faqState: getTermsFAQState(state),
    langsState: getLangsData(state),
    updates: updateTermsFAQState(state),
});

const FAQForm = Form.create<FAQProps>()(FAQEdit);

export default connect(
    mapStateToProps,
    {
        fetchFAQ: TermsActions.fetchFAQ,
        updateFAQ: TermsActions.updateFAQ,
    },
)(FAQForm);
