export const LIST = 'discounts/LIST';
export const LIST_SUCCESS = 'discounts/LIST_SUCCESS';
export const LIST_FAIL = 'discounts/LIST_FAIL';

export const DETAILS = 'discounts/DETAILS';
export const DETAILS_SUCCESS = 'discounts/DETAILS_SUCCESS';
export const DETAILS_FAIL = 'discounts/DETAILS_FAIL';

export const CREATE = 'discounts/CREATE';
export const CREATE_SUCCESS = 'discounts/CREATE_SUCCESS';
export const CREATE_FAIL = 'discounts/CREATE_FAIL';

export const UPDATE = 'discounts/UPDATE';
export const UPDATE_SUCCESS = 'discounts/UPDATE_SUCCESS';
export const UPDATE_FAIL = 'discounts/UPDATE_FAIL';

export const DELETE = 'discounts/DELETE';
export const DELETE_SUCCESS = 'discounts/DELETE_SUCCESS';
export const DELETE_FAIL = 'discounts/DELETE_FAIL';
