import { CounterPaiement, Refund } from './../api/apiTypes';

import { combineReducers } from 'redux';

import * as reduxTypes from '../types/orders';
import { Order } from '../api/apiTypes';
import { MainReducerState, RequestState } from './';
import { requestReducer } from './_generics';

export interface OrdersState {
    readonly list: RequestState<Order[]>;
    readonly listByClient: RequestState<Order[]>;
    readonly details: RequestState<Order>;
    readonly update: RequestState<Order>;
    readonly invoice: RequestState<string>;
    readonly invoiceByMail: RequestState<string>;
    readonly waitForPaiements: RequestState<Order[]>;
    readonly listCounterPaiement: RequestState<CounterPaiement[]>;
    readonly createCounterPaiement: RequestState<CounterPaiement>;
    readonly updateCounterPaiement: RequestState<CounterPaiement>;
    readonly deleteCounterPaiement: RequestState;
    readonly createRefund: RequestState<Refund>;
    readonly listRefunds: RequestState<Refund[]>;
}

const initialState: Order[] = [];

export default combineReducers<OrdersState>({
    list: requestReducer<Order[]>({
        START: reduxTypes.LIST,
        SUCCESS: reduxTypes.LIST_SUCCESS,
        FAILED: reduxTypes.LIST_FAIL,
    }, initialState),
    waitForPaiements: requestReducer<Order[]>({
        START: reduxTypes.WAIT_FOR_PAIEMENT,
        SUCCESS: reduxTypes.WAIT_FOR_PAIEMENT_SUCCESS,
        FAILED: reduxTypes.WAIT_FOR_PAIEMENT_FAIL,
        START_POLLING: reduxTypes.START_POLLING,
        STOP_POLLING: reduxTypes.STOP_POLLING,
    }, initialState),
    listByClient: requestReducer({
        START: reduxTypes.LIST_BY_CLIENT,
        SUCCESS: reduxTypes.LIST_BY_CLIENT_SUCCESS,
        FAILED: reduxTypes.LIST_BY_CLIENT_FAIL,
    }),
    details: requestReducer({
        START: reduxTypes.DETAILS,
        SUCCESS: reduxTypes.DETAILS_SUCCESS,
        FAILED: reduxTypes.DETAILS_FAIL,
    }),
    update: requestReducer({
        START: reduxTypes.UPDATE,
        SUCCESS: reduxTypes.UPDATE_SUCCESS,
        FAILED: reduxTypes.UPDATE_FAIL,
    }),
    invoice: requestReducer({
        START: reduxTypes.INVOICE,
        SUCCESS: reduxTypes.INVOICE_SUCCESS,
        FAILED: reduxTypes.INVOICE_FAIL,
    }),
    invoiceByMail: requestReducer({
        START: reduxTypes.INVOICE_BY_MAIL,
        SUCCESS: reduxTypes.INVOICE_BY_MAIL_SUCCESS,
        FAILED: reduxTypes.INVOICE_BY_MAIL_FAIL,
    }),
    listCounterPaiement: requestReducer({
        START: reduxTypes.LIST_COUNTER_PAIEMENT,
        SUCCESS: reduxTypes.LIST_COUNTER_PAIEMENT_SUCCESS,
        FAILED: reduxTypes.LIST_COUNTER_PAIEMENT_FAIL,
    }),
    createCounterPaiement: requestReducer({
        START: reduxTypes.CREATE_COUNTER_PAIEMENT,
        SUCCESS: reduxTypes.CREATE_COUNTER_PAIEMENT_SUCCESS,
        FAILED: reduxTypes.CREATE_COUNTER_PAIEMENT_FAIL,
    }),
    updateCounterPaiement: requestReducer({
        START: reduxTypes.UPDATE_COUNTER_PAIEMENT,
        SUCCESS: reduxTypes.UPDATE_COUNTER_PAIEMENT_SUCCESS,
        FAILED: reduxTypes.UPDATE_COUNTER_PAIEMENT_FAIL,
    }),
    deleteCounterPaiement: requestReducer({
        START: reduxTypes.DELETE_COUNTER_PAIEMENT,
        SUCCESS: reduxTypes.DELETE_COUNTER_PAIEMENT_SUCCESS,
        FAILED: reduxTypes.DELETE_COUNTER_PAIEMENT_FAIL,
    }),
    createRefund: requestReducer({
        START: reduxTypes.CREATE_REFUND,
        SUCCESS: reduxTypes.CREATE_REFUND_SUCCESS,
        FAILED: reduxTypes.CREATE_REFUND_FAIL,
    }),
    listRefunds: requestReducer({
        START: reduxTypes.LIST_REFUNDS,
        SUCCESS: reduxTypes.LIST_REFUNDS_SUCCESS,
        FAILED: reduxTypes.LIST_REFUNDS_FAIL,
    }),
});

export const getOrdersListState = (state: MainReducerState) => state.orders.list;
export const getOrdersListByClientState = (state: MainReducerState) => state.orders.listByClient;
export const getOrderDetailsState = (state: MainReducerState) => state.orders.details;
export const updateOrderState = (state: MainReducerState) => state.orders.update;
export const invoiceState = (state: MainReducerState) => state.orders.invoice;
export const invoiceByMailState = (state: MainReducerState) => state.orders.invoiceByMail;
export const getWaitingForPaiementOrders = (state: MainReducerState) => state.orders.waitForPaiements;
export const getCounterPaimentState = (state: MainReducerState) => state.orders.listCounterPaiement;
export const getUpdateCounterPaiementState = (state: MainReducerState) => state.orders.updateCounterPaiement;
export const getcreationCounterPaiementState = (state: MainReducerState) => state.orders.createCounterPaiement;
export const getDeleteCounterPaiementState = (state: MainReducerState) => state.orders.deleteCounterPaiement;
export const getCreateRefundState = (state: MainReducerState) => state.orders.createRefund;
export const getListRefundsState = (state: MainReducerState) => state.orders.listRefunds;
