import React, { FC, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Descriptions, Button, Spin, Icon, Tabs, Table, Typography, Tooltip } from 'antd';
import { TabsProps } from 'antd/lib/tabs';
import { ColumnProps } from 'antd/lib/table';
import { Link } from 'react-router-dom';
// tslint:disable-next-line: no-implicit-dependencies
import { RouteChildrenProps } from 'react-router';

import { MainReducerState } from '../../store/reducers';
import * as CatalogProductActions from '../../store/actions/catalogProducts';
import { getCatalogProductDetailsState, CatalogProductsState } from '../../store/reducers/catalogProducts';
import { Lang, CatalogProductSuppIngredient } from '../../store/api/apiTypes';
import { ParcsState, getSelectedParcState } from '../../store/reducers/parcs';
import { getLangsData } from '../../store/reducers/langs';

import { CatalogProductForm } from '.';
import Constants from '../../utils/constants';
import { getRoute, RoutePathName } from '../../routes';
import AllergensTooltip from '../../components/AllergensTooltip';
import imageSelector from '../../utils/imageSelector';

interface MatchParams {
  id: string;
}

interface CatalogProductProps extends RouteChildrenProps<MatchParams> {
    catalogProductsState: CatalogProductsState['details'];
    getDetails: typeof CatalogProductActions.details;
    langsState: Lang[];
    siteIdState: ParcsState['selected'];
}

const CatalogProduct: FC<CatalogProductProps> = ({
    match, catalogProductsState, langsState, getDetails, siteIdState,
}) => {

    const [ isFormVisible, setIsFormVisible ] = useState(false);
    const [ selectedLang, setSelectedLang ] = useState(Constants.DEFAULT_LANG);

    useEffect(() => {
        getDetails(match ? parseInt(match.params.id, 10) : null, {
            siteId: siteIdState,
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getDetails(match ? parseInt(match.params.id, 10) : null, {
            siteId: siteIdState,
        });
    }, [siteIdState]); // eslint-disable-line react-hooks/exhaustive-deps

    const onUpdateSuccess = () => {
        getDetails(match ? parseInt(match.params.id, 10) : null, {
            siteId: siteIdState,
        });
    };

    const onDrawerClose = () => {
        setIsFormVisible(false);
    };

    const setEditMode = () => {
        setIsFormVisible(true);
    };

    const rowKey = (item: CatalogProductSuppIngredient) => `${item.id}`;

    const columns: Array<ColumnProps<CatalogProductSuppIngredient>> = [
        {
            dataIndex: 'suppIngredient.product.techLabel',
            title: 'Label technique',
        },
        {
            dataIndex: `suppIngredient.product.label[${selectedLang}]`,
            title: 'Label',
        },
        {
            dataIndex: 'suppIngredient.price',
            title: 'Prix',
            render: (val) => new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(val),
        },
        {
            dataIndex: 'suppIngredient.vatRate',
            title: 'TVA',
            render: (val) => `${val}%`,
        },
        {
            dataIndex: 'suppIngredient.isEnabled',
            title: 'Actif',
            render: (val) => val === true ? <Icon type="check" /> : '',
        },
        {
            dataIndex: 'suppIngredient.product.internalRef',
            title: 'Référence',
        },
    ];

    const changeLang: TabsProps['onChange'] = (event) => {
        setSelectedLang(event);
    };

    return (
        <>
            <Spin spinning={catalogProductsState.loading} tip="Chargement...">
                <Tabs
                    tabBarExtraContent={(
                        <Button
                            type="primary"
                            shape="round"
                            icon="edit"
                            onClick={setEditMode}
                        >
                            Modifier
                        </Button>
                    )}
                    onChange={changeLang}
                    type="card"
                >
                    {langsState && langsState.map((e) => (
                        <Tabs.TabPane
                            tab={e.languageCode}
                            key={e.languageCode}
                        />
                    ))}
                </Tabs>
                <Descriptions title="Détails de produit catalogue" className="mb-24" bordered>
                    <Descriptions.Item label="Prix">
                        {catalogProductsState.data ?
                            new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' })
                                .format(catalogProductsState.data.price) :
                            null
                        }
                    </Descriptions.Item>
                    <Descriptions.Item label="TVA">
                        {catalogProductsState.data ? catalogProductsState.data.vatRate : null}
                    </Descriptions.Item>
                    <Descriptions.Item label="Actif">
                        {catalogProductsState.data && catalogProductsState.data.isEnabled ?
                            <Icon type="check" /> :
                            null
                        }
                    </Descriptions.Item>
                    <Descriptions.Item label="Restaurant">
                        {catalogProductsState.data && catalogProductsState.data.restaurant ?
                            (
                                <Link
                                    to={getRoute(RoutePathName.restaurant, {
                                        id: catalogProductsState.data.restaurant.id,
                                    })}
                                >
                                    {catalogProductsState.data.restaurant.label}
                                </Link>
                            ) :
                            null
                        }
                    </Descriptions.Item>
                </Descriptions>
                <Descriptions title="Produit associé" className="mb-24" bordered>
                    <Descriptions.Item label="Réf. interne">
                        {catalogProductsState.data ? catalogProductsState.data.product.internalRef : null}
                    </Descriptions.Item>
                    <Descriptions.Item label="Label technique">
                        {catalogProductsState.data ? catalogProductsState.data.product.techLabel : null}
                    </Descriptions.Item>
                    <Descriptions.Item label="Label">
                        {catalogProductsState.data && catalogProductsState.data.product.label[selectedLang] ?
                        catalogProductsState.data.product.label[selectedLang] : null}
                    </Descriptions.Item>
                    <Descriptions.Item label="Description">
                        {catalogProductsState.data && catalogProductsState.data.product.description[selectedLang] ?
                        catalogProductsState.data.product.description[selectedLang] : null}
                    </Descriptions.Item>
                    <Descriptions.Item label="Ingrédients">
                        {catalogProductsState.data && catalogProductsState.data.product.ingredients[selectedLang] ?
                        catalogProductsState.data.product.ingredients[selectedLang] : null}
                    </Descriptions.Item>
                    <Descriptions.Item label="Allergen codes">
                        {catalogProductsState.data ? (
                            <Tooltip
                                placement="right"
                                title={<AllergensTooltip list={catalogProductsState.data.product.allergenCodes}/>}
                            >
                                {catalogProductsState.data.product.allergenCodes}
                            </Tooltip>
                        ) : null}
                    </Descriptions.Item>
                    <Descriptions.Item label="Actif">
                        {catalogProductsState.data && catalogProductsState.data.product.isEnabled ?
                            <Icon type="check"/> :
                            null
                        }
                    </Descriptions.Item>
                    <Descriptions.Item label="Ingrédient supplémentaire">
                        {catalogProductsState.data && catalogProductsState.data.product.isSupplementalIngredient ?
                            <Icon type="check"/> :
                            null
                        }
                    </Descriptions.Item>
                    <Descriptions.Item label="Menu">
                        {catalogProductsState.data && catalogProductsState.data.product.isMenu ?
                            <Icon type="check"/> :
                            null
                        }
                    </Descriptions.Item>
                    <Descriptions.Item label="Produit d'un menu">
                        {catalogProductsState.data && catalogProductsState.data.product.isMenuProduct ? <Icon type="check"/> : null}
                    </Descriptions.Item>
                    <Descriptions.Item label="Variante d'un produit">
                        {catalogProductsState.data && catalogProductsState.data.product.isVariantProduct ? <Icon type="check"/> : null}
                    </Descriptions.Item>
                    <Descriptions.Item label="Catégorie Principale">
                        {catalogProductsState.data && catalogProductsState.data.product.category !== null &&
                        catalogProductsState.data.product.category.mainCategory &&
                        catalogProductsState.data.product.category.mainCategory.categoryLabel ?
                        catalogProductsState.data.product.category.mainCategory.categoryLabel[selectedLang] : null}
                    </Descriptions.Item>
                    <Descriptions.Item label="Catégorie">
                        {catalogProductsState.data && catalogProductsState.data.product.category !== null &&
                        catalogProductsState.data.product.category.subcategoryLabel ?
                        catalogProductsState.data.product.category.subcategoryLabel[selectedLang] : null}
                    </Descriptions.Item>
                    <Descriptions.Item label="Type de repas">
                        {catalogProductsState.data && catalogProductsState.data.product.mealType !== null &&
                        catalogProductsState.data.product.mealType.label ?
                            catalogProductsState.data.product.mealType.label[selectedLang] :
                            null
                        }
                    </Descriptions.Item>
                    <Descriptions.Item label="Image produit">
                        {catalogProductsState.data && catalogProductsState.data.product.images.length > 0 ?
                            <img src={imageSelector(catalogProductsState.data.product.images)} alt="produit"/> :
                            null
                        }
                    </Descriptions.Item>
                </Descriptions>
                <Typography.Title level={4}>Ingrédients supplémentaires</Typography.Title>
                <Table<CatalogProductSuppIngredient>
                    bordered={false}
                    rowKey={rowKey}
                    columns={columns}
                    dataSource={catalogProductsState.data && catalogProductsState.data.suppIngredients.length ?
                        catalogProductsState.data.suppIngredients :
                        []
                    }
                    loading={catalogProductsState.loading}
                    pagination={false}
                />
                <CatalogProductForm
                    id={catalogProductsState.data ? catalogProductsState.data.id : undefined}
                    onUpdateSuccess={onUpdateSuccess}
                    isVisible={isFormVisible}
                    siteId={siteIdState}
                    onClose={onDrawerClose}
                />
            </Spin>
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    catalogProductsState: getCatalogProductDetailsState(state),
    langsState: getLangsData(state),
    siteIdState: getSelectedParcState(state),
});

export default connect(
    mapStateToProps,
    {
      getDetails: CatalogProductActions.details,
    },
)(CatalogProduct);
