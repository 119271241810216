import api from './_client';
import { Banner } from './apiTypes';

export const list = () => {
    return api.get(`/cms/banners`);
};

export const details = (id: Banner['id']) => {
    return api.get<Banner>(`/cms/banner/${id}`);
};

export const create = (body: any) => {
    const formData = new FormData();
    Object.keys(body).forEach((payloadKey) => {
        formData.append(payloadKey, body[payloadKey]);
    });
    return api.post<Banner>(`/cms/banner`, formData);
};

export const update = (id: Banner['id'], body: any) => {
    const formData = new FormData();
    Object.keys(body).forEach((payloadKey) => {
        formData.append(payloadKey, body[payloadKey]);
    });
    return api.post<Banner>(`/cms/banner/${id}`, formData);
};
