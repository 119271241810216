import { compile } from 'path-to-regexp';

import { hasOwnProp, debug } from './utils';

import { Banners } from './pages/banners';
import { Categories } from './pages/categories';
import { Presales } from './pages/presales';
import { CatalogProducts, CatalogProduct } from './pages/catalogProducts';
import { CGU, CGV, FAQ } from './pages/terms';
import { Clients } from './pages/clients';
import Dashboard from './pages/dashboard';
import { Discounts } from './pages/discounts';

import { Menus } from './pages/menus';
import { Order, Orders } from './pages/orders';
import { Parc, Parcs } from './pages/parcs';
import { Product, Products } from './pages/products';
import { Restaurant, Restaurants } from './pages/restaurants';
import { Suggestions } from './pages/suggestions';
import { User, Users } from './pages/users';
import { AppRoute, UserRole, MenuRoute } from './store/api/apiTypes';

export enum RoutePathName {
    banners = 'banners',
    catalogProducts = 'catalogProducts',
    catalogProduct = 'catalogProduct',
    categories = 'categories',
    cgu = 'cgu',
    cgv = 'cgv',
    clients = 'clients',
    discounts = 'discounts',
    faq = 'faq',
    home = 'home',
    login = 'login',
    menus = 'menus',
    notFound = 'notFound',
    order = 'order',
    orders = 'orders',
    parc = 'parc',
    parcs = 'parcs',
    product = 'product',
    products = 'products',
    restaurant = 'restaurant',
    restaurants = 'restaurants',
    suggestions = 'suggestions',
    user = 'user',
    users = 'users',
    presale= 'presale',
    presales= 'presales',
}

export type Routes = {
    [r in RoutePathName]: string;
};

export enum AllowedOrdersStatus {
    all = 'all',
    waitForPaiement = 'wait-for-paiement',
}
export const routes: Routes = {
    [RoutePathName.home]: '/',
    [RoutePathName.banners]: '/banners',
    [RoutePathName.catalogProducts]: '/catalog-products',
    [RoutePathName.categories]: '/categories',
    [RoutePathName.catalogProduct]: '/catalog-product/:id',
    [RoutePathName.cgu]: '/cgu',
    [RoutePathName.cgv]: '/cgv',
    [RoutePathName.clients]: '/clients',
    [RoutePathName.discounts]: '/discounts',
    [RoutePathName.faq]: '/faq',
    [RoutePathName.login]: '/login',
    [RoutePathName.menus]: '/menus',
    [RoutePathName.notFound]: '/404',
    [RoutePathName.order]: '/order/:id',
    [RoutePathName.orders]: `/orders/:status(${Object.values(AllowedOrdersStatus).join('|')})`,
    [RoutePathName.parc]: '/parc/:id',
    [RoutePathName.parcs]: '/parcs',
    [RoutePathName.product]: '/product/:id',
    [RoutePathName.products]: '/products',
    [RoutePathName.restaurant]: '/restaurant/:id',
    [RoutePathName.restaurants]: '/restaurants',
    [RoutePathName.suggestions]: '/suggestions',
    [RoutePathName.user]: '/user/:id',
    [RoutePathName.users]: '/users',
    [RoutePathName.presales]: '/presales',
    [RoutePathName.presale]: '/presale/:id',
};
export interface RouteParams {
    [param: string]: string | number;
}

// returns raw react-router string path
export const getRawRoute = (path: RoutePathName) => {
    if (!hasOwnProp(routes, path)) {
        console.error(`[getRawRoute] Route not found for path "${path}", returning /404.`);
        return '/404';
    } else {
        // console.log('>>>>>>>>>>>', routes[path]);
        return routes[path];
    }
};

// returns real-world path
export const getRoute = (
    path: RoutePathName,
    params?: RouteParams,
    queryParams?: string | string[][] | Record<string, string> | URLSearchParams | undefined,
    anchor?: string,
) => {
    let route = getRawRoute(path);
    const compiler = compile(route, { encode: encodeURIComponent });

    try {
        route = compiler(params);
    } catch (error) {
        debug.error('[getRoute] route compile error :', error);
    }

    if (queryParams && Object.keys(queryParams).length) {
        const urlQueryParams = new URLSearchParams(queryParams);
        route += `?${urlQueryParams.toString()}`;
    }

    if (anchor) {
        route = `${route}#${anchor}`;
    }

    return route;
};

const adminRoles: UserRole[] = [UserRole.admin, UserRole.superAdmin];

export const appRoutes: AppRoute[] = [
    {
        name: 'home', path: getRawRoute(RoutePathName.home), exact: true,
        component: Dashboard, roles: adminRoles,
    },
    {
        name: 'parc', path: getRawRoute(RoutePathName.parc), exact: true, component: Parc,
        roles: adminRoles,
    },
    {
        name: 'parcs', path: getRawRoute(RoutePathName.parcs), exact: true, component: Parcs,
        roles: adminRoles,
    },
    {
        name: 'product', path: getRawRoute(RoutePathName.product), exact: true, component: Product,
        roles: [UserRole.superAdmin],
    },
    {
        name: 'products', path: getRawRoute(RoutePathName.products), exact: true, component: Products,
        roles: [UserRole.superAdmin],
    },
    {
        name: 'menus', path: getRawRoute(RoutePathName.menus), exact: true, component: Menus,
        roles: [UserRole.superAdmin],
    },
    {
        name: 'restaurant', path: getRawRoute(RoutePathName.restaurant), exact: false, component: Restaurant,
        roles: adminRoles,
    },
    {
        name: 'restaurants', path: getRawRoute(RoutePathName.restaurants), exact: true, component: Restaurants,
        roles: adminRoles,
    },
    {
        name: 'discounts', path: getRawRoute(RoutePathName.discounts), exact: true, component: Discounts,
        roles: adminRoles,
    },
    {
        name: 'catalog product', path: getRawRoute(RoutePathName.catalogProduct), exact: true,
        component: CatalogProduct, roles: adminRoles,
    },
    {
        name: 'catalog products', path: getRawRoute(RoutePathName.catalogProducts), exact: true,
        component: CatalogProducts, roles: adminRoles,
    },
    {
        name: 'suggestions', path: getRawRoute(RoutePathName.suggestions), exact: true, component: Suggestions,
        roles: adminRoles,
    },
    {
        name: 'clients', path: getRawRoute(RoutePathName.clients), exact: true, component: Clients,
        roles: adminRoles,
    },
    {
        name: 'order', path: getRawRoute(RoutePathName.order), exact: true, component: Order,
        roles: [...adminRoles, UserRole.desk],
    },
    {
        name: 'orders', path: getRawRoute(RoutePathName.orders), exact: true, component: Orders,
        roles: [...adminRoles, UserRole.desk],
    },
    {
        name: 'user', path: getRawRoute(RoutePathName.user), exact: false, component: User,
        roles: adminRoles,
    },
    {
        name: 'users', path: getRawRoute(RoutePathName.users), exact: true, component: Users,
        roles: adminRoles,
    },
    {
        name: 'cgu', path: getRawRoute(RoutePathName.cgu), exact: true, component: CGU,
        roles: adminRoles,
    },
    {
        name: 'cgv', path: getRawRoute(RoutePathName.cgv), exact: true, component: CGV,
        roles: adminRoles,
    },
    {
        name: 'faq', path: getRawRoute(RoutePathName.faq), exact: true, component: FAQ,
        roles: adminRoles,
    },
    {
        name: 'banners', path: getRawRoute(RoutePathName.banners), exact: true, component: Banners,
        roles: adminRoles,
    },
    {
        name: 'categories', path: getRawRoute(RoutePathName.categories), exact: true, component: Categories,
        roles: adminRoles,
    },
    {
        name: 'Pré-vente', path: getRoute(RoutePathName.presales), exact: true, component: Presales,
        roles: [...adminRoles, UserRole.desk],
    },
];

export const MenuRoutesByRole: MenuRoute[] = [
    {
        name: 'home', path: getRoute(RoutePathName.home), icon: 'home', roles: [...adminRoles],
    },
    {
        name: 'parcs', path: getRoute(RoutePathName.parcs), icon: 'dribbble-circle', theme: 'filled',
        roles: adminRoles,
    },
    {
        name: 'Produits', path: getRoute(RoutePathName.products), icon: 'coffee', roles: [UserRole.superAdmin],
    },
    {
        name: 'Catégories', path: getRoute(RoutePathName.categories), icon: 'ordered-list',
        roles: [UserRole.superAdmin],
    },
    {
        name: 'Menus', path: getRoute(RoutePathName.menus), icon: 'profile', roles: [UserRole.superAdmin],
    },
    {
        path: getRoute(RoutePathName.restaurants), name: 'Restaurants', icon: 'shop', roles: adminRoles,
    },
    {
        name: 'Produits catalogue', path: getRoute(RoutePathName.catalogProducts), icon: 'shopping',
        roles: adminRoles,
    },
    {
        name: 'Suggestions', path: getRoute(RoutePathName.suggestions), icon: 'fork', roles: adminRoles,
    },
    {
        name: 'Codes réduction', path: getRoute(RoutePathName.discounts), icon: 'euro', roles: adminRoles,
    },
    {
        name: 'Commandes', path: getRoute(RoutePathName.orders, { status: AllowedOrdersStatus.waitForPaiement }),
        icon: 'shopping-cart', roles: [...adminRoles, UserRole.desk],
    },
    {
        name: 'Employés', path: getRoute(RoutePathName.users), icon: 'usergroup-add', roles: adminRoles,
    },
    {
        name: 'Clients', path: getRoute(RoutePathName.clients), icon: 'user', roles: adminRoles,
    },
    {
        name: 'CGU', path: getRoute(RoutePathName.cgu), icon: 'user', roles: [UserRole.superAdmin],
    },
    {
        name: 'CGV', path: getRoute(RoutePathName.cgv), icon: 'user', roles: [UserRole.superAdmin],
    },
    {
        name: 'FAQ', path: getRoute(RoutePathName.faq), icon: 'user', roles: [UserRole.superAdmin],
    },
    {
        name: 'Bannières', path: getRoute(RoutePathName.banners), icon: 'file-image', roles: [UserRole.superAdmin],
    },
    {
        name: 'Pré-vente', path: getRoute(RoutePathName.presales), icon: 'file-image',
        roles: [...adminRoles, UserRole.desk],
    },
];
