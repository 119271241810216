import { combineReducers } from 'redux';

import * as reduxTypes from '../types/restaurants';
import { Restaurant } from '../api/apiTypes';
import { MainReducerState, RequestState } from './';
import { requestReducer } from './_generics';

export interface RestaurantsState {
    readonly list: RequestState<Restaurant[]>;
    readonly listSelect: RequestState<Restaurant[]>;
    readonly create: RequestState<Restaurant>;
    readonly details: RequestState<Restaurant>;
    readonly update: RequestState<Partial<Restaurant>>;
}

const initialState: Restaurant[] = [];

export default combineReducers<RestaurantsState>({
    list: requestReducer({
        START: reduxTypes.LIST,
        SUCCESS: reduxTypes.LIST_SUCCESS,
        FAILED: reduxTypes.LIST_FAIL,
    }, initialState),
    listSelect: requestReducer({
        START: reduxTypes.LIST_SELECT,
        SUCCESS: reduxTypes.LIST_SELECT_SUCCESS,
        FAILED: reduxTypes.LIST_SELECT_FAIL,
    }, initialState),
    create: requestReducer({
        START: reduxTypes.CREATE,
        SUCCESS: reduxTypes.CREATE_SUCCESS,
        FAILED: reduxTypes.CREATE_FAIL,
    }),
    details: requestReducer({
        START: reduxTypes.DETAILS,
        SUCCESS: reduxTypes.DETAILS_SUCCESS,
        FAILED: reduxTypes.DETAILS_FAIL,
    }),
    update: requestReducer({
        START: reduxTypes.UPDATE,
        SUCCESS: reduxTypes.UPDATE_SUCCESS,
        FAILED: reduxTypes.UPDATE_FAIL,
    }),
});

export const getRestaurantsState = (state: MainReducerState) => state.restaurants.list;
export const getRestaurantSelectListState = (state: MainReducerState) => state.restaurants.listSelect;
export const createRestaurantState = (state: MainReducerState) => state.restaurants.create;
export const getRestaurantDetailsState = (state: MainReducerState) => state.restaurants.details;
export const updateRestaurantState = (state: MainReducerState) => state.restaurants.update;
