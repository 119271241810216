import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Form, Drawer, Button,
    Divider, Row, Col,
    DatePicker, Alert, Select, Typography } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import moment from 'moment';

import { MainReducerState } from '../../store/reducers';
import * as SuggestionsActions from '../../store/actions/suggestions';
import { CatalogProductsState } from '../../store/reducers/catalogProducts';
import { SuggestionsState, getSuggestionDetailsState, updateSuggestionState,
     createSuggestionState, getSuggestionsState } from '../../store/reducers/suggestions';
import { Suggestion, CatalogProduct } from '../../store/api/apiTypes';
import { DrawerProps } from 'antd/lib/drawer';
import { ButtonProps } from 'antd/lib/button';

interface SuggestionsEditProps extends FormComponentProps {
    id: Suggestion['id'];
    createState: boolean;
    createSuggestion: typeof SuggestionsActions.create;
    creates: SuggestionsState['create'];
    isVisible: boolean;
    canAddSuggestion: boolean;
    onUpdateSuccess: () => void;
    onSelectProductChange: (id: Suggestion['id']) => void;
    onSelectProductSearch: (str: string) => void;
    onClose: () => void;
    suggestionsDetails: SuggestionsState['detail'];
    getDetails: typeof SuggestionsActions.detail;
    siteId: number | null;
    suggestionsState: SuggestionsState['list'];
    updateSuggestion: typeof SuggestionsActions.update;
    updates: SuggestionsState['update'];
    catalogProductsState: CatalogProductsState['list'];
}

const SuggestionEdit: FC<SuggestionsEditProps> = ({
    id, createSuggestion, creates, suggestionsDetails, form, getDetails, isVisible,
    onUpdateSuccess, onClose, siteId, updateSuggestion, updates, canAddSuggestion,
    onSelectProductChange, onSelectProductSearch, catalogProductsState, suggestionsState,
    createState,
}) => {
    const { Title } = Typography;
    const [errorDate, setErrorDate] = useState(false);

    useEffect(() => {
        if (id) {
            getDetails(id, {
                siteId,
            });
        }
    }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (creates.success || updates.success) {
            form.resetFields();
            onUpdateSuccess();
            onClose();
        }
    }, [creates.success, updates.success]); // eslint-disable-line react-hooks/exhaustive-deps

    const { getFieldDecorator } = form;

    const onDrawerClose: DrawerProps['onClose'] & ButtonProps['onClick'] = () => {
        form.resetFields();
        onClose();
    };

    const onSubmit = (e?: React.FormEvent) => {
        if (e) {
            e.preventDefault();
        }
        form.validateFieldsAndScroll(async (err, val) => {
            if (err) {
                return;
            }
            if (val && moment(val.endDate).format('YYYY-MM-DD') >= moment(val.startDate).format('YYYY-MM-DD')) {
                setErrorDate(false);
                const payload = {
                    siteId,
                    catalogProductId: id,
                    startDate: moment(val.startDate).format('YYYY-MM-DD'),
                    endDate: moment(val.endDate).format('YYYY-MM-DD'),
                };
                if (!createState) {
                    updateSuggestion(id, payload);
                } else {
                    createSuggestion(payload);
                }
            } else {
                setErrorDate(true);
            }
        });
    };

    const dateFormat: string = 'DD/MM/YYYY';

    return (
        <>
            <Drawer
                title={!createState ? 'Modification de la Suggestion ' : 'Ajout de Suggestion'}
                width={600}
                onClose={onClose}
                visible={isVisible}
                className="edit-product-drawer"
            >
                        <Form>
                            <Row gutter={12}>
                            {createState ? (
                                <Select
                                    placeholder="Choisissez un produit"
                                    showSearch
                                    value={id}
                                    onChange={onSelectProductChange}
                                    onSearch={onSelectProductSearch}
                                    filterOption={false}
                                    className="suggestion-select"
                                >
                                    {catalogProductsState.data.filter((catalogProduct) =>
                                        !suggestionsState.data.find((suggestion) =>
                                            suggestion.id === catalogProduct.id,
                                        )).map((prod: CatalogProduct) => (
                                            <Select.Option
                                                key={prod.id}
                                                value={prod.id}
                                            >
                                           {prod.product ? prod.product.techLabel : undefined}
                                            </Select.Option>
                                        ))}
                                </Select>
                        ) : (
                            <Title level={4}>{suggestionsDetails.data &&
                                suggestionsDetails.data.catalogProduct ?
                                suggestionsDetails.data.catalogProduct.product.techLabel : undefined}</Title>
                        )
                    }
                            </Row>
                            <Row gutter={16}>
                                <Col span={10}>
                                    <Form.Item label="Date de début">
                                        {getFieldDecorator('startDate', {
                                            rules: [{
                                                required: true,
                                                message: 'champ requis',
                                            }],
                                            initialValue: !createState && suggestionsDetails.data ?
                                                moment(suggestionsDetails.data.startDate) : undefined,
                                        })((
                                            <DatePicker
                                                format={dateFormat}
                                                placeholder="Choisissez une date"
                                            />
                                        ))}
                                    </Form.Item>
                                </Col>
                                <Col span={10}>
                                    <Form.Item label="Date de fin">
                                        {getFieldDecorator('endDate', {
                                            rules: [{
                                                required: true,
                                                message: 'champ requis',
                                            }],
                                            initialValue: !createState && suggestionsDetails.data ?
                                                moment(suggestionsDetails.data.endDate) : undefined,
                                        })((
                                            <DatePicker
                                                format={dateFormat}
                                                placeholder="Choisissez une date"
                                            />
                                        ))}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <div className="form-actions">
                                <Button htmlType="submit" type="primary" onClick={onSubmit} >
                                    {!createState ? 'Mettre à jour' : 'Ajouter'}
                                </Button>
                                <Divider type="vertical"/>
                                <Button onClick={onDrawerClose} type="ghost">
                                    Annuler
                                </Button>
                            </div>
                            <div className="modifier-button">
                                {!creates.loading && creates.error && (
                                    <Alert
                                        type="error"
                                        message={creates.error.errorCode === 'SUGGESTION_EXISTS' ?
                                        'Ce code de réduction est déjà utilisé!' :
                                            creates.error.errorMessage}
                                        showIcon
                                    />
                                )}
                            </div>
                            <div className="modifier-button">
                                {!updates.loading && updates.error && (
                                    <Alert
                                        type="error"
                                        message={updates.error.errorCode === 'SUGGESTIONS_NOT_FOUND' ?
                                         `Cette suggestion n'existe pas!` :
                                            updates.error.errorMessage}
                                        showIcon
                                    />
                                )}
                            </div>
                            <div className="modifier-button">
                                {errorDate && (
                                    <Alert
                                        type="error"
                                        message={
                                         `veuillez choisir une date de fin supèrieure ou égale à la date de début`}
                                        showIcon
                                    />
                                )}
                            </div>
                        </Form>
            </Drawer>
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    creates: createSuggestionState(state),
    suggestionsDetails: getSuggestionDetailsState(state),
    suggestionsState: getSuggestionsState(state),
    updates: updateSuggestionState(state),
});

const SuggestionFormDrawer = Form.create<SuggestionsEditProps>()(SuggestionEdit);

export default connect(
    mapStateToProps,
    {
        createSuggestion: SuggestionsActions.create,
        getDetails: SuggestionsActions.detail,
        updateSuggestion: SuggestionsActions.update,
    },
)(SuggestionFormDrawer);
