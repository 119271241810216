export const LIST = 'presales/LIST';
export const LIST_SUCCESS = 'presales/LIST_SUCCESS';
export const LIST_FAIL = 'presales/LIST_FAIL';

export const CREATE = 'presales/CREATE';
export const CREATE_SUCCESS = 'presales/CREATE_SUCCESS';
export const CREATE_FAIL = 'presales/CREATE_FAIL';

export const DETAILS = 'presales/DETAILS';
export const DETAILS_SUCCESS = 'presales/DETAILS_SUCCESS';
export const DETAILS_FAIL = 'presales/DETAILS_FAIL';

export const UPDATE = 'presales/UPDATE';
export const UPDATE_SUCCESS = 'presales/UPDATE_SUCCESS';
export const UPDATE_FAIL = 'presales/UPDATE_FAIL';

export const LIST_SELECT = 'presales/LIST_SELECT';
export const LIST_SELECT_SUCCESS = 'presales/LIST_SELECT_SUCCESS';
export const LIST_SELECT_FAIL = 'presales/LIST_SELECT_FAIL';

export const REMOVE = 'presales/REMOVE';
export const REMOVE_SUCCESS = 'presales/REMOVE_SUCCESS';
export const REMOVE_FAIL = 'presales/REMOVE_FAIL';
