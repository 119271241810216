import React, { FC } from 'react';
import { connect } from 'react-redux';
import {
    BrowserRouter,
    Route,
    Switch,
    Redirect,
} from 'react-router-dom';

import './assets/styles/App.less';
import './assets/styles/utils.less';
import './assets/styles/mixins.less';

import { getRawRoute, RoutePathName, appRoutes, getRoute, AllowedOrdersStatus } from './routes';
import ProtectedRoute from './components/ProtectedRoute';
import MainLayout from './components/MainLayout';
import { AuthState, getUser } from './store/reducers/auth';
import { MainReducerState } from './store/reducers';
import Login from './pages/login';
import { UserRole } from './store/api/apiTypes';

interface AppProps {
    user: AuthState['data'];
}

const App: FC<AppProps> = ({ user }) => {

    return (
        <BrowserRouter>
            <Switch>
                <Route exact path={getRawRoute(RoutePathName.login)} component={Login} />
                <ProtectedRoute loginPath={getRawRoute(RoutePathName.login)}>
                    <MainLayout>
                        <Switch>
                            {user && appRoutes.reduce((acc: any[], { name, roles, ...props }) => {
                                return roles.includes(user.role) ? acc.concat(<Route key={name}  {...props} />) : acc;
                            }, [])}
                            {
                                // override home page for desk users
                                user && user.role === UserRole.desk && (
                                    <Route exact path={getRawRoute(RoutePathName.home)}>
                                        <Redirect
                                            to={getRoute(RoutePathName.orders,
                                                { status: AllowedOrdersStatus.waitForPaiement })}
                                        />
                                    </Route>
                                )
                            }
                            <Route>
                                <Redirect to={getRawRoute(RoutePathName.notFound)} />
                            </Route>
                        </Switch>
                    </MainLayout>
                </ProtectedRoute>
            </Switch>
        </BrowserRouter >
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    user: getUser(state),
});

export default connect(
    mapStateToProps,
)(App);
