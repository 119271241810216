import { call, takeLatest, put } from 'redux-saga/effects';

import * as reduxActions from '../actions/logsRegister';
import * as reduxTypes from '../types/logsRegister';
import * as api from '../api/logsRegister';
import { Order } from '../api/apiTypes';
import { IdAction } from '../actions';

export function* listSaga(action: IdAction<Order['id']>) {
    try {
        const response = yield call(api.list, action.id);
        return yield put(reduxActions.listSuccess(response));
    } catch (error) {
        return yield put(reduxActions.listFailed(error));
    }
}

export default function* logsRegisterSaga() {
    yield takeLatest(reduxTypes.LIST, listSaga);
}
