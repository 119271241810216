import api, { urlWithQuery } from './_client';
import { Product, ProductVariant } from './apiTypes';
import { QueryListPayload } from '../actions';

export const list = (payload: QueryListPayload) => {
    return api.get(urlWithQuery('/cms/products', payload));
};

export const details = (id: Product['id']) => {
    return api.get<Product>(`/cms/product/${id}`);
};

export const create = (body: Product) => {
    return api.post<Product>(`/cms/product`, body);
};

export const update = (id: Product['id'], body: Partial<Product>) => {
    return api.put<Product>(`/cms/product/${id}`, body);
};

export const listVariants = (id: Product['id']) => {
    return api.get<ProductVariant[]>(`/cms/variants/product/${id}`);
};

export const addVariant = (data: any) => {
    return api.put<ProductVariant>(`/cms/variants/product/${data.main}/variant/${data.variant}`);
};

export const removeVariant = (id: ProductVariant['id']) => {
    return api.delete<ProductVariant>(`/cms/variants/${id}`);
};
