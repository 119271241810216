import { Action, ActionCreator } from 'redux';

import * as reduxTypes from '../types/categories';
import { DataAction, IdAction } from '.';
import { Category, Subcategory } from '../api/apiTypes';

export const listCategories: ActionCreator<Action> = () => ({
    type: reduxTypes.LIST_CATEGORIES,
});

export const listCategoriesSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_CATEGORIES_SUCCESS,
    data,
});

export const listCategoriesFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_CATEGORIES_FAIL,
    data,
});

export const listSubcategories: ActionCreator<Action> = () => ({
    type: reduxTypes.LIST_SUBCATEGORIES,
});

export const listSubcategoriesSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_SUBCATEGORIES_SUCCESS,
    data,
});

export const listSubcategoriesFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_SUBCATEGORIES_FAIL,
    data,
});

export const detailsCategory: ActionCreator<IdAction<Category['id']>> = (id: Category['id']) => ({
    type: reduxTypes.DETAILS_CATEGORY,
    id,
});

export const detailsCategorySuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.DETAILS_CATEGORY_SUCCESS,
    data,
});

export const detailsCategoryFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.DETAILS_CATEGORY_FAIL,
    data,
});

export const detailsSubcategory: ActionCreator<IdAction<Subcategory['id']>> = (id: Subcategory['id']) => ({
    type: reduxTypes.DETAILS_SUBCATEGORY,
    id,
});

export const detailsSubcategorySuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.DETAILS_SUBCATEGORY_SUCCESS,
    data,
});

export const detailsSubcategoryFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.DETAILS_SUBCATEGORY_FAIL,
    data,
});

export const createCategory: ActionCreator<DataAction> = (data: Category) => ({
    type: reduxTypes.CREATE_CATEGORY,
    data,
});

export const createCategorySuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.CREATE_CATEGORY_SUCCESS,
    data,
});

export const createCategoryFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.CREATE_CATEGORY_FAIL,
    data,
});

export const createSubcategory: ActionCreator<DataAction> = (data: Subcategory) => ({
    type: reduxTypes.CREATE_SUBCATEGORY,
    data,
});

export const createSubcategorySuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.CREATE_SUBCATEGORY_SUCCESS,
    data,
});

export const createSubcategoryFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.CREATE_SUBCATEGORY_FAIL,
    data,
});

export const updateCategory: ActionCreator<DataAction> = (id: Category['id'], data: Category) => ({
    type: reduxTypes.UPDATE_CATEGORY,
    id,
    data,
});

export const updateCategorySuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.UPDATE_CATEGORY_SUCCESS,
    data,
});

export const updateCategoryFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.UPDATE_CATEGORY_FAIL,
    data,
});

export const updateSubcategory: ActionCreator<DataAction> = (id: Subcategory['id'], data: Subcategory) => ({
    type: reduxTypes.UPDATE_SUBCATEGORY,
    id,
    data,
});

export const updateSubcategorySuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.UPDATE_SUBCATEGORY_SUCCESS,
    data,
});

export const updateSubcategoryFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.UPDATE_SUBCATEGORY_FAIL,
    data,
});
