export const LIST = 'orders/LIST';
export const LIST_SUCCESS = 'orders/LIST_SUCCESS';
export const LIST_FAIL = 'orders/LIST_FAIL';

export const LIST_BY_CLIENT = 'orders/LIST_BY_CLIENT';
export const LIST_BY_CLIENT_SUCCESS = 'orders/LIST_BY_CLIENT_SUCCESS';
export const LIST_BY_CLIENT_FAIL = 'orders/LIST_BY_CLIENT_FAIL';

export const DETAILS = 'orders/DETAILS';
export const DETAILS_SUCCESS = 'orders/DETAILS_SUCCESS';
export const DETAILS_FAIL = 'orders/DETAILS_FAIL';

export const UPDATE = 'orders/UPDATE';
export const UPDATE_SUCCESS = 'orders/UPDATE_SUCCESS';
export const UPDATE_FAIL = 'orders/UPDATE_FAIL';

export const INVOICE = 'orders/INVOICE';
export const INVOICE_SUCCESS = 'orders/INVOICE_SUCCESS';
export const INVOICE_FAIL = 'orders/INVOICE_FAIL';

export const INVOICE_BY_MAIL = 'orders/INVOICE_BY_MAIL';
export const INVOICE_BY_MAIL_SUCCESS = 'orders/INVOICE_BY_MAIL_SUCCESS';
export const INVOICE_BY_MAIL_FAIL = 'orders/INVOICE_BY_MAIL_FAIL';

export const WAIT_FOR_PAIEMENT = 'orders/WAIT_FOR_PAIEMENT';
export const WAIT_FOR_PAIEMENT_SUCCESS = 'orders/WAIT_FOR_PAIEMENT_SUCCESS';
export const WAIT_FOR_PAIEMENT_FAIL = 'orders/WAIT_FOR_PAIEMENT_FAIL';
export const START_POLLING = 'orders/START_POLLING';
export const STOP_POLLING = 'orders/STOP_POLLING';

export const CREATE_COUNTER_PAIEMENT = 'orders/CREATE_COUNTER_PAIEMENT';
export const CREATE_COUNTER_PAIEMENT_SUCCESS = 'orders/CREATE_COUNTER_PAIEMENT_SUCCESS';
export const CREATE_COUNTER_PAIEMENT_FAIL = 'orders/CREATE_COUNTER_PAIEMENT_FAIL';

export const LIST_COUNTER_PAIEMENT = 'orders/LIST_COUNTER_PAIEMENT';
export const LIST_COUNTER_PAIEMENT_SUCCESS = 'orders/LIST_COUNTER_PAIEMENT_SUCCESS';
export const LIST_COUNTER_PAIEMENT_FAIL = 'orders/LIST_COUNTER_PAIEMENT_FAIL';

export const UPDATE_COUNTER_PAIEMENT = 'orders/UPDATE_COUNTER_PAIEMENT';
export const UPDATE_COUNTER_PAIEMENT_SUCCESS = 'orders/UPDATE_COUNTER_PAIEMENT_SUCCESS';
export const UPDATE_COUNTER_PAIEMENT_FAIL = 'orders/UPDATE_COUNTER_PAIEMENT_FAIL';

export const DELETE_COUNTER_PAIEMENT = 'orders/DELETE_COUNTER_PAIEMENT';
export const DELETE_COUNTER_PAIEMENT_SUCCESS = 'orders/DELETE_COUNTER_PAIEMENT_SUCCESS';
export const DELETE_COUNTER_PAIEMENT_FAIL = 'orders/DELETE_COUNTER_PAIEMENT_FAIL';

export const CREATE_REFUND = 'orders/CREATE_REFUND';
export const CREATE_REFUND_SUCCESS = 'orders/CREATE_REFUND_SUCCESS';
export const CREATE_REFUND_FAIL = 'orders/CREATE_REFUND_FAIL';

export const LIST_REFUNDS = 'orders/LIST_REFUNDS';
export const LIST_REFUNDS_SUCCESS = 'orders/LIST_REFUNDS_SUCCESS';
export const LIST_REFUNDS_FAIL = 'orders/LIST_REFUNDS_FAIL';
