export const LIST = 'catalogProducts/LIST';
export const LIST_SUCCESS = 'catalogProducts/LIST_SUCCESS';
export const LIST_FAIL = 'catalogProducts/LIST_FAIL';

export const LIST_SELECT = 'catalogProducts/LIST_SELECT';
export const LIST_SELECT_SUCCESS = 'catalogProducts/LIST_SELECT_SUCCESS';
export const LIST_SELECT_FAIL = 'catalogProducts/LIST_SELECT_FAIL';

export const LIST_SUPP_INGREDIENTS = 'catalogProducts/LIST_SUPP_INGREDIENTS';
export const LIST_SUPP_INGREDIENTS_SUCCESS = 'catalogProducts/LIST_SUPP_INGREDIENTS_SUCCESS';
export const LIST_SUPP_INGREDIENTS_FAIL = 'catalogProducts/LIST_SUPP_INGREDIENTS_FAIL';

export const LIST_ALL_SUPP_INGREDIENTS = 'catalogProducts/LIST_ALL_SUPP_INGREDIENTS';
export const LIST_ALL_SUPP_INGREDIENTS_SUCCESS = 'catalogProducts/LIST_ALL_SUPP_INGREDIENTS_SUCCESS';
export const LIST_ALL_SUPP_INGREDIENTS_FAIL = 'catalogProducts/LIST_ALL_SUPP_INGREDIENTS_FAIL';

export const DETAILS = 'catalogProducts/DETAILS';
export const DETAILS_SUCCESS = 'catalogProducts/DETAILS_SUCCESS';
export const DETAILS_FAIL = 'catalogProducts/DETAILS_FAIL';

export const UPDATE = 'catalogProducts/UPDATE';
export const UPDATE_SUCCESS = 'catalogProducts/UPDATE_SUCCESS';
export const UPDATE_FAIL = 'catalogProducts/UPDATE_FAIL';

export const CREATE = 'catalogProducts/CREATE';
export const CREATE_SUCCESS = 'catalogProducts/CREATE_SUCCESS';
export const CREATE_FAIL = 'catalogProducts/CREATE_FAIL';

export const ASSIGN_SUPP_INGREDIENTS = 'catalogProducts/ASSIGN_SUPP_INGREDIENTS';
export const ASSIGN_SUPP_INGREDIENTS_SUCCESS = 'catalogProducts/ASSIGN_SUPP_INGREDIENTS_SUCCESS';
export const ASSIGN_SUPP_INGREDIENTS_FAIL = 'catalogProducts/ASSIGN_SUPP_INGREDIENTS_FAIL';

export const UNASSIGN_SUPP_INGREDIENTS = 'catalogProducts/UNASSIGN_SUPP_INGREDIENTS';
export const UNASSIGN_SUPP_INGREDIENTS_SUCCESS = 'catalogProducts/UNASSIGN_SUPP_INGREDIENTS_SUCCESS';
export const UNASSIGN_SUPP_INGREDIENTS_FAIL = 'catalogProducts/UNASSIGN_SUPP_INGREDIENTS_FAIL';

export const RESET_SUCCESS = 'catalogProducts/RESET_SUCCESS';
