import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Spin, Drawer, Form, Input, Button, Divider } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import Tabs, { TabsProps } from 'antd/lib/tabs';
import { DrawerProps } from 'antd/lib/drawer';
import { ButtonProps } from 'antd/lib/button';

import * as MenusActions from '../../store/actions/menus';
import { MenusState, getMenuDetailsState, getUpdateMenuState } from '../../store/reducers/menus';
import { RequestState, MainReducerState } from '../../store/reducers';
import { getLangsData } from '../../store/reducers/langs';
import { Lang, Menu, MenuItem } from '../../store/api/apiTypes';
import Constants from '../../utils/constants';

interface MenuEditProps extends FormComponentProps {
    getDetails: typeof MenusActions.details;
    id?: Menu['id'];
    menuItemId?: MenuItem['id'];
    isVisible: boolean;
    langsState: Lang[];
    onClose: () => void;
    onUpdateSuccess: () => void;
    details: MenusState['details'];
    resetSuccess: typeof MenusActions.resetUpdateSuccess;
    updateMenuItem: typeof MenusActions.update;
    updates: RequestState;
}

const MenuEdit: FC<MenuEditProps> = ({
    form, getDetails, id, isVisible, langsState, menuItemId, onClose, onUpdateSuccess, details,
    resetSuccess, updateMenuItem, updates,

}) => {
    const [ selectedLang, setSelectedLang ] = useState(Constants.DEFAULT_LANG);

    useEffect(() => {
        if (updates.success) {
            form.resetFields();
            resetSuccess();
            onUpdateSuccess();
            onClose();
        }
    }, [updates.success]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (id !== undefined && isVisible) {
            getDetails(id);
        }
    }, [isVisible]); // eslint-disable-line react-hooks/exhaustive-deps

    const menuItem = details.data ? details.data.find((item) => item.id === menuItemId) : null;

    const detailsDependency = menuItem ? menuItem.id : null;
    useEffect(() => {
        form.setFieldsValue({levelName: menuItem ? menuItem.levelName[selectedLang] : ''});
    }, [selectedLang, detailsDependency]); // eslint-disable-line react-hooks/exhaustive-deps

    const changeLang: TabsProps['onChange'] = (event) => {
        setSelectedLang(event);
    };

    const onDrawerClose: DrawerProps['onClose'] & ButtonProps['onClick'] = () => {
        onClose();
        form.resetFields();
    };

    const onSubmit = (e?: React.FormEvent) => {
        if (e) {
            e.preventDefault();
        }
        form.validateFieldsAndScroll(async (err, val) => {
            if (err) {
                return;
            }

            const payload = {
                ...val,
                languageCode: selectedLang,
            };

            updateMenuItem(menuItemId, payload);
        });
    };

    const { getFieldDecorator } = form;

    return (
        <>
            <Spin spinning={details.loading} tip="Chargement...">
                <Drawer
                    title={`Edition de l'élément de menu : ${
                        menuItem ?
                            menuItem.product.techLabel :
                            ''
                    }`}
                    width={580}
                    onClose={onDrawerClose}
                    visible={isVisible}
                >
                    <Tabs onChange={changeLang} type="card">
                        {langsState && langsState.map((e) => (
                            <Tabs.TabPane
                                tab={e.languageCode}
                                key={e.languageCode}
                            />
                        ))}
                    </Tabs>
                    <Spin spinning={details.loading}>
                        <Form onSubmit={onSubmit} layout="vertical">
                            <Form.Item label="Nom de section de menu (1 par langue)">
                                {getFieldDecorator('levelName', {
                                    rules: [{
                                        required: true,
                                        message: 'champ requis',
                                    }],
                                    initialValue: menuItem ?
                                        menuItem.levelName[selectedLang] : '',
                                })(
                                    <Input placeholder="Entrée, plât principal..." />,
                                )}
                            </Form.Item>
                            <div className="form-actions">
                                <Button htmlType="submit" type="primary" onClick={onSubmit} loading={updates.loading}>
                                    Mettre à jour
                                </Button>
                                <Divider type="vertical"/>
                                <Button onClick={onDrawerClose} type="ghost">
                                    Annuler
                                </Button>
                            </div>
                        </Form>
                    </Spin>
                </Drawer>
            </Spin>
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    langsState: getLangsData(state),
    details: getMenuDetailsState(state),
    updates: getUpdateMenuState(state),
});

const ProductFormDrawer = Form.create<MenuEditProps>()(MenuEdit);

export default connect(
    mapStateToProps,
    {
        getDetails: MenusActions.details,
        updateMenuItem: MenusActions.update,
        resetSuccess: MenusActions.resetUpdateSuccess,
    },
)(ProductFormDrawer);
