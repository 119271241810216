import { ActionCreator } from 'redux';
import { DataAction, QueryListPayload, QueryListAction } from '../actions';
import * as reduxTypes from '../types/terms';
import { CGU, CGV, FAQ } from '../api/apiTypes';

export const fetchCGU: ActionCreator<QueryListAction> = (payload: QueryListPayload) => ({
    type: reduxTypes.FETCH_CGU,
    ...payload,
});

export const fetchCGUSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.FETCH_CGU_SUCCESS,
    data,
});

export const fetchCGUFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.FETCH_CGU_FAIL,
    data,
});

export const fetchCGV: ActionCreator<QueryListAction> = (paylaod: QueryListPayload) => ({
    type: reduxTypes.FETCH_CGV,
    ...paylaod,
});

export const fetchCGVSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.FETCH_CGV_SUCCESS,
    data,
});

export const fetchCGVFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.FETCH_CGV_FAIL,
    data,
});

export const fetchFAQ: ActionCreator<QueryListAction> = (payload: QueryListPayload) => ({
    type: reduxTypes.FETCH_FAQ,
    ...payload,
});

export const fetchFAQSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.FETCH_FAQ_SUCCESS,
    data,
});

export const fetchFAQFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.FETCH_FAQ_FAIL,
    data,
});

export const updateCGU: ActionCreator<DataAction> = (data: CGU) => ({
    type: reduxTypes.UPDATE_CGU,
    data,
});

export const updateCGUSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.UPDATE_CGU_SUCCESS,
    data,
});

export const updateCGUFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.UPDATE_CGU_FAIL,
    data,
});

export const updateCGV: ActionCreator<DataAction> = (data: CGV) => ({
    type: reduxTypes.UPDATE_CGV,
    data,
});

export const updateCGVSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.UPDATE_CGV_SUCCESS,
    data,
});

export const updateCGVFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.UPDATE_CGV_FAIL,
    data,
});

export const updateFAQ: ActionCreator<DataAction> = (data: FAQ) => ({
    type: reduxTypes.UPDATE_FAQ,
    data,
});

export const updateFAQSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.UPDATE_FAQ_SUCCESS,
    data,
});

export const updateFAQFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.UPDATE_FAQ_FAIL,
    data,
});
