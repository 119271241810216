import { call, takeLatest, put } from 'redux-saga/effects';

import * as dashboardActions from '../actions/dashboard';
import * as dashboardTypes from '../types/dashboard';
import * as dashboardApi from '../api/dashboard';
import { DataAction, IdAction, QueryListAction } from '../actions';

export function* fetchSaga(action: QueryListAction) {
    try {
        const response = yield call(dashboardApi.fetch, action);
        return yield put(dashboardActions.fetchSuccess(response));
    } catch (error) {
        return yield put(dashboardActions.fetchFailed(error));
    }
}

export function* getCaSaga(action: QueryListAction) {
    try {
        const response = yield call(dashboardApi.getCa, action);
        return yield put(dashboardActions.getCaSuccess(response));
    } catch (error) {
        return yield put(dashboardActions.getCaFailed(error));
    }
}

export function* getDiscountsSaga(action: QueryListAction) {
    try {
        const response = yield call(dashboardApi.getDiscounts, action);
        return yield put(dashboardActions.getDiscountsSuccess(response));
    } catch (error) {
        return yield put(dashboardActions.getDiscountsFailed(error));
    }
}

export function* getDeliveredTurnoverSaga(action: QueryListAction) {
    try {
        const response = yield call(dashboardApi.getDeliveredTurnover, action);
        return yield put(dashboardActions.getDeliveredTurnoverSuccess(response));
    } catch (error) {
        return yield put(dashboardActions.getDeliveredTurnoverFailed(error));
    }
}

export function* getAverageCartSizeSaga(action: QueryListAction) {
    try {
        const response = yield call(dashboardApi.getAverageCartSize, action);
        return yield put(dashboardActions.getAverageCartSizeSuccess(response));
    } catch (error) {
        return yield put(dashboardActions.getAverageCartSizeFailed(error));
    }
}

export function* getTop10ProductsSaga(action: QueryListAction) {
    try {
        const response = yield call(dashboardApi.getTop10Products, action);
        return yield put(dashboardActions.getTop10ProductsSuccess(response));
    } catch (error) {
        return yield put(dashboardActions.getTop10ProductsFailed(error));
    }
}

export function* getNotCollectedSaga(action: QueryListAction) {
    try {
        const response = yield call(dashboardApi.getNotCollected, action);
        return yield put(dashboardActions.getNotCollectedSuccess(response));
    } catch (error) {
        return yield put(dashboardActions.getNotCollectedFailed(error));
    }
}

export function* getDeliveryTimeRankingSaga(action: QueryListAction) {
    try {
        const response = yield call(dashboardApi.getDeliveryTimeRanking, action);
        return yield put(dashboardActions.getDeliveryTimeRankingSuccess(response));
    } catch (error) {
        return yield put(dashboardActions.getDeliveryTimeRankingFailed(error));
    }
}

export function* getMonthlyExportSaga(action: QueryListAction) {
    try {
        const response = yield call(dashboardApi.getMonthlyExport, action);
        return yield put(dashboardActions.getMonthlyExportSuccess(response));
    } catch (error) {
        return yield put(dashboardActions.getMonthlyExportFailed(error));
    }
}

export function* getOrderReportSaga(action: IdAction<number> & DataAction) {
    try {
        const response = yield call(dashboardApi.getOrderReport, action.id, action.data);
        return yield put(dashboardActions.getOrderReportSuccess(response));
    } catch (error) {
        return yield put(dashboardActions.getOrderReportFailed(error));
    }
}

export function* getProdReportSaga(action: IdAction<number> & DataAction) {
    try {
        const response = yield call(dashboardApi.getProdReport, action.id, action.data);
        return yield put(dashboardActions.getProdReportSuccess(response));
    } catch (error) {
        return yield put(dashboardActions.getProdReportFailed(error));
    }
}

export default function* dashboardSaga() {
    yield takeLatest(dashboardTypes.FETCH, fetchSaga);
    yield takeLatest(dashboardTypes.GET_CA, getCaSaga);
    yield takeLatest(dashboardTypes.GET_DISCOUNTS, getDiscountsSaga);
    yield takeLatest(dashboardTypes.GET_DELIVERED_TURNOVER, getDeliveredTurnoverSaga);
    yield takeLatest(dashboardTypes.GET_AVERAGE_CART_SIZE, getAverageCartSizeSaga);
    yield takeLatest(dashboardTypes.GET_TOP10_PRODUCTS, getTop10ProductsSaga);
    yield takeLatest(dashboardTypes.GET_NOT_COLLECTED, getNotCollectedSaga);
    yield takeLatest(dashboardTypes.GET_DELIVERY_TIME_RANKING, getDeliveryTimeRankingSaga);
    yield takeLatest(dashboardTypes.GET_MONTHLY_EXPORT, getMonthlyExportSaga);
    yield takeLatest(dashboardTypes.GET_ORDER_REPORT, getOrderReportSaga);
    yield takeLatest(dashboardTypes.GET_PROD_REPORT, getProdReportSaga);
}
