import React, { FC, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { ColumnProps, TableProps } from 'antd/lib/table';
import { Button, Table, Tabs } from 'antd';
import { TabsProps } from 'antd/lib/tabs';
import moment from 'moment';

import { MainReducerState } from '../../store/reducers';
import { Category, Subcategory, Lang } from '../../store/api/apiTypes';
import * as CategoriesActions from '../../store/actions/categories';
import { CategoriesState , createCategoryState, getCategoriesState } from '../../store/reducers/categories';
import { getLangsData } from '../../store/reducers/langs';

import EmptyResult from '../../components/EmptyResult';
import CategoryEdit from './CategoryEdit';
import { constants } from '../../utils';

interface CategoriesProps {
    categoriesState: CategoriesState['listCategories'];
    createsCategory: CategoriesState['createCategory'];
    getCategories: typeof CategoriesActions.listCategories;
    langsState: Lang[];
}

const Categories: FC<CategoriesProps> = ({ categoriesState, getCategories, langsState }) => {

    const [ isFormVisible, setIsFormVisible ] = useState(false);
    const [ selectedId, setSelectedId ] = useState();
    const [ selectedLang, setSelectedLang ] = useState(constants.DEFAULT_LANG);

    useEffect(() => {
        getCategories();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const setEditMode = (id: number) => {
        setSelectedId(id);
        setIsFormVisible(true);
    };

    const onUpdateSuccess = () => {
        getCategories();
    };

    const onDrawerClose = () => {
        setIsFormVisible(false);
    };

    const onCreateCategory = () => {
        setSelectedId(undefined);
        setIsFormVisible(true);
    };

    const rowKey = (item: Category) => `${item.id}`;

    const columns: Array<ColumnProps<Category>> = [
        {
            dataIndex: 'id',
            title: 'Id',
        },
        {
            dataIndex: 'categoryLabel',
            title: 'Nom',
            render: (text, record) => (record.categoryLabel && record.categoryLabel[selectedLang] ?
                record.categoryLabel[selectedLang] : 'Pas de nom pour cette langue'),
        },
        {
            dataIndex: 'code',
            title: 'Code',
        },
        {
            dataIndex: 'creationDate',
            title: 'Date de création',
            render: (record) => moment(record).format('YYYY-MM-DD'),
        },
        {
            dataIndex: 'Actions',
            title: 'Actions',
            render: (text, record) => (
                <Button
                    icon="edit"
                    className="list-action-button-edit"
                    onClick={setEditMode.bind(null, record.id)}
                />
            ),
        },

    ];

    const subcategoriesTableColumns: Array<ColumnProps<Subcategory>> = [
        {
            dataIndex: 'id',
            title: 'ID',
        },
        {
            dataIndex: 'subcategoryLabel',
            title: 'Sous-catégories',
            render: (text, record) => (record.subcategoryLabel ?
                record.subcategoryLabel[selectedLang] : ''
            ),
        },
        {
            dataIndex: 'code',
            title: 'Code',
        },
        {
            dataIndex: 'subCategories.creationDate',
            title: 'Date création',
            render: (record) => moment(record).format('YYYY-MM-DD'),
        },
    ];

    const SubTable: TableProps<Category>['expandedRowRender'] = (category) => {
        const subRowKey = (item: Subcategory) => `${item.id}`;

        return (
            <Table<Subcategory>
                columns={subcategoriesTableColumns}
                dataSource={category.subCategories}
                locale={{
                    emptyText: <EmptyResult />,
                }}
                pagination={false}
                rowKey={subRowKey}
            />
        );
    };

    const changeLang: TabsProps['onChange'] = (event) => {
        setSelectedLang(event);
    };

    return (
        <>
            <Tabs onChange={changeLang} type="card">
                {langsState && langsState.map((e) => (
                    <Tabs.TabPane
                        tab={e.languageCode}
                        key={e.languageCode}
                    />
                ))}
            </Tabs>
            <Button
                type="primary"
                icon="plus-circle"
                onClick={onCreateCategory}
            >
                Ajouter une catégorie
            </Button>
            <Table<Category>
                bordered={false}
                rowKey={rowKey}
                columns={columns}
                dataSource={categoriesState.data}
                expandedRowRender={SubTable}
                loading={categoriesState.loading}
            />
            <CategoryEdit
                id={selectedId}
                onUpdateSuccess={onUpdateSuccess}
                isVisible={isFormVisible}
                onClose={onDrawerClose}
            />
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    categoriesState: getCategoriesState(state),
    createsCategory: createCategoryState(state),
    langsState: getLangsData(state),
});

export default connect(
    mapStateToProps,
    {
        getCategories: CategoriesActions.listCategories,
    },
)(Categories);
