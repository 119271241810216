import { combineReducers } from 'redux';

import * as reduxTypes from '../types/logsRegister';
import { LogRegister } from '../api/apiTypes';
import { MainReducerState, RequestState } from './';
import { requestReducer } from './_generics';

export interface LogsRegisterState {
    readonly list: RequestState<LogRegister[]>;
}

const initialState: LogRegister[] = [];

export default combineReducers<LogsRegisterState>({
    list: requestReducer({
        START: reduxTypes.LIST,
        SUCCESS: reduxTypes.LIST_SUCCESS,
        FAILED: reduxTypes.LIST_FAIL,
    }, initialState),
});

export const getLogsRegisterState = (state: MainReducerState) => state.logsRegister.list;
