import api from './_client';
import { Category, Subcategory } from './apiTypes';

export const listCategories = () => {
    return api.get(`/cms/products/categories`);
};

export const listSubcategories = () => {
    return api.get(`/cms/subcategories`);
};

export const detailsCategory = (id: Category['id']) => {
    return api.get<Category>(`/cms/category/${id}`);
};

export const detailsSubcategory = (id: Subcategory['id']) => {
    return api.get<Subcategory>(`/cms/subcategory/${id}`);
};

export const createCategory = (body: Category) => {
    return api.post<Category>(`/cms/category`, body);
};

export const createSubcategory = (body: Subcategory) => {
    return api.post<Subcategory>(`/cms/subcategory`, body);
};

export const updateCategory = (id: Category['id'], body: Category) => {
    return api.put<Category>(`/cms/category/${id}`, body);
};

export const updateSubcategory = (id: Subcategory['id'], body: Subcategory) => {
    return api.put<Subcategory>(`/cms/subcategory/${id}`, body);
};
