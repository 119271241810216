import React, { FC, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { ColumnProps } from 'antd/lib/table';
import { Button, Table, Popconfirm, Divider, Switch, Icon } from 'antd';
import moment from 'moment';

import { MainReducerState } from '../../store/reducers';
import { Discount } from '../../store/api/apiTypes';
import * as DiscountsActions from '../../store/actions/discounts';
import { DiscountsState , getDiscountsState, updateDiscountState, deleteDiscountState} from '../../store/reducers/discounts';
import { ParcsState, getSelectedParcState } from '../../store/reducers/parcs';

import DiscountEdit from './DiscountEdit';

interface DiscountsProps {
    deleteDiscount: typeof DiscountsActions.del;
    deletes: DiscountsState['delete'];
    discountsState: DiscountsState['list'];
    getDiscounts: typeof DiscountsActions.list;
    siteIdState: ParcsState['selected'];
    updateDiscount: typeof DiscountsActions.update;
    updates: DiscountsState['update'];
}

const Discounts: FC<DiscountsProps> = ({
    deleteDiscount, deletes, discountsState, getDiscounts, siteIdState, updateDiscount, updates,
}) => {

    const [ isFormVisible, setIsFormVisible ] = useState(false);
    const [ selectedId, setSelectedId ] = useState();

    useEffect(() => {
        getDiscounts({
            siteId: siteIdState,
        });
    }, [siteIdState]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (deletes.success) {
            getDiscounts({
                siteId: siteIdState,
            });
        }
    }, [deletes.success]); // eslint-disable-line react-hooks/exhaustive-deps

    const setEditMode = (id: number) => {
        setSelectedId(id);
        setIsFormVisible(true);
    };

    const onUpdateSuccess = () => {
        getDiscounts({
            siteId: siteIdState,
        });
    };

    const onDrawerClose = () => {
        setIsFormVisible(false);
    };

    const onCreateDiscount = () => {
        setSelectedId(undefined);
        setIsFormVisible(true);
    };

    const onSwitchChange = (elem: Discount) => {
        const payload = {
            ...elem,
            isEnabled : !elem.isEnabled,
            startDate: moment(elem.startDate).format('YYYY-MM-DD HH:mm:ss'),
            endDate: moment(elem.endDate).format('YYYY-MM-DD HH:mm:ss'),
        };
        updateDiscount(
            elem.id,
            {
                siteId: siteIdState,
            },
            payload,
        );
    };

    const onDeleteDiscount = (elem: Discount['id']) => {
        deleteDiscount(elem, {siteId: siteIdState});
    };

    const rowKey = (item: Discount) => `${item.id}`;

    const columns: Array<ColumnProps<Discount>> = [
        {
            dataIndex: 'id',
            title: 'Id',
        },
        {
            dataIndex: 'label',
            title: 'Nom',
        },
        {
            dataIndex: 'code',
            title: 'Code réduc',
        },
        {
            dataIndex: 'maxUseCount',
            title: 'Max',
        },
        {
            dataIndex: 'minOrderAmount',
            title: 'Montant min',
        },
        {
            dataIndex: 'amountValue',
            title: 'en €',
        },
        {
            dataIndex: 'percentValue',
            title: 'en %',
        },
        {
            dataIndex: 'startDate',
            title: 'Début',
            render: (record) => record.substring(0, 10),
        },
        {
            dataIndex: 'endDate',
            title: 'Fin',
            render: (record) => record.substring(0, 10),
        },
        {
            dataIndex: 'usedCount',
            title: 'Utilisé',
        },
        {
            dataIndex: 'isEnabled',
            title: 'Statut',
            render: (text, record) => (
                <Popconfirm
                    title="Voulez-vous vraiment changer le statut?"
                    onConfirm={onSwitchChange.bind(null, record)}
                    okText="Oui"
                    cancelText="Non"
                >
                    <Switch
                        checked={text}
                        checkedChildren={<Icon type="check" />}
                        unCheckedChildren={<Icon type="close" />}
                    />
                </Popconfirm>
            ),
        },
        {
            dataIndex: 'Actions',
            title: 'Actions',
            render: (text, record) => (
                <>
                {/* <ButtonLink
                    className="list-action-button-details"
                    to={getRoute(RoutePathName.discount, { id: record.id })}
                >
                    Détails
                </ButtonLink> */}
                <Button
                    icon="edit"
                    className="list-action-button-edit"
                    onClick={setEditMode.bind(null, record.id)}
                />
                <Divider type="vertical"/>
                <Popconfirm
                    title="Voulez-vous vraiment changer le statut?"
                    onConfirm={onDeleteDiscount.bind(null, record.id)}
                    okText="Oui"
                    cancelText="Non"
                >
                    <Button icon="delete" type="danger" />
                </Popconfirm>
                </>
            ),
        },

    ];

    return (
        <>
            <Button
                type="primary"
                icon="plus-circle"
                onClick={onCreateDiscount}
            >
                Ajouter code promo
            </Button>
            <Table<Discount>
                bordered={false}
                rowKey={rowKey}
                columns={columns}
                dataSource={discountsState.data}
                loading={discountsState.loading}
            />
            <DiscountEdit
                id={selectedId}
                siteId={siteIdState}
                onUpdateSuccess={onUpdateSuccess}
                isVisible={isFormVisible}
                onClose={onDrawerClose}
            />
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    discountsState: getDiscountsState(state),
    deletes: deleteDiscountState(state),
    siteIdState: getSelectedParcState(state),
    updates: updateDiscountState(state),
});

export default connect(
    mapStateToProps,
    {
        deleteDiscount: DiscountsActions.del,
        getDiscounts: DiscountsActions.list,
        updateDiscount: DiscountsActions.update,
    },
)(Discounts);
