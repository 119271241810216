import api, { urlWithQuery } from './_client';
import { Suggestion } from './apiTypes';
import { QueryListPayload } from '../actions';

export const list = (payload: QueryListPayload) => {
    return api.get(urlWithQuery('/cms/suggestions', payload));
};

export const create = (body: Partial<Suggestion>) => {
    return api.post(`/cms/suggestion`, body);
};

export const update = (id: Suggestion['id'], body: Partial<Suggestion>) => {
    return api.post(`/cms/suggestion/${id}`, body);
};

export const del = (id: Suggestion['id']) => {
    return api.delete(`/cms/suggestion/${id}`);
};

export const detail = (id: Suggestion['id']) => {
    return api.get(`/cms/suggestion/${id}`);
};
