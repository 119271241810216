import React, { FC } from 'react';
import { connect } from 'react-redux';
import { List } from 'antd';
import { t } from '../utils';

import { MainReducerState } from '../store/reducers/index';
import { getAllergensData } from '../store/reducers/allergens';
import { Allergen } from '../store/api/apiTypes';

interface AllergensProps {
    allergensState: Allergen[];
    list: string;
}

const AllergensTooltip: FC<AllergensProps> = ({ allergensState, list }) => {

    const filteredList = list.split('').reduce<Allergen[]>((tab, all) => {
        const match = allergensState.find((elem) => elem.allergenCode === all);
        if (match) {
            tab.push(match);
        }
        return tab;
    }, []);

    return (
        <>
            <List>
                {filteredList.map((elem: Allergen) => (
                    <List.Item key={elem.id}>
                        <span className="allergen-item-title">{elem.allergenCode}:&nbsp;</span>
                        <span className="allergen-item-text">{t(elem.label)}</span>
                    </List.Item>
                ))}
            </List>
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    allergensState: getAllergensData(state),
});

export default connect(
    mapStateToProps,
    {},
)(AllergensTooltip);
