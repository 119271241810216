import { ActionCreator } from 'redux';

import { DataAction, QueryListPayload, QueryListAction, IdAction } from './';
import * as reduxTypes from '../types/clients';
import { Client } from '../api/apiTypes';

export const list: ActionCreator<QueryListAction> = (payload: QueryListPayload) => ({
    type: reduxTypes.LIST,
    ...payload,
});

export const listSuccess: ActionCreator<DataAction> = (data: Client[]) => ({
    type: reduxTypes.LIST_SUCCESS,
    data,
});

export const listFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_FAIL,
    data,
});

export const details: ActionCreator<IdAction<Client['id']>> = (id: Client['id']) => ({
    type: reduxTypes.DETAILS,
    id,
});

export const detailsSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.DETAILS_SUCCESS,
    data,
});

export const detailsFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.DETAILS_FAIL,
    data,
});
