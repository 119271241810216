import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { Spin, Drawer, Form, Row, Col, Switch, Select, Icon, Divider, TimePicker, InputNumber } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { DrawerProps } from 'antd/lib/drawer';
import Button, { ButtonProps } from 'antd/lib/button';
import moment from 'moment';

import * as ConstraintActions from '../../store/actions/constraints';
import * as MealTypesActions from '../../store/actions/mealTypes';
import * as RestaurantsActions from '../../store/actions/restaurants';
import * as TimeRangesActions from '../../store/actions/timeRanges';
import { ConstraintsState, getConstraintDetailsState, createConstraintState,
    updateConstraintState } from '../../store/reducers/constraints';
import { MainReducerState } from '../../store/reducers';
import { DeliveryTimeRange, TimeRangesList, MealType, Restaurant } from '../../store/api/apiTypes';
import { getTimeRangesData } from '../../store/reducers/timeRanges';
import { getMealTypesData } from '../../store/reducers/mealTypes';
import { getRestaurantsState } from '../../store/reducers/restaurants';

interface ConstraintEditProps extends FormComponentProps {
    getDetails: typeof ConstraintActions.list;
    getRestaurants: typeof RestaurantsActions.list;
    getMealTypes: typeof MealTypesActions.list;
    getTimeRanges: typeof TimeRangesActions.list;
    id ?: number;
    siteId: number | null;
    isVisible: boolean;
    onClose: () => void;
    onUpdateSuccess: () => void;
    restaurantsListData: Restaurant[];
    mealTypesListData: MealType[];
    timeRangesListData: TimeRangesList[];
    constraintState: ConstraintsState['details'];
    createConstraint: typeof ConstraintActions.create;
    updateConstraint: typeof ConstraintActions.update;
    creates: ConstraintsState['create'];
    updates: ConstraintsState['update'];
}

const ConstraintEdit: FC<ConstraintEditProps> = ({
    id, siteId, getDetails, getTimeRanges, getMealTypes, getRestaurants, form, isVisible, onClose,
    onUpdateSuccess, constraintState, createConstraint, updateConstraint,
    creates, updates, mealTypesListData, restaurantsListData, timeRangesListData,
}) => {

    useEffect(() => {
        if (updates.success || creates.success) {
            form.resetFields();
            onUpdateSuccess();
            onClose();
        }
    }, [updates.success, creates.success]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (id === undefined) {
            getRestaurants({
                siteId,
            });
            getMealTypes();
            getTimeRanges();
        } else {
            getDetails(id);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (id !== undefined) {
            getTimeRanges();
            getDetails(id);
        } else {
            form.resetFields();
        }
    }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

    const onDrawerClose: DrawerProps['onClose'] & ButtonProps['onClick'] = () => {
        form.resetFields();
        onClose();
    };

    const onTimeRangeChange = (rangeId: DeliveryTimeRange['id']) => {
        form.setFieldsValue({timeRangeId: rangeId});
    };

    const onMealTypeChange = (mealId: MealType['id']) => {
        form.setFieldsValue({mealTypeId: mealId});
    };

    const onRestaurantChange = (rId: Restaurant['id']) => {
        form.setFieldsValue({restaurantId: rId});
    };

    const onSwitchChange = (val: boolean) => {
        form.setFieldsValue({isEnabled: val});
    };

    const onSubmit = (e?: React.FormEvent) => {
        if (e) {
            e.preventDefault();
        }
        form.validateFieldsAndScroll(async (err, val) => {
            if (err) {
                return;
            }
            if (id !== undefined) {
                const payload = {
                    ...val,
                    restaurantId: constraintState.data.restaurantId,
                    mealTypeId: constraintState.data.mealTypeId,
                    deliveryTimeRangeId: constraintState.data.deliveryTimeRangeId,
                };
                payload.deliveryMessage = ' ';
                payload.minTimeToOrder = moment(payload.minTimeToOrder)
                .set({year: 1970, month: 0, date: 1}).format('YYYY-MM-DD HH:mm:ss');
                payload.maxTimeToOrder = moment(payload.maxTimeToOrder)
                .set({year: 1970, month: 0, date: 1}).format('YYYY-MM-DD HH:mm:ss');
                updateConstraint(id, val);
            } else {
                const payload = {
                    ...val,
                };
                payload.deliveryMessage = ' ';
                payload.minTimeToOrder = moment(payload.minTimeToOrder)
                .set({year: 1970, month: 0, date: 1}).format('YYYY-MM-DD HH:mm:ss');
                payload.maxTimeToOrder = moment(payload.maxTimeToOrder)
                .set({year: 1970, month: 0, date: 1}).format('YYYY-MM-DD HH:mm:ss');
                createConstraint(payload);
            }
        });
    };

    const { getFieldDecorator } = form;
    const timeFormat = 'HH:mm';

    return (
        <>
            <Drawer
                title={id === undefined ? 'Creation de contrainte' : 'Modification de contrainte'}
                width={960}
                onClose={onDrawerClose}
                visible={isVisible}
                className="edit-product-drawer"
            >
                <Spin spinning={constraintState.loading}>
                    <Form>
                        {!id && (
                            <Row gutter={16}>
                                <Col span={8}>
                                    <Form.Item label="Restaurant">
                                        {getFieldDecorator('restaurantId', {
                                            rules: [{
                                                required: true,
                                                message: 'champ requis',
                                            }],
                                            initialValue: constraintState.data ?
                                                constraintState.data.restaurantId : '',
                                        })((
                                            <Select
                                                onChange={onRestaurantChange}
                                                placeholder="Choisissez le restaurant"
                                            >
                                                {restaurantsListData.map((restaurant: Restaurant) => (
                                                    <Select.Option
                                                        key={restaurant.id}
                                                        value={restaurant.id}
                                                    >
                                                        {restaurant.label}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        ))}
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label="Type de repas">
                                        {getFieldDecorator('mealTypeId', {
                                            rules: [{
                                                required: true,
                                                message: 'champ requis',
                                            }],
                                            initialValue: constraintState.data ?
                                                constraintState.data.mealTypeId : '',
                                        })((
                                            <Select
                                                onChange={onMealTypeChange}
                                                placeholder="Choisissez le type de repas"
                                            >
                                                {mealTypesListData.map((mealType: MealType) => (
                                                    <Select.Option
                                                        key={mealType.id}
                                                        value={mealType.id}
                                                    >
                                                        {mealType.label ? mealType.label.en : 'Pas de label pour cette langue'}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        ))}
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                <Form.Item label="Créneau">
                                        {getFieldDecorator('deliveryTimeRangeId', {
                                            rules: [{
                                                required: true,
                                                message: 'champ requis',
                                            }],
                                            initialValue: constraintState.data ?
                                                constraintState.data.deliveryTimeRangeId : '',
                                        })((
                                            <Select
                                                onChange={onTimeRangeChange}
                                                placeholder="Créneau de livraison"
                                            >
                                                {timeRangesListData.map((category: TimeRangesList) => (
                                                    <Select.OptGroup
                                                        label={category.label ? category.label.fr : ''}
                                                        key={category.id}
                                                    >
                                                        {category.deliveryTimeRanges.map(
                                                            (subcat: DeliveryTimeRange) => (
                                                            <Select.Option
                                                                key={subcat.id}
                                                                value={subcat.id}
                                                            >
                                                                {subcat.timeStart ?
                                                                    subcat.timeStart.substr(0, 5) + '-' +
                                                                    subcat.timeEnd.substr(0, 5) :
                                                                    'missing labels'}
                                                            </Select.Option>
                                                        ))}
                                                    </Select.OptGroup>
                                                ))}
                                            </Select>
                                        ))}
                                    </Form.Item>
                                </Col>
                            </Row>
                        )}
                        <Row gutter={16}>
                            {/* <Col span={12}>
                                <Form.Item label="Message de livraison">
                                    {getFieldDecorator('deliveryMessage', {
                                        rules: [{
                                            required: false,
                                            message: 'champ requis',
                                        }],
                                        initialValue: constraintState.data ?
                                            constraintState.data.deliveryMessage : ' ',
                                    })((
                                        <Input placeholder="Ecrivez un message"/>
                                    ))}
                                </Form.Item>
                            </Col> */}
                            <Col span={6}>
                                    <Form.Item label="Début des commandes">
                                        {getFieldDecorator('minTimeToOrder', {
                                            rules: [{
                                                required: true,
                                                message: 'champ requis',
                                            }],
                                            initialValue: constraintState.data ?
                                                moment(constraintState.data.minTimeToOrder, 'HH:mm:ss') : undefined,
                                        })((
                                            <TimePicker
                                                format={timeFormat}
                                                minuteStep={30}
                                            />
                                        ))}
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="Fin des commandes">
                                        {getFieldDecorator('maxTimeToOrder', {
                                            rules: [{
                                                required: true,
                                                message: 'champ requis',
                                            }],
                                            initialValue: constraintState.data ?
                                                moment(constraintState.data.maxTimeToOrder, 'HH:mm:ss') : undefined,
                                        })((
                                            <TimePicker
                                                format={timeFormat}
                                                minuteStep={30}
                                            />
                                        ))}
                                    </Form.Item>
                                </Col>
                            <Col span={6}>
                                <Form.Item label="Capacité de production">
                                    {getFieldDecorator('productionCapacity', {
                                        rules: [{
                                            required: true,
                                            type: 'number',
                                            message: 'champ requis',
                                        }],
                                        initialValue: constraintState.data ?
                                            constraintState.data.productionCapacity : '',
                                    })((
                                        <InputNumber placeholder="Entrez un nombre"/>
                                    ))}
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item label="Actif">
                                    {getFieldDecorator('isEnabled', {
                                        valuePropName: 'checked',
                                        initialValue: constraintState.data ? constraintState.data.isEnabled : true,
                                    })((
                                        <Switch
                                            checkedChildren={<Icon type="check" />}
                                            unCheckedChildren={<Icon type="close" />}
                                            onChange={onSwitchChange}
                                        />
                                    ))}
                                </Form.Item>
                            </Col>
                        </Row>
                        <hr/>
                            <div className="form-actions">
                                <Button htmlType="submit" type="primary" onClick={onSubmit} loading={updates.loading}>
                                    {id === undefined ? 'Ajouter' : 'Mettre à jour'}
                                </Button>
                                <Divider type="vertical"/>
                                <Button onClick={onDrawerClose} type="ghost">
                                    Annuler
                                </Button>
                        </div>
                    </Form>
                </Spin>
            </Drawer>
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    constraintState: getConstraintDetailsState(state),
    creates: createConstraintState(state),
    updates: updateConstraintState(state),
    restaurantsListData: getRestaurantsState(state).data,
    mealTypesListData: getMealTypesData(state),
    timeRangesListData: getTimeRangesData(state),
});

const ConstraintFormDrawer = Form.create<ConstraintEditProps>()(ConstraintEdit);

export default connect(
    mapStateToProps,
    {
        getDetails: ConstraintActions.details,
        getRestaurants: RestaurantsActions.list,
        getMealTypes: MealTypesActions.list,
        getTimeRanges: TimeRangesActions.list,
        createConstraint: ConstraintActions.create,
        updateConstraint: ConstraintActions.update,
    },
)(ConstraintFormDrawer);
