import api, { urlWithQuery } from './_client';
import { Discount } from './apiTypes';
import { QueryListPayload } from '../actions';

export const list = (payload: QueryListPayload) => {
    return api.get(urlWithQuery('/cms/discounts', payload));
};

export const details = (id: Discount['id'], payload: QueryListPayload) => {
    return api.get<Discount>(urlWithQuery(`/cms/discount/${id}`, payload));
};

export const create = (payload: QueryListPayload, body: Partial<Discount>) => {
    return api.post(urlWithQuery(`/cms/discount`, payload), body);
};

export const update = (id: Discount['id'], payload: QueryListPayload, body: Partial<Discount>) => {
    return api.put(urlWithQuery(`/cms/discount/${id}`, payload), body);
};

export const del = (id: Discount['id'], payload: QueryListPayload) => {
    return api.delete(urlWithQuery(`/cms/discount/${id}`, payload));
};
