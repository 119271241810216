import { Action, ActionCreator } from 'redux';
import { DataAction, IdAction, QueryListAction, QueryListPayload } from '../actions';
import * as reduxTypes from '../types/products';
import { Product, ProductVariant } from '../api/apiTypes';

export const list: ActionCreator<QueryListAction> = (payload: QueryListPayload) => ({
    type: reduxTypes.LIST,
    ...payload,
});

export const listSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_SUCCESS,
    data,
});

export const listFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_FAIL,
    data,
});

export const listSelect: ActionCreator<QueryListAction> = (payload: QueryListPayload) => ({
    type: reduxTypes.LIST_SELECT,
    ...payload,
});

export const listSelectSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_SELECT_SUCCESS,
    data,
});

export const listSelectFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_SELECT_FAIL,
    data,
});

export const details: ActionCreator<IdAction<Product['id']>> = (id: Product['id']) => ({
    type: reduxTypes.DETAILS,
    id,
});

export const detailsSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.DETAILS_SUCCESS,
    data,
});

export const detailsFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.DETAILS_FAIL,
    data,
});

export const create: ActionCreator<DataAction> = (data: Product) => ({
    type: reduxTypes.CREATE,
    data,
});

export const createSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.CREATE_SUCCESS,
    data,
});

export const createFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.CREATE_FAIL,
    data,
});

export const update: ActionCreator<DataAction> = (id: Product['id'], data: Product) => ({
    type: reduxTypes.UPDATE,
    id,
    data,
});

export const updateSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.UPDATE_SUCCESS,
    data,
});

export const updateFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.UPDATE_FAIL,
    data,
});

export const resetUpdateSuccess: ActionCreator<Action> = () => ({
    type: reduxTypes.RESET_SUCCESS,
});

export const listVariants: ActionCreator<IdAction<Product['id']>> = (id: Product['id']) => ({
    type: reduxTypes.LIST_VARIANTS,
    id,
});

export const listVariantsSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_VARIANTS_SUCCESS,
    data,
});

export const listVariantsFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_VARIANTS_FAIL,
    data,
});

export const addVariant: ActionCreator<DataAction> = (main: Product['id'], variant: Product['id']) => ({
    type: reduxTypes.ADD_VARIANT,
    data: {
        main,
        variant,
    },
});

export const addVariantSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.ADD_VARIANT_SUCCESS,
    data,
});

export const addVariantFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.ADD_VARIANT_FAIL,
    data,
});

export const removeVariant: ActionCreator<IdAction<ProductVariant['id']>> =
(id: ProductVariant['id']) => ({
    type: reduxTypes.REMOVE_VARIANT,
    id,
});

export const removeVariantSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.REMOVE_VARIANT_SUCCESS,
    data,
});

export const removeVariantFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.REMOVE_VARIANT_FAIL,
    data,
});
