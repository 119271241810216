import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { Layout, Button, Select } from 'antd';

import * as AuthActions from '../store/actions/auth';
import * as ParcsActions from '../store/actions/parcs';
import { MainReducerState } from '../store/reducers';
import { AuthState, getUser } from '../store/reducers/auth';
import { getParcsState, getSelectedParcState, ParcsState } from '../store/reducers/parcs';
import { Parc } from '../store/api/apiTypes';

import { isSuperAdmin } from '../utils';

interface HeaderProps {
    getSites: typeof ParcsActions.list;
    setSelectedSite: typeof ParcsActions.setSelectedSite;
    selectedSiteState: ParcsState['selected'];
    sitesState: ParcsState['list'];
    logout: typeof AuthActions.logout;
    user: AuthState['data'];
}
const Header: FC<HeaderProps> = ({
    getSites, setSelectedSite, selectedSiteState, sitesState, logout, user,
}) => {

    useEffect(() => {
        getSites();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onSiteChange = (id: Parc['id']) => {
        const value = id === -1 ? null : id;
        setSelectedSite(value);
    };

    return (
    <Layout.Header id="main-header">
        <div id="logo-nav-wrapper">
            <div className="logo-wrapper">
                <img id="logo" src={`${process.env.PUBLIC_URL}/images/logo_delivery_cms.png`} alt="logo" />
            </div>
        </div>
        <div className="header-inputs">
            {isSuperAdmin(user) && (
                <Select
                    onChange={onSiteChange}
                    defaultValue={selectedSiteState !== null ? selectedSiteState : -1}
                    className="header-select"
                    placeholder="Tous les sites"
                >
                    <Select.Option value={-1}>
                        Tous les sites
                    </Select.Option>
                    {sitesState.data.map((site) => (
                        <Select.Option
                            key={site.id}
                            value={site.id}
                        >
                        {site.label}
                        </Select.Option>
                    ))}
                </Select>
            )}
            <Button
                type="primary"
                onClick={logout}
                className="header-logout"
            >
                Logout
            </Button>
        </div>
    </Layout.Header>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    sitesState: getParcsState(state),
    selectedSiteState: getSelectedParcState(state),
    user: getUser(state),
});

export default connect(
    mapStateToProps,
    {
        getSites: ParcsActions.list,
        logout: AuthActions.logout,
        setSelectedSite: ParcsActions.setSelectedSite,
    },
)(Header);
