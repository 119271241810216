import { combineReducers } from 'redux';

import * as reduxTypes from '../types/langs';
import { Lang } from '../api/apiTypes';
import { MainReducerState, RequestState } from './';
import { requestReducer } from './_generics';

export interface LangsState {
    readonly list: RequestState<Lang[]>;
}

const initialState: Lang[] = [];

export default combineReducers<LangsState>({
    list: requestReducer({
        START: reduxTypes.LIST,
        SUCCESS: reduxTypes.LIST_SUCCESS,
        FAILED: reduxTypes.LIST_FAIL,
    }, initialState),
});

export const getLangsData = (state: MainReducerState) => state.langs.list.data;
