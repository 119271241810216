import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    Spin, Drawer, Form, Row, Col, Switch, Select, Button, Icon, Table, Popconfirm, Divider,
    // InputNumber,
    Alert,
} from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { DrawerProps } from 'antd/lib/drawer';
import { ButtonProps } from 'antd/lib/button';
import { ColumnProps } from 'antd/lib/table';
import { DateTime } from 'luxon';

import * as CatalogProductActions from '../../store/actions/catalogProducts';
import * as ProductsActions from '../../store/actions/products';
import {
    CatalogProductsState, getCatalogProductDetailsState, getUnAssignesSuppIngredientState,
    getAssignesSuppIngredientState, getSuppIngredientsListState, getAllSuppIngredientsListState,
    getCatalogProductUpdatesState,
} from '../../store/reducers/catalogProducts';
import { RequestState, MainReducerState } from '../../store/reducers';
import { SuppIngredient, SuppIngredientForCatalogProduct, Product, Restaurant, CatalogProduct } from '../../store/api/apiTypes';
import { getRestaurantsState, RestaurantsState } from '../../store/reducers/restaurants';
import { getSelectProducts, ProductsState } from '../../store/reducers/products';

import Constants from '../../utils/constants';

interface CatalogProductFormProps extends FormComponentProps {
    id?: number;
    isVisible: boolean;
    onClose: () => void;
    onUpdateSuccess: () => void;
    catalogProductState: CatalogProductsState['details'];
    productsSelectListState: ProductsState['listSelect'];
    assignes: RequestState;
    unAssignes: RequestState;
    updates: RequestState;
    suppIngredientsState: CatalogProductsState['listSuppIngredients'];
    allSuppIngredientsState: CatalogProductsState['listAllSuppIngredients'];
    getDetails: typeof CatalogProductActions.details;
    getSelectProductsList: typeof ProductsActions.listSelect;
    updateCatalogProduct: typeof CatalogProductActions.update;
    restaurantsState: RestaurantsState['list'];
    resetSuccess: typeof CatalogProductActions.resetUpdateSuccess;
    siteId: number | null;
    getAllSuppIngredients: typeof CatalogProductActions.listAllSuppIngredients;
    getCatalogProductSuppIngredients: typeof CatalogProductActions.listSuppIngredients;
    assignSuppIngredient: typeof CatalogProductActions.assignSuppIngredient;
    unAssignSuppIngredient: typeof CatalogProductActions.unAssignSuppIngredient;
}

const CatalogProductForm: FC<CatalogProductFormProps> = ({
    id, isVisible, onClose, onUpdateSuccess, catalogProductState, productsSelectListState,
    assignes, unAssignes, updates, suppIngredientsState,
    allSuppIngredientsState, getDetails, getSelectProductsList, restaurantsState,
    updateCatalogProduct, resetSuccess, getAllSuppIngredients, getCatalogProductSuppIngredients,
    assignSuppIngredient, unAssignSuppIngredient, form, siteId,
}) => {
    const [ selectedSuppIngredientCatalogProductId, setSelectedSuppIngredientCatalogProductId ] = useState();
    const [ canAddSuppIngredient, setCanAddSuppIngredient ] = useState(false);

    useEffect(() => {
        if (updates.success) {
            form.resetFields();
            resetSuccess();
            onUpdateSuccess();
            onClose();
        }
    }, [updates.success]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (suppIngredientsState.success) {
            getCatalogProductSuppIngredients(id, { siteId });
        }
    }, [assignes.success, unAssignes.success]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (id !== undefined && isVisible) {
            getDetails(id, { siteId });
            getCatalogProductSuppIngredients(id, { siteId });
            getAllSuppIngredients({ siteId });
        }
    }, [isVisible]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getSelectProductsList();
    }, [getSelectProductsList]); // eslint-disable-line react-hooks/exhaustive-deps

    const onDrawerClose: DrawerProps['onClose'] & ButtonProps['onClick'] = () => {
        onClose();
        form.resetFields();
    };

    const onProductSearch = (search: string) => {
        getSelectProductsList({
            searchString: search,
            limit: Constants.PAGE_SIZE,
            throttling: 300,
        });
    };

    // no search supported yet
    // const onRestaurantSearch = (search: string) => {
    //     getSelectRestaurantsList({
    //         searchString: search,
    //         limit: Constants.PAGE_SIZE,
    //         throttling: 300,
    //         siteId,
    //     });
    // };

    const onProductChange = (productId: Product['id']) => {
        form.setFieldsValue({ productId });
    };

    const onRestaurantChange = (restaurantId: Restaurant['id']) => {
        form.setFieldsValue({ restaurantId });
    };

    const onSwitchChange = (isEnabled: boolean) => {
        form.setFieldsValue({ isEnabled });
    };

    const onSubmit = (e?: React.FormEvent) => {
        if (e) {
            e.preventDefault();
        }
        form.validateFieldsAndScroll(async (err, val) => {
            if (err) {
                return;
            }

            if (id !== undefined) {
                const payload = {
                    enableDate: DateTime.fromISO(catalogProductState.data.enableDate).toFormat('yyyy-LL-dd HH:mm:ss'),
                    disableDate: DateTime.fromISO(catalogProductState.data.disableDate).toFormat('yyyy-LL-dd HH:mm:ss'),
                    vatRate: catalogProductState.data.vatRate,
                    price: catalogProductState.data.price,
                    ...val,
                };
                updateCatalogProduct(id, payload, { siteId });
            } else {
                // createCatalogProduct(val);
            }
        });
    };

    const { getFieldDecorator } = form;

    const onSelectSuppIngredientChange = (catalogProductId: CatalogProduct['id']) => {
        setSelectedSuppIngredientCatalogProductId(catalogProductId);
    };

    const onSelectSuppIngredientSearch = (str: Partial<Product['techLabel']>) => {
        if (str.length >= 3) {
            getAllSuppIngredients({
                searchString: str,
                limit: Constants.PAGE_SIZE,
                throttling: 300,
                siteId,
            });
        }
    };

    const onAddSuppIngredient = () => {
        if (selectedSuppIngredientCatalogProductId) {
            assignSuppIngredient({
                catalogProductId: catalogProductState.data.id,
                siteId,
                suppCatalogProductId: selectedSuppIngredientCatalogProductId,
            });
            setCanAddSuppIngredient(false);
            setSelectedSuppIngredientCatalogProductId(undefined);
        } else {
            alert('Choisissez un produit!');
        }
    };

    const cancelAddSuppIngredient = () => {
        setSelectedSuppIngredientCatalogProductId(undefined);
        setCanAddSuppIngredient(false);
    };

    const onRemoveSuppIngredient = (suppIngredientId: SuppIngredient['id']) => {
        unAssignSuppIngredient(suppIngredientId);
    };

    const rowKey = (item: SuppIngredientForCatalogProduct) => `${item.id}`;

    const columns: Array<ColumnProps<SuppIngredientForCatalogProduct>> = [
        {
            dataIndex: 'suppIngredient.product.techLabel',
            title: 'Produit catalogue',
        },
        {
            dataIndex: 'suppIngredient.price',
            title: 'Prix',
            render: (val) => new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(val),
        },
        {
            dataIndex: 'suppIngredient.vatRate',
            title: 'TVA',
            render: (val) => `${val}%`,
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (
                <>
                    {suppIngredientsState.data.length >= 1 ? (
                        <Popconfirm
                            title="Voulez-vous vraiment supprimer?"
                            className="list-action-button-delete"
                            onConfirm={onRemoveSuppIngredient.bind(null, record.id)}
                            okText="Oui"
                            cancelText="Non"
                        >
                           <Button icon="delete" type="danger"/>
                        </Popconfirm>
                    ) : null}
                </>
            ),
        },
    ];

    return (
        <>
            <Spin spinning={catalogProductState.loading} tip="Chargement...">
                <Drawer
                    title={'Edition du produit catalogue : ' + !catalogProductState.loading &&
                    catalogProductState.data ? catalogProductState.data.product.techLabel : ''}
                    width={960}
                    onClose={onDrawerClose}
                    visible={isVisible}
                >
                    <Spin spinning={catalogProductState.loading}>
                        <Form onSubmit={onSubmit} layout="vertical">
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item label="Produit">
                                        {getFieldDecorator('productId', {
                                            rules: [{
                                                required: true,
                                                message: 'choisissez un produit',
                                            }],
                                            initialValue: id !== undefined && catalogProductState.data ?
                                                catalogProductState.data.productId : null,
                                        })((
                                            <Select
                                                filterOption={false}
                                                loading={productsSelectListState.loading}
                                                onChange={onProductChange}
                                                onSearch={onProductSearch}
                                                placeholder="Choisissez un produit"
                                                showSearch
                                            >
                                                {productsSelectListState.data.map((product: Product) => (
                                                    <Select.Option
                                                        key={product.id}
                                                        value={product.id}
                                                    >
                                                        {product.techLabel}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        ))}
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Restaurant">
                                        {getFieldDecorator('restaurantId', {
                                            rules: [{
                                                required: true,
                                                message: 'choisissez un produit',
                                            }],
                                            initialValue: id !== undefined && catalogProductState.data ?
                                                catalogProductState.data.restaurantId : null,
                                        })((
                                            <Select
                                                filterOption={false}
                                                loading={restaurantsState.loading}
                                                onChange={onRestaurantChange}
                                                // onSearch={onRestaurantSearch}
                                                placeholder="Choisissez un restaurant"
                                            >
                                                {restaurantsState.data.map((restaurant: Restaurant) => (
                                                    <Select.Option
                                                        key={restaurant.id}
                                                        value={restaurant.id}
                                                    >
                                                        {restaurant.label ?
                                                            restaurant.label :
                                                            `Id: ${restaurant.id}, label manquant pour cette langue`}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        ))}
                                    </Form.Item>
                                </Col>
                            </Row>
                            {/* <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item label="Prix">
                                        {getFieldDecorator('price', {
                                            rules: [{
                                                required: true,
                                                message: 'champ requis',
                                                type: 'number',
                                            }],
                                            initialValue: id !== undefined && catalogProductState.data ?
                                                catalogProductState.data.price : '',
                                        })((
                                            <InputNumber
                                                min={0}
                                                step={0.05}
                                            />
                                        ))}
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="TVA">
                                        {getFieldDecorator('vatRate', {
                                            rules: [{
                                                required: true,
                                                message: 'champ requis',
                                                type: 'number',
                                            }],
                                            initialValue: id !== undefined && catalogProductState.data ?
                                                catalogProductState.data.vatRate : '',
                                        })((
                                            <InputNumber
                                                min={0}
                                                max={100}
                                                step={0.1}
                                            />
                                        ))}
                                    </Form.Item>
                                </Col>
                            </Row> */}
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item label="Actif">
                                        {getFieldDecorator('isEnabled', {
                                            valuePropName: 'checked',
                                            initialValue: id !== undefined && catalogProductState.data ?
                                                catalogProductState.data.isEnabled :
                                                true,
                                        })((
                                            <Switch
                                                checkedChildren={<Icon type="check" />}
                                                unCheckedChildren={<Icon type="close" />}
                                                onChange={onSwitchChange}
                                            />
                                        ))}
                                    </Form.Item>
                                </Col>
                            </Row>
                            {id !== undefined && (
                                <>
                                    <hr />
                                    <h3>Ingrédients supplémentaires</h3>
                                    <Table<SuppIngredientForCatalogProduct>
                                        bordered={false}
                                        rowKey={rowKey}
                                        columns={columns}
                                        dataSource={suppIngredientsState.data}
                                        loading={suppIngredientsState.loading}
                                        className="product-variants-table"
                                    />
                                    <hr />
                                    <Row gutter={16} className="list-header">
                                        <Col span={10}>
                                            <Button
                                                icon="plus-circle"
                                                type="primary"
                                                onClick={setCanAddSuppIngredient.bind(null, true)}
                                            >
                                                Ajouter un ingrédient supplémentaire
                                            </Button>
                                        </Col>
                                        <Col span={10}>
                                            {canAddSuppIngredient ? (
                                                <Select
                                                    placeholder="Choisissez un ingrédient supplémentaire"
                                                    showSearch
                                                    value={selectedSuppIngredientCatalogProductId}
                                                    onChange={onSelectSuppIngredientChange}
                                                    onSearch={onSelectSuppIngredientSearch}
                                                    filterOption={false}
                                                    className="variant-select"
                                                >
                                                    {allSuppIngredientsState.data.filter((suppIngredient) =>
                                                        !suppIngredientsState.data.find((ingr) =>
                                                            ingr.productId === suppIngredient.productId,
                                                        )).map((suppIngredient: SuppIngredientForCatalogProduct) => (
                                                            <Select.Option
                                                                key={suppIngredient.id}
                                                                value={suppIngredient.id}
                                                            >
                                                                {suppIngredient.product.techLabel}
                                                            </Select.Option>
                                                        ))}
                                                </Select>
                                            ) : null
                                            }
                                        </Col>
                                        <Col span={4}>
                                            {canAddSuppIngredient && selectedSuppIngredientCatalogProductId ? (
                                                <>
                                                    <Button
                                                        type="primary"
                                                        icon="save"
                                                        onClick={onAddSuppIngredient}
                                                        loading={assignes.loading}
                                                    />
                                                    <Divider type="vertical" />
                                                    <Button icon="rollback" onClick={cancelAddSuppIngredient} />
                                                </>
                                            ) : null
                                            }
                                        </Col>
                                    </Row>
                                    <hr />
                                </>
                            )}
                            {!updates.loading && updates.error && (
                                <Form.Item>
                                    <Alert
                                        message="Une erreur est survenue, veuillez réessayer plus tard ou contacter un administrateur"
                                        type="error"
                                    />
                                </Form.Item>
                            )}
                            <div className="form-actions">
                                <Button htmlType="submit" type="primary" onClick={onSubmit} loading={updates.loading}>
                                    {id !== undefined ? 'Mettre à jour' : 'Créer'}
                                </Button>
                                <Divider type="vertical"/>
                                <Button onClick={onDrawerClose} type="ghost">
                                    Annuler
                                </Button>
                            </div>
                        </Form>
                    </Spin>
                </Drawer>
            </Spin>
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    catalogProductState: getCatalogProductDetailsState(state),
    productsSelectListState: getSelectProducts(state),
    restaurantsState: getRestaurantsState(state),
    assignes: getAssignesSuppIngredientState(state),
    unAssignes: getUnAssignesSuppIngredientState(state),
    updates: getCatalogProductUpdatesState(state),
    suppIngredientsState: getSuppIngredientsListState(state),
    allSuppIngredientsState: getAllSuppIngredientsListState(state),
});

const CatalogProductFormDrawer = Form.create<CatalogProductFormProps>()(CatalogProductForm);

export default connect(
    mapStateToProps,
    {
        getDetails: CatalogProductActions.details,
        getSelectProductsList: ProductsActions.listSelect,
        updateCatalogProduct: CatalogProductActions.update,
        resetSuccess: CatalogProductActions.resetUpdateSuccess,
        getAllSuppIngredients: CatalogProductActions.listAllSuppIngredients,
        getCatalogProductSuppIngredients: CatalogProductActions.listSuppIngredients,
        assignSuppIngredient: CatalogProductActions.assignSuppIngredient,
        unAssignSuppIngredient: CatalogProductActions.unAssignSuppIngredient,
    },
)(CatalogProductFormDrawer);
