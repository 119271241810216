import { combineReducers } from 'redux';

import * as reduxTypes from '../types/presales';
import { PreSale } from '../api/apiTypes';
import { MainReducerState, RequestState } from './';
import { requestReducer } from './_generics';

export interface PreSalesState {
    readonly list: RequestState<PreSale[]>;
    readonly listSelect: RequestState<PreSale[]>;
    readonly create: RequestState<PreSale>;
    readonly details: RequestState<PreSale>;
    readonly update: RequestState<Partial<PreSale>>;
    readonly remove: RequestState<PreSale>;
}

const initialState: PreSale[] = [];

export default combineReducers<PreSalesState>({
    list: requestReducer({
        START: reduxTypes.LIST,
        SUCCESS: reduxTypes.LIST_SUCCESS,
        FAILED: reduxTypes.LIST_FAIL,
    }, initialState),
    listSelect: requestReducer({
        START: reduxTypes.LIST_SELECT,
        SUCCESS: reduxTypes.LIST_SELECT_SUCCESS,
        FAILED: reduxTypes.LIST_SELECT_FAIL,
    }, initialState),
    create: requestReducer({
        START: reduxTypes.CREATE,
        SUCCESS: reduxTypes.CREATE_SUCCESS,
        FAILED: reduxTypes.CREATE_FAIL,
    }),
    details: requestReducer({
        START: reduxTypes.DETAILS,
        SUCCESS: reduxTypes.DETAILS_SUCCESS,
        FAILED: reduxTypes.DETAILS_FAIL,
    }),
    update: requestReducer({
        START: reduxTypes.UPDATE,
        SUCCESS: reduxTypes.UPDATE_SUCCESS,
        FAILED: reduxTypes.UPDATE_FAIL,
    }),
    remove: requestReducer({
        START: reduxTypes.REMOVE,
        SUCCESS: reduxTypes.REMOVE_SUCCESS,
        FAILED: reduxTypes.REMOVE_FAIL,
    }),
});

export const getPresalesState = (state: MainReducerState) => state.presales.list;
export const getPresaleSelectListState = (state: MainReducerState) => state.presales.listSelect;
export const createPresaleState = (state: MainReducerState) => state.presales.create;
export const removePresaleState = (state: MainReducerState) => state.presales.remove;
export const getPresaleDetailsState = (state: MainReducerState) => state.presales.details;
export const updatePresaleState = (state: MainReducerState) => state.presales.update;
