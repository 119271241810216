import * as reduxTypes from '../types/dashboard';
import { DashboardTotals } from '../api/apiTypes';
import { MainReducerState, RequestState } from '.';
import { requestReducer } from './_generics';
import { combineReducers } from 'redux';
import { AxiosResponse } from 'axios';

export interface DashboardState {
    readonly fetch: RequestState<DashboardTotals>;
    readonly getCa: RequestState<AxiosResponse<string>>;
    readonly getDiscounts: RequestState<AxiosResponse<string>>;
    readonly getDeliveredTurnover: RequestState<AxiosResponse<string>>;
    readonly getAverageCartSize: RequestState<AxiosResponse<string>>;
    readonly getTop10Products: RequestState<AxiosResponse<string>>;
    readonly getNotCollected: RequestState<AxiosResponse<string>>;
    readonly getDeliveryTimeRanking: RequestState<AxiosResponse<string>>;
    readonly getMonthlyExport: RequestState<AxiosResponse<string>>;
    readonly getOrderReport: RequestState<AxiosResponse<string>>;
    readonly getProdReport: RequestState<AxiosResponse<string>>;
}

const initialState: DashboardTotals = {
    ca: '0',
    clientsCount: 0,
    ordersCount: 0,
    productsCount: 0,
    siteCount: 0,
};

export default combineReducers<DashboardState>({
    fetch: requestReducer({
        START: reduxTypes.FETCH,
        SUCCESS: reduxTypes.FETCH_SUCCESS,
        FAILED: reduxTypes.FETCH_FAIL,
    }, initialState),
    getCa: requestReducer({
        START: reduxTypes.GET_CA,
        SUCCESS: reduxTypes.GET_CA_SUCCESS,
        FAILED: reduxTypes.GET_CA_FAIL,
    }),
    getDiscounts: requestReducer({
        START: reduxTypes.GET_DISCOUNTS,
        SUCCESS: reduxTypes.GET_DISCOUNTS_SUCCESS,
        FAILED: reduxTypes.GET_DISCOUNTS_FAIL,
    }),
    getDeliveredTurnover: requestReducer({
        START: reduxTypes.GET_DELIVERED_TURNOVER,
        SUCCESS: reduxTypes.GET_DELIVERED_TURNOVER_SUCCESS,
        FAILED: reduxTypes.GET_DELIVERED_TURNOVER_FAIL,
    }),
    getAverageCartSize: requestReducer({
        START: reduxTypes.GET_AVERAGE_CART_SIZE,
        SUCCESS: reduxTypes.GET_AVERAGE_CART_SIZE_SUCCESS,
        FAILED: reduxTypes.GET_AVERAGE_CART_SIZE_FAIL,
    }),
    getTop10Products: requestReducer({
        START: reduxTypes.GET_TOP10_PRODUCTS,
        SUCCESS: reduxTypes.GET_TOP10_PRODUCTS_SUCCESS,
        FAILED: reduxTypes.GET_TOP10_PRODUCTS_FAIL,
    }),
    getNotCollected: requestReducer({
        START: reduxTypes.GET_NOT_COLLECTED,
        SUCCESS: reduxTypes.GET_NOT_COLLECTED_SUCCESS,
        FAILED: reduxTypes.GET_NOT_COLLECTED_FAIL,
    }),
    getDeliveryTimeRanking: requestReducer({
        START: reduxTypes.GET_DELIVERY_TIME_RANKING,
        SUCCESS: reduxTypes.GET_DELIVERY_TIME_RANKING_SUCCESS,
        FAILED: reduxTypes.GET_DELIVERY_TIME_RANKING_FAIL,
    }),
    getMonthlyExport: requestReducer({
        START: reduxTypes.GET_MONTHLY_EXPORT,
        SUCCESS: reduxTypes.GET_MONTHLY_EXPORT_SUCCESS,
        FAILED: reduxTypes.GET_MONTHLY_EXPORT_FAIL,
    }),
    getOrderReport: requestReducer({
        START: reduxTypes.GET_ORDER_REPORT,
        SUCCESS: reduxTypes.GET_ORDER_REPORT_SUCCESS,
        FAILED: reduxTypes.GET_ORDER_REPORT_FAIL,
    }),
    getProdReport: requestReducer({
        START: reduxTypes.GET_PROD_REPORT,
        SUCCESS: reduxTypes.GET_PROD_REPORT_SUCCESS,
        FAILED: reduxTypes.GET_PROD_REPORT_FAIL,
    }),
});

export const getDashboardFetchState = (state: MainReducerState) => state.dashboard.fetch;
export const getDashboardCaState = (state: MainReducerState) => state.dashboard.getCa;
export const getDashboardDiscountsState = (state: MainReducerState) => state.dashboard.getDiscounts;
export const getDeliveredTurnoverState = (state: MainReducerState) => state.dashboard.getDeliveredTurnover;
export const getAverageCartSizeState = (state: MainReducerState) => state.dashboard.getAverageCartSize;
export const getTop10ProductsState = (state: MainReducerState) => state.dashboard.getTop10Products;
export const getNotCollectedState = (state: MainReducerState) => state.dashboard.getNotCollected;
export const getDeliveryTimeRankingState = (state: MainReducerState) => state.dashboard.getDeliveryTimeRanking;
export const getMonthlyExportState = (state: MainReducerState) => state.dashboard.getMonthlyExport;
export const getOrderReportState = (state: MainReducerState) => state.dashboard.getOrderReport;
export const getProdReportState = (state: MainReducerState) => state.dashboard.getProdReport;
