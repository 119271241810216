import React, { FC } from 'react';
import { Icon } from 'antd';

import '../assets/styles/EmptyResult.less';

interface EmptyResultProps extends React.HTMLProps<JSX.Element> {
    text?: string | React.ReactNode;
    icon?: string;
}

const EmptyResult: FC<EmptyResultProps> = ({ text, icon }) => (
    <div className="empty-result">
        <Icon type={icon || 'frown'} />
        <p>{text || 'Aucuns résultats'}</p>
    </div>
);

export default EmptyResult;
