import api, { PaginatedListResponse, UpdateData, urlWithQuery } from './_client';
import { CatalogProduct, SuppIngredient } from './apiTypes';
import { QueryListPayload } from '../actions';
import { SiteIdQuery, SearchQuery } from '.';
import { AssignSuppIngredientAction } from '../actions/catalogProducts';

export interface CatalogProductListResponse extends PaginatedListResponse<CatalogProduct> {}
export type CatalogProductUpdateData = UpdateData<CatalogProduct>;

export const list = (queryParams: QueryListPayload & SearchQuery) => {
    return api.get<CatalogProductListResponse>(urlWithQuery('/cms/catalogProducts', queryParams));
};

export const details = (id: CatalogProduct['id'], queryParams: QueryListPayload & SiteIdQuery) => {
    return api.get<CatalogProduct>(urlWithQuery(`/cms/catalogProduct/${id}`, queryParams));
};

export const update = (
    id: CatalogProduct['id'],
    queryParams: QueryListPayload & SiteIdQuery,
    body: Partial<CatalogProduct>,
) => {
    return api.put<CatalogProduct>(urlWithQuery(`/cms/catalogProduct/${id}`, queryParams), body);
};

export const listAllSuppIngredients = (queryParams: QueryListPayload & SiteIdQuery) => {
    return api.get<CatalogProductListResponse>(urlWithQuery('/cms/suppIngredients', queryParams));
};

export const listSuppIngredients = (
    catalogProductId: CatalogProduct['id'],
    queryParams: QueryListPayload & SiteIdQuery,
) => {
    return api.get<CatalogProductListResponse>(
        urlWithQuery(`/cms/suppIngredients/catalogProduct/${catalogProductId}`, queryParams),
    );
};

export const assignSuppIngredient = (
    catalogProductId: AssignSuppIngredientAction['catalogProductId'],
    siteId: AssignSuppIngredientAction['siteId'],
    suppCatalogProductId: AssignSuppIngredientAction['suppCatalogProductId'],
) => {
    return api.post<CatalogProductListResponse>(
        urlWithQuery(`/cms/suppIngredient/catalogProduct/${catalogProductId}`, { siteId }),
        { suppCatalogProductId },
    );
};

export const unAssignSuppIngredient = (suppIngredientId: SuppIngredient['id']) =>
    api.delete<CatalogProductListResponse>(`/cms/suppIngredient/${suppIngredientId}`);
