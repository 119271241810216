import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Form, Drawer, Spin, Button, Divider, Input, Row, Col, Alert } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import Tabs, { TabsProps } from 'antd/lib/tabs';
import { DrawerProps } from 'antd/lib/drawer';
import { ButtonProps } from 'antd/lib/button';

import { MainReducerState } from '../../store/reducers';
import * as CategoriesActions from '../../store/actions/categories';
import { CategoriesState, getDetailsCategoryState, createCategoryState,
    createSubcategoryState, updateCategoryState, updateSubcategoryState } from '../../store/reducers/categories';
import { Category, Lang } from '../../store/api/apiTypes';

import Constants from '../../utils/constants';
import { getLangsData } from '../../store/reducers/langs';
import { InputProps } from 'antd/lib/input';

interface CategoryEditProps extends FormComponentProps {
    id?: Category['id'];
    createCategory: typeof CategoriesActions.createCategory;
    createSubcategory: typeof CategoriesActions.createSubcategory;
    createsCategory: CategoriesState['createCategory'];
    createsSubcategory: CategoriesState['createSubcategory'];
    detailsCategory: CategoriesState['detailsCategory'];
    getDetailsCategory: typeof CategoriesActions.detailsCategory;
    isVisible: boolean;
    langsState: Lang[];
    onUpdateSuccess: () => void;
    onClose: () => void;
    updateCategory: typeof CategoriesActions.updateCategory;
    updateSubcategory: typeof CategoriesActions.updateSubcategory;
    updatesCategory: CategoriesState['updateCategory'];
    updatesSubcategory: CategoriesState['updateSubcategory'];
}

const CategoryEdit: FC<CategoryEditProps> = ({
    id, createCategory, createSubcategory, createsCategory, createsSubcategory,
    isVisible, detailsCategory, form, getDetailsCategory, onUpdateSuccess, onClose,
    langsState, updateCategory, updateSubcategory, updatesCategory, updatesSubcategory,
}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [isTabSwitching, setIsTabSwitching] = useState(false);
    const [selectedLang, setSelectedLang] = useState(Constants.DEFAULT_LANG);
    const [canAddSubcategory, setCanAddSubcategory] = useState(false);
    const [newSubcatLabel, setNewSubcatLabel] = useState('');
    const [subcats, setSubcats] = useState<Category['subCategories']>([]);

    useEffect(() => {
        if (id) {
            getDetailsCategory(id);
        }
        setIsEditing(!!id);
    }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (detailsCategory.data && detailsCategory.data.subCategories) {
            setSubcats(detailsCategory.data.subCategories.slice());
        }
    }, [detailsCategory.data]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (updatesCategory.success && isTabSwitching) {
            setIsTabSwitching(false);
            form.resetFields();
            getDetailsCategory(id);
        } else if (createsCategory.success || updatesCategory.success) {
            form.resetFields();
            onUpdateSuccess();
            onClose();
            setIsEditing(false);
        }
        if (createsSubcategory.success || updatesSubcategory.success) {
            getDetailsCategory(id);
        }
    // tslint:disable-next-line
    }, [createsCategory.success, createsSubcategory.success, updatesCategory.success, updatesSubcategory.success]); // eslint-disable-line react-hooks/exhaustive-deps

    const { getFieldDecorator } = form;

    const onTabChange: TabsProps['onChange'] = (event) => {
        if (id !== undefined && form.getFieldValue('categoryLabel') !== undefined &&
            form.isFieldTouched('categoryLabel')
        ) {
            updateCategory(id, {
                categoryLabel: form.getFieldValue('categoryLabel'),
                languageCode: selectedLang,
            });
            setIsTabSwitching(true);
        }
        setSelectedLang(event);
    };

    const onDrawerClose: DrawerProps['onClose'] & ButtonProps['onClick'] = () => {
        form.resetFields();
        setIsEditing(false);
        onClose();
    };

    const onSubmit = (e?: React.FormEvent) => {
        if (e) {
            e.preventDefault();
        }
        form.validateFieldsAndScroll(async (err, val) => {
            if (err) {
                return;
            }
            const payload = {
                ...val,
                languageCode: selectedLang,
            };
            if (id !== undefined) {
                updateCategory(id, payload);
            } else {
                createCategory(payload);
            }
        });
    };

    const onAddSubcat = async () => {
        if (newSubcatLabel && newSubcatLabel !== '') {
            createSubcategory({
                mainCategoryId: detailsCategory.data.id,
                languageCode: selectedLang,
                subcategoryLabel: newSubcatLabel,
            });
            setCanAddSubcategory(false);
            setNewSubcatLabel('');
        } else {
            alert('La valeur ne peut pas être vide');
        }
    };

    const cancelAddSubcategory = () => {
        setCanAddSubcategory(false);
    };

    const onChangeNew: InputProps['onChange'] = (e) => {
        setNewSubcatLabel(e.target.value);
    };

    const onChangeEdit = (i: number, e: React.ChangeEvent<HTMLInputElement>) => {
        const temp = [...subcats];
        temp[i].subcategoryLabel[selectedLang] = e.target.value;
        setSubcats(temp);
    };

    const onBlurEdit = (i: number) => {
        if (subcats && subcats[i].subcategoryLabel[selectedLang] &&
            subcats[i].subcategoryLabel[selectedLang] !== '') {
            updateSubcategory(subcats[i].id, {
                subcategoryLabel: subcats[i].subcategoryLabel[selectedLang],
                mainCategoryId: subcats[i].mainCategoryId,
                languageCode: selectedLang,
            });
            setSubcats([]);
        }
    };

    return (
        <>
            <Drawer
                title={id !== undefined ? 'Modification de Catégorie' : 'Création de Catégorie'}
                width={960}
                onClose={onDrawerClose}
                visible={isVisible}
                className="edit-product-drawer"
            >
                <Tabs onChange={onTabChange} type="card">
                    {langsState && langsState.map((e) => (
                        <Tabs.TabPane
                            tab={e.languageCode}
                            key={e.languageCode}
                        />
                    ))}
                </Tabs>
                <Spin spinning={detailsCategory.loading}>
                    {(!isEditing || (isEditing && !detailsCategory.loading && detailsCategory.data)) && (
                        <Form>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item label="Nom">
                                        {getFieldDecorator('categoryLabel', {
                                            rules: [{
                                                required: true,
                                                message: 'champ requis',
                                            }],
                                            initialValue: isEditing &&
                                                detailsCategory.data && detailsCategory.data.categoryLabel &&
                                                    detailsCategory.data.categoryLabel[selectedLang] ?
                                                    detailsCategory.data.categoryLabel[selectedLang] : '',
                                        })((
                                            <Input placeholder="Entrez un nom"/>
                                        ))}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <hr/>
                            <h4>Sous-catégories:</h4>
                            {isEditing && detailsCategory.data && detailsCategory.data.subCategories && (
                                <Row gutter={16}>
                                    <Col span={24}>
                                        {subcats.map((subcat, index) => (
                                            <div
                                                key={subcat.id}
                                                className="list-inline"
                                            >
                                                <Input
                                                    style={{ width: '40%'}}
                                                    onChange={onChangeEdit.bind(null, index)}
                                                    onBlur={onBlurEdit.bind(null, index)}
                                                    value={subcat.subcategoryLabel &&
                                                        subcat.subcategoryLabel[selectedLang] ?
                                                        subcat.subcategoryLabel[selectedLang] :
                                                        'Pas de label pour la langue'
                                                    }
                                                />
                                            </div>
                                        ))}
                                    </Col>
                                </Row>
                            )}
                            <Row gutter={16} className="list-header">
                                <Col span={6}>
                                    <Button
                                        icon="plus-circle"
                                        type="primary"
                                        onClick={setCanAddSubcategory.bind(null, true)}
                                    >
                                        Ajouter Sous-catégorie
                                    </Button>
                                </Col>
                                {canAddSubcategory ? (
                                    <Col span={12}>
                                        <Input
                                            value={newSubcatLabel}
                                            onChange={onChangeNew}
                                            placeholder="Nom de la sous-catégorie"
                                        />
                                    </Col>
                                ) : null}
                                <Col span={4}>
                                    {canAddSubcategory && form.getFieldValue('subcategoryCode') !== '' &&
                                        form.getFieldValue('subcategoryLabel') !== '' ? (
                                        <>
                                            <Button type="primary" icon="save" onClick={onAddSubcat}/>
                                            <Divider type="vertical"/>
                                            <Button icon="rollback" onClick={cancelAddSubcategory} />
                                        </>
                                        ) : null
                                    }
                                </Col>
                            </Row>
                            <hr/>
                            <div className="form-actions">
                                <Button
                                    type="primary"
                                    onClick={onSubmit}
                                    loading={updatesCategory.loading}
                                >
                                    {id !== undefined ? 'Mettre à jour' : 'Ajouter'}
                                </Button>
                                <Divider type="vertical"/>
                                <Button onClick={onDrawerClose} type="ghost">
                                    Annuler
                                </Button>
                            </div>
                            <div className="modifier-button">
                                {!createsCategory.loading && createsCategory.error && (
                                    <Alert
                                        type="error"
                                        message="Une erreur s'est produite"
                                        showIcon
                                    />
                                )}
                            </div>
                            <div className="modifier-button">
                                {!updatesCategory.loading && updatesCategory.error && (
                                    <Alert
                                        type="error"
                                        message="Une erreur s'est produite"
                                        showIcon
                                    />
                                )}
                            </div>
                        </Form>
                    )}
                </Spin>
            </Drawer>
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    createsCategory: createCategoryState(state),
    createsSubcategory: createSubcategoryState(state),
    detailsCategory: getDetailsCategoryState(state),
    langsState: getLangsData(state),
    updatesCategory: updateCategoryState(state),
    updatesSubcategory: updateSubcategoryState(state),
});

const CategoryFormDrawer = Form.create<CategoryEditProps>()(CategoryEdit);

export default connect(
    mapStateToProps,
    {
        createCategory: CategoriesActions.createCategory,
        createSubcategory: CategoriesActions.createSubcategory,
        getDetailsCategory: CategoriesActions.detailsCategory,
        updateCategory: CategoriesActions.updateCategory,
        updateSubcategory: CategoriesActions.updateSubcategory,
    },
)(CategoryFormDrawer);
