import { combineReducers } from 'redux';

import * as reduxTypes from '../types/users';
import { User } from '../api/apiTypes';
import { MainReducerState, RequestState } from './';
import { requestReducer } from './_generics';

export interface UsersState {
    readonly list: RequestState<User[]>;
    readonly details: RequestState<User>;
    readonly create: RequestState<User>;
    readonly update: RequestState<Partial<User>>;
    // readonly delete: RequestState<User>;
}

const initialState: User[] = [];

export default combineReducers<UsersState>({
    list: requestReducer({
        START: reduxTypes.LIST,
        SUCCESS: reduxTypes.LIST_SUCCESS,
        FAILED: reduxTypes.LIST_FAIL,
    }, initialState),
    details: requestReducer({
        START: reduxTypes.DETAILS,
        SUCCESS: reduxTypes.DETAILS_SUCCESS,
        FAILED: reduxTypes.DETAILS_FAIL,
    }),
    create: requestReducer({
        START: reduxTypes.CREATE,
        SUCCESS: reduxTypes.CREATE_SUCCESS,
        FAILED: reduxTypes.CREATE_FAIL,
    }),
    update: requestReducer({
        START: reduxTypes.UPDATE,
        SUCCESS: reduxTypes.UPDATE_SUCCESS,
        FAILED: reduxTypes.UPDATE_FAIL,
    }),
    // delete: requestReducer({
    //     START: reduxTypes.DELETE,
    //     SUCCESS: reduxTypes.DELETE_SUCCESS,
    //     FAILED: reduxTypes.DELETE_FAIL,
    // }),
});

export const getUsersState = (state: MainReducerState) => state.users.list;
export const getUserDetailsState = (state: MainReducerState) => state.users.details;
export const createUserState = (state: MainReducerState) => state.users.create;
export const updateUserState = (state: MainReducerState) => state.users.update;
// export const deleteUserState = (state: MainReducerState) => state.users.delete;
