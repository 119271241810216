import React, { FC, useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { MainReducerState } from '../../store/reducers';
import * as ParcActions from '../../store/actions/parcs';
import { getParcDetailsState, ParcsState } from '../../store/reducers/parcs';
import { ParcEdit } from '.';
import { Descriptions, Button, Spin, Icon } from 'antd';
// tslint:disable-next-line: no-implicit-dependencies
import { RouteChildrenProps } from 'react-router';

interface MatchParams {
  id: string;
}

interface ParcProps extends RouteChildrenProps<MatchParams> {
    parcState: ParcsState['details'];
    getDetails: typeof ParcActions.details;
    updateParc: typeof ParcActions.update;
}

const Parc: FC<ParcProps> = ({ match, parcState, getDetails }) => {

    const [ isFormVisible, setIsFormVisible ] = useState(false);

    useEffect(() => {
        getDetails(match ? parseInt(match.params.id, 10) : null);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onDrawerClose = () => {
        setIsFormVisible(false);
    };

    const onUpdateSuccess = () => {
        getDetails(match ? parseInt(match.params.id, 10) : null);
    };

    const setEditMode = () => {
        setIsFormVisible(true);
    };

    return (
        <>
            <Spin spinning={parcState.loading} tip="Chargement...">
                <Descriptions title="Détails de parc" bordered>
                    <Descriptions.Item label="Référence">
                        {parcState.data ? parcState.data.siteReference : null}
                    </Descriptions.Item>
                    <Descriptions.Item label="Nom">
                        {parcState.data ? parcState.data.label : null}
                    </Descriptions.Item>
                    <Descriptions.Item label="Code postal">
                        {parcState.data ? parcState.data.zipCode : null}
                    </Descriptions.Item>
                    <Descriptions.Item label="Ville">
                        {parcState.data ? parcState.data.city : null}
                    </Descriptions.Item>
                    <Descriptions.Item label="Adresse">
                        {parcState.data ? parcState.data.address : null}
                    </Descriptions.Item>
                    <Descriptions.Item label="Livraisons">
                        {parcState.data && parcState.data.isDeliveryEnabled ?
                            <Icon type="check"/> :
                            <Icon type="close"/>
                        }
                    </Descriptions.Item>
                    <Descriptions.Item label="Frais de livraison">
                        {parcState.data ? parcState.data.deliveryFees : null}
                    </Descriptions.Item>
                    <Descriptions.Item label="Email">
                        {parcState.data ? parcState.data.email : null}
                    </Descriptions.Item>
                    <Descriptions.Item label="Téléphone">
                        {parcState.data ? parcState.data.phone : null}
                    </Descriptions.Item>
                    <Descriptions.Item label="Contact">
                        {parcState.data ? parcState.data.contact : null}
                    </Descriptions.Item>
                    <Descriptions.Item label="URL partielle">
                        {parcState.data ? parcState.data.slug : null}
                    </Descriptions.Item>
                </Descriptions>
            </Spin>
            <Button
                type="primary"
                shape="round"
                icon="edit"
                onClick={setEditMode}
                className="modifier-button"
            >
                Modifier
            </Button>
            <ParcEdit
                id={parcState.data ? parcState.data.id : undefined}
                isVisible={isFormVisible}
                onUpdateSuccess={onUpdateSuccess}
                onClose={onDrawerClose}
            />
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    parcState: getParcDetailsState(state),
});

export default connect(
    mapStateToProps,
    {
      getDetails: ParcActions.details,
    },
)(Parc);
