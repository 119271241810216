import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { Table, Tag, Tooltip } from 'antd';
import { ColumnProps, TableProps } from 'antd/lib/table';
import { DateTime } from 'luxon';

import { Order, getOrderStatusName, getOrderStatusColor, getDeliveryModeType, OrderStatus, getCartType } from '../../store/api/apiTypes';
import * as OrderActions from '../../store/actions/orders';
import { MainReducerState } from '../../store/reducers';
import { getOrdersListState, OrdersState } from '../../store/reducers/orders';

import Constants from '../../utils/constants';
import { getFullName, formatCurrency, getOrderPrices } from '../../utils';
import ButtonLink from '../../components/ButtonLink';
import SearchOrder from './SearchOrder';
import { getRoute, RoutePathName } from '../../routes';
import { InputProps } from 'antd/lib/input';
import { QueryListPayload } from '../../store/actions';
export interface AllOrdersTabProps {
    ordersListState: OrdersState['list'];
    getList: typeof OrderActions.list;
    siteId: number | null;
}

const AllOrdersTab: FC<AllOrdersTabProps> = ({ ordersListState, getList, siteId }) => {

    const fetchOrdersWithoutWFPStatus = (queryParams?: QueryListPayload) => {

        getList({
            limit: Constants.PAGE_SIZE,
            siteId,
            status: Object.values(OrderStatus).filter((status) => status !== OrderStatus.waitForPaiement).join(','),
            ...queryParams,
        });
    };

    useEffect(() => {
        fetchOrdersWithoutWFPStatus();

    }, [siteId]); // eslint-disable-line react-hooks/exhaustive-deps

    const onTableChange: TableProps<Order>['onChange'] = (pagination, filters) => {
        const filterParams = {
            ...(filters && filters.status ? {
                status: filters.status.join(','),
            } : {}),
            page: (pagination.current || 1) - 1,
        };
        fetchOrdersWithoutWFPStatus(filterParams);
    };
    const handlesearchChange: InputProps['onChange'] = (e) => {
        const searchValue = e.target.value;
        handleSearch(searchValue);
    };

    const handleSearch = (searchString: string) => {

        fetchOrdersWithoutWFPStatus({ throttling: 300, searchString });

    };
    const rowKey = (item: Order) => `${item.id}`;

    const columns: Array<ColumnProps<Order>> = [
        {
            dataIndex: 'reference',
            title: 'Référence',
        },
        {
            dataIndex: 'creationDate',
            title: 'Date de commande',
            render: (val) => val ? DateTime.fromISO(val).toLocaleString({day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit'}) : '',
        },
        {
            dataIndex: 'cart.deliveryMode',
            title: 'Type',
            render: (val) => val ? getDeliveryModeType(val) : null,
        },
        {
            dataIndex: 'cart.cartType',
            title: 'Canal',
            render: (val) => val ? getCartType(val) : null,
        },
        {
            dataIndex: 'bagsCount',
            title: 'Sac(s)',
        },
        {
            dataIndex: 'status',
            title: 'Statut',
            filters: [
                { text: getOrderStatusName(OrderStatus.waitForPrepare), value: OrderStatus.waitForPrepare },
                { text: getOrderStatusName(OrderStatus.preparing), value: OrderStatus.preparing },
                { text: getOrderStatusName(OrderStatus.collected), value: OrderStatus.collected },
                { text: getOrderStatusName(OrderStatus.prepared), value: OrderStatus.prepared },
                { text: getOrderStatusName(OrderStatus.delivered), value: OrderStatus.delivered },
                { text: getOrderStatusName(OrderStatus.cancelled), value: OrderStatus.cancelled },
                { text: getOrderStatusName(OrderStatus.refunded), value: OrderStatus.refunded },
            ],
            render: (val) => (
                <Tag color={getOrderStatusColor(val)}>
                    {getOrderStatusName(val)}
                </Tag>
            ),
            width: 200,
        },
        {
            title: 'Client',
            render: (order: Order) => {
                const { client, cart } = order;
                return client ? getFullName(client.firstName, client.lastName) : cart.clientEmail;
            },
        },
        {
            title: 'Cottage',
            render: (record: Order) => record.client ? record.client.vacation.cottageNumber : record.cart.cottageNumber,
        },
        {
            key: 'deliveryTimeRange',
            title: 'Créneau',
            render: (val, record) => `${
                new Intl.DateTimeFormat('fr-FR', { hour: 'numeric', minute: 'numeric' }).format(
                    new Date(`01/01/1970 ${record.deliveryTimeRange.timeStart}`),
                )
                } - ${
                new Intl.DateTimeFormat('fr-FR', { hour: 'numeric', minute: 'numeric' }).format(
                    new Date(`01/01/1970 ${record.deliveryTimeRange.timeEnd}`),
                )
                }`,
        },
        {
            key: 'totalWithDiscount',
            title: 'Total',
            render: (val, record) => {
                const prices = getOrderPrices(record);
                return (
                <Tooltip
                    title={(
                        <p>
                            Total sans taxes: {
                                formatCurrency(record.cart.totalWithoutTax)
                            }<br />
                            Total avec TVA sans réduc: {
                                formatCurrency(record.cart.totalWithTaxWithoutDiscount)
                            }<br />
                            Total avec TVA et réduc: {
                                formatCurrency(record.cart.totalWithTaxAndDiscount)
                            }<br />
                            Total livré sans taxes: {
                                formatCurrency(record.cart.deliveredProductsTotalWithoutTax)
                            }<br />
                            Total livré avec TVA sans réduc: {
                                formatCurrency(record.cart.deliveredProductsTotalWithTaxWithoutDiscount)
                            }<br />
                            Total livré avec TVA et réduc: {
                                formatCurrency(record.cart.deliveredProductsTotalWithTaxAndDiscount)
                            }<br />
                        </p>
                    )}
                >
                        {formatCurrency(prices.withTaxAndDiscount)}
                </Tooltip>
                );
            },
        },
        {
            dataIndex: 'cart.discount.code',
            title: 'Code réduc',
        },
        {
            dataIndex: 'cart.reference',
            title: 'Ref panier',
        },
        {
            dataIndex: 'deliveryDate',
            title: 'Date livraison',
            render: (val) => val ? DateTime.fromISO(val).toLocaleString() : '',
        },
        {
            title: 'Actions',
            key: 'actions',
            fixed: 'right',
            render: (record) => (
                <>
                    <ButtonLink
                        className="list-action-button-details"
                        to={getRoute(RoutePathName.order, { id: record.id })}
                    >
                        Détails
                    </ButtonLink>
                </>
            ),
            width: 100,
        },

    ];
    return (
        <>
            <SearchOrder
                onSearch={handleSearch}
                onChange={handlesearchChange}

            />
            <Table<Order>
                bordered={false}
                rowKey={rowKey}
                columns={columns}
                scroll={{ x: 1200 }}
                onChange={onTableChange}
                dataSource={ordersListState.data}
                loading={ordersListState.loading}
                pagination={{
                    current: ordersListState.page + 1,
                    pageSize: ordersListState.pageSize,
                    total: ordersListState.totalItems,
                }}
            />
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    ordersListState: getOrdersListState(state),

});

export default connect(
    mapStateToProps,
    {
        getList: OrderActions.list,
    },
)(AllOrdersTab);
