import { Action } from 'redux';
import { call, takeLatest, put, delay } from 'redux-saga/effects';

import * as reduxActions from '../actions/catalogProducts';
import * as reduxTypes from '../types/catalogProducts';
import * as api from '../api/catalogProducts';
import { SiteIdQuery, SearchQuery } from '../api';
import { IdAction, DataAction } from '../actions';
import { CatalogProduct, SuppIngredient } from '../api/apiTypes';

export function* listSaga(action: Action & SearchQuery) {
    try {
        yield delay(action.throttling || 0);
        const response = yield call(api.list, action);
        return yield put(reduxActions.listSuccess(response));
    } catch (error) {
        return yield put(reduxActions.listFailed(error));
    }
}

export function* detailsSaga(action: IdAction<CatalogProduct['id']> & SiteIdQuery) {
    try {
        const response = yield call(api.details, action.id, action);
        return yield put(reduxActions.detailsSuccess(response));
    } catch (error) {
        return yield put(reduxActions.detailsFailed(error));
    }
}

export function* updateSaga(
    action: IdAction<CatalogProduct['id']> & SiteIdQuery & DataAction<Partial<CatalogProduct>>,
) {
    try {
        const response = yield call(api.update, action.id, action, action.data);
        return yield put(reduxActions.updateSuccess(response));
    } catch (error) {
        return yield put(reduxActions.updateFailed(error));
    }
}

export function* listAllSuppIngredientsSaga(action: Action & SiteIdQuery) {
    try {
        const response = yield call(api.listAllSuppIngredients, action);
        return yield put(reduxActions.listAllSuppIngredientsSuccess(response));
    } catch (error) {
        return yield put(reduxActions.listAllSuppIngredientsFailed(error));
    }
}

export function* listSuppIngredientsSaga(action: IdAction<CatalogProduct['id']> & SiteIdQuery) {
    try {
        const response = yield call(api.listSuppIngredients, action.id, action);
        return yield put(reduxActions.listSuppIngredientsSuccess(response));
    } catch (error) {
        return yield put(reduxActions.listSuppIngredientsFailed(error));
    }
}

export function* assignSuppIngredientSaga(action: reduxActions.AssignSuppIngredientAction) {
    try {
        const response = yield call(
            api.assignSuppIngredient,
            action.catalogProductId,
            action.siteId,
            action.suppCatalogProductId,
        );
        return yield put(reduxActions.assignSuppIngredientSuccess(response));
    } catch (error) {
        return yield put(reduxActions.assignSuppIngredientFailed(error));
    }
}

export function* unAssignSuppIngredientSaga(action: IdAction<SuppIngredient['id']>) {
    try {
        const response = yield call(api.unAssignSuppIngredient, action.id);
        return yield put(reduxActions.unAssignSuppIngredientSuccess(response));
    } catch (error) {
        return yield put(reduxActions.unAssignSuppIngredientFailed(error));
    }
}

export default function* catalogProductsSaga() {
    yield takeLatest(reduxTypes.LIST, listSaga);
    yield takeLatest(reduxTypes.DETAILS, detailsSaga);
    yield takeLatest(reduxTypes.UPDATE, updateSaga);
    yield takeLatest(reduxTypes.LIST_ALL_SUPP_INGREDIENTS, listAllSuppIngredientsSaga);
    yield takeLatest(reduxTypes.LIST_SUPP_INGREDIENTS, listSuppIngredientsSaga);
    yield takeLatest(reduxTypes.ASSIGN_SUPP_INGREDIENTS, assignSuppIngredientSaga);
    yield takeLatest(reduxTypes.UNASSIGN_SUPP_INGREDIENTS, unAssignSuppIngredientSaga);
}
