import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Table, Divider, Icon, Tooltip, Input } from 'antd';
import { ColumnProps, TableProps } from 'antd/lib/table';

import ButtonLink from '../../components/ButtonLink';
import { Product as ProductType } from '../../store/api/apiTypes';
import * as ProductActions from '../../store/actions/products';
import { MainReducerState } from '../../store/reducers';
import ProductEdit from './ProductEdit';
import { getProducts, ProductsState } from '../../store/reducers/products';
import { getRoute, RoutePathName } from '../../routes';
import Constants from '../../utils/constants';
import AllergensTooltip from '../../components/AllergensTooltip';
import { t } from '../../utils';
import { InputProps, SearchProps } from 'antd/lib/input';

export interface ProductsProps {
    productsState: ProductsState['list'];
    getList: typeof ProductActions.list;
}

const Products: FC<ProductsProps> = ({ productsState, getList }) => {
    const [ isFormVisible, setIsFormVisible ] = useState(false);
    const [ selectedId, setSelectedId ] = useState();
    const [ search, setSearch ] = useState<string>();

    useEffect(() => {
        getList({
            searchString: search,
            limit: Constants.PAGE_SIZE,
            throttling: 300,
        });
    }, [search]); // eslint-disable-line react-hooks/exhaustive-deps

    const setEditMode = (id: number) => {
        setIsFormVisible(true);
        setSelectedId(id);
    };

    const onSearchChange: InputProps['onChange'] = (e) => {
        setSearch(e.target.value);
    };

    const onSearch: SearchProps['onSearch'] = (value) => {
        setSearch(value);
    };

    const onDrawerClose = () => {
        setIsFormVisible(false);
    };

    const onUpdateSuccess = () => {
        getList({
            limit: Constants.PAGE_SIZE,
        });
    };

    // const onAddProductClick = () => {
        //     setIsFormVisible(true);
        // };

    const onTableChange: TableProps<ProductType>['onChange'] = (pagination) => {
        getList({
            // search: this.state.search,
            page: (pagination.current || 1) - 1,
            limit: Constants.PAGE_SIZE,
        });
    };

    const rowKey =  (item: ProductType) => `${item.id}`;

    const columns: Array<ColumnProps<ProductType>> = [
        {
            dataIndex: 'internalRef',
            title: 'Référence',
        },
        {
            dataIndex: 'techLabel',
            title: 'Produit',
            onFilter: (value, record) => record.techLabel.indexOf(value) === 0,
        },
        {
            dataIndex: `description`,
            title: 'Description',
            render: t,
            ellipsis: true,
            width: 250,
        },
        {
            dataIndex: 'allergenCodes',
            title: 'Allergènes',
            render: (text) => (
                <Tooltip placement="right" title={<AllergensTooltip list={text}/>}>
                    {text}
                </Tooltip>
            ),
        },
        {
            dataIndex: 'isEnabled',
            title: 'Actif',
            render: (val) => val === true ? <Icon type="check"/> : '',
        },
        {
            dataIndex: 'isSupplementalIngredient',
            title: 'Supp.',
            // sorter: (x, y) => (x === y) ? 0 : x ? -1 : 1,
            render: (val) => val === true ? <Icon type="check"/> : '',
        },
        {
            dataIndex: 'isVariantProduct',
            title: 'Variante',
            render: (val) => val === true ? <Icon type="check"/> : '',
        },
        {
            dataIndex: 'isMenu',
            title: 'Menu',
            render: (val) => val === true ? <Icon type="check"/> : '',
        },
        {
            dataIndex: 'isMenuProduct',
            title: 'Menu produit',
            render: (val) => val === true ? <Icon type="check"/> : '',
        },
        {
            dataIndex: `category.mainCategory.categoryLabel`,
            title: 'Catégorie',
            render: t,
        },
        {
            dataIndex: `category.subcategoryLabel`,
            title: 'Sous-cat.',
            render: t,
        },
        {
            dataIndex: `mealType.label`,
            title: 'Type',
            render: t,
        },
        {
            title: 'Actions',
            key: 'actions',
            fixed: 'right',
            render: (text, record) => (
                <>
                    <ButtonLink
                        className="list-action-button-details"
                        to={getRoute(RoutePathName.product, { id: record.id })}
                    >
                        Détails
                    </ButtonLink>
                    <Divider type="vertical"/>
                    <Button
                        icon="edit"
                        className="list-action-button-edit"
                        onClick={setEditMode.bind(null, record.id)}
                    />
                </>
            ),
        },
    ];

    return (
        <>
            <div className="list-inline">
                <h1>Liste des produits</h1>
                <div className="search-input">
                    <Input.Search
                        className="table-search"
                        placeholder="Rechercher"
                        onChange={onSearchChange}
                        onSearch={onSearch}
                        size="small"
                    />
                </div>
            </div>
            <Table<ProductType>
                bordered={false}
                rowKey={rowKey}
                columns={columns}
                scroll={{x: 1600}}
                onChange={onTableChange}
                dataSource={productsState.data}
                loading={productsState.loading}
                pagination={{
                    current: productsState.page + 1,
                    pageSize: productsState.pageSize,
                    total: productsState.totalItems,
                }}
            />
            <ProductEdit
                id={selectedId}
                onUpdateSuccess={onUpdateSuccess}
                isVisible={isFormVisible}
                onClose={onDrawerClose}
            />
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    productsState: getProducts(state),
});

export default connect(
    mapStateToProps,
    {
        getList: ProductActions.list,
    },
)(Products);
