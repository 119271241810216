import React, { FC, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { ColumnProps } from 'antd/lib/table';
import { Button, Table, Popconfirm, Divider, Switch, Icon , message} from 'antd';

import { MainReducerState } from '../../store/reducers';
import { Restaurant } from '../../store/api/apiTypes';
import * as RestaurantsActions from '../../store/actions/restaurants';
import { RestaurantsState , getRestaurantsState, updateRestaurantState } from '../../store/reducers/restaurants';
import { ParcsState, getSelectedParcState } from '../../store/reducers/parcs';

import RestaurantEdit from './RestaurantEdit';
import ButtonLink from '../../components/ButtonLink';
import { getRoute, RoutePathName } from '../../routes';

interface RestaurantsProps {
    restaurantsState: RestaurantsState['list'];
    restaurantUpdateState: RestaurantsState['update'];
    getRestaurants: typeof RestaurantsActions.list;
    siteIdState: ParcsState['selected'];
    updateRestaurant: typeof RestaurantsActions.update;
}

const Restaurants: FC<RestaurantsProps> = ({
    restaurantsState, restaurantUpdateState, getRestaurants, siteIdState, updateRestaurant }) => {

    const [ isFormVisible, setIsFormVisible ] = useState(false);
    const [ selectedId, setSelectedId ] = useState();

    useEffect(() => {
        if (siteIdState !== null) {
            getRestaurants({
                siteId: siteIdState,
            });
        } else {
            getRestaurants({});
        }
    }, [siteIdState]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (restaurantUpdateState.success && siteIdState !== null) {
            getRestaurants({
                siteId: siteIdState,
            });
        }
    }, [restaurantUpdateState.success]); // eslint-disable-line react-hooks/exhaustive-deps

    const setEditMode = (id: number) => {
        setSelectedId(id);
        setIsFormVisible(true);
    };

    const onUpdateSuccess = () => {
        if (siteIdState !== null) {
            getRestaurants({
            siteId: siteIdState,
            });
        } else {
            message.error('Veuillez sélectionner un site.');
        }
    };

    const onDrawerClose = () => {
        setIsFormVisible(false);
    };

    const onCreateDiscount = () => {
        setSelectedId(undefined);
        setIsFormVisible(true);
    };

    const onSwitchChange = (elem: Restaurant) => {
        updateRestaurant(
            elem.id,
            {
                siteId: siteIdState,
            },
            {
                label: elem.label,
                isEnabled: !elem.isEnabled,
            },
        );
    };

    const rowKey = (item: Restaurant) => `${item.id}`;

    const columns: Array<ColumnProps<Restaurant>> = [
        {
            dataIndex: 'id',
            title: 'Id du restaurant',
        },
        {
            dataIndex: 'label',
            title: 'Nom du restaurant',
        },
        {
            dataIndex: 'isEnabled',
            title: 'Statut',
            render: (text, record) => (
                <Popconfirm
                    title="Voulez-vous vraiment changer le statut?"
                    onConfirm={onSwitchChange.bind(null, record)}
                    okText="Oui"
                    cancelText="Non"
                >
                    <Switch
                        checked={text}
                        checkedChildren={<Icon type="check" />}
                        unCheckedChildren={<Icon type="close" />}
                    />
                </Popconfirm>
            ),
        },
        {
            dataIndex: 'Actions',
            title: 'Actions',
            render: (text, record) => (
                <>
                <ButtonLink
                    className="list-action-button-details"
                    to={getRoute(RoutePathName.restaurant, { id: record.id })}
                >
                    Détails
                </ButtonLink>
                <Divider type="vertical"/>
                <Button
                    icon="edit"
                    className="list-action-button-edit"
                    onClick={setEditMode.bind(null, record.id)}
                />
                </>
            ),
        },

    ];

    return (
        <>
            {siteIdState !== null && (
                <Button
                    type="primary"
                    icon="plus-circle"
                    onClick={onCreateDiscount}
                >
                    Ajouter un restaurant
                </Button>
            )}
            <Table<Restaurant>
                bordered={false}
                rowKey={rowKey}
                columns={columns}
                dataSource={restaurantsState.data}
                loading={restaurantsState.loading}
            />
            <RestaurantEdit
                id={selectedId}
                siteId={siteIdState}
                onUpdateSuccess={onUpdateSuccess}
                isVisible={isFormVisible}
                onClose={onDrawerClose}
            />
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    restaurantsState: getRestaurantsState(state),
    restaurantUpdateState: updateRestaurantState(state),
    siteIdState: getSelectedParcState(state),
});

export default connect(
    mapStateToProps,
    {
        getRestaurants: RestaurantsActions.list,
        updateRestaurant: RestaurantsActions.update,
    },
)(Restaurants);
