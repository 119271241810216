import { call, takeLatest, put } from 'redux-saga/effects';
import * as authActions from '../actions/auth';
import * as authTypes from '../types/auth';
import * as authApi from '../api/auth';
import axios from '../api/_client';

export function* loginSaga(action: any) {
    try {
        const response = yield call(authApi.login, {
            login: action.data.email,
            password: action.data.password,
        });

        localStorage.setItem('userToken', response.authToken);
        axios.defaults.headers['x-centerparc-cms-auth-token'] = response.authToken;

        return yield put(authActions.loginSuccess(response));
    } catch (error) {
        return yield put(authActions.loginFailed(error));
    }
}

export function* logoutSaga(action: any) {
    try {
        const response = yield call(authApi.logout);
        localStorage.removeItem('userToken');
        return yield put(authActions.logoutSuccess(response));
    } catch (error) {
        return yield put(authActions.logoutFailed(error));
    }
}

export function* checkLoginStatusSaga(action: any) {
    try {
        const response = yield call(authApi.checkLoginStatus);
        return yield put(authActions.checkLoginStatusSuccess(response));
    } catch (error) {
        return yield put(authActions.checkLoginStatusFailed(error));
    }
}

export default function* authSaga() {
    yield takeLatest(authTypes.LOGIN, loginSaga);
    yield takeLatest(authTypes.LOGOUT, logoutSaga);
    yield takeLatest(authTypes.CHECK_LOGIN_STATUS, checkLoginStatusSaga);
}
