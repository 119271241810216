import api, { urlWithQuery } from './_client';
import { QueryListPayload } from '../actions';
import { Order, Client, CounterPaiement, Refund } from './apiTypes';

export const list = (payload: QueryListPayload) => {
    return api.get(urlWithQuery('/cms/orders', payload));
};

export const waitForPaiment = (payload: QueryListPayload) => {
    return api.get(urlWithQuery('/cms/orders/', payload));
};

export const listByClient = (id: Client['id']) => {
    return api.get(`/cms/orders/client/${id}`);
};

export const details = (id: Order['id'], payload: QueryListPayload) => {
    return api.get<Order>(urlWithQuery(`/cms/order/${id}`, payload));
};

export const update = (id: Order['id'], body: Partial<Order>) => {
    return api.put<Order>(`/cms/order/${id}`, body);
};

export const invoice = (id: Order['id'], payload: QueryListPayload) => {
    return api.get<Order>(urlWithQuery(`/cms/order/${id}/invoice`, payload), { responseType: 'blob' });
};
export const invoiceByMail = (id: Order['id']) => {
    return api.get<Order>(`/cms/order/${id}/sendInvoice`);
};

export const createCounterPaiement = (id: Order['id'], body: Partial<CounterPaiement>) => {
    return api.post<CounterPaiement[]>(`/cms/order/${id}/counterPayment`, body);
};

export const listCounterPaiement = (id: Order['id']) => {
    return api.get<CounterPaiement>(`/cms/order/${id}/counterPayment`);
};

export const updateCounterPaiement = (id: CounterPaiement['id'], body: Partial<CounterPaiement>) => {
    return api.put<CounterPaiement>(`/cms/counterPayment/${id}`, body);
};

export const deleteCounterPaiement = (id: CounterPaiement['id']) => {
    return api.delete<CounterPaiement>(`/cms/counterPayment/${id}`);
};

export const createRefund = (body: Refund) => {
    return api.post<Refund>(`/cms/order/${body.orderId}/refund`, body);
};

export const listRefunds = (id: Order['id']) => {
    return api.get(`/cms/order/${id}/refund`);
};
