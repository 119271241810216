import api from './_client';

export interface LoginPayload {
    login: string;
    password: string;
}

export const login = (payload: LoginPayload) => api.post('/cms/login', payload);

export const logout = () => api.post('/cms/logout');

export const checkLoginStatus = () => api.get('/cms/user/me');
