import { combineReducers } from 'redux';

import * as reduxTypes from '../types/allergens';
import { Allergen } from '../api/apiTypes';
import { MainReducerState, RequestState } from './';
import { requestReducer } from './_generics';

export interface AllergensState {
    readonly list: RequestState<Allergen[]>;
}

const initialState: Allergen[] = [];

export default combineReducers<AllergensState>({
    list: requestReducer({
        START: reduxTypes.LIST,
        SUCCESS: reduxTypes.LIST_SUCCESS,
        FAILED: reduxTypes.LIST_FAIL,
    }, initialState),
});

export const getAllergensData = (state: MainReducerState) => state.allergens.list.data;
