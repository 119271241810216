import { combineReducers } from 'redux';

import * as reduxTypes from '../types/categories';
import { Category, Subcategory } from '../api/apiTypes';
import { MainReducerState, RequestState } from './';
import { requestReducer } from './_generics';

export interface CategoriesState {
    readonly listCategories: RequestState<Category[]>;
    readonly listSubcategories: RequestState<Subcategory[]>;
    readonly detailsCategory: RequestState<Category>;
    readonly detailsSubcategory: RequestState<Subcategory>;
    readonly createCategory: RequestState<Category>;
    readonly createSubcategory: RequestState<Subcategory>;
    readonly updateCategory: RequestState<Category>;
    readonly updateSubcategory: RequestState<Subcategory>;
}

const initialStateCategory: Category[] = [];
const initialStateSubcategory: Subcategory[] = [];

export default combineReducers<CategoriesState>({
    listCategories: requestReducer({
        START: reduxTypes.LIST_CATEGORIES,
        SUCCESS: reduxTypes.LIST_CATEGORIES_SUCCESS,
        FAILED: reduxTypes.LIST_CATEGORIES_FAIL,
    }, initialStateCategory),
    listSubcategories: requestReducer({
        START: reduxTypes.LIST_SUBCATEGORIES,
        SUCCESS: reduxTypes.LIST_SUBCATEGORIES_SUCCESS,
        FAILED: reduxTypes.LIST_SUBCATEGORIES_FAIL,
    }, initialStateSubcategory),
    detailsCategory: requestReducer({
        START: reduxTypes.DETAILS_CATEGORY,
        SUCCESS: reduxTypes.DETAILS_CATEGORY_SUCCESS,
        FAILED: reduxTypes.DETAILS_CATEGORY_FAIL,
    }),
    detailsSubcategory: requestReducer({
        START: reduxTypes.DETAILS_SUBCATEGORY,
        SUCCESS: reduxTypes.DETAILS_SUBCATEGORY_SUCCESS,
        FAILED: reduxTypes.DETAILS_SUBCATEGORY_FAIL,
    }),
    createCategory: requestReducer({
        START: reduxTypes.CREATE_CATEGORY,
        SUCCESS: reduxTypes.CREATE_CATEGORY_SUCCESS,
        FAILED: reduxTypes.CREATE_CATEGORY_FAIL,
    }),
    createSubcategory: requestReducer({
        START: reduxTypes.CREATE_SUBCATEGORY,
        SUCCESS: reduxTypes.CREATE_SUBCATEGORY_SUCCESS,
        FAILED: reduxTypes.CREATE_SUBCATEGORY_FAIL,
    }),
    updateCategory: requestReducer({
        START: reduxTypes.UPDATE_CATEGORY,
        SUCCESS: reduxTypes.UPDATE_CATEGORY_SUCCESS,
        FAILED: reduxTypes.UPDATE_CATEGORY_FAIL,
    }),
    updateSubcategory: requestReducer({
        START: reduxTypes.UPDATE_SUBCATEGORY,
        SUCCESS: reduxTypes.UPDATE_SUBCATEGORY_SUCCESS,
        FAILED: reduxTypes.UPDATE_SUBCATEGORY_FAIL,
    }),
});

export const getCategoriesState = (state: MainReducerState) => state.categories.listCategories;
export const getSubcategoriesState = (state: MainReducerState) => state.categories.listSubcategories;
export const getDetailsCategoryState = (state: MainReducerState) => state.categories.detailsCategory;
export const getDetailsSubcategoryState = (state: MainReducerState) => state.categories.detailsSubcategory;
export const createCategoryState = (state: MainReducerState) => state.categories.createCategory;
export const createSubcategoryState = (state: MainReducerState) => state.categories.createSubcategory;
export const updateCategoryState = (state: MainReducerState) => state.categories.updateCategory;
export const updateSubcategoryState = (state: MainReducerState) => state.categories.updateSubcategory;
