import React, { FC, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { ColumnProps } from 'antd/lib/table';
import { Button, Table, Popconfirm, Row, Col, Divider, message } from 'antd';
import moment from 'moment';

import { MainReducerState } from '../../store/reducers';
import { Suggestion, CatalogProduct } from '../../store/api/apiTypes';
import * as SuggestionsActions from '../../store/actions/suggestions';
import * as CatalogProductActions from '../../store/actions/catalogProducts';
import { createSuggestionState, SuggestionsState , getSuggestionsState, deleteSuggestionState} from '../../store/reducers/suggestions';
import { CatalogProductsState , getCatalogProductsState} from '../../store/reducers/catalogProducts';
import { ParcsState, getSelectedParcState } from '../../store/reducers/parcs';
import { t } from '../../utils';
import SuggestionEdit from './suggestionsEdit';
interface SuggestionsProps {
    catalogProductsState: CatalogProductsState['list'];
    creates: SuggestionsState['create'];
    deleteSuggestion: typeof SuggestionsActions.del;
    deletes: SuggestionsState['delete'];
    suggestionsState: SuggestionsState['list'];
    getSuggestions: typeof SuggestionsActions.list;
    getCatalogProducts: typeof CatalogProductActions.list;
    siteIdState: ParcsState['selected'];
}

const Suggestions: FC<SuggestionsProps> = ({
    catalogProductsState, creates, deleteSuggestion, deletes,
    getCatalogProducts, getSuggestions, siteIdState, suggestionsState,
}) => {

    const [ canAddSuggestion, setCanAddSuggestion ] = useState(false);
    const [ selectedSuggestionId, setSelectedSuggestionId ] = useState();
    const [ CreateState, setCreateState ] = useState(false);
    const [ isFormVisible, setIsFormVisible ] = useState(false);

    useEffect(() => {
        if (siteIdState !== null) {
        getSuggestions({
            siteId: siteIdState,
        });
        getCatalogProducts({
            siteId: siteIdState,
        });
    } else {
        message.error('Veuillez sélectionner un site.');
    }
    }, [siteIdState]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (creates.success) {
            setCanAddSuggestion(false);
            getSuggestions({
                siteId: siteIdState,
            });
        }
    }, [creates.success]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (deletes.success) {
            setCanAddSuggestion(false);
            getSuggestions({
                siteId: siteIdState,
            });
        }
    }, [deletes.success]); // eslint-disable-line react-hooks/exhaustive-deps

    const onAddButton = () => {
        setCanAddSuggestion(true);
        setSelectedSuggestionId(undefined);
        setCreateState(true);
        setIsFormVisible(true);
    };

    const onDeleteSuggestion = (suggestionId: CatalogProduct['id']) => {
        removeSuggestion(suggestionId);
    };

    const removeSuggestion = (id: CatalogProduct['id']) => {
        deleteSuggestion(id);
    };
    const setEditMode = (id: number) => {
        setSelectedSuggestionId(id);
        setIsFormVisible(true);
        setCreateState(false);
    };

    const onUpdateSuccess = () => {
        getSuggestions({
            siteId: siteIdState,
        });
    };

    const onDrawerClose = () => {
        setSelectedSuggestionId(undefined);
        setIsFormVisible(false);
    };

    const onSelectProductChange = (suggestionId: CatalogProduct['id']) => {
        console.log(suggestionId);
        setSelectedSuggestionId(suggestionId);
    };

    const onSelectProductSearch = (str: string) => {
        if (str.length >= 3) {
            getCatalogProducts({
                siteId: siteIdState,
                searchString: str,
                throttling: 300,
            });
        }
    };
    const rowKey = (item: Suggestion) => `${item.id}`;

    const columns: Array<ColumnProps<Suggestion>> = [
        {
            dataIndex: 'id',
            title: 'Id',
        },
        {
            dataIndex: 'catalogProduct.product.techLabel',
            title: 'Produit catalogue',
            width: 300,
        },
        {
            dataIndex: 'catalogProduct.id',
            title: 'Id produit',
        },
        {
            dataIndex: 'mealType.label',
            title: 'Type repas',
            render: t,
        },
        {
            dataIndex: 'creationDate',
            title: 'Crée le',
            render: (record) => record.substring(0, 10),
        },
        {
            dataIndex: 'startDate',
            title: 'date de debut',
            render: (record) => record ? moment(record).format('YYYY-MM-DD') : undefined,
        },
        {
            dataIndex: 'endDate',
            title: 'date de fin',
            render: (record) => record ? moment(record).format('YYYY-MM-DD') : undefined,
        },
        {
            dataIndex: 'Actions',
            title: 'Actions',
            render: (text, record) => (
                <>
                <Button
                    icon="edit"
                    className="list-action-button-edit"
                    onClick={setEditMode.bind(null, record.id)}
                />
                <Divider type="vertical"/>
                <Popconfirm
                    title="Voulez-vous vraiment supprimer la suggestion?"
                    onConfirm={onDeleteSuggestion.bind(null, record.id)}
                    okText="Oui"
                    cancelText="Non"
                >
                    <Button icon="delete" type="danger" />
                </Popconfirm>
                </>
            ),
        },

    ];

    return (
        <>
            <Row gutter={16} className="list-header">
                <Col span={16}>
                    <Button
                        icon="plus-circle"
                        type="primary"
                        onClick={onAddButton}
                    >
                        Ajouter une suggestion
                    </Button>
                </Col>
            </Row>

            <Table<Suggestion>
                bordered={false}
                rowKey={rowKey}
                columns={columns}
                dataSource={suggestionsState.data}
                loading={suggestionsState.loading}
            />
                <SuggestionEdit
                 canAddSuggestion={canAddSuggestion}
                 id={selectedSuggestionId}
                 createState={CreateState}
                 onSelectProductChange={onSelectProductChange}
                 onSelectProductSearch={onSelectProductSearch}
                 catalogProductsState={catalogProductsState}
                 siteId={siteIdState}
                 onUpdateSuccess={onUpdateSuccess}
                 isVisible={isFormVisible}
                 onClose={onDrawerClose}
                />
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    catalogProductsState: getCatalogProductsState(state),
    creates: createSuggestionState(state),
    suggestionsState: getSuggestionsState(state),
    deletes: deleteSuggestionState(state),
    siteIdState: getSelectedParcState(state),
});

export default connect(
    mapStateToProps,
    {
        createSuggestion: SuggestionsActions.create,
        deleteSuggestion: SuggestionsActions.del,
        getSuggestions: SuggestionsActions.list,
        getCatalogProducts: CatalogProductActions.list,
    },
)(Suggestions);
