import { Action } from 'redux';
import { call, takeLatest, put, delay } from 'redux-saga/effects';

import * as reduxActions from '../actions/clients';
import * as reduxTypes from '../types/clients';
import * as api from '../api/clients';
import { SearchQuery } from '../api';
import { IdAction } from '../actions';
import { Client } from '../api/apiTypes';

export function* listSaga(action: Action & SearchQuery) {
    try {
        yield delay(action.throttling || 0);
        const response = yield call(api.list, action);
        return yield put(reduxActions.listSuccess(response));
    } catch (error) {
        return yield put(reduxActions.listFailed(error));
    }
}

export function* detailsSaga(action: IdAction<Client['id']>) {
    try {
        const response = yield call(api.details, action.id);
        return yield put(reduxActions.detailsSuccess(response));
    } catch (error) {
        return yield put(reduxActions.detailsFailed(error));
    }
}

export default function* clientsSaga() {
    yield takeLatest(reduxTypes.LIST, listSaga);
    yield takeLatest(reduxTypes.DETAILS, detailsSaga);
}
