import { Reducer } from 'redux';

import * as reduxTypes from '../types/auth';
import { User } from '../api/apiTypes';
import { MainReducerState } from './';

export interface AuthState {
    data?: User;
    error?: any;
    hasCheckedLoginStatus: boolean;
    isConnected: boolean;
    loading: boolean;
}

const authInitialState: AuthState = {
    hasCheckedLoginStatus: false,
    isConnected: false,
    loading: false,
};

const auth: Reducer<AuthState> = (state = authInitialState, action) => {
    switch (action.type) {
        case reduxTypes.LOGIN:
            return {
                ...state,
                loading: true,
            };

        case reduxTypes.LOGIN_SUCCESS:
            return {
                data: action.data,
                hasCheckedLoginStatus: true,
                isConnected: true,
                loading: false,
            };

        case reduxTypes.LOGIN_FAILED:
            return {
                error: action.data,
                hasCheckedLoginStatus: true,
                isConnected: false,
                loading: false,
            };

        case reduxTypes.CHECK_LOGIN_STATUS:
            return {
                ...state,
                hasCheckedLoginStatus: false,
                isConnected: false,
            };

        case reduxTypes.CHECK_LOGIN_STATUS_SUCCESS:
            return {
                ...state,
                data: action.data,
                hasCheckedLoginStatus: true,
                isConnected: true,
            };

        case reduxTypes.CHECK_LOGIN_STATUS_FAILED:
            return {
                ...state,
                hasCheckedLoginStatus: true,
                isConnected: false,
                error: undefined,
            };

        case reduxTypes.LOGOUT:
            return {
                ...state,
                loading: true,
            };

        case reduxTypes.LOGOUT_SUCCESS:
            return {
                hasCheckedLoginStatus: true,
                isConnected: false,
                loading: false,
            };

        case reduxTypes.LOGOUT_FAILED:
            return {
                ...state,
                isConnected: false,
                loading: false,
            };

        default:
            return state;
    }
};

export default auth;

export const getAuthState = (state: MainReducerState) => state.auth;

export const getUser = (state: MainReducerState) => state.auth.data;
