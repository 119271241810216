import { ActionCreator } from 'redux';
import { DataAction, QueryListAction, QueryListPayload, IdAction } from '../actions';
import * as reduxTypes from '../types/users';
import { User } from '../api/apiTypes';

export const list: ActionCreator<QueryListAction> = (payload: QueryListPayload) => ({
    type: reduxTypes.LIST,
    ...payload,
});

export const listSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_SUCCESS,
    data,
});

export const listFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_FAIL,
    data,
});

export const details: ActionCreator<QueryListAction> = (id: User['id']) => ({
    type: reduxTypes.DETAILS,
    id,
});

export const detailsSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.DETAILS_SUCCESS,
    data,
});

export const detailsFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.DETAILS_FAIL,
    data,
});

export const create: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.CREATE,
    data,
});

export const createSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.CREATE_SUCCESS,
    data,
});

export const createFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.CREATE_FAIL,
    data,
});

export const update: ActionCreator<IdAction<User['id']>> = (id: User['id'], data: any) => ({
    type: reduxTypes.UPDATE,
    id,
    data,
});

export const updateSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.UPDATE_SUCCESS,
    data,
});

export const updateFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.UPDATE_FAIL,
    data,
});

// export const del: ActionCreator<QueryListAction> = (id: User['id'], payload: QueryListPayload) => ({
//     type: reduxTypes.DELETE,
//     id,
//     ...payload,
// });

// export const deleteSuccess: ActionCreator<DataAction> = (data: any) => ({
//     type: reduxTypes.DELETE_SUCCESS,
//     data,
// });

// export const deleteFailed: ActionCreator<DataAction> = (data: any) => ({
//     type: reduxTypes.DELETE_FAIL,
//     data,
// });
