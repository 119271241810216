import { Layout } from 'antd';
import React, { FC } from 'react';

import '../assets/styles/App.less';

import Header from './Header';
import MainMenu from './MainMenu';

const MainLayout: FC = ({ children }) => (
    <Layout id="main-layout">
        <Header />
        <Layout>
            <MainMenu />
            <Layout>
                <Layout.Content id="main-content">
                    {children}
                </Layout.Content>
            </Layout>
        </Layout>
    </Layout>
);

export default MainLayout;
