import { Action } from 'redux';
import { call, takeLatest, put, delay } from 'redux-saga/effects';

import * as reduxActions from '../actions/products';
import * as reduxTypes from '../types/products';
import * as api from '../api/products';
import { SearchQuery } from '../api';
import { IdAction, DataAction } from '../actions';
import { Product, ProductVariant } from '../api/apiTypes';

export function* listSaga(action: Action & SearchQuery) {
    try {
        yield delay(action.throttling || 0);
        const response = yield call(api.list, action);
        return yield put(reduxActions.listSuccess(response));
    } catch (error) {
        return yield put(reduxActions.listFailed(error));
    }
}

export function* listSelectSaga(action: Action & SearchQuery) {
    try {
        yield delay(action.throttling || 0);
        const response = yield call(api.list, action);
        return yield put(reduxActions.listSelectSuccess(response));
    } catch (error) {
        return yield put(reduxActions.listSelectFailed(error));
    }
}

export function* detailsSaga(action: IdAction<Product['id']>) {
    try {
        const response = yield call(api.details, action.id);
        return yield put(reduxActions.detailsSuccess(response));
    } catch (error) {
        return yield put(reduxActions.detailsFailed(error));
    }
}

export function* updateSaga(action: IdAction<Product['id']> & DataAction<Partial<Product>>) {
    try {
        const response = yield call(api.update, action.id, action.data);
        return yield put(reduxActions.updateSuccess(response));
    } catch (error) {
        return yield put(reduxActions.updateFailed(error));
    }
}

export function* listVariantsSaga(action: IdAction<Product['id']>) {
    try {
        const response = yield call(api.listVariants, action.id);
        return yield put(reduxActions.listVariantsSuccess(response));
    } catch (error) {
        return yield put(reduxActions.listVariantsFailed(error));
    }
}

export function* addVariantSaga(action: DataAction) {
    try {
        const response = yield call(api.addVariant, action.data);
        return yield put(reduxActions.addVariantSuccess(response));
    } catch (error) {
        return yield put(reduxActions.addVariantFailed(error));
    }
}

export function* removeVariantSaga(action: IdAction<ProductVariant['id']>) {
    try {
        const response = yield call(api.removeVariant, action.id);
        return yield put(reduxActions.removeVariantSuccess(response));
    } catch (error) {
        return yield put(reduxActions.removeVariantFailed(error));
    }
}

export default function* productsSaga() {
    yield takeLatest(reduxTypes.LIST, listSaga);
    yield takeLatest(reduxTypes.LIST_SELECT, listSelectSaga);
    yield takeLatest(reduxTypes.DETAILS, detailsSaga);
    yield takeLatest(reduxTypes.LIST_VARIANTS, listVariantsSaga);
    yield takeLatest(reduxTypes.ADD_VARIANT, addVariantSaga);
    yield takeLatest(reduxTypes.REMOVE_VARIANT, removeVariantSaga);
    yield takeLatest(reduxTypes.UPDATE, updateSaga);
}
