import { Action, ActionCreator } from 'redux';

import * as reduxTypes from '../types/auth';
import { LoginPayload } from '../api/auth';
import { User } from '../api/apiTypes';
import { DataAction } from './';

export const login: ActionCreator<DataAction> = (data: LoginPayload) => ({
    type: reduxTypes.LOGIN,
    data,
});

export const loginSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LOGIN_SUCCESS,
    data,
});

export const loginFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LOGIN_FAILED,
    data,
});

export const checkLoginStatus: ActionCreator<{}> = () => ({
    type: reduxTypes.CHECK_LOGIN_STATUS,
});

export const checkLoginStatusSuccess: ActionCreator<DataAction> = (data: User) => ({
    type: reduxTypes.CHECK_LOGIN_STATUS_SUCCESS,
    data,
});

export const checkLoginStatusFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.CHECK_LOGIN_STATUS_FAILED,
    data,
});

export const logout: ActionCreator<Action> = () => ({
    type: reduxTypes.LOGOUT,
});

export const logoutSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LOGOUT_SUCCESS,
    data,
});

export const logoutFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LOGOUT_FAILED,
    data,
});
