
enum ImageSuffix {
    small = 'small.jpg',
    medium = 'medium.jpg',
    large = 'large.jpg',
}

function pointBySize(imageUrl: string): number {
    const splitedImgUrl = imageUrl.split('_');
    let point = 0;
    switch (splitedImgUrl[splitedImgUrl.length - 1]) {
        case ImageSuffix.small:
            point += 0;
            break;
        case ImageSuffix.medium:
            point += 1;
            break;
        default:
            point += 2;
            break;
    }
    return point;
}
export default function imageSelector(imageUrls: string[]): string {
    const sizeOrderedImages = imageUrls.sort((image1, image2) => {
        return pointBySize(image1) - pointBySize(image2);
    });
    return sizeOrderedImages[sizeOrderedImages.length - 1];
}
