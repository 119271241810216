import { ActionCreator, Action } from 'redux';
import { DataAction, IdAction, QueryListPayload, QueryListAction  } from '../actions';
import * as reduxTypes from '../types/catalogProducts';
import { CatalogProduct, SuppIngredient, Parc } from '../api/apiTypes';
import { CatalogProductListResponse } from '../api/catalogProducts';
import { SiteIdQuery } from '../api';

export const list: ActionCreator<QueryListAction> = (payload: QueryListPayload) => ({
    type: reduxTypes.LIST,
    ...payload,
});

export const listSuccess: ActionCreator<DataAction> = (data: CatalogProductListResponse) => ({
    type: reduxTypes.LIST_SUCCESS,
    data,
});

export const listFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_FAIL,
    data,
});

export const details: ActionCreator<IdAction<CatalogProduct['id']>> = (
    id: CatalogProduct['id'],
    payload: QueryListPayload,
) => ({
    type: reduxTypes.DETAILS,
    ...payload,
    id,
});

export const detailsSuccess: ActionCreator<DataAction> = (data: CatalogProduct) => ({
    type: reduxTypes.DETAILS_SUCCESS,
    data,
});

export const detailsFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.DETAILS_FAIL,
    data,
});

export const update: ActionCreator<DataAction> = (
    id: CatalogProduct['id'],
    data: Partial<CatalogProduct>,
    queryParams: QueryListAction,
) => ({
    type: reduxTypes.UPDATE,
    id,
    data,
    ...queryParams,
});

export const updateSuccess: ActionCreator<DataAction> = (data: CatalogProduct) => ({
    type: reduxTypes.UPDATE_SUCCESS,
    data,
});

export const updateFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.UPDATE_FAIL,
    data,
});

export const resetUpdateSuccess: ActionCreator<Action> = () => ({
    type: reduxTypes.RESET_SUCCESS,
});

// export const create: ActionCreator<DataAction> = (data: Product) => ({
//     type: reduxTypes.CREATE,
//     data,
// });

// export const createSuccess: ActionCreator<DataAction> = (data: any) => ({
//     type: reduxTypes.CREATE_SUCCESS,
//     data,
// });

// export const createFailed: ActionCreator<DataAction> = (data: any) => ({
//     type: reduxTypes.CREATE_FAIL,
//     data,
// });

export const listSuppIngredients: ActionCreator<IdAction<CatalogProduct['id']> & SiteIdQuery> =
    (id, payload) => ({
        type: reduxTypes.LIST_SUPP_INGREDIENTS,
        ...payload,
        id,
    });

export const listSuppIngredientsSuccess: ActionCreator<DataAction> = (data) => ({
    type: reduxTypes.LIST_SUPP_INGREDIENTS_SUCCESS,
    data,
});

export const listSuppIngredientsFailed: ActionCreator<DataAction> = (data) => ({
    type: reduxTypes.LIST_SUPP_INGREDIENTS_FAIL,
    data,
});

export const listAllSuppIngredients: ActionCreator<QueryListAction> = (payload) => ({
        type: reduxTypes.LIST_ALL_SUPP_INGREDIENTS,
        ...payload,
    });

export const listAllSuppIngredientsSuccess: ActionCreator<DataAction> = (data) => ({
    type: reduxTypes.LIST_ALL_SUPP_INGREDIENTS_SUCCESS,
    data,
});

export const listAllSuppIngredientsFailed: ActionCreator<DataAction> = (data) => ({
    type: reduxTypes.LIST_ALL_SUPP_INGREDIENTS_FAIL,
    data,
});

export interface AssignSuppIngredientAction extends Action {
    catalogProductId: CatalogProduct['id'];
    siteId: Parc['id'];
    suppCatalogProductId: SuppIngredient['id'];
}
export const assignSuppIngredient: ActionCreator<AssignSuppIngredientAction> = (payload) => ({
        type: reduxTypes.ASSIGN_SUPP_INGREDIENTS,
        ...payload,
    });

export const assignSuppIngredientSuccess: ActionCreator<DataAction> = (data) => ({
    type: reduxTypes.ASSIGN_SUPP_INGREDIENTS_SUCCESS,
    data,
});

export const assignSuppIngredientFailed: ActionCreator<DataAction> = (data) => ({
    type: reduxTypes.ASSIGN_SUPP_INGREDIENTS_FAIL,
    data,
});

export const unAssignSuppIngredient: ActionCreator<IdAction<SuppIngredient['id']>> = (id) => ({
        type: reduxTypes.UNASSIGN_SUPP_INGREDIENTS,
        id,
    });

export const unAssignSuppIngredientSuccess: ActionCreator<DataAction> = (data) => ({
    type: reduxTypes.UNASSIGN_SUPP_INGREDIENTS_SUCCESS,
    data,
});

export const unAssignSuppIngredientFailed: ActionCreator<DataAction> = (data) => ({
    type: reduxTypes.UNASSIGN_SUPP_INGREDIENTS_FAIL,
    data,
});
