import { Action } from 'redux';
import { call, takeLatest, put } from 'redux-saga/effects';

import * as reduxActions from '../actions/terms';
import * as reduxTypes from '../types/terms';
import * as api from '../api/terms';
import { SearchQuery } from '../api';
import { DataAction } from '../actions';
import { CGU, CGV, FAQ } from '../api/apiTypes';

export function* fetchCGUSaga(action: Action & SearchQuery) {
    try {
        const response = yield call(api.fetchCGU, action);
        return yield put(reduxActions.fetchCGUSuccess(response));
    } catch (error) {
        return yield put(reduxActions.fetchCGUFailed(error));
    }
}

export function* updateCGUSaga(action: DataAction<CGU>) {
    try {
        const response = yield call(api.updateCGU, action.data);
        return yield put(reduxActions.updateCGUSuccess(response));
    } catch (error) {
        return yield put(reduxActions.updateCGUFailed(error));
    }
}

export function* fetchCGVSaga(action: Action & SearchQuery) {
    try {
        const response = yield call(api.fetchCGV, action);
        return yield put(reduxActions.fetchCGVSuccess(response));
    } catch (error) {
        return yield put(reduxActions.fetchCGVFailed(error));
    }
}

export function* updateCGVSaga(action: DataAction<CGV>) {
    try {
        const response = yield call(api.updateCGV, action.data);
        return yield put(reduxActions.updateCGVSuccess(response));
    } catch (error) {
        return yield put(reduxActions.updateCGVFailed(error));
    }
}

export function* fetchFAQSaga(action: Action & SearchQuery) {
    try {
        const response = yield call(api.fetchFAQ, action);
        return yield put(reduxActions.fetchFAQSuccess(response));
    } catch (error) {
        return yield put(reduxActions.fetchFAQFailed(error));
    }
}

export function* updateFAQSaga(action: DataAction<FAQ>) {
    try {
        const response = yield call(api.updateFAQ, action.data);
        return yield put(reduxActions.updateFAQSuccess(response));
    } catch (error) {
        return yield put(reduxActions.updateFAQFailed(error));
    }
}

export default function* termsSaga() {
    yield takeLatest(reduxTypes.FETCH_CGU, fetchCGUSaga);
    yield takeLatest(reduxTypes.UPDATE_CGU, updateCGUSaga);
    yield takeLatest(reduxTypes.FETCH_CGV, fetchCGVSaga);
    yield takeLatest(reduxTypes.UPDATE_CGV, updateCGVSaga);
    yield takeLatest(reduxTypes.FETCH_FAQ, fetchFAQSaga);
    yield takeLatest(reduxTypes.UPDATE_FAQ, updateFAQSaga);
}
