import { combineReducers } from 'redux';

import * as reduxTypes from '../types/presaleNatures';
import { PreSaleNature } from '../api/apiTypes';
import { MainReducerState, RequestState } from './';
import { requestReducer } from './_generics';

export interface PreSaleNaturesState {
    readonly list: RequestState<PreSaleNature[]>;
}

const initialState: PreSaleNature[] = [];

export default combineReducers<PreSaleNaturesState>({
    list: requestReducer({
        START: reduxTypes.LIST,
        SUCCESS: reduxTypes.LIST_SUCCESS,
        FAILED: reduxTypes.LIST_FAIL,
    }, initialState),
});

export const getPreSaleNaturesData = (state: MainReducerState) => state.presaleNatures.list;
