import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Form, Drawer, Spin, Button, Divider, Input, Row, Col, Alert, Select, Switch, Icon } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { DrawerProps } from 'antd/lib/drawer';
import { ButtonProps } from 'antd/lib/button';

import { MainReducerState } from '../../store/reducers';
import * as UsersActions from '../../store/actions/users';
import * as ParcsActions from '../../store/actions/parcs';
import { UsersState, getUserDetailsState, updateUserState, createUserState } from '../../store/reducers/users';
import { AuthState, getUser } from '../../store/reducers/auth';
import { ParcsState, getParcsState, getSelectedParcState } from '../../store/reducers/parcs';
import { User, Parc, UserRole, getUserRole } from '../../store/api/apiTypes';

import { isSuperAdmin } from '../../utils';
interface UserEditProps extends FormComponentProps {
    id?: User['id'];
    createUser: typeof UsersActions.create;
    creates: UsersState['create'];
    user: AuthState['data'];
    userDetails: UsersState['details'];
    getDetails: typeof UsersActions.details;
    getParcs: typeof ParcsActions.list;
    isVisible: boolean;
    onUpdateSuccess: () => void;
    onClose: () => void;
    parcsState: ParcsState['list'];
    updateUser: typeof UsersActions.update;
    updates: UsersState['update'];
    siteId: number | null;
    siteIdState: ParcsState['selected'];
}

const UserEdit: FC<UserEditProps> = ({
    id, createUser, creates, userDetails, form, getDetails, isVisible, siteIdState,
    onUpdateSuccess, onClose, siteId, parcsState, getParcs, user, updateUser, updates,
}) => {

    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        getParcs();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (id) {
            setIsEditing(true);
            getParcs();
            getDetails(id, {
                siteId: siteIdState,
            });
        }
    }, [id]); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (id) {
            setIsEditing(true);
            getParcs();
            getDetails(id, {
                siteId: siteIdState,
            });
        }
    }, [siteIdState]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (creates.success || updates.success) {
            form.resetFields();
            onUpdateSuccess();
            onClose();
            setIsEditing(false);
        }
    }, [creates.success, updates.success]); // eslint-disable-line react-hooks/exhaustive-deps

    const { getFieldDecorator } = form;

    const onDrawerClose: DrawerProps['onClose'] & ButtonProps['onClick'] = () => {
        form.resetFields();
        setIsEditing(false);
        onClose();
    };

    const onRoleChange = (role: User['role']) => {
        if (role === 'superAdmin') {
            form.setFieldsValue({ siteId: null });
        }
        form.setFieldsValue({ role });
    };

    const onParcChange = (parc: Parc['id']) => {
        form.setFieldsValue({ siteId: parc });
    };

    const onSwitchChange = (val: boolean) => {
        form.setFieldsValue({ isEnabled: val });
    };

    const defaultParc = parcsState.data.find((parc) => parc.id === (user && user.siteId));

    const onSubmit = (e?: React.FormEvent) => {
        if (e) {
            e.preventDefault();
        }
        form.validateFieldsAndScroll(async (err, val) => {
            if (err) {
                return;
            }
            if (id !== undefined) {
                updateUser(id, val);
            } else {
                createUser(val);
            }
        });
    };

    return (
        <>
            <Drawer
                title={id !== undefined ? 'Modification d\'employé' : 'Création d\'employé'}
                width={960}
                onClose={onDrawerClose}
                visible={isVisible}
                className="edit-product-drawer"
            >
                <Spin spinning={userDetails.loading}>
                    {(!isEditing || (isEditing && !userDetails.loading && userDetails.data)) && (
                        <Form>
                            <Row gutter={16}>
                                <Col span={8}>
                                    <Form.Item label="Nom">
                                        {getFieldDecorator('firstName', {
                                            rules: [{
                                                required: true,
                                                message: 'champ requis',
                                            }],
                                            initialValue: isEditing && userDetails.data ?
                                                userDetails.data.firstName : '',
                                        })((
                                            <Input placeholder="Entrez le nom" />
                                        ))}
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label="Prénom">
                                        {getFieldDecorator('lastName', {
                                            rules: [{
                                                required: true,
                                                message: 'champ requis',
                                            }],
                                            initialValue: isEditing && userDetails.data ?
                                                userDetails.data.lastName : '',
                                        })((
                                            <Input placeholder="Entrez le prénom" />
                                        ))}
                                    </Form.Item>
                                </Col>
                                <Col span={2} offset={4}>
                                    <Form.Item label="Actif">
                                        {getFieldDecorator('isEnabled', {
                                            valuePropName: 'checked',
                                            initialValue: isEditing && userDetails.data ?
                                                userDetails.data.isEnabled : true,
                                        })((
                                            <Switch
                                                checkedChildren={<Icon type="check" />}
                                                unCheckedChildren={<Icon type="close" />}
                                                onChange={onSwitchChange}
                                            />
                                        ))}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={8}>
                                    <Form.Item label="Rôle">
                                        {getFieldDecorator('role', {
                                            rules: [{
                                                required: true,
                                                message: 'champ requis',
                                            }],
                                            initialValue: isEditing && userDetails.data &&
                                                userDetails.data.role ? userDetails.data.role : '',
                                        })((
                                            <Select
                                                filterOption={false}
                                                onChange={onRoleChange}
                                                placeholder="Choisissez un rôle"
                                            >
                                                {isSuperAdmin(user) && (
                                                    <Select.Option value="superAdmin">
                                                        SuperAdmin
                                                    </Select.Option>
                                                )}
                                                <Select.Option value="admin">
                                                    Admin
                                                </Select.Option>

                                                <Select.Option value="collect">
                                                    Préparateur
                                                    </Select.Option>
                                                <Select.Option value="delivery">
                                                    Livreur
                                                    </Select.Option>
                                                <Select.Option value={UserRole.desk}>
                                                    {getUserRole(UserRole.desk)}
                                                </Select.Option>
                                            </Select>
                                        ))}
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    {form.getFieldValue('role') !== 'superAdmin' && (
                                        <Form.Item label="Parc">
                                            {getFieldDecorator('siteId', {
                                                rules: [{
                                                    required: true,
                                                    message: 'champ requis',
                                                }],
                                                initialValue: isEditing && userDetails.data && id !== undefined ?
                                                    userDetails.data.siteId : defaultParc && defaultParc.id,
                                            })((
                                                < Select
                                                    disabled={!!defaultParc}
                                                    filterOption={false}
                                                    onChange={onParcChange}
                                                    placeholder="Choisissez un Parc"
                                                >
                                                    {isSuperAdmin(user) ? parcsState.data.map((parc) => (
                                                        <Select.Option
                                                            value={parc.id}
                                                            key={parc.id}
                                                        >

                                                            {parc.label}
                                                        </Select.Option>
                                                    )) : defaultParc && (
                                                        <Select.Option
                                                            key={defaultParc.id}
                                                            value={defaultParc.id}
                                                        >
                                                            {defaultParc.label}
                                                        </Select.Option>
                                                    )

                                                    }
                                                </Select>
                                            ))}
                                        </Form.Item>
                                    )}
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={8}>
                                    <Form.Item label="Login">
                                        {getFieldDecorator('login', {
                                            rules: [{
                                                required: true,
                                                message: 'champ requis',
                                            }],
                                            initialValue: isEditing && userDetails.data ?
                                                userDetails.data.login : '',
                                        })((
                                            <Input placeholder="Choisieez un login" />
                                        ))}
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    {id === undefined && (
                                        <Form.Item label="Mot de passe">
                                            {getFieldDecorator('password', {
                                                rules: [{
                                                    required: true,
                                                    message: 'champ requis',
                                                }],
                                                initialValue: isEditing && userDetails.data ?
                                                    userDetails.data.password : '',
                                            })((
                                                <Input placeholder="Entrez un mot de passe" />
                                            ))}
                                        </Form.Item>
                                    )}
                                </Col>
                                <Col span={8}>
                                    <Form.Item label="Téléphone">
                                        {getFieldDecorator('phone', {
                                            rules: [{
                                                required: false,
                                                message: 'champ requis',
                                            }],
                                            initialValue: isEditing && userDetails.data ?
                                                userDetails.data.phone : '',
                                        })((
                                            <Input placeholder="Entrez le téléphone" />
                                        ))}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <div className="form-actions">
                                <Button htmlType="submit" type="primary" onClick={onSubmit} loading={updates.loading}>
                                    {id !== undefined ? 'Mettre à jour' : 'Ajouter'}
                                </Button>
                                <Divider type="vertical" />
                                <Button onClick={onDrawerClose} type="ghost">
                                    Annuler
                                </Button>
                            </div>
                            <div className="modifier-button">
                                {!creates.loading && creates.error && (
                                    <Alert
                                        type="error"
                                        message={creates.error.errorCode === 'DISCOUNT_EXISTS' ?
                                            'Ce code de réduction est déjà utilisé!' :
                                            creates.error.errorMessage}
                                        showIcon
                                    />
                                )}
                            </div>
                            <div className="modifier-button">
                                {!updates.loading && updates.error && (
                                    <Alert
                                        type="error"
                                        message={updates.error.errorCode === 'DISCOUNT_EXISTS' ?
                                            'Ce code de réduction est déjà utilisé!' :
                                            updates.error.errorMessage}
                                        showIcon
                                    />
                                )}
                            </div>
                        </Form>
                    )}
                </Spin>
            </Drawer>
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    creates: createUserState(state),
    parcsState: getParcsState(state),
    siteIdState: getSelectedParcState(state),
    user: getUser(state),
    userDetails: getUserDetailsState(state),
    updates: updateUserState(state),
});

const UserFormDrawer = Form.create<UserEditProps>()(UserEdit);

export default connect(
    mapStateToProps,
    {
        createUser: UsersActions.create,
        getDetails: UsersActions.details,
        getParcs: ParcsActions.list,
        updateUser: UsersActions.update,
    },
)(UserFormDrawer);
