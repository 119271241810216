import { call, takeLatest, put } from 'redux-saga/effects';

import * as reduxActions from '../actions/parcs';
import * as reduxTypes from '../types/parcs';
import * as api from '../api/parcs';
import { IdAction, DataAction } from '../actions';
import { Parc } from '../api/apiTypes';

export function* listSaga(action: any) {
    try {
        const response = yield call(api.list);
        return yield put(reduxActions.listSuccess(response));
    } catch (error) {
        return yield put(reduxActions.listFailed(error));
    }
}

export function* detailsSaga(action: IdAction<Parc['id']>) {
    try {
        const response = yield call(api.details, action.id);
        return yield put(reduxActions.detailsSuccess(response));
    } catch (error) {
        return yield put(reduxActions.detailsFailed(error));
    }
}

export function* createSaga(action: DataAction<Parc>) {
    try {
        const response = yield call(api.create, action.data);
        return yield put(reduxActions.createSuccess(response));
    } catch (error) {
        return yield put(reduxActions.createFailed(error));
    }
}

export function* updateSaga(action: IdAction<Parc['id']> & DataAction<Partial<Parc>>) {
    try {
        const response = yield call(api.update, action.id, action.data);
        return yield put(reduxActions.updateSuccess(response));
    } catch (error) {
        return yield put(reduxActions.updateFailed(error));
    }
}

export default function* parcsSaga() {
    yield takeLatest(reduxTypes.LIST, listSaga);
    yield takeLatest(reduxTypes.DETAILS, detailsSaga);
    yield takeLatest(reduxTypes.CREATE, createSaga);
    yield takeLatest(reduxTypes.UPDATE, updateSaga);
}
