export const FETCH_CGU = 'terms/FETCH_CGU';
export const FETCH_CGU_SUCCESS = 'terms/FETCH_CGU_SUCCESS';
export const FETCH_CGU_FAIL = 'terms/FETCH_CGU_FAIL';

export const UPDATE_CGU = 'terms/UPDATE_CGU';
export const UPDATE_CGU_SUCCESS = 'terms/UPDATE_CGU_SUCCESS';
export const UPDATE_CGU_FAIL = 'terms/UPDATE_CGU_FAIL';

export const FETCH_CGV = 'terms/FETCH_CGV';
export const FETCH_CGV_SUCCESS = 'terms/FETCH_CGV_SUCCESS';
export const FETCH_CGV_FAIL = 'terms/FETCH_CGV_FAIL';

export const UPDATE_CGV = 'terms/UPDATE_CGV';
export const UPDATE_CGV_SUCCESS = 'terms/UPDATE_CGV_SUCCESS';
export const UPDATE_CGV_FAIL = 'terms/UPDATE_CGV_FAIL';

export const FETCH_FAQ = 'terms/FETCH_FAQ';
export const FETCH_FAQ_SUCCESS = 'terms/FETCH_FAQ_SUCCESS';
export const FETCH_FAQ_FAIL = 'terms/FETCH_FAQ_FAIL';

export const UPDATE_FAQ = 'terms/UPDATE_FAQ';
export const UPDATE_FAQ_SUCCESS = 'terms/UPDATE_FAQ_SUCCESS';
export const UPDATE_FAQ_FAIL = 'terms/UPDATE_FAQ_FAIL';
