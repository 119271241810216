export const LIST = 'banners/LIST';
export const LIST_SUCCESS = 'banners/LIST_SUCCESS';
export const LIST_FAIL = 'banners/LIST_FAIL';

export const DETAILS = 'banners/DETAILS';
export const DETAILS_SUCCESS = 'banners/DETAILS_SUCCESS';
export const DETAILS_FAIL = 'banners/DETAILS_FAIL';

export const CREATE = 'banners/CREATE';
export const CREATE_SUCCESS = 'banners/CREATE_SUCCESS';
export const CREATE_FAIL = 'banners/CREATE_FAIL';

export const UPDATE = 'banners/UPDATE';
export const UPDATE_SUCCESS = 'banners/UPDATE_SUCCESS';
export const UPDATE_FAIL = 'banners/UPDATE_FAIL';
