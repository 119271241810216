import { combineReducers } from 'redux';

import * as reduxTypes from '../types/discounts';
import { Discount } from '../api/apiTypes';
import { MainReducerState, RequestState } from './';
import { requestReducer } from './_generics';

export interface DiscountsState {
    readonly list: RequestState<Discount[]>;
    readonly details: RequestState<Discount>;
    readonly create: RequestState<Discount>;
    readonly update: RequestState<Partial<Discount>>;
    readonly delete: RequestState<Discount>;
}

const initialState: Discount[] = [];

export default combineReducers<DiscountsState>({
    list: requestReducer({
        START: reduxTypes.LIST,
        SUCCESS: reduxTypes.LIST_SUCCESS,
        FAILED: reduxTypes.LIST_FAIL,
    }, initialState),
    details: requestReducer({
        START: reduxTypes.DETAILS,
        SUCCESS: reduxTypes.DETAILS_SUCCESS,
        FAILED: reduxTypes.DETAILS_FAIL,
    }),
    create: requestReducer({
        START: reduxTypes.CREATE,
        SUCCESS: reduxTypes.CREATE_SUCCESS,
        FAILED: reduxTypes.CREATE_FAIL,
    }),
    update: requestReducer({
        START: reduxTypes.UPDATE,
        SUCCESS: reduxTypes.UPDATE_SUCCESS,
        FAILED: reduxTypes.UPDATE_FAIL,
    }),
    delete: requestReducer({
        START: reduxTypes.DELETE,
        SUCCESS: reduxTypes.DELETE_SUCCESS,
        FAILED: reduxTypes.DELETE_FAIL,
    }),
});

export const getDiscountsState = (state: MainReducerState) => state.discounts.list;
export const getDiscountDetailsState = (state: MainReducerState) => state.discounts.details;
export const createDiscountState = (state: MainReducerState) => state.discounts.create;
export const updateDiscountState = (state: MainReducerState) => state.discounts.update;
export const deleteDiscountState = (state: MainReducerState) => state.discounts.delete;
