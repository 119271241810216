import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Form, Drawer, Spin, Button, Icon, Divider, Switch, Input, Row, Col, Select, Alert, Upload } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { DrawerProps } from 'antd/lib/drawer';
import { ButtonProps } from 'antd/lib/button';
import { RcFile } from 'antd/lib/upload/interface';

import { MainReducerState } from '../../store/reducers';
import * as BannersActions from '../../store/actions/banners';
import { BannersState, getBannerDetailsState,
    updateBannerState, createBannerState } from '../../store/reducers/banners';
import { Lang, Banner } from '../../store/api/apiTypes';
import { getLangsData } from '../../store/reducers/langs';

interface BannerEditProps extends FormComponentProps {
    id?: Banner['id'];
    createBanner: typeof BannersActions.create;
    creates: BannersState['create'];
    bannerDetails: BannersState['details'];
    getDetails: typeof BannersActions.details;
    isVisible: boolean;
    langsState: Lang[];
    onUpdateSuccess: () => void;
    onClose: () => void;
    updateBanner: typeof BannersActions.update;
    updates: BannersState['update'];
}

const BannerEdit: FC<BannerEditProps> = ({
    id, createBanner, creates, bannerDetails, form, getDetails, langsState,
    isVisible, onUpdateSuccess, onClose, updateBanner, updates,
}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [imageFile, setImageFile] = useState<RcFile>();
    const [language, setLanguage] = useState('fr');
    console.log(id);
    useEffect(() => {
        if (id && isVisible) {
            setIsEditing(true);
            getDetails(id);
            console.log(bannerDetails);

        }
    }, [id, isVisible]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (creates.success || updates.success) {
            form.resetFields();
            onUpdateSuccess();
            onClose();
            setIsEditing(false);
        }
    }, [creates.success, updates.success]); // eslint-disable-line react-hooks/exhaustive-deps

    const { getFieldDecorator } = form;

    const onSwitchChange = (val: boolean) => {
        form.setFieldsValue({isEnabled: val});
    };

    const onLangCodeChange = (lang: Lang['languageCode']) => {
        setLanguage(lang);
        form.setFieldsValue({languageCode: lang});
    };

    const onDrawerClose: DrawerProps['onClose'] & ButtonProps['onClick'] = () => {
        form.resetFields();
        setIsEditing(false);
        onClose();
    };

    const onSubmit = (e?: React.FormEvent) => {
        if (e) {
            e.preventDefault();
        }
        form.validateFieldsAndScroll(async (err, val) => {
            if (err) {
                return;
            }
            const payload = {
                ...val,
                image: imageFile ? imageFile : undefined, // .originFileObj : undefined,
            };
            if (id !== undefined) {
                updateBanner(id, payload);
            } else {
                createBanner(payload);
            }
        });
    };

    const beforeBannerUpload = (file: RcFile) => {
        setImageFile(file);

        return false;
    };

    return (
        <>
            <Drawer
                title={id !== undefined ? 'Modification de bannière' : 'Création de bannière'}
                width={960}
                onClose={onDrawerClose}
                visible={isVisible}
                className="edit-product-drawer"
            >
                <Spin spinning={bannerDetails.loading}>
                    {(!isEditing || (isEditing && !bannerDetails.loading && bannerDetails.data)) && (
                        <Form>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item label="Titre">
                                        {getFieldDecorator('label', {
                                            rules: [{
                                                required: true,
                                                message: 'champ requis',
                                            }],
                                            initialValue: isEditing && bannerDetails.data ?
                                            language === 'fr' ?
                                            bannerDetails.data.label.fr :  bannerDetails.data.label.en : '',
                                        })((
                                            <Input placeholder="Entrez un titre"/>
                                        ))}
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label="Langue">
                                        {getFieldDecorator('languageCode', {
                                            rules: [{
                                                required: true,
                                                message: 'choisissez une langue',
                                            }],
                                            initialValue: bannerDetails.data ?
                                                'fr' : undefined,
                                        })((
                                            <Select onChange={onLangCodeChange} placeholder="Choisissez une langue">
                                                {langsState && langsState.map((lang: Lang) => (
                                                    <Select.Option
                                                        key={lang.languageCode}
                                                        value={lang.languageCode}
                                                    >
                                                        {lang.language}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        ))}
                                    </Form.Item>
                                </Col>
                                <Col span={2} offset={1}>
                                    <Form.Item label="Active">
                                        {getFieldDecorator('isEnabled', {
                                            valuePropName: 'checked',
                                            initialValue: bannerDetails.data ? bannerDetails.data.isEnabled : true,
                                        })((
                                            <Switch
                                                checkedChildren={<Icon type="check" />}
                                                unCheckedChildren={<Icon type="close" />}
                                                onChange={onSwitchChange}
                                            />
                                        ))}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                {/* <Col span={12}>
                                    <Form.Item label="Url lien">
                                        {getFieldDecorator('linkUrl', {
                                            rules: [{
                                                required: true,
                                                message: 'champ requis',
                                            }],
                                            initialValue: isEditing && bannerDetails.data ?
                                                bannerDetails.data.linkUrl : '',
                                        })((
                                            <Input placeholder="Entrez une url"/>
                                        ))}
                                    </Form.Item>
                                </Col> */}
                                <Col span={12}>
                                    <Form.Item label="Fichier">
                                        {getFieldDecorator('image', {
                                            rules: [{
                                                required: false,
                                                message: 'champ requis',
                                            }],
                                            initialValue: isEditing && bannerDetails.data ?
                                                bannerDetails.data.image : undefined,
                                        })((
                                            <Upload beforeUpload={beforeBannerUpload}>
                                                <Button>
                                                    <Icon type="upload" /> Choisir un fichier
                                                </Button>
                                            </Upload>
                                        ))}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <div className="form-actions">
                                <Button htmlType="submit" type="primary" onClick={onSubmit} loading={updates.loading}>
                                    {id !== undefined ? 'Mettre à jour' : 'Ajouter'}
                                </Button>
                                <Divider type="vertical"/>
                                <Button onClick={onDrawerClose} type="ghost">
                                    Annuler
                                </Button>
                            </div>
                            <div className="modifier-button">
                                {!creates.loading && creates.error && (
                                    <Alert
                                        type="error"
                                        message={creates.error.errorCode === 'DISCOUNT_EXISTS' ?
                                            'Ce code de réduction est déjà utilisé!' :
                                            creates.error.errorMessage}
                                        showIcon
                                    />
                                )}
                            </div>
                            <div className="modifier-button">
                                {!updates.loading && updates.error && (
                                    <Alert
                                        type="error"
                                        message={updates.error.errorCode === 'DISCOUNT_EXISTS' ?
                                            'Ce code de réduction est déjà utilisé!' :
                                            updates.error.errorMessage}
                                        showIcon
                                    />
                                )}
                            </div>
                        </Form>
                    )}
                </Spin>
            </Drawer>
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    bannerDetails: getBannerDetailsState(state),
    creates: createBannerState(state),
    langsState: getLangsData(state),
    updates: updateBannerState(state),
});

const BannerFormDrawer = Form.create<BannerEditProps>()(BannerEdit);

export default connect(
    mapStateToProps,
    {
        createBanner: BannersActions.create,
        getDetails: BannersActions.details,
        updateBanner: BannersActions.update,
    },
)(BannerFormDrawer);
