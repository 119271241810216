export const LIST = 'parcs/LIST';
export const LIST_SUCCESS = 'parcs/LIST_SUCCESS';
export const LIST_FAIL = 'parcs/LIST_FAIL';

export const DETAILS = 'parcs/DETAILS';
export const DETAILS_SUCCESS = 'parcs/DETAILS_SUCCESS';
export const DETAILS_FAIL = 'parcs/DETAILS_FAIL';

export const CREATE = 'parcs/CREATE';
export const CREATE_SUCCESS = 'parcs/CREATE_SUCCESS';
export const CREATE_FAIL = 'parcs/CREATE_FAIL';

export const UPDATE = 'parcs/UPDATE';
export const UPDATE_SUCCESS = 'parcs/UPDATE_SUCCESS';
export const UPDATE_FAIL = 'parcs/UPDATE_FAIL';

export const SET_SELECTED = 'parcs/SET_SELECTED';
