import { combineReducers } from 'redux';

import * as reduxTypes from '../types/constraints';
import { DeliveryTimeConstraint } from '../api/apiTypes';
import { MainReducerState, RequestState } from './';
import { requestReducer } from './_generics';

export interface ConstraintsState {
    readonly list: RequestState<DeliveryTimeConstraint[]>;
    readonly details: RequestState<DeliveryTimeConstraint>;
    readonly create: RequestState<DeliveryTimeConstraint>;
    readonly update: RequestState<DeliveryTimeConstraint>;
    readonly remove: RequestState<DeliveryTimeConstraint>;
}

const initialState: DeliveryTimeConstraint[] = [];

export default combineReducers<ConstraintsState>({
    list: requestReducer({
        START: reduxTypes.LIST,
        SUCCESS: reduxTypes.LIST_SUCCESS,
        FAILED: reduxTypes.LIST_FAIL,
    }, initialState),
    details: requestReducer({
        START: reduxTypes.DETAILS,
        SUCCESS: reduxTypes.DETAILS_SUCCESS,
        FAILED: reduxTypes.DETAILS_FAIL,
    }),
    create: requestReducer({
        START: reduxTypes.CREATE,
        SUCCESS: reduxTypes.CREATE_SUCCESS,
        FAILED: reduxTypes.CREATE_FAIL,
    }),
    update: requestReducer({
        START: reduxTypes.UPDATE,
        SUCCESS: reduxTypes.UPDATE_SUCCESS,
        FAILED: reduxTypes.UPDATE_FAIL,
    }),
    remove: requestReducer({
        START: reduxTypes.REMOVE,
        SUCCESS: reduxTypes.REMOVE_SUCCESS,
        FAILED: reduxTypes.REMOVE_FAIL,
    }),
});

export const getConstraints = (state: MainReducerState) => state.constraints.list;
export const getConstraintDetailsState = (state: MainReducerState) => state.constraints.details;
export const createConstraintState = (state: MainReducerState) => state.constraints.create;
export const updateConstraintState = (state: MainReducerState) => state.constraints.update;
export const removeConstraintState = (state: MainReducerState) => state.constraints.remove;
