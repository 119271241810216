export const downloadFile = (data: string, filename: string) => {
    const element = document.createElement('a');
    let file: any;
    if (filename.endsWith('.csv')) {
        file = new Blob([data], {type: 'csv'});
    } else if (filename.endsWith('.xlsx')) {
        file = new Blob([data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
        });
    } else {
        return;
    }
    element.href = URL.createObjectURL(file);
    element.download = filename;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
};
