import axios, { AxiosResponse } from 'axios';
import { RequestError, SearchPaginationQuery } from './';

const paginationHeaders: { [apiHeader: string]: string } = {
    count: 'count',
    filteredcount: 'filteredCount',
    totalcount: 'totalCount',
    page: 'page',
    pagesize: 'pageSize',
};

const getPaginationFromHeaders = (headers: AxiosResponse['headers']) => {
    const pagination: { [storePaginationKey: string]: number } = {};

    Object.keys(paginationHeaders).forEach((apiHeader: string) => {
        if (headers[apiHeader]) {
            pagination[paginationHeaders[apiHeader]] = parseInt(headers[apiHeader], 10);
        }
    });

    return pagination;
};

const client = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
        'x-centerparc-cms-auth-token': localStorage.getItem('userToken'),
        'x-centerparc-api-key': 'atxenT2CgwYy89Y4Gz1706q35tGrYfDoui',
        'x-centerparc-api-language': 'en', // remove once the API is modified to take query params instead
    },
    // withCredentials: true, // automatically send saved cookie
});

client.interceptors.response.use(
    (response) => {
        if (response.headers['content-type'] && response.headers['content-type'].startsWith('application/json')) {

            if (response.headers.page) {
                return {
                    items: response.data,
                    ...getPaginationFromHeaders(response.headers),
                };
            }

            return response.data;
        }
        return response;
    },
    (error) => {
        const formattedError: RequestError = {
            status: 0,
            message: '',
        };
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
            formattedError.status = error.response.status;
            formattedError.data = error.response.data;
            formattedError.headers = error.response.headers;
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest
            // console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            // console.log('Error', error.message);
        }

        throw formattedError;
    },
);

export type ListResponse<T> = T[];

export type UpdateData<T> = Pick<T, Exclude<keyof T, keyof { id: any, creationDate: any }>>;

export interface PaginatedListResponse<T> {
    count: number;
    filteredCount: number;
    items: ListResponse<T>;
    page: number;
    pageSize: number;
    totalCount: number;
}

export const urlWithQuery = (url: string, payload: SearchPaginationQuery, queryParams: any = {}): string => {
    const query = {
        ...queryParams,
    };

    if (payload.page !== undefined) {
        query.page = payload.page;
    }

    if (payload.limit !== undefined) {
        query.limit = payload.limit;
    }

    if (payload.search !== undefined) {
        query.search = payload.search;
    }

    if (payload.searchString !== undefined) {
        query.searchString = payload.searchString;
    }

    if (payload.siteId !== undefined) {
        query.siteId = payload.siteId;
    }

    if (payload.lang !== undefined) {
        query.lang = payload.lang;
    }

    if (payload.file !== undefined) {
        query.file = payload.file;
    }

    if (payload.status !== undefined) {
        query.status = payload.status;
    }

    if (payload.startDate !== undefined) {
        query.startDate = payload.startDate;
    }

    if (payload.endDate !== undefined) {
        query.endDate = payload.endDate;
    }

    if (payload.siteId !== undefined) {
        query.siteId = payload.siteId;
    }

    if (payload.sort !== undefined) {
        query.sort = payload.sort;
    }

    if (payload.sortOrder !== undefined) {
        query.sortOrder = payload.sortOrder;
    }

    if (payload.contentType !== undefined) {
        query.ContentType = payload.contentType;
    }

    if (payload.contentDisposition !== undefined) {
        query.contentDisposition = payload.contentDisposition;
    }

    const urlQueryParams = new URLSearchParams(query);
    return Object.keys(query).length ? `${url}?${urlQueryParams.toString()}` : url;
};

export default client;
