import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Spin, Drawer, Form, Input, Row, Col, Switch, Select, Button, Icon, Table, Popconfirm, Divider } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import Tabs, { TabsProps } from 'antd/lib/tabs';
import { DrawerProps } from 'antd/lib/drawer';
import { ButtonProps } from 'antd/lib/button';
import { ColumnProps } from 'antd/lib/table';
import TextArea from 'antd/lib/input/TextArea';

import * as ProductActions from '../../store/actions/products';
import * as ProductCategoriesActions from '../../store/actions/productCategories';
import * as MealTypesActions from '../../store/actions/mealTypes';
import { ProductsState, getProductDetailsState, updateProductState, getSelectProducts,
         addVariantState, removeVariantState, getVariantsList } from '../../store/reducers/products';
import { RequestState, MainReducerState } from '../../store/reducers';
import { getLangsData } from '../../store/reducers/langs';
import { Lang, MealType, ProductCategory, Product, ProductSubCategory, ProductVariant } from '../../store/api/apiTypes';
import { getProductCategoriesData } from '../../store/reducers/productCategories';
import { getMealTypesData } from '../../store/reducers/mealTypes';

import Constants from '../../utils/constants';

interface ProductEditProps extends FormComponentProps {
    addVariant: typeof ProductActions.addVariant;
    getDetails: typeof ProductActions.details;
    getMealTypes: typeof MealTypesActions.list;
    getProductCategories: typeof ProductCategoriesActions.list;
    getSelectProductsList: typeof ProductActions.listSelect;
    getVariants: typeof ProductActions.listVariants;
    id?: number;
    isVisible: boolean;
    langsState: Lang[];
    mealTypesListData: MealType[];
    onClose: () => void;
    onUpdateSuccess: () => void;
    productCategoriesListData: ProductCategory[];
    productState: ProductsState['details'];
    productsSelectListData: ProductsState['listSelect'];
    productVariantsData: ProductsState['variantsList'];
    removeVariant: typeof ProductActions.removeVariant;
    removes: RequestState;
    resetSuccess: typeof ProductActions.resetUpdateSuccess;
    updateProduct: typeof ProductActions.update;
    updates: RequestState;
    variants: RequestState;
}

const ProductEdit: FC<ProductEditProps> = ({
    addVariant, form, getDetails, getMealTypes, getProductCategories, getSelectProductsList, getVariants, id,
    isVisible, langsState, onClose, mealTypesListData, onUpdateSuccess,
    productCategoriesListData, productsSelectListData, productState, productVariantsData, removes, removeVariant,
    resetSuccess, updateProduct, updates, variants,

}) => {

    const [ selectedLang, setSelectedLang ] = useState(Constants.DEFAULT_LANG);
    const [ selectedVariantId, setSelectedVariantId ] = useState();
    const [ canAddVariant, setCanAddVariant ] = useState(false);

    useEffect(() => {
        if (updates.success) {
            form.resetFields();
            resetSuccess();
            onUpdateSuccess();
            onClose();
        }
    }, [updates.success]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (variants.success) {
            getVariants(id);
        }
    }, [variants.success, removes.success]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (id !== undefined && isVisible) {
            getDetails(id);
            getVariants(id);
        }
    }, [isVisible]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getProductCategories();
        getMealTypes();
        getSelectProductsList();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const productDetailsDependency = productState.data ? productState.data.id : null;
    useEffect(() => {
        form.setFieldsValue({label: productState.data ? productState.data.label[selectedLang] : ''});
        form.setFieldsValue({description: productState.data ? productState.data.description[selectedLang] : ''});
        form.setFieldsValue({ingredients: productState.data ? productState.data.ingredients[selectedLang] : ''});
    }, [selectedLang, productDetailsDependency]); // eslint-disable-line react-hooks/exhaustive-deps

    const changeLang: TabsProps['onChange'] = (event) => {
        setSelectedLang(event);
    };

    const onDrawerClose: DrawerProps['onClose'] & ButtonProps['onClick'] = () => {
        onClose();
        form.resetFields();
    };

    const onCategoryChange = (subCatId: ProductCategory['id']) => {
        form.setFieldsValue({productCategoryId: subCatId});
    };

    const onMealTypeChange = (mealId: MealType['id']) => {
        form.setFieldsValue({mealTypeId: mealId});
    };

    const onSwitchChange = (val: boolean) => {
        form.setFieldsValue({isEnabled: val});
    };

    const onSubmit = (e?: React.FormEvent) => {
        if (e) {
            e.preventDefault();
        }
        form.validateFieldsAndScroll(async (err, val) => {
            if (err) {
                return;
            }

            if (id !== undefined) {
                const payload = {
                    ...val,
                    languageCode: selectedLang,
                };
                updateProduct(id, payload);
            } else {
                // createProduct(val);
            }
        });
    };

    const { getFieldDecorator } = form;

    const onSelectVariantChange = (variantId: Product['id']) => {
        setSelectedVariantId(variantId);
    };

    const onSelectVariantSearch = (str: Partial<Product['techLabel']>) => {
        if (str.length >= 3) {
            getSelectProductsList({
                searchString: str,
                limit: Constants.PAGE_SIZE,
                throttling: 300,
            });
        }
    };

    const onAddVariant = () => {
        if (selectedVariantId) {
            addVariant(productState.data.id, selectedVariantId);
            setCanAddVariant(false);
            setSelectedVariantId(undefined);
        } else {
            alert('Choisissez un produit!');
        }
    };

    const cancelAddVariant = () => {
        setSelectedVariantId(undefined);
        setCanAddVariant(false);
    };

    const onRemoveVariant = (variant: ProductVariant['id']) => {
        removeVariant(variant);
        getVariants();
    };

    const rowKey =  (item: ProductVariant) => `${item.id}`;

    const columns: Array<ColumnProps<ProductVariant>> = [
        {
            dataIndex: 'variantProductId',
            title: 'Id variante',
        },
        {
            dataIndex: 'product.techLabel',
            title: 'Label variante',
        },
        {
            dataIndex: 'creationDate',
            title: 'Date de création',
            render: (record) => record.substring(0, 10),
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (
                <>
                    {productVariantsData.data.length >= 1 ? (
                        <Popconfirm
                            title="Voulez-vous vraiment supprimer?"
                            className="list-action-button-delete"
                            onConfirm={onRemoveVariant.bind(null, record.id)}
                            okText="Oui"
                            cancelText="Non"
                        >
                            <Button icon="delete" type="danger"/>
                        </Popconfirm>
                    ) : null}
                </>
            ),
        },
    ];

    return (
        <>
            <Spin spinning={productState.loading} tip="Chargement...">
                <Drawer
                    title={'Edition du produit: ' + !productState.loading &&
                    productState.data ? productState.data.techLabel : ''}
                    width={960}
                    onClose={onDrawerClose}
                    visible={isVisible}
                >
                    <Tabs onChange={changeLang} type="card">
                        {langsState && langsState.map((e) => (
                            <Tabs.TabPane
                                tab={e.languageCode}
                                key={e.languageCode}
                            />
                        ))}
                    </Tabs>
                    <Spin spinning={productState.loading}>

                        <Form onSubmit={onSubmit} layout="vertical">
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item label="Label (1 par langue)">
                                        {getFieldDecorator('label', {
                                            rules: [{
                                                required: true,
                                                message: 'champ requis',
                                            }],
                                            initialValue: productState.data ?
                                                productState.data.label[selectedLang] : '',
                                        })(
                                            <Input placeholder="label"/>,
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={2} offset={5}>
                                    <Form.Item label="Actif">
                                        {getFieldDecorator('isEnabled', {
                                            valuePropName: 'checked',
                                            initialValue: productState.data ? productState.data.isEnabled : true,
                                        })((
                                            <Switch
                                                checkedChildren={<Icon type="check" />}
                                                unCheckedChildren={<Icon type="close" />}
                                                onChange={onSwitchChange}
                                            />
                                        ))}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item label="Description (1 par langue)">
                                        {getFieldDecorator('description', {
                                            rules: [{
                                                required: true,
                                                message: 'Description détaillée',
                                            }],
                                            initialValue: productState.data ?
                                                productState.data.description[selectedLang] : '',
                                        })(
                                            <TextArea placeholder="description"/>,
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Ingrédients (1 par langue)">
                                        {getFieldDecorator('ingredients', {
                                            rules: [{
                                                required: true,
                                                message: 'champ requis',
                                            }],
                                            initialValue: productState.data ?
                                                productState.data.ingredients[selectedLang] : '',
                                        })(
                                            <TextArea placeholder="liste des ingrédients"/>,
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item label="Catégorie produit">
                                        {getFieldDecorator('productCategoryId', {
                                            rules: [{
                                                required: true,
                                                message: 'champ requis',
                                            }],
                                            initialValue: productState.data && productState.data.category !== null ?
                                            productState.data.category.id : null,
                                        })((
                                            <Select onChange={onCategoryChange}>
                                                {productCategoriesListData.map((category: ProductCategory) => (
                                                    <Select.OptGroup
                                                        label={category.categoryLabel &&
                                                        category.categoryLabel[selectedLang] ?
                                                        category.categoryLabel[selectedLang] :
                                                        'Label manquant pour cette langue'}
                                                        key={category.id}
                                                    >
                                                        {category.subCategories.map((subcat: ProductSubCategory) => (
                                                            <Select.Option
                                                                key={subcat.id}
                                                                value={subcat.id}
                                                            >
                                                                {subcat.subcategoryLabel &&
                                                                subcat.subcategoryLabel[selectedLang] ?
                                                                subcat.subcategoryLabel[selectedLang] :
                                                                `Id: ${subcat.id}, label manquant pour cette langue`}
                                                            </Select.Option>
                                                        ))}
                                                    </Select.OptGroup>
                                                ))}
                                            </Select>
                                        ))}
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Type de repas">
                                        {getFieldDecorator('mealTypeId', {
                                            rules: [{
                                                required: true,
                                                message: 'choisissez un type de repas',
                                            }],
                                            initialValue: productState.data && productState.data.mealType !== null ?
                                            productState.data.mealType.id : null,
                                        })((
                                            <Select onChange={onMealTypeChange} placeholder="Choisissez un type">
                                                {mealTypesListData.map((meal: MealType) => (
                                                    <Select.Option
                                                        key={meal.id}
                                                        value={meal.id}
                                                    >
                                                        {meal.label && meal.label[selectedLang] ?
                                                        meal.label[selectedLang] :
                                                        `Id: ${meal.id}, label manquant pour cette langue`}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        ))}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <hr/>
                            <Table<ProductVariant>
                                bordered={false}
                                rowKey={rowKey}
                                columns={columns}
                                dataSource={productVariantsData.data}
                                loading={productVariantsData.loading}
                                className="product-variants-table"
                            />
                            <hr/>
                            <Row gutter={16} className="list-header">
                                <Col span={6}>
                                    <Button
                                        icon="plus-circle"
                                        type="primary"
                                        onClick={setCanAddVariant.bind(null, true)}
                                    >
                                        Ajouter variante
                                    </Button>
                                </Col>
                                <Col span={14}>
                                    {canAddVariant ? (
                                        <Select
                                            placeholder="Choisissez un produit"
                                            showSearch
                                            value={selectedVariantId}
                                            onChange={onSelectVariantChange}
                                            onSearch={onSelectVariantSearch}
                                            filterOption={false}
                                            className="variant-select"
                                        >
                                            {productsSelectListData.data.filter((productSelect) =>
                                                !productVariantsData.data.find((variant) =>
                                                    variant.variantProductId === productSelect.id,
                                                )).map((prod: Product) => (
                                                    <Select.Option
                                                        key={prod.id}
                                                        value={prod.id}
                                                    >
                                                        {prod.techLabel}
                                                    </Select.Option>
                                                ))}
                                        </Select>
                                        ) : null
                                    }
                                </Col>
                                <Col span={4}>
                                    {canAddVariant && selectedVariantId ? (
                                        <>
                                            <Button type="primary" icon="save" onClick={onAddVariant}/>
                                            <Divider type="vertical"/>
                                            <Button icon="rollback" onClick={cancelAddVariant} />
                                        </>
                                        ) : null
                                    }
                                </Col>
                            </Row>
                            <hr/>
                            <div className="form-actions">
                                <Button htmlType="submit" type="primary" onClick={onSubmit} loading={updates.loading}>
                                    Mettre à jour
                                </Button>
                                <Divider type="vertical"/>
                                <Button onClick={onDrawerClose} type="ghost">
                                    Annuler
                                </Button>
                        </div>

                        </Form>
                    </Spin>
                </Drawer>
            </Spin>
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    langsState: getLangsData(state),
    mealTypesListData: getMealTypesData(state),
    productState: getProductDetailsState(state),
    productsSelectListData: getSelectProducts(state),
    productVariantsData: getVariantsList(state),
    productCategoriesListData: getProductCategoriesData(state),
    removes: removeVariantState(state),
    updates: updateProductState(state),
    variants: addVariantState(state),
});

const ProductFormDrawer = Form.create<ProductEditProps>()(ProductEdit);

export default connect(
    mapStateToProps,
    {
        getDetails: ProductActions.details,
        getSelectProductsList: ProductActions.listSelect,
        getMealTypes: MealTypesActions.list,
        getProductCategories: ProductCategoriesActions.list,
        updateProduct: ProductActions.update,
        resetSuccess: ProductActions.resetUpdateSuccess,
        getVariants: ProductActions.listVariants,
        addVariant: ProductActions.addVariant,
        removeVariant: ProductActions.removeVariant,
    },
)(ProductFormDrawer);
