import React, { FC, useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { ColumnProps } from 'antd/lib/table';
import {
    Spin, Descriptions, Icon, Table, Button, Select, Tag, Popconfirm, Row, Col,
    Alert, Typography, Divider, message,
} from 'antd';
// import { ColumnProps } from 'antd/lib/table';
// tslint:disable-next-line: no-implicit-dependencies
import { RouteChildrenProps } from 'react-router';
import moment from 'moment';

import { MainReducerState } from '../../store/reducers';
import * as OrderActions from '../../store/actions/orders';

import {
    OrdersState, invoiceState, getOrderDetailsState, getOrdersListState,
    updateOrderState, getCounterPaimentState, invoiceByMailState, getListRefundsState, getCreateRefundState,
    getUpdateCounterPaiementState,
    getcreationCounterPaiementState, getDeleteCounterPaiementState,
} from '../../store/reducers/orders';
import { getUser, AuthState } from '../../store/reducers/auth';
import {
    OrderStatus, getOrderStatusName, getOrderStatusColor,
    getDeliveryModeType, PaiementModes, CounterPaiement, getPaiementModeByKey, Refund, UserRole, getCartType, CartItem,
} from '../../store/api/apiTypes';

import { ParcsState, getSelectedParcState } from '../../store/reducers/parcs';

import {
    getFullName, formatCurrency, getPaiementMothod, canCancelOrder,
    canRefundOrder, getOrderPrices, usePrevious,
} from '../../utils';
import CounterPaiementForm from './CounterPaiementForm';
import RefundForm from './RefundForm';
import LogsDrawer from './LogsDrawer';
import imageSelector from '../../utils/imageSelector';

const { Title, Text } = Typography;
interface MatchParams {
    id: string;
}

interface OrderProps extends RouteChildrenProps<MatchParams> {
    getDetails: typeof OrderActions.details;
    getInvoice: typeof OrderActions.invoice;
    invoice: OrdersState['invoice'];
    getInvoiceByMail: typeof OrderActions.invoiceByMail;
    invoiceByMail: OrdersState['invoiceByMail'];
    orderDetails: OrdersState['details'];
    siteIdState: ParcsState['selected'];
    updateOrder: typeof OrderActions.update;
    updates: OrdersState['update'];
    counterPaiementState: OrdersState['listCounterPaiement'];
    getCounterPaiementList: typeof OrderActions.listCounterPaiement;
    createCounterPaiementState: OrdersState['createCounterPaiement'];
    updateCounterPaiementState: OrdersState['updateCounterPaiement'];
    deleteCounterPaiementState: OrdersState['deleteCounterPaiement'];
    createRefundState: OrdersState['createRefund'];
    getRefundsList: typeof OrderActions.listRefunds;
    listRefundsState: OrdersState['listRefunds'];
    user: AuthState['data'];
}

const Order: FC<OrderProps> = ({
    getDetails, getInvoice, invoice, match, orderDetails, siteIdState,
    updateOrder, updates, counterPaiementState, getCounterPaiementList,
    createRefundState, getRefundsList, listRefundsState, user, invoiceByMail, getInvoiceByMail,
    createCounterPaiementState, updateCounterPaiementState, deleteCounterPaiementState,
}) => {
    const [selectedStatus, setSelectedStatus] = useState<OrderStatus>();
    const [isFormVisible, setIsFormVisible] = useState<boolean>(false);
    const [isPrint, setIsPrint] = useState<boolean>(false);
    const [selectedPaimentMode, setSelectedPaiementMode] = useState<string | undefined>();
    const [selectedCounterPaiementId, setSelectedCounterPaiementId] = useState<CounterPaiement['id'] | undefined>();
    const [leftToPay, setLeftToPay] = useState<number>(0);
    const [isRefundFormVisible, setIsRefundFormVisible] = useState<boolean>(false);
    const [orderStatus, setOrderStatus] = useState<OrderStatus>(OrderStatus.waitForPaiement);
    const siteID = siteIdState || (user && user.siteId);

    const getOrderId = () => {
        return match ? parseInt(match.params.id, 10) : null;
    };

    const fetchDetails = () => {
        const id = getOrderId();
        getDetails(id);
    };

    useEffect(() => {
        const id = getOrderId();
        fetchDetails();
        getCounterPaiementList(id);
    }, [siteID]); // eslint-disable-line react-hooks/exhaustive-deps

    const updatePrevProps = usePrevious<Partial<OrderProps>>({
        updates, createRefundState, createCounterPaiementState, updateCounterPaiementState,
        deleteCounterPaiementState,
    });
    const previousInvoice = usePrevious<OrdersState['invoice']>(invoice);

    const updateDependencies = [updates, createRefundState, createCounterPaiementState, updateCounterPaiementState,
        deleteCounterPaiementState];

    useEffect(() => {

        const prevStateLoading = updatePrevProps && Object.values(updatePrevProps).map((dep: any) => {
            return dep && dep.loading;
        }).some(Boolean);

        const isDataFetched = updateDependencies.map((dep) => !dep.loading && dep.success && dep.data).some(Boolean);

        if (isDataFetched && prevStateLoading) {
            fetchDetails();
        }

    }, updateDependencies.map((dep) => dep && dep.success)); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (orderDetails.success) {
            setSelectedStatus(orderDetails.data && orderDetails.data.status);
            setOrderStatus(orderDetails.data && orderDetails.data.status);
            if (orderDetails.data.status === OrderStatus.refunded) {
                getRefundsList(orderDetails.data.id);
            }
        }

    }, [orderDetails.success]);  // eslint-disable-line react-hooks/exhaustive-deps
    const printPage = (url: string) => {
        let iframe = document.getElementById('print-iframe') as HTMLIFrameElement;
        console.log(iframe);
        if (iframe) {
            iframe.src = url;
        } else {
            iframe = document.createElement('iframe');
            console.log(iframe);
            iframe.onload = () => {
                iframe.contentWindow!.onbeforeunload = () => {
                    document.body.removeChild(document.getElementById('print-iframe')!);
                };
                iframe.contentWindow!.onafterprint = () => {
                    document.body.removeChild(document.getElementById('print-iframe')!);
                };
                iframe.contentWindow!.focus(); // Required for IE
                iframe.contentWindow!.print();
            };
            iframe.style.position = 'fixed';
            iframe.style.right = '0';
            iframe.style.bottom = '0';
            iframe.style.width = '0';
            iframe.style.height = '0';
            iframe.style.border = '0';
            iframe.id = 'print-iframe';
            iframe.src = url;
            document.body.appendChild(iframe);
        }
    };

    const downloadFile = useCallback(
        (filename: string) => {
            const element = document.createElement('a');
            element.href = invoice.data;
            element.download = filename;
            document.body.appendChild(element); // Required for this to work in FireFox
            element.click();
        },
        [invoice.data],
    );

    useEffect(() => {
        if (siteIdState !== null) {
            if (previousInvoice && previousInvoice.loading && !invoice.loading && invoice.success) {
                console.log('test', invoice.data);
                if (isPrint) {
                    console.log('test', invoice.data);
                    printPage(invoice.data);
                } else {
                    downloadFile(`C${orderDetails.data.id}.pdf`);
                }
            }
        }
    }, [siteIdState, isPrint,
        previousInvoice,
        invoice, orderDetails, downloadFile]);

    useEffect(() => {
        if (invoiceByMail.success) {
            message.success('La facture a été envoyé avec succès');
        }
        if (invoiceByMail.error) {
            message.error('Une erreur s\'est produite, veuillez réessayer plus tard');
        }
    }, [invoiceByMail.success, invoiceByMail.error]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (orderDetails.success && counterPaiementState.success) {

            const totalCounterPaiements = ((counterPaiementState && counterPaiementState.data) || [])
                .reduce((acc: number, el) => acc + el.amount, 0);
            const leftAmount = orderDetails.data && (getOrderPrice() - totalCounterPaiements);
            setLeftToPay(leftAmount);
        }
    }, [orderDetails.success, counterPaiementState.success]); // eslint-disable-line react-hooks/exhaustive-deps

    const onStatusChange = (val: OrderStatus) => {
        setSelectedStatus(val);
    };

    const onUpdateStatus = () => {
        updateOrder(orderDetails.data.id, {
            status: selectedStatus,
        });
    };

    const cancelOrder = () => {
        updateOrder(orderDetails.data.id, {
            status: OrderStatus.cancelled,
        });
    };

    const onInvoice = (event: React.MouseEvent<HTMLButtonElement>) => {
        setIsPrint(false);
        const id = getOrderId();
        if (siteID !== undefined && siteID !== null) {
            getInvoice(id, {
                siteId: siteID,
                file: 'invoice',
                contentDisposition: event.currentTarget.dataset.contentdisposition,
            });
        } else {
            message.error('Veuillez sélectionner un site.');
        }
    };
    const onInvoiceByMail = () => {
        const id = getOrderId();
        getInvoiceByMail(id);
    };

    const onInvoicePrint = () => {
        setIsPrint(true);
        const id = getOrderId();
        if (siteID !== undefined && siteID !== null) {
            getInvoice(id, {
                siteId: siteID,
                file: 'invoice',
            });
        } else {
            message.error('Veuillez sélectionner un site.');
        }
    };

    const rowKey = (item: CartItem) => `${item.id}`;
    const columns: Array<ColumnProps<CartItem>> = [
        // {
        //     dataIndex: 'catalogProduct.id',
        //     title: 'Id',
        // },
        {
            dataIndex: 'catalogProduct.product.techLabel',
            title: 'Nom produit',
        },
        {
            dataIndex: 'catalogProduct.price',
            title: 'Prix',
            render: (text, record) => (
                formatCurrency(record.priceWithTax)
            ),
        },
        {
            dataIndex: 'catalogProduct.restaurant.label',
            title: 'Nom restaurant',
        },
        {
            dataIndex: 'catalogProduct.product.images',
            title: 'Image',
            render: (text, record) => (
                <img className="order-details-image" src={imageSelector(record.catalogProduct.product.images)} alt="produit" />
            ),
        },
    ];

    const getOrderPrice = () => {
        if (!orderDetails.data) { return 0; }
        const prices = getOrderPrices(orderDetails.data);
        return prices.withTaxAndDiscount;
    };

    const getOrderPriceFormatted = () => {
        return formatCurrency(
            getOrderPrice());
    };

    const selectPaiementMode = (mode: string) => {
        return () => {
            setSelectedCounterPaiementId(undefined);
            setSelectedPaiementMode(mode);
            setIsFormVisible(true);
        };
    };

    const closePaiementForm = () => {
        setIsFormVisible(false);
    };

    const selectCounterPaiment = (cpId: number | undefined) => {
        return () => {
            setSelectedCounterPaiementId(cpId);
            setIsFormVisible(true);
        };
    };

    const closeRefundForm: () => void = () => {
        setIsRefundFormVisible(false);
    };
    const openRefundForm: () => void = () => {
        setIsRefundFormVisible(true);
    };
    const onRefundFormSubmit: () => void = () => {
        closeRefundForm();
    };

    const canChangeStatus = () => {
        return user && [UserRole.superAdmin, UserRole.admin].includes(user.role)
            && orderStatus !== OrderStatus.refunded;

    };

    const notRefundedOrCancelled = () => {

        return [OrderStatus.refunded, OrderStatus.cancelled].every((status) => status !== orderStatus);
    };
    const isOrderWaitingToPaiement = () => {

        return OrderStatus.waitForPaiement === orderStatus;
    };
    const getExcesCounterPaiment = () => {
        const result = (counterPaiementState.data.reduce((a, b) =>
        a + (b.amount * (b.occurrences || 1) - (b.returned || 0)), 0)
        - getOrderPrice()).toFixed(2);
        return result.toString();
    };
    const renderPeimentMode = () => {
        const { pre_sale, ...allowedPaiementModes } = PaiementModes;
        return orderDetails.data && (
            <Descriptions.Item span={4} className="paiement-mode" label="Encaissement">
                <Row type="flex" align="middle">
                    {isOrderWaitingToPaiement() && (
                        <Col xs={24} className="paiement-col" >
                            <Title className="sub-title-1">
                                Ajouter un moyen de paiement :
                            </Title>
                            {Object.entries(allowedPaiementModes).map(([key, value]) => (
                                <Button
                                    className="mb-10 mr-15 mt-5"
                                    type="primary"
                                    key={key}
                                    onClick={selectPaiementMode(value)}
                                >
                                    {value}
                                </Button>

                            ))}
                            <Divider type="horizontal" />
                        </Col>
                    )
                    }

                    { // ounterPaiementState.data.length  could be changed with ounterPaiementState.totalItems when
                        // pagination is implimented in backend
                        counterPaiementState.data && counterPaiementState.data.length > 0 && (
                            <Col xs={24} className="paiement-col">
                                <Title className="sub-title-2">
                                    Moyens de paiement utilisés :
                                </Title>
                                {counterPaiementState.data.map((cp) => (
                                    <Row
                                        key={cp.id}
                                        type="flex"
                                        align="middle"
                                        className="mt-10"
                                        justify="space-between"
                                    >
                                        <Col span={14}>{getPaiementModeByKey(cp.method)}
                                            {cp.returned ? (
                                                <Text
                                                    type="secondary"
                                                    className="ml-20"
                                                >
                                                    ( Rendu monnaie : {formatCurrency(cp.returned)})
                                                </Text>
                                            ) : null}
                                        </Col>
                                        <Col>
                                            <Text className="p-30" >{formatCurrency(cp.amount * (cp.occurrences || 1))}
                                            </Text>
                                            {isOrderWaitingToPaiement() && (

                                                <Button
                                                    icon="edit"
                                                    type="primary"
                                                    ghost={true}
                                                    onClick={selectCounterPaiment(cp.id)}
                                                />
                                            )
                                            }
                                        </Col>
                                        <Col>
                                            <Text className="p-30" >
                                                {cp.userId && (
                                                    (`Vendeur n° ${cp.userId}`)
                                                )}
                                            </Text>
                                        </Col>
                                    </Row>
                                ),
                                )}
                            </Col>
                        )}
                    {notRefundedOrCancelled() && (
                        <Col span={24} >
                            {
                                isOrderWaitingToPaiement() ? (
                                    <Alert
                                        className="no-border-radius"
                                        type={'warning'}
                                        message={
                                            (
                                                <Row
                                                    type="flex"
                                                    align="middle"
                                                    justify="space-between"
                                                    className="p-10"
                                                >
                                                    <Col>
                                                        <Text strong={true}>
                                                            Reste à payer
                                       </Text>
                                                    </Col>
                                                    <Col>
                                                        <Text className="rest-of-paiement-price" strong={true}>
                                                            {formatCurrency(leftToPay)}
                                                        </Text>

                                                    </Col>
                                                </Row>
                                            )}
                                    />
                                ) : canRefundOrder(orderDetails.data.paymentMethod, orderStatus) && (
                                    <Alert
                                        className="no-border-radius"
                                        type={'success'}
                                        message={(
                                            <Row type="flex" align="middle" justify="center">
                                                <Col>
                                                    <Title level={4} className="payed-color m-10">
                                                        Réglée
                                                    </Title>
                                                </Col>
                                            </Row>
                                        )}
                                    />
                                )}
                        </Col>
                    )}
                </Row>
            </Descriptions.Item>
        );
    };

    const renderCancelOrderBtn = () => {

        if (!orderDetails.data) { return; }
        return canCancelOrder(orderDetails.data.paymentMethod, orderStatus) && (
            <Popconfirm
                title="Voulez-vous vraiment annuler la commande ?"
                onConfirm={cancelOrder}
                okText="Oui"
                cancelText="Non"
            >
                <Button type="danger" ghost={true} className="mr-15">
                    Annuler la commande
                </Button>
            </Popconfirm>
        );
    };

    const renderRefundBtn = () => {
        if (!orderDetails.data) { return; }

        return canRefundOrder(orderDetails.data.paymentMethod, orderStatus) && (
            <Button type="ghost" onClick={openRefundForm} className="mr-15">
                Effectuer un remboursement
</Button>
        );
    };
    const canCancelOrRefund = () => {
        return [OrderStatus.refunded, OrderStatus.cancelled].every((status) => status !== orderStatus);
    };

    const canInvoice = () => {
        return ![OrderStatus.cancelled, OrderStatus.waitForPaiement]
            .includes(orderStatus);
    };

    return (
        <Spin spinning={orderDetails.loading} tip="Chargement...">
            {orderDetails.data && (
                <>
                    <Descriptions column={{ xs: 1, sm: 1, lg: 2, xl: 3 }} title="Détails de la commande" bordered>
                        <Descriptions.Item label="Id">
                            {orderDetails.data.id}
                        </Descriptions.Item>
                        <Descriptions.Item label="Date de commande">
                        {orderDetails.data.creationDate ?
                                moment(orderDetails.data.creationDate).format('DD/MM/YYYY HH:mm') : null}
                        </Descriptions.Item>
                        <Descriptions.Item label="Référence">
                            {orderDetails.data.reference ? orderDetails.data.reference : null}
                        </Descriptions.Item>
                        <Descriptions.Item label="Sacs">
                            {orderDetails.data.bagsCount}
                        </Descriptions.Item>
                        <Descriptions.Item label="Date de livraison">
                            {orderDetails.data.deliveryDate ?
                                moment(orderDetails.data.deliveryDate).format('DD/MM/YYYY HH:mm') : null}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label={orderDetails.data.cart &&
                                orderDetails.data.cart.deliveryMode && orderDetails.data.cart.deliveryMode === 'T' ?
                                'Créneau de retrait' : 'Créneau de livraison'
                            }
                        >
                            {orderDetails.data.deliveryTimeRange ?
                                `${orderDetails.data.deliveryTimeRange.timeStart.substr(0, 5)} - ${orderDetails.data.deliveryTimeRange.timeEnd.substr(0, 5)}` : null}
                        </Descriptions.Item>
                        <Descriptions.Item label="Substitutions?">
                            {orderDetails.data.allowsSubstitute ?
                                <Icon type="check" /> :
                                <Icon type="minus" />}
                        </Descriptions.Item>
                        <Descriptions.Item label="Animaux?">
                            {orderDetails.data.animalPresence ?
                                <Icon type="check" /> :
                                <Icon type="minus" />}
                        </Descriptions.Item>
                        <Descriptions.Item label="Prix avec TVA">
                            {getOrderPriceFormatted()}
                        </Descriptions.Item>
                        <Descriptions.Item label="Cottage">
                            {orderDetails.data.client && orderDetails.data.client.vacation ?
                                orderDetails.data.client.vacation.cottageNumber : orderDetails.data.cart ?
                                orderDetails.data.cart.cottageNumber : null}
                        </Descriptions.Item>
                        <Descriptions.Item label="Nom client">
                            {orderDetails.data.client ?
                                getFullName(orderDetails.data.client.firstName, orderDetails.data.client.lastName)
                                : null}
                        </Descriptions.Item>
                        {canChangeStatus() ? (
                            <Descriptions.Item label="Statut" >
                                <Select
                                    onChange={onStatusChange}
                                    className="order-details-select"
                                    value={selectedStatus || orderStatus}
                                >
                                    {Object.values(OrderStatus).map((status) => (
                                        <Select.Option
                                            key={status}
                                            value={status}
                                        >
                                            <Tag color={getOrderStatusColor(status)}>
                                                {getOrderStatusName(status)}
                                            </Tag>
                                        </Select.Option>
                                    ))}
                                </Select>
                                <Popconfirm
                                    title="Voulez-vous vraiment changer le statut?"
                                    onConfirm={onUpdateStatus}
                                    okText="Oui"
                                    cancelText="Non"
                                    disabled={[undefined, OrderStatus.refunded].includes(selectedStatus)}
                                >
                                    <Button
                                        disabled={[undefined, OrderStatus.refunded].includes(selectedStatus)}
                                        className="order-details-confirm"
                                        type="primary"
                                    >
                                        Mettre à jour
                                    </Button>
                                </Popconfirm>
                            </Descriptions.Item>
                        ) : (
                                <Descriptions.Item label="Statut">
                                    <Tag color={getOrderStatusColor(orderStatus)}>
                                        {getOrderStatusName(orderStatus)}
                                    </Tag>
                                </Descriptions.Item>
                            )}
                        <Descriptions.Item label="Type de livraison">
                            {orderDetails.data.cart && orderDetails.data.cart.deliveryMode ?
                                getDeliveryModeType(orderDetails.data.cart.deliveryMode) : null}
                        </Descriptions.Item>

                        <Descriptions.Item span={4} key="cartType" label="Canal commande">
                            {orderDetails.data.cart && getCartType(orderDetails.data.cart.cartType)}
                        </Descriptions.Item>
                        {counterPaiementState.data && counterPaiementState.data.length > 0  && (
                            <Descriptions.Item span={4} key="cartType" label="Non rendue monnaie">
                                {getExcesCounterPaiment()}
                            </Descriptions.Item>
                        )
                        }
                        {orderStatus !== OrderStatus.cancelled &&
                            [(
                                <Descriptions.Item span={4} key="paymentMode" label="Canal paiement">
                                    {orderDetails.data.cart && getPaiementMothod(orderDetails.data.paymentMethod)}
                                </Descriptions.Item>
                            ),
                            renderPeimentMode()]
                        }

                        {orderStatus === OrderStatus.refunded && listRefundsState.data && (
                            <Descriptions.Item span={4} label="Remboursement">
                                {listRefundsState.data.map((refund: Refund) => (
                                    <div key={refund.id}>
                                        <Text strong={true} className="mr-20">{formatCurrency(refund.amount)}</Text>
                                        <Text type="secondary"> {`(Motif: ${refund.reason})`} </Text>
                                    </div>
                                ))}
                            </Descriptions.Item>
                        )}
                        {canInvoice() && (
                            (
                                <Descriptions.Item span={4} label="Facture">
                                    <Button
                                        id="invoiceButton"
                                        loading={invoice.loading && !isPrint}
                                        className="margin-button"
                                        type="primary"
                                        icon="download"
                                        data-contentDisposition="attachement"
                                        onClick={onInvoice}
                                    >
                                        Télécharger
                                    </Button>
                                    <Button
                                        id="sendToEmail"
                                        className="margin-button"
                                        type="primary"
                                        icon="mail"
                                        onClick={onInvoiceByMail}
                                    >
                                        Envoyer par e-mail
                                    </Button>
                                    <Button
                                        id="toPrint"
                                        className="margin-button"
                                        type="primary"
                                        icon="printer"
                                        loading={invoice.loading && isPrint}
                                        onClick={onInvoicePrint}
                                        data-contentDisposition="inline"
                                    >
                                        Imprimer
                                    </Button>
                                </Descriptions.Item>
                            )
                        )}
                        <Descriptions.Item span={4} label="Actions">
                            {canCancelOrRefund() && (
                                <>
                                {renderCancelOrderBtn()}
                                {renderRefundBtn()}
                                </>
                            )}
                            <LogsDrawer />
                        </Descriptions.Item>
                    </Descriptions>
                    <CounterPaiementForm
                        isVisible={isFormVisible}
                        paiementMode={selectedPaimentMode}
                        counterPaiementId={selectedCounterPaiementId}
                        onClose={closePaiementForm}
                        orderId={getOrderId()}
                        leftToPay={leftToPay}

                    />

                    <RefundForm
                        orderId={orderDetails.data.id}
                        isVisible={isRefundFormVisible}
                        orderPrice={Number(getOrderPrice())}
                        formatCurrency={formatCurrency}
                        onClose={closeRefundForm}
                        onSubmit={onRefundFormSubmit}
                    />
                    <hr />
                    <h1>Produits commandés</h1>
                    <hr />
                    <Table
                        columns={columns}
                        dataSource={orderDetails.data.cart.cartItems}
                        rowKey={rowKey}
                    />
                </>
            )}
        </Spin>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    invoice: invoiceState(state),
    invoiceByMail: invoiceByMailState(state),
    orderDetails: getOrderDetailsState(state),
    ordersList: getOrdersListState(state),
    siteIdState: getSelectedParcState(state),
    updates: updateOrderState(state),
    counterPaiementState: getCounterPaimentState(state),
    updateCounterPaiementState: getUpdateCounterPaiementState(state),
    createCounterPaiementState: getcreationCounterPaiementState(state),
    deleteCounterPaiementState: getDeleteCounterPaiementState(state),
    user: getUser(state),
    listRefundsState: getListRefundsState(state),
    createRefundState: getCreateRefundState(state),
});

export default connect(
    mapStateToProps,
    {
        getDetails: OrderActions.details,
        getInvoice: OrderActions.invoice,
        getInvoiceByMail: OrderActions.invoiceByMail,
        updateOrder: OrderActions.update,
        getCounterPaiementList: OrderActions.listCounterPaiement,
        getRefundsList: OrderActions.listRefunds,
    },
)(Order);
