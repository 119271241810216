import React, { FC, useCallback, useEffect, useState } from 'react';
import { Button, Form, message, Modal, Select, Tag } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import { FormComponentProps } from 'antd/lib/form';
import * as DashboardActions from '../../store/actions/dashboard';
import { DashboardState, getProdReportState } from '../../store/reducers/dashboard';
import * as MealTypesActions from '../../store/actions/mealTypes';
import { getMealTypesData } from '../../store/reducers/mealTypes';
import { MainReducerState } from '../../store/reducers';
import { usePrevious } from '../../utils';
import { downloadFile } from '../../utils/downloadFile';
import { MealType } from '../../store/api/apiTypes';

export interface ProdReportModalProps extends FormComponentProps {
    siteId: number | null | undefined;
    display: boolean;
    setDisplay: (display: boolean) => void;
    getProdReport: typeof DashboardActions.getProdReport;
    prodReportState: DashboardState['getProdReport'];
    getMealTypes: typeof MealTypesActions.list;
    mealTypesListData: MealType[];
}

const ProdReportModal: FC<ProdReportModalProps> = ({
    siteId,
    display,
    setDisplay,
    form,
    getProdReport,
    prodReportState,
    getMealTypes,
    mealTypesListData,
}) => {
    const { Option } = Select;

    const closeModal = useCallback(() => {
        setDisplay(false);
    }, [setDisplay]);

    const j = moment().endOf('day').toISOString();
    const jPlusOne = moment().add(1, 'days').endOf('day').toISOString();

    const { getFieldDecorator } = form;

    const [mealTypes, setMealTypes] = useState<MealType[]>([]);
    const onSubmit = (e?: React.FormEvent) => {
        if (e) {
            e.preventDefault();
        }
        form.validateFieldsAndScroll(async (err, val) => {
            if (err) {
                return;
            }
            getProdReport(siteId, {
                category: mealTypes.map((item) => item.id),
                end: moment(val.end).format('YYYY-MM-DD'),
            });
        });
    };

    const onTagClose = (id: number) => () => {
        setMealTypes(mealTypes.filter((c) => c.id !== id));
    };

    const prevProps = usePrevious<Partial<ProdReportModalProps>>({
        prodReportState,
    });

    useEffect(() => {
        getMealTypes();
    }, [getMealTypes]);

    useEffect(() => {
        if (prevProps && prevProps.prodReportState && prevProps.prodReportState.loading &&
            !prodReportState.loading && prodReportState.success && prodReportState.data
        ) {
            if (prodReportState.data.data && prodReportState.data.headers && prodReportState.data.headers.filename) {
                downloadFile(prodReportState.data.data, prodReportState.data.headers.filename);
            } else {
                message.error('Problème avec le fichier ou le nom du fichier.');
                console.warn(
                    'caState.success data, headers',
                    prodReportState.data.data,
                    prodReportState.data.headers,
                );
            }
            closeModal();
        }
    }, [prodReportState, prevProps, closeModal]);

    useEffect(() => {
        setMealTypes(mealTypesListData);
    }, [mealTypesListData]);

    return (
        <Modal
            title="Rapport de production"
            centered
            onCancel={closeModal}
            visible={display}
            width={1000}
            footer={null}
        >
            {mealTypes.map((item) => (
                <Tag key={item.id} closable={true} onClose={onTagClose(item.id)}>{item.label.fr}</Tag>
            ))}
            <Form
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                autoComplete="off"
                onSubmit={onSubmit}
            >
                <Form.Item
                    label="Fin"
                >
                    {getFieldDecorator('end', {
                        rules: [{
                            required: true,
                            message: 'champ requis',
                        }],
                    })(
                        (
                        <Select
                            placeholder="Sélectionner une date de fin"
                            defaultValue={j}
                        >
                            <Option value={j}>j</Option>
                            <Option value={jPlusOne}>j+1</Option>
                        </Select>
                        ),
                        )}
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={prodReportState.loading}>
                        Télécharger
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

const ProdReportModalDrawer = Form.create<ProdReportModalProps>()(ProdReportModal);

const mapStateToProps = (state: MainReducerState) => ({
    prodReportState: getProdReportState(state),
    mealTypesListData: getMealTypesData(state),
});

export default connect(mapStateToProps, {
    getProdReport: DashboardActions.getProdReport,
    getMealTypes    : MealTypesActions.list,
})(ProdReportModalDrawer);
