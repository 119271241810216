import { call, takeLatest, put } from 'redux-saga/effects';

import * as reduxActions from '../actions/productCategories';
import * as reduxTypes from '../types/productCategories';
import * as api from '../api/productCategories';

export function* listSaga(action: any) {
    try {
        const response = yield call(api.list);
        return yield put(reduxActions.listSuccess(response));
    } catch (error) {
        return yield put(reduxActions.listFailed(error));
    }
}

export default function* productCategoriesSaga() {
    yield takeLatest(reduxTypes.LIST, listSaga);
}
