import { ActionCreator } from 'redux';
import { DataAction, QueryListAction, QueryListPayload } from '../actions';
import * as reduxTypes from '../types/presales';
import { PreSale } from '../api/apiTypes';

export const list: ActionCreator<QueryListAction> = (payload: QueryListPayload) => ({
    type: reduxTypes.LIST,
    ...payload,

});

export const listSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_SUCCESS,
    data,
});

export const listFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_FAIL,
    data,
});

export const listSelect: ActionCreator<QueryListAction> = (payload: QueryListPayload) => ({
    type: reduxTypes.LIST_SELECT,
    ...payload,
});

export const listSelectSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_SELECT_SUCCESS,
    data,
});

export const listSelectFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_SELECT_FAIL,
    data,
});

export const create: ActionCreator<DataAction> = (payload: QueryListAction, data: any) => ({
    type: reduxTypes.CREATE,
    ...payload,
    data,
});

export const createSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.CREATE_SUCCESS,
    data,
});

export const createFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.CREATE_FAIL,
    data,
});

export const details: ActionCreator<QueryListAction> = (id: PreSale['id'], payload: QueryListPayload) => ({
    type: reduxTypes.DETAILS,
    ...payload,
    id,
});

export const detailsSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.DETAILS_SUCCESS,
    data,
});

export const detailsFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.DETAILS_FAIL,
    data,
});
export const remove: ActionCreator<QueryListAction> = (id: PreSale['id']) => ({
    type: reduxTypes.REMOVE,
    id,
});

export const removeSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.REMOVE_SUCCESS,
    data,
});

export const removeFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.REMOVE_FAIL,
    data,
});

export const update: ActionCreator<DataAction> = (id: PreSale['id'], payload: QueryListAction, data: any) => ({
    type: reduxTypes.UPDATE,
    id,
    ...payload,
    data,
});

export const updateSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.UPDATE_SUCCESS,
    data,
});

export const updateFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.UPDATE_FAIL,
    data,
});
