export const LIST = 'menus/LIST';
export const LIST_SUCCESS = 'menus/LIST_SUCCESS';
export const LIST_FAIL = 'menus/LIST_FAIL';

export const DETAILS = 'menus/DETAILS';
export const DETAILS_SUCCESS = 'menus/DETAILS_SUCCESS';
export const DETAILS_FAIL = 'menus/DETAILS_FAIL';

export const UPDATE = 'menus/UPDATE';
export const UPDATE_SUCCESS = 'menus/UPDATE_SUCCESS';
export const UPDATE_FAIL = 'menus/UPDATE_FAIL';
export const RESET_SUCCESS = 'menus/RESET_SUCCESS';
