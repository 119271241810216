import React, { FC, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Descriptions, Button, Spin, Icon } from 'antd';
// tslint:disable-next-line: no-implicit-dependencies
import { RouteChildrenProps } from 'react-router';
import moment from 'moment';

import { MainReducerState } from '../../store/reducers';
import * as UserActions from '../../store/actions/users';
import { getUserDetailsState, UsersState } from '../../store/reducers/users';
import { ParcsState, getSelectedParcState } from '../../store/reducers/parcs';

import { UserEdit } from '.';

interface MatchParams {
    id: string;
}

interface UserProps extends RouteChildrenProps<MatchParams> {
    usersState: UsersState['details'];
    getDetails: typeof UserActions.details;
    siteIdState: ParcsState['selected'];
}

const User: FC<UserProps> = ({ match, usersState, getDetails, siteIdState }) => {
    const [isFormVisible, setIsFormVisible] = useState(false);

    useEffect(() => {
        getDetails(match ? parseInt(match.params.id, 10) : null);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        getDetails(match ? parseInt(match.params.id, 10) : null);
    }, [siteIdState]); // eslint-disable-line react-hooks/exhaustive-deps

    const onUpdateSuccess = () => {
        getDetails(match ? parseInt(match.params.id, 10) : null);
    };

    const onDrawerClose = () => {
        setIsFormVisible(false);
    };

    const setEditMode = () => {
        setIsFormVisible(true);
    };

    return (
        <>
            <div className="list-header">
                <h1 className="list-header-title">Détails de l'employé</h1>
                <Button
                    type="primary"
                    shape="round"
                    icon="edit"
                    onClick={setEditMode}
                    className="modifier-button"
                >
                    Modifier
                </Button>
            </div>
            <Spin spinning={usersState.loading} tip="Chargement...">
                <Descriptions bordered>
                    <Descriptions.Item label="Id">
                        {usersState.data ? usersState.data.id : null}
                    </Descriptions.Item>
                    <Descriptions.Item label="Prénom">
                        {usersState.data ? usersState.data.firstName : null}
                    </Descriptions.Item>
                    <Descriptions.Item label="Nom">
                        {usersState.data ? usersState.data.lastName : null}
                    </Descriptions.Item>
                    <Descriptions.Item label="Rôle">
                        {usersState.data && usersState.data.role ? usersState.data.role : null}
                    </Descriptions.Item>
                    <Descriptions.Item label="Login">
                        {usersState.data && usersState.data.login ? usersState.data.login : ''}
                    </Descriptions.Item>
                    <Descriptions.Item label="Id parc">
                        {usersState.data && usersState.data.siteId !== null ?
                            usersState.data.siteId : <Icon type="smile" />}
                    </Descriptions.Item>
                    <Descriptions.Item label="Crée">
                        {usersState.data && usersState.data.creationDate ?
                            moment(usersState.data.creationDate).format('DD/MM/YYYY') : null}
                    </Descriptions.Item>
                    <Descriptions.Item label="Téléphone">
                        {usersState.data && usersState.data.phone ? usersState.data.phone : ''}
                    </Descriptions.Item>
                    {/* <Descriptions.Item label="Contact">
                        {usersState.data && usersState.data.contact ?
                        usersState.data.contact : ''}
                    </Descriptions.Item> */}
                    {/* <Descriptions.Item label="Mot de passe">
                        {usersState.data && usersState.data.password ? usersState.data.password : ''}
                    </Descriptions.Item> */}
                </Descriptions>
                {(
                    <UserEdit
                        id={usersState.data ? usersState.data.id : undefined}
                        siteId={siteIdState}
                        onUpdateSuccess={onUpdateSuccess}
                        isVisible={isFormVisible}
                        onClose={onDrawerClose}
                    />
                )}
            </Spin>
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    usersState: getUserDetailsState(state),
    siteIdState: getSelectedParcState(state),
});

export default connect(
    mapStateToProps,
    {
        getDetails: UserActions.details,
    },
)(User);
