import { ActionCreator } from 'redux';
import { DataAction, QueryListPayload, QueryListAction } from '.';
import * as reduxTypes from '../types/dashboard';
import { OrderReportPayload, ProdReportPayload } from '../types/report';

export const fetch: ActionCreator<QueryListAction> = (payload: QueryListPayload) => ({
    type: reduxTypes.FETCH,
    ...payload,
});

export const fetchSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.FETCH_SUCCESS,
    data,
});

export const fetchFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.FETCH_FAIL,
    data,
});

export const getCa: ActionCreator<QueryListAction> = (payload: QueryListPayload) => ({
    type: reduxTypes.GET_CA,
    ...payload,
});

export const getCaSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.GET_CA_SUCCESS,
    data,
});

export const getCaFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.GET_CA_FAIL,
    data,
});

export const getDiscounts: ActionCreator<QueryListAction> = (payload: QueryListPayload) => ({
    type: reduxTypes.GET_DISCOUNTS,
    ...payload,
});

export const getDiscountsSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.GET_DISCOUNTS_SUCCESS,
    data,
});

export const getDiscountsFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.GET_DISCOUNTS_FAIL,
    data,
});

export const getDeliveredTurnover: ActionCreator<QueryListAction> = (payload: QueryListPayload) => ({
    type: reduxTypes.GET_DELIVERED_TURNOVER,
    ...payload,
});

export const getDeliveredTurnoverSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.GET_DELIVERED_TURNOVER_SUCCESS,
    data,
});

export const getDeliveredTurnoverFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.GET_DELIVERED_TURNOVER_FAIL,
    data,
});

export const getAverageCartSize: ActionCreator<QueryListAction> = (payload: QueryListPayload) => ({
    type: reduxTypes.GET_AVERAGE_CART_SIZE,
    ...payload,
});

export const getAverageCartSizeSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.GET_AVERAGE_CART_SIZE_SUCCESS,
    data,
});

export const getAverageCartSizeFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.GET_AVERAGE_CART_SIZE_FAIL,
    data,
});

export const getTop10Products: ActionCreator<QueryListAction> = (payload: QueryListPayload) => ({
    type: reduxTypes.GET_TOP10_PRODUCTS,
    ...payload,
});

export const getTop10ProductsSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.GET_TOP10_PRODUCTS_SUCCESS,
    data,
});

export const getTop10ProductsFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.GET_TOP10_PRODUCTS_FAIL,
    data,
});

export const getNotCollected: ActionCreator<QueryListAction> = (payload: QueryListPayload) => ({
    type: reduxTypes.GET_NOT_COLLECTED,
    ...payload,
});

export const getNotCollectedSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.GET_NOT_COLLECTED_SUCCESS,
    data,
});

export const getNotCollectedFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.GET_NOT_COLLECTED_FAIL,
    data,
});

export const getDeliveryTimeRanking: ActionCreator<QueryListAction> = (payload: QueryListPayload) => ({
    type: reduxTypes.GET_DELIVERY_TIME_RANKING,
    ...payload,
});

export const getDeliveryTimeRankingSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.GET_DELIVERY_TIME_RANKING_SUCCESS,
    data,
});

export const getDeliveryTimeRankingFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.GET_DELIVERY_TIME_RANKING_FAIL,
    data,
});

export const getMonthlyExport: ActionCreator<QueryListAction> = (payload: QueryListPayload) => ({
    type: reduxTypes.GET_MONTHLY_EXPORT,
    ...payload,
});

export const getMonthlyExportSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.GET_MONTHLY_EXPORT_SUCCESS,
    data,
});

export const getMonthlyExportFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.GET_MONTHLY_EXPORT_FAIL,
    data,
});

export const getOrderReport: ActionCreator<DataAction> = (id: number, data: OrderReportPayload) => ({
    type: reduxTypes.GET_ORDER_REPORT,
    id,
    data,
});

export const getOrderReportSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.GET_ORDER_REPORT_SUCCESS,
    data,
});

export const getOrderReportFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.GET_ORDER_REPORT_FAIL,
    data,
});

export const getProdReport: ActionCreator<DataAction> = (id: number, data: ProdReportPayload) => ({
    type: reduxTypes.GET_PROD_REPORT,
    id,
    data,
});

export const getProdReportSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.GET_PROD_REPORT_SUCCESS,
    data,
});

export const getProdReportFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.GET_PROD_REPORT_FAIL,
    data,
});
